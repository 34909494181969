import React from 'react';
import BlockwiseDashboardHOC from 'screens/blockwise/BlockwiseDashboardHOC';
import CommunityAsksDashboard from 'screens/community-asks/CommunityAsksDashboard';
import CommunitySurveyDashboardHOC from 'screens/community-survey/CommunitySurveyDashboardHOC';
import CustomerExperienceDashboard from 'screens/customer-experience/CustomerExperienceDashboard';
import LocalFeatureSelection from 'screens/local-selection/LocalFeatureSelection';
import PulseDashboardHOC from 'screens/pulse/PulseDashboardHOC';
import { Client } from 'types/client';
import { User } from 'types/user';
import { isPathIncluded } from 'utils/utils';
import { config } from '../config/config';

interface Props {
  user: User;
  client: Client;
}

export const DashboardSelection = (props: Props) => {
  const { user, client } = props;
  const currentUrl = window.location.href;

  if (isPathIncluded(config.communitySurveys.baseRouterUrl, currentUrl)) {
    return <CommunitySurveyDashboardHOC user={user} client={client} />;
  }

  if (isPathIncluded(config.blockwise.baseRouterUrl, currentUrl)) {
    return <BlockwiseDashboardHOC user={user} client={client} />;
  }

  if (isPathIncluded(config.communityAsks.baseRouterUrl, currentUrl)) {
    return <CommunityAsksDashboard user={user} client={client} />;
  }

  if (isPathIncluded(config.customerExperience.baseRouterUrl, currentUrl)) {
    return <CustomerExperienceDashboard user={user} client={client} />;
  }

  if (isPathIncluded(config.pulse.baseRouterUrl, currentUrl)) {
    return <PulseDashboardHOC user={user} client={client} />;
  }

  return <LocalFeatureSelection />;
};
