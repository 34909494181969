import { HorizontalSeparator, Icon } from '@zencity/common-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GraphEmptyState.module.scss';

/**
 * When there are < 2 completed survey cycles, this will be displayed for the
 * overall score over time widget.
 */
export const GraphEmptyState: React.FC = () => {
  const { t: translate } = useTranslation();
  return (
    <div className={styles.emptyStateGraphSection}>
      <div className={styles.topBoundary}>
        <span>100</span>
        <HorizontalSeparator />
      </div>
      <div className={styles.graph}>
        <Icon name="survey-data.svg" width={45} height={45} />
        <p className={styles.notEnoughDataMessage}>{translate('scoreCard.moreDataNeeded')}</p>
      </div>
      <div className={styles.graphBottomWrapper}>
        <div className={styles.bottomBoundary}>
          <span>0</span>
          <HorizontalSeparator />
        </div>
        <div className={styles.legend} />
      </div>
    </div>
  );
};
