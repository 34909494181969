/* eslint-disable max-statements */
/* eslint-disable id-denylist */
import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js';
import { DateRangeContext } from 'contexts/DateRangeContext';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ScorePerDateRange } from 'types/score';
import { ChartJsDataset, convertDateRangeToLabels } from 'utils/chartWidget';
import { GraphEmptyState } from '../GraphEmptyState/GraphEmptyState';
import { getOverallScoresChartConfig } from './helper';
import styles from './OverallScoreGraph.module.scss';

Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Tooltip);

interface Props {
  overallScores: ScorePerDateRange[];
  disableTooltip?: boolean;
}

/**
 * This graph display the overall score over a set of periods.
 */
export const OverallScoreGraph: React.FC<Props> = (props: Props) => {
  const { overallScores, disableTooltip } = props;
  const { clientCadence } = useContext(DateRangeContext);
  const [hasError, setHasError] = useState(false);
  const chartConfig = getOverallScoresChartConfig(overallScores, disableTooltip);

  const chartData = useMemo(() => {
    // An array of date range labels.
    const dateRangeXAxisLabels: string[][] = [];
    const datasets: ChartJsDataset[] = [];
    const scoreData: number[] = [];

    try {
      overallScores.forEach((overallScore) => {
        const { scores, startDate, endDate } = overallScore;
        scoreData.push(scores ? scores.positive : 0);
        const dateChartLabel = convertDateRangeToLabels({ startDate, endDate }, clientCadence as string).split(' ');
        dateRangeXAxisLabels.push(dateChartLabel);
      });
    } catch (error) {
      setHasError(true);
    }

    datasets.push({
      label: '',
      data: scoreData,
      backgroundColor: styles.green,
      borderColor: styles.green,
      pointRadius: 3,
    });

    return { labels: dateRangeXAxisLabels, datasets };
  }, [clientCadence, overallScores]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (canvasRef.current) {
      const config = {
        ...chartConfig,
        data: chartData,
      };

      chartRef.current = new Chart(canvasRef.current, config);
      return () => {
        chartRef.current?.destroy();
      };
    }
  }, [chartData, chartConfig]);

  if (!clientCadence) {
    return <></>;
  }

  return (
    <>
      {hasError && <GraphEmptyState />}
      {!hasError && (
        <div className={styles.overallScoreGraph}>
          <canvas ref={canvasRef} />
        </div>
      )}
    </>
  );
};
