import React, { ReactElement } from 'react';
import styles from './MenuOption.module.scss';

export interface Option {
  id: number;
  text: string;
}

interface Props {
  option: Option;
  isSelected?: boolean;
  description?: string;
  onClick: (index: number) => void;
}

/**
 * A menu item (button) in the vertical menu
 * @param {object} props - option: Option - the menu's content and id
 * @param {object} props - isSelected: boolean - whether the menu item has been clicked on
 * @param {object} props - onClick: function - what action should be done when clicking on a menu item
 * @param {object} props - description: string - extra information about the menu item
 */
const MenuOption = ({ option, isSelected = false, description, onClick }: Props): ReactElement => {
  const classList = `${styles.menuOption} ${isSelected ? styles.selected : ''}`;
  return (
    <>
      <button className={classList} type="button" onClick={(): void => onClick(option.id)}>
        {option.text}
      </button>
      {description && <span>{description}</span>}
    </>
  );
};
export default MenuOption;
