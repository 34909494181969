import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { CxServiceTypeData } from 'types/cx_survey';
import { Nullable } from 'types/misc';
import styles from '../Dashboard.module.scss';
import { CXSatisfactionDistribution } from './CXSatisfactionDistribution';

interface Props {
  serviceTypes: CxServiceTypeData[];
  serviceTypeIdToFilterBy: Nullable<number>;
}

export const ServiceTypesScoresTable: React.FC<Props> = function ServiceTypesScoresTable(props): ReactElement {
  const { serviceTypes, serviceTypeIdToFilterBy } = props;

  serviceTypes.sort((first, second) => {
    if (!first.score || !second.score) {
      // Sometimes for some service types there won't be a score due to
      // filtering.
      return -1;
    }

    if (first.score.average_score < second.score.average_score) {
      return 1;
    }
    if (first.score.average_score > second.score.average_score) {
      return -1;
    }
    return 0;
  });

  return (
    <>
      {serviceTypes.length > 0 ? (
        <ol className={styles.serviceTypesList}>
          {serviceTypes.map((serviceType) => (
            <li
              className={classnames(styles.serviceTypeRow, {
                [styles.isFilteredBy]: serviceTypeIdToFilterBy ? serviceType.id !== serviceTypeIdToFilterBy : false,
              })}
              data-testid="breakdown-row"
              key={serviceType.id}
            >
              <p>{serviceType.label}</p>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {serviceType.score && (
                <CXSatisfactionDistribution
                  score={serviceType.score.average_score}
                  positive={serviceType.score.satisfaction_distribution.positive}
                  negative={serviceType.score.satisfaction_distribution.negative}
                  neutral={serviceType.score.satisfaction_distribution.neutral}
                />
              )}
            </li>
          ))}
        </ol>
      ) : (
        <div>No data yet</div>
      )}
    </>
  );
};
