/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { ZCDButton } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { Spacer } from 'components/Spacer/Spacer';
import { AnalyticWidgetNames } from 'constants/analytics';
import { TabKeys } from 'constants/tabs';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { MapsContext } from 'contexts/MapsContext';
import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import { isFeedLatestResult } from 'utils/dateRange';
import styles from './AspectWidget.module.scss';
import { AspectWidgetTabs } from './AspectWidgetTabs/AspectWidgetTabs';
import { DemographicGroups } from './DemographicGroups/DemographicGroups';
import { GeographicBreakdown } from './GeographicBreakdown/GeographicBreakdown';
import { OverallSatisfaction } from './OverallSatisfaction/OverallSatisfaction';
import { useAspectWidgetData } from './useAspectWidgetData';
import { useDivisionWithScoreByAspect } from './useDivisionWithScoreByAspect';
import { useDemographicTypesFilter } from './useDemographicTypesFilter';

export const AspectWidget = (): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState<number>();
  const { t: translate } = useTranslation();
  const { selectedDateRange, comparisonDateRange, completedCyclesDateRanges } = useContext(DateRangeContext);
  const { setAreasWithScoresData } = useContext(MapsContext);

  const navigate = useNavigate();
  const activeFilters = useFilterNames();
  const isLatestResult = isFeedLatestResult(selectedDateRange);
  // In case selected date range is latest result, we need to get the first completed cycle date range.
  const selectedCycle = isLatestResult
    ? completedCyclesDateRanges?.[0]
    : completedCyclesDateRanges?.find(
        (dateRange) =>
          dateRange?.startDate === selectedDateRange?.startDate && dateRange?.endDate === selectedDateRange?.endDate,
      );
  const { widgetData: selectedDateWidgetData, loading } = useAspectWidgetData({
    startDate: selectedCycle?.startDate,
    endDate: selectedCycle?.endDate,
  });
  const { widgetData: comparisonDateWidgetData } = useAspectWidgetData({
    startDate: comparisonDateRange?.startDate,
    endDate: comparisonDateRange?.endDate,
  });

  const divisionWithScoreByAspect = useDivisionWithScoreByAspect({
    selectedDateWidgetData,
    activeTab,
  });

  const { filterData: demographicFilter } = useDemographicTypesFilter();

  const handleRedirectToResults = () => {
    navigate(`/${TabKeys.ANALYSIS}`);

    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('blockwiseSummaryTab.aspectWidget.exploreTheData'),
      widgetName: AnalyticWidgetNames.RESULTS_BY_QUESTION_WIDGET,
      activeFilters,
    });
  };

  useEffect(() => {
    const firstAspectId = selectedDateWidgetData?.aspect_scores[0]?.aspect_id;
    if (!firstAspectId && firstAspectId !== 0) return;
    // Setting initial active tab to the first aspect.
    setActiveTab(selectedDateWidgetData?.aspect_scores[0]?.aspect_id);
  }, [selectedDateWidgetData]);

  useEffect(() => {
    if (!divisionWithScoreByAspect) return;
    // Setting the Division scores per Aspect. These will be used in the Summary Tab, unlike the Map tab, which sets Division scores per Question.
    setAreasWithScoresData(divisionWithScoreByAspect);
  }, [divisionWithScoreByAspect, setAreasWithScoresData]);

  const handleTabChange = (aspectId: number) => {
    setActiveTab(aspectId);
  };

  return (
    <div className={styles.aspectWidget}>
      <Flex justifyContent="space-between">
        <span className={styles.title}>{translate('blockwiseSummaryTab.aspectWidget.title')}</span>
        <ZCDButton
          text={translate('blockwiseSummaryTab.aspectWidget.exploreTheData')}
          postIcon="arrow-right"
          variant="secondary"
          onClick={handleRedirectToResults}
        />
      </Flex>
      <Spacer orientation="vertical" size="small" />
      <LoaderMask isLoading={loading}>
        <SummaryAspectWidgetContext.Provider
          value={{
            selectedDateWidgetData,
            comparisonDateWidgetData,
            handleTabChange,
            activeTab,
            demographicTypes: demographicFilter,
          }}
        >
          <AspectWidgetTabs />
          <div className={styles.widgetsGrid}>
            <OverallSatisfaction />
            <DemographicGroups />
            <GeographicBreakdown />
          </div>
        </SummaryAspectWidgetContext.Provider>
      </LoaderMask>
    </div>
  );
};
