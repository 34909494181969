import React from 'react';
import styles from './StatusIndicatorDot.module.scss';

export enum StatusIndicatorDotTypes {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  NON_SUBSTANTIATIVE = 'nonSubstantiative',
}

interface Props {
  type: StatusIndicatorDotTypes;
}
/* 
    This component display status colored circle according to the type.
 */
export const StatusIndicatorDot = ({ type }: Props) => <span className={`${styles.dot} ${styles[type]}`} />;
