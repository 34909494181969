import { Cadence } from '@zencity/survey-types';
import { ChartConfiguration } from 'chart.js';
import { format, parseISO } from 'date-fns';
import { DateRange } from 'types/dateRange';
import { hex, score } from 'wcag-contrast';

export interface ChartJsDataset {
  label: string;
  // eslint-disable-next-line id-denylist
  data: number[];
  backgroundColor: string;
  borderColor: string;
  pointRadius: number | ((context: unknown) => number);
}

/**
 * Generates a random foreground color that is accessible according to WCAG 2.0 Guidelines.
 * This foreground is specific to a white background #fff.
 */
export const getWcagAccessibleColors = (amount: number): string[] => {
  const colors: string[] = [];
  while (colors.length < amount) {
    const randomColor = `#${Math.floor(Math.random() * (0xffffff + 1))
      .toString(16)
      .padStart(6, '0')}`;
    if (!colors.includes(randomColor) && score(hex('#fff', randomColor)) === 'AA') colors.push(randomColor);
  }
  return colors;
};

/**
 * The ChartJS config for a line chart. As seen in the Overall Score widget
 * and the Maps widget.
 */
export const chartConfig: Omit<ChartConfiguration, 'data'> = {
  type: 'line',
  options: {
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false, // This option disables tooltips.
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#6d6d6d',
          font: {
            size: 10,
            family: 'Inter',
          },
          align: 'inner',
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          font: {
            size: 10,
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 0,
      },
    },
  },
};

/**
 * Find the numerical month difference between two dates.
 */
export const calculateDifferenceBetweenMonths = (startDate: Date, endDate: Date): number =>
  endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());

export enum DateLabelFormat {
  ABBREVIATED_MONTH_AND_YEAR = 'MMM yyyy',
  ABBREVIATED_MONTH = 'MMM',
  ABBREVIATED_DAY_FOR_WEEK = 'MMM dd',
}

/**
 * Converts date ranges to chart labels, based off of the dates monthly difference for cadence.
 */
export const convertDateRangeToLabels = (dateRange: DateRange, clientCadence: string): string => {
  // Calculate whether the client cadence is semi-annually, monthly, or quarterly.
  const startDate = parseISO(dateRange.startDate);
  const endDate = parseISO(dateRange.endDate);
  switch (clientCadence) {
    case Cadence.MONTHLY:
      return format(startDate, DateLabelFormat.ABBREVIATED_MONTH_AND_YEAR).toUpperCase();
    case Cadence.SEMI_ANNUALLY:
    case Cadence.QUARTERLY:
    case Cadence.YEARLY:
      return `${format(startDate, DateLabelFormat.ABBREVIATED_MONTH)}-${format(
        endDate,
        DateLabelFormat.ABBREVIATED_MONTH_AND_YEAR,
      )}`.toUpperCase();
    default:
      throw new Error('Could not match the dates cadence to a client cadence.');
  }
};
