/* eslint-disable i18next/no-literal-string */
import { Button, ZCDSpinner } from '@zencity/common-ui';
import { SurveyType, SurveyGroup } from '@zencity/survey-types';
import { xlsIconSvg } from 'assets/svgIcons';
import { CommunityAsksToastContext } from 'contexts/CommunityAsksToastContext';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { exportSubmissionsBySurveyGroup } from 'services/exportSubmissions';
import { logger } from 'utils/community-asks/logger';
import styles from './ExportSubmissions.module.scss';

interface Props {
  surveyGroup: SurveyGroup;
  closeActionsDropdown: () => void;
}

export const ExportSubmissions = (props: Props): ReactElement => {
  const { surveyGroup, closeActionsDropdown } = props;
  const { t: translate } = useTranslation();
  const { toastError } = useContext(CommunityAsksToastContext);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line max-statements
  const handleOnClick = async () => {
    setIsLoading(true);
    try {
      await exportSubmissionsBySurveyGroup({ survey_group: surveyGroup.id, format: 'xlsx' });
      setIsLoading(false);
      closeActionsDropdown();
      if (surveyGroup.type === SurveyType.COMMUNITY_ASKS) {
        analyticsService.communityAsksEvents.resultsExport();
      } else if (surveyGroup.type === SurveyType.CUSTOMER_EXPERIENCE) {
        analyticsService.customerExperienceEvents.resultsExport();
      }
    } catch (error) {
      toastError(translate('communityAsks.toastMessages.exportSubmissionsFailure'));
      logger.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.exportSubmissionsWrapper}>
      <Button
        type="button"
        disabled={isLoading}
        onClick={handleOnClick}
        variant="link"
        size="md"
        className={styles.exportSubmissionsButton}
      >
        {xlsIconSvg}
        {translate('communityAsks.shareResults.exportRawData')}
      </Button>
      {isLoading && (
        <div className={styles.spinnerWrapper}>
          <ZCDSpinner size={18} />
        </div>
      )}
    </div>
  );
};
