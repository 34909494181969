import { Answer } from '@zencity/survey-types';
import { LeadingTopic } from 'types/leadingTopics';

export interface SurveyState {
  /**
   * Are we currently fetching data from the backend?
   */
  loading: boolean;
  /**
   * The selected question
   */
  currentQuestion: number;
  /**
   * All answers for the selected question
   */
  answers: Answer[];
  /**
   * Current page of answers that's been loaded
   */
  currentPage: number;
  /**
   * Total number of answers
   */
  totalAnswers: number;
  /**
   * Leading topics (used in the leading topics widget)
   */
  leadingTopics: LeadingTopic[];
  /**
   * Loading leading topics.
   */
  loadingLeadingTopics: boolean;
}

export const initialData: SurveyState = {
  loading: true,
  loadingLeadingTopics: true,
  answers: [],
  leadingTopics: [],
  currentPage: 1,
  currentQuestion: 0,
  totalAnswers: 0,
};
