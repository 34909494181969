import classNames from 'classnames';
import React, { useState, useEffect, ReactElement } from 'react';
import styles from '../SatisfactionDistribution.module.scss';

export enum PercentageSize {
  SMALL = 'small',
  REGULAR = 'regular',
  LARGE = 'large',
}

interface Props {
  positivePercentage: number;
  size: PercentageSize;
}

export const PercentageDisplay = (props: Props): ReactElement => {
  const { positivePercentage, size } = props;
  const [percentageDisplay, setPercentageDisplay] = useState<string | null>(null);

  useEffect(() => {
    const percentage = `${Math.round(positivePercentage)}%`;
    setPercentageDisplay(percentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positivePercentage]);

  return (
    // eslint-disable-next-line i18next/no-literal-string
    <h4 className={classNames(styles.percentageDisplay, styles[size])} data-testid="percentage-display">
      {percentageDisplay}
    </h4>
  );
};
