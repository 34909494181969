// we ignore naming convention so we can tie between the demographic choices response
// and the mapped demographics filter options
export enum DemographicFilters {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Age = 'age_group',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Gender = 'gender',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Ethnicity = 'ethnicity',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Education = 'education',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Income = 'income',
}

interface DemographicFilterOptions {
  id: number;
  label: string;
}

export type DemographicsChoicesResponse = Record<keyof typeof DemographicFilters, DemographicFilterOptions[]>;
export type MappedDemographicFiltersType = Record<DemographicFilters, string>;

interface DemographicTypesFilterOption {
  value: DemographicFilters;
  label: string;
}

export type SurveyGroupDemographicTypes = Record<DemographicFilters, DemographicTypesFilterOption>;
