// TODO: remove disable
import React, { ReactElement } from 'react';
import { TakeAnAction } from 'components/TakeAnAction/TakeAnAction';
import { SurveyType } from '@zencity/survey-types';
import styles from '../Summary.module.scss';

export const NextSteps = (): ReactElement => (
  <section className={styles.nextSteps}>
    <div className={styles.takeAnAction}>
      <TakeAnAction surveyType={SurveyType.COMMUNITY_SURVEY} />
    </div>
  </section>
);
