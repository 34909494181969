import { SurveyType } from '@zencity/survey-types';
import React from 'react';

export interface QuestionsFeedContextInterface {
  surveyType: SurveyType;
  surveyGroupId?: number;
}

export const QuestionsFeedContext = React.createContext<QuestionsFeedContextInterface>(
  {} as QuestionsFeedContextInterface,
);
