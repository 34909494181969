/* eslint-disable i18next/no-literal-string */
import { DemographicFilter } from 'components/DemographicFilter/DemographicFilter';
import { GeographicDivisionsFilter } from 'components/GeographicDivisionsFilter/GeographicDivisionsFilter';
import { Header } from 'components/Header/Header';
import { Map } from 'components/Map/Map';
import { NoScoresEmptyState } from 'components/NoScoresEmptyState/NoScoresEmptyState';
import { Analysis } from 'components/Analysis/Analysis';
import { TabKeys } from 'constants/tabs';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { MapsContext } from 'contexts/MapsContext';
import { useScreenViewedEvent } from 'hooks/screenViewedEvent';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Summary } from 'screens/community-survey/Summary/Summary';
import { OpenQuestionFeed } from 'screens/OpenQuestionFeed/OpenQuestionFeed';
import { ZencityRoutesProps } from 'types/router';
import { SurveyType, SurveyGroup } from '@zencity/survey-types';
import { logger } from 'utils/community-survey/logger';
import { hasScoreDataForAnyDateRange } from 'utils/dateRange';
import { doAreasHaveEnoughDimensionsToShowMap } from 'utils/Map/helpers';
import { autoRedirectState } from 'utils/router';
import '../../i18n';
import '../../index.scss';

export const getDefaultTab = (hasFeedOnlyAccess: boolean): TabKeys => {
  if (hasFeedOnlyAccess) {
    return TabKeys.FEED;
  }

  // Default, and fallback to the "Summary" tab.
  return TabKeys.SUMMARY;
};

interface Props extends ZencityRoutesProps {
  currentSurveyGroup: SurveyGroup;
}

/*
 * This component generates the Routes for the CommunitySurveyDashboard.
 * We do this using the useRoutes hook with a RouteObject, instead of
 * with the <Routes><Route></Routes> elements format.
 *
 * See an example from the docs here: https://stackblitz.com/github/
 * remix-run/react-router/tree/main/examples/route-objects?file=src%2FApp.tsx
 *
 * Note that we use the useRoutes hook here instead of directly in the
 * CommunitySurveyDashboard component since the BrowserRouter component
 * needs to embed the element that uses useRoutes. It cannot use useRoutes
 * in the same component without throwing an error.
 */

const FILTERS_ALLOWED_TABS = [TabKeys.FEED, TabKeys.ANALYSIS];
export const CommunitySurveyRoutes = ({
  basename,
  currentSurveyGroup,
}: Props): React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> | null => {
  const { dashboardDateRanges = [] } = useContext(DateRangeContext);
  const hasFeedOnlyAccess = !hasScoreDataForAnyDateRange(dashboardDateRanges);
  const { areasWithScoresData, divisions } = useContext(MapsContext);
  const shouldShowMap = doAreasHaveEnoughDimensionsToShowMap(areasWithScoresData) || hasFeedOnlyAccess;
  const clientDefaultTab = getDefaultTab(hasFeedOnlyAccess);
  const displayGeographicDivisionsFilter = divisions.filter((division) => division.depth === 1).length > 1;
  const { t: translate } = useTranslation();
  const [currentTab, setCurrentTab] = useState<TabKeys>(clientDefaultTab);

  useEffect(() => {
    if (window.location.href.includes(TabKeys.FEED)) {
      setCurrentTab(TabKeys.FEED);
    }
  }, []);

  // The Header component will render these tabs in the order they appear here.
  const tabs: TabKeys[] = shouldShowMap
    ? [TabKeys.SUMMARY, TabKeys.FEED, TabKeys.MAP, TabKeys.ANALYSIS]
    : [TabKeys.SUMMARY, TabKeys.FEED, TabKeys.ANALYSIS];

  const routes: RouteObject[] = [
    {
      path: '/',
      element: (
        <Header
          tabs={tabs}
          headerIcon="large-smiley-face-cards"
          currentSurveyGroup={currentSurveyGroup}
          headerTitle={translate('header.communitySurveyTitle')}
          extraFilterComponents={[
            <DemographicFilter isDisabled={!FILTERS_ALLOWED_TABS.includes(currentTab)} key="demographics" />,
            displayGeographicDivisionsFilter ? (
              <GeographicDivisionsFilter
                surveyType={SurveyType.COMMUNITY_SURVEY}
                isDisabled={!FILTERS_ALLOWED_TABS.includes(currentTab)}
                key="divisions"
              />
            ) : null,
          ]}
          onTabSwitch={setCurrentTab}
          shouldDisplaySearchBar={currentTab === TabKeys.FEED}
        />
      ),
      children: [
        { index: true, element: <Navigate to={clientDefaultTab} state={autoRedirectState} /> },
        { path: TabKeys.SUMMARY, element: hasFeedOnlyAccess ? <NoScoresEmptyState /> : <Summary /> },
        { path: TabKeys.FEED, element: <OpenQuestionFeed /> },
        shouldShowMap ? { path: TabKeys.MAP, element: hasFeedOnlyAccess ? <NoScoresEmptyState /> : <Map /> } : {},
        {
          path: TabKeys.ANALYSIS,
          element: hasFeedOnlyAccess ? <NoScoresEmptyState /> : <Analysis />,
        },
      ],
    },
    { path: '*', element: <div>404 - Page Not Found</div> },
  ];

  useScreenViewedEvent(routes, basename, logger);

  return useRoutes(routes);
};
