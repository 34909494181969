import { Checkbox, CheckboxState } from '@zencity/common-ui';
import classNames from 'classnames';
import BreakdownRow from 'components/BreakdownRow';
import { Spacer } from 'components/Spacer/Spacer';
import { AnalyticEventsChangeTypes, AnalyticOriginPropertyValues } from 'constants/analytics';

import { MapsContext } from 'contexts/MapsContext';
import React, { MouseEventHandler, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { analyticsService } from 'services/analytics/analytics';
import { generateAreaFilterValue, generateLevelFilterValue } from 'utils/utils';
import { SatisfactionScore } from '../../../../types/score';
import styles from './SatisfactionScoresByAreaRow.module.scss';

interface Props {
  /** The ID of the polygon area */
  areaId: string;
  /** The name of the polygon area */
  areaName: string;
  /** The satisfaction score for the quarter */
  satisfactionScore?: SatisfactionScore | null;
  /** The corresponding graph area color */
  color: string;
  /** Checked or unchecked default checkbox state */
  defaultCheckboxState?: CheckboxState;
  onMouseEnter?: MouseEventHandler<HTMLLIElement>;
  onMouseLeave?: MouseEventHandler<HTMLLIElement>;
  totalSubmissions?: number;
  comparisonScore?: SatisfactionScore | null;
  totalSubmissionsToCompare?: number;
}

/**
 * The area-satisfaction forw used in SatisfactionScoresByAreaList.
 */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
export const SatisfactionScoresByAreaRow = (props: Props): ReactElement => {
  const {
    areaId,
    areaName,
    satisfactionScore,
    color,
    defaultCheckboxState = CheckboxState.UNCHECKED,
    onMouseEnter,
    onMouseLeave,
    totalSubmissions,
    comparisonScore,
    totalSubmissionsToCompare,
  } = props;
  const checkboxContainerRef = useRef<HTMLDivElement>(null);
  const { selectedAreaIds, setSelectedAreaIds, divisions } = useContext(MapsContext);
  const [checkboxState, setCheckboxState] = useState<CheckboxState>(defaultCheckboxState);

  const handleOnCheckboxClick = () => {
    if (checkboxState === CheckboxState.CHECKED) {
      setSelectedAreaIds(selectedAreaIds.filter((id) => areaId !== id));
      setCheckboxState(CheckboxState.UNCHECKED);
    } else if (checkboxState === CheckboxState.UNCHECKED) {
      setSelectedAreaIds([...selectedAreaIds, areaId]);
      setCheckboxState(CheckboxState.CHECKED);
    }

    const selectedDivision = divisions.find((division) => division.geo_targeting_ref.id === areaId);
    analyticsService.crossProductEvents.mapLevelSelected({
      origin: AnalyticOriginPropertyValues.WIDGET_CLICK,
      areaValue: generateAreaFilterValue(selectedDivision?.geo_targeting_ref?.name),
      filterValue: generateLevelFilterValue(selectedDivision?.depth),
      changeType:
        checkboxState === CheckboxState.CHECKED ? AnalyticEventsChangeTypes.HIDE : AnalyticEventsChangeTypes.SHOW,
    });
  };

  useEffect(() => {
    if (selectedAreaIds.includes(areaId)) {
      setCheckboxState(CheckboxState.CHECKED);
    } else {
      setCheckboxState(CheckboxState.UNCHECKED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAreaIds]);

  return (
    <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <a
        onClick={handleOnCheckboxClick}
        className={classNames(styles.satisfactionScoresByAreaRow, styles[checkboxState])}
        role="button"
      >
        {checkboxState === CheckboxState.CHECKED && (
          <div className={styles.areaColor} style={{ backgroundColor: color }} />
        )}
        <div className={classNames(styles.rowContent, styles.satisfactionScoresByAreaRow)}>
          <div className={classNames(styles.checkboxContainer, styles[checkboxState])} ref={checkboxContainerRef}>
            <Checkbox state={checkboxState} setState={setCheckboxState} name={areaName} />
          </div>
          <Spacer orientation="horizontal" size="xsmall" />
          <BreakdownRow
            text={areaName}
            satisfactionScore={satisfactionScore}
            showUnrepresentativeIndicator
            totalSubmissions={totalSubmissions}
            comparisonScore={comparisonScore}
            totalSubmissionsToCompare={totalSubmissionsToCompare}
          />
        </div>
      </a>
    </li>
  );
};
