import React, { ReactElement } from 'react';
import { Card } from '@zencity/common-ui';
import { useTranslation } from 'react-i18next';
import styles from './PreviewCard.module.scss';
import { ConditionalLink } from '../ConditionalLink/ConditionalLink';

export interface Props {
  title: string;
  thumbnailUrl: string;
  link?: string;
}

/**
 * A card for displaying a card with an image preview.
 * Originally created for the typeform example survey cards
 * in Community Asks.
 * @param title - The only descriptive text on the card
 * @param thumbnailUrl - The url for the image to display
 * @param link - Where to navigate when preview selected. Optional if the whole card is clickable to a link.
 */
export const PreviewCard = (props: Props): ReactElement => {
  const { link, title, thumbnailUrl } = props;

  const { t: translate } = useTranslation();

  return (
    <Card.Wrapper className={styles.wrapperOverride}>
      <div className={styles.container}>
        <img className={styles.thumbnail} src={thumbnailUrl} alt={translate('previewCard.imageAltText')} />
        <div className={styles.bookmark}>
          <Card.Title>{title}</Card.Title>
          <Card.Link>
            <ConditionalLink className={styles.previewLink} link={link} condition={!!link}>
              {translate('previewCard.linkText')}
            </ConditionalLink>
          </Card.Link>
        </div>
      </div>
    </Card.Wrapper>
  );
};
