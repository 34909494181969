import { ZCDSpinner } from '@zencity/common-ui';
import classNames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import styles from './LoaderMask.module.scss';

interface Props {
  isLoading: boolean;
  children: ReactNode | ReactNode[];
  className?: string;
}

/**
 * A wrapper to mask the given children with a spinner while loading.
 */
export const LoaderMask: React.FC<Props> = function LoaderMask({
  isLoading,
  children,
  className,
}: Props): ReactElement {
  return (
    <div className={classNames(styles.loaderMaskWrapper, className)}>
      {isLoading && (
        <div className={styles.loaderMask}>
          <ZCDSpinner />
        </div>
      )}
      {!isLoading && children}
    </div>
  );
};
