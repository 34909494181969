import { ZCDCard } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScorePerDateRange } from 'types/score';
import { GraphContext } from '../misc';
import styles from './GraphTooltip.module.scss';
import { ScoreTitle } from './ScoreTitle';

interface GraphTooltipProps {
  context: GraphContext;
  overallScores: ScorePerDateRange[];
}

export const GraphTooltip = (props: GraphTooltipProps): React.ReactElement | null => {
  const { context, overallScores } = props;
  const { tooltip } = context;
  const { t: translate } = useTranslation();
  const tooltipTitle = tooltip.title[0];
  const scores = overallScores[tooltip.dataPoints[0].dataIndex]?.scores;

  return (
    <ZCDCard elevated>
      <div className={styles.tooltipTitle}>{tooltipTitle.replace(',', ' ')}</div>
      <Spacer orientation="vertical" size="medium" />
      <ScoreTitle
        valueColor={styles.positive}
        value={scores?.positive}
        title={translate('overallScoreGraph.positive')}
      />
      <Spacer orientation="vertical" size="small" />
      <ScoreTitle
        valueColor={styles.negative}
        value={scores?.negative}
        title={translate('overallScoreGraph.negative')}
      />
      <Spacer orientation="vertical" size="small" />
      <ScoreTitle valueColor={styles.neutral} value={scores?.neutral} title={translate('overallScoreGraph.neutral')} />
    </ZCDCard>
  );
};
