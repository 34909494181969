import { IconName, TooltipInfo, ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import React from 'react';
import styles from './IconWithTooltip.module.scss';

interface Props {
  iconName: IconName;
  tooltipText: string;
  iconColor?: ZCDIconColor;
  tooltipPosition?: 'top' | 'bottom';
}

export const IconWithTooltip = (props: Props): React.ReactElement => {
  const { iconName, iconColor, tooltipText, tooltipPosition = 'top' } = props;

  return (
    <TooltipInfo
      className={styles.iconWithTooltip}
      position={tooltipPosition}
      tooltipText={tooltipText}
      renderButton={(buttonRef, buttonProps): React.ReactNode => (
        <div
          ref={buttonRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
        >
          <ZCDIcon name={iconName} color={iconColor} />
        </div>
      )}
    />
  );
};
