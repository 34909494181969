import { DivisionTree, GeoTargetingRef } from '@zencity/survey-types';
import { SurveyType } from '@zencity/survey-types';
import { londonAreasMock } from './londonAreas.mock';

const getPolygonsById = (id: string): GeoTargetingRef => {
  const matchingLondonArea = londonAreasMock.find((area) => area.id === id);
  return {
    polygon_id: matchingLondonArea?.id ?? '',
    polygon_name: matchingLondonArea?.name ?? '',
  };
};

export const divisionsTreeMock: DivisionTree[] = [
  {
    id: 1,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.BLOCKWISE,
    },
    depth: 0,
    geo_targeting_ref: getPolygonsById('634e5a8a95350a5d3321308f'),
    parent_division: null,
    children_divisions: [
      {
        id: 35,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f0'),
        parent_division: 1,
        children_divisions: [
          {
            id: 10,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac604a'),
            parent_division: 35,
            children_divisions: [],
          },
          {
            id: 14,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6012'),
            parent_division: 35,
            children_divisions: [],
          },
          {
            id: 20,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac604b'),
            parent_division: 35,
            children_divisions: [],
          },
          {
            id: 28,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6025'),
            parent_division: 35,
            children_divisions: [],
          },
        ],
      },
      {
        id: 36,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f7'),
        parent_division: 1,
        children_divisions: [
          {
            id: 5,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac603f'),
            parent_division: 36,
            children_divisions: [],
          },
          {
            id: 31,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6021'),
            parent_division: 36,
            children_divisions: [],
          },
        ],
      },
      {
        id: 37,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9fb'),
        parent_division: 1,
        children_divisions: [
          {
            id: 7,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6045'),
            parent_division: 37,
            children_divisions: [],
          },
          {
            id: 9,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6040'),
            parent_division: 37,
            children_divisions: [],
          },
        ],
      },
      {
        id: 38,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f3'),
        parent_division: 1,
        children_divisions: [
          {
            id: 2,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6023'),
            parent_division: 38,
            children_divisions: [],
          },
          {
            id: 29,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6017'),
            parent_division: 38,
            children_divisions: [],
          },
          {
            id: 30,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac603c'),
            parent_division: 38,
            children_divisions: [],
          },
        ],
      },
      {
        id: 39,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9fa'),
        parent_division: 1,
        children_divisions: [
          {
            id: 4,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6044'),
            parent_division: 39,
            children_divisions: [],
          },
          {
            id: 21,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6046'),
            parent_division: 39,
            children_divisions: [],
          },
        ],
      },
      {
        id: 40,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f9'),
        parent_division: 1,
        children_divisions: [
          {
            id: 3,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6049'),
            parent_division: 40,
            children_divisions: [],
          },
          {
            id: 6,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6047'),
            parent_division: 40,
            children_divisions: [],
          },
          {
            id: 17,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6048'),
            parent_division: 40,
            children_divisions: [],
          },
        ],
      },
      {
        id: 41,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f5'),
        parent_division: 1,
        children_divisions: [
          {
            id: 24,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac601d'),
            parent_division: 41,
            children_divisions: [],
          },
          {
            id: 27,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac601c'),
            parent_division: 41,
            children_divisions: [],
          },
        ],
      },
      {
        id: 42,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f2'),
        parent_division: 1,
        children_divisions: [
          {
            id: 13,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6016'),
            parent_division: 42,
            children_divisions: [],
          },
          {
            id: 15,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6018'),
            parent_division: 42,
            children_divisions: [],
          },
          {
            id: 16,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6015'),
            parent_division: 42,
            children_divisions: [],
          },
        ],
      },
      {
        id: 43,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f6'),
        parent_division: 1,
        children_divisions: [
          {
            id: 22,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac601e'),
            parent_division: 43,
            children_divisions: [],
          },
          {
            id: 25,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac601f'),
            parent_division: 43,
            children_divisions: [],
          },
          {
            id: 32,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6020'),
            parent_division: 43,
            children_divisions: [],
          },
        ],
      },
      {
        id: 44,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f8'),
        parent_division: 1,
        children_divisions: [
          {
            id: 18,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac603e'),
            parent_division: 44,
            children_divisions: [],
          },
          {
            id: 33,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6022'),
            parent_division: 44,
            children_divisions: [],
          },
        ],
      },
      {
        id: 45,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f1'),
        parent_division: 1,
        children_divisions: [
          {
            id: 12,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6013'),
            parent_division: 45,
            children_divisions: [],
          },
          {
            id: 23,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6024'),
            parent_division: 45,
            children_divisions: [],
          },
          {
            id: 26,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6014'),
            parent_division: 45,
            children_divisions: [],
          },
        ],
      },
      {
        id: 46,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9f4'),
        parent_division: 1,
        children_divisions: [
          {
            id: 8,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac601b'),
            parent_division: 46,
            children_divisions: [],
          },
          {
            id: 11,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac601a'),
            parent_division: 46,
            children_divisions: [],
          },
          {
            id: 19,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac6019'),
            parent_division: 46,
            children_divisions: [],
          },
        ],
      },
      {
        id: 47,
        division_layer: {
          id: 1,
          client: {
            id: 346,
            name: 'London Metropolitan Police',
            state: null,
            machine_name: 'london_metropolitan_police',
            zc_client_id: '631a0e77b20381a264bc01cf',
          },
          survey_type: SurveyType.BLOCKWISE,
        },
        depth: 1,
        geo_targeting_ref: getPolygonsById('634fbdf08cfb9d593dcfd9fc'),
        parent_division: 1,
        children_divisions: [
          {
            id: 48,
            division_layer: {
              id: 1,
              client: {
                id: 346,
                name: 'London Metropolitan Police',
                state: null,
                machine_name: 'london_metropolitan_police',
                zc_client_id: '631a0e77b20381a264bc01cf',
              },
              survey_type: SurveyType.BLOCKWISE,
            },
            depth: 2,
            geo_targeting_ref: getPolygonsById('634fbed03e7b2a65aaac603a'),
            parent_division: 47,
            children_divisions: [],
          },
        ],
      },
    ],
  },
];
