/* eslint-disable i18next/no-literal-string */
import classnames from 'classnames';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeformExample } from '@zencity/survey-types';
import { Link } from 'react-router-dom';
import { CustomRequestCard } from 'screens/community-asks/Workspace/components/CustomRequestCard/CustomRequestCard';
import { CommunityAsksContext } from '../../contexts/CommunityAsksContext';
import { analyticsService } from '../../services/analytics/analytics';
import PreviewCard from '../PreviewCard';
import styles from './TypeformExampleCardList.module.scss';

/**
 * List component to hold the Typeform example cards for Community Asks.
 *
 * @param withSurveyRequests - Determines if it is a screen with survey requests for styling purposes.
 */
export const TypeformExampleCardList: React.FC = function TypeformExampleCardList(): ReactElement {
  const { t: translate } = useTranslation();
  const { surveyRequests, typeformExamples } = useContext(CommunityAsksContext);

  const withSurveyRequests = surveyRequests && surveyRequests.length > 0;

  const baseTranslationPath = 'communityAsks.workspaceScreen.typeformExampleListHeader';

  return (
    <div className={withSurveyRequests ? classnames(styles.container, styles.withRequests) : styles.container}>
      <h3 className={styles.header}>
        {withSurveyRequests
          ? translate(`${baseTranslationPath}.withRequestsScreen`)
          : translate(`${baseTranslationPath}.emptyState`)}
      </h3>
      <ul className={styles.typeformExampleCardList}>
        {withSurveyRequests && (
          <li className={styles.typeformExampleCard} key="customRequestCard">
            <CustomRequestCard />
          </li>
        )}
        {typeformExamples &&
          typeformExamples.map((typeformExample: TypeformExample) => {
            const link = `/typeformExample/${typeformExample.id}`;

            return (
              <li className={styles.typeformExampleCard} key={typeformExample.id}>
                <Link
                  to={link}
                  onClick={() => analyticsService.communityAsksEvents.previewTemplateClicked(typeformExample.title)}
                >
                  <PreviewCard title={typeformExample.title} thumbnailUrl={typeformExample.thumbnail_image_url} />
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
