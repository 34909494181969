/* eslint-disable i18next/no-literal-string */
import { TabKeys } from 'constants/tabs';
import { useScreenViewedEvent } from 'hooks/screenViewedEvent';
import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Dashboard } from 'screens/customer-experience/Dashboard/Dashboard';
import { CustomerExperienceRouterPaths } from 'screens/customer-experience/routerPaths';
import { Summary } from 'screens/customer-experience/Summary/Summary';
import { SurveyLayout } from 'screens/customer-experience/SurveyLayout/SurveyLayout';
import { Workspace } from 'screens/customer-experience/Workspace/Workspace';
import { OpenQuestionFeed } from 'screens/OpenQuestionFeed/OpenQuestionFeed';
import { ZencityRoutesProps } from 'types/router';
import { logger } from 'utils/customer-experience/logger';
import { autoRedirectState } from 'utils/router';
import '../../i18n';
import '../../index.scss';

/*
 * This component generates the Routes for the CommunityAsksDashboard.
 * We do this using the useRoutes hook with a RouteObject, instead of
 * with the <Routes><Route></Routes> elements format.
 *
 * See an example from the docs here: https://stackblitz.com/github/
 * remix-run/react-router/tree/main/examples/route-objects?file=src%2FApp.tsx
 *
 * We use useRoutes here instead of directly in CommunitySurveyDashboard
 * component since the component that contains the BrowserRouter cannot
 * also use useRoutes. That root component with the BrowserRouter needs to
 * embed another element that uses useRoutes.
 */
export const CustomerExperienceRoutes = ({
  basename,
}: ZencityRoutesProps): React.ReactElement<
  ZencityRoutesProps,
  string | React.JSXElementConstructor<ZencityRoutesProps>
> | null => {
  const defaultTab = TabKeys.DASHBOARD;

  const routes: RouteObject[] = [
    { index: true, element: <Workspace /> },
    {
      path: CustomerExperienceRouterPaths.SURVEY_PAGE,
      element: <SurveyLayout />,
      children: [
        { index: true, element: <Navigate to={defaultTab} state={autoRedirectState} /> },
        { path: TabKeys.DASHBOARD, element: <Dashboard /> },
        { path: TabKeys.QUESTIONS, element: <Summary /> },
        { path: TabKeys.SUGGESTIONS, element: <OpenQuestionFeed /> },
      ],
    },
    /* eslint-disable-next-line i18next/no-literal-string */
    { path: '*', element: <div>404 - Page Not Found</div> },
  ];

  useScreenViewedEvent(routes, basename, logger);

  return useRoutes(routes);
};
