import styles from './OvertimeTrendsIndication.module.scss';

export const OVERTIME_TRENDS_INDICATION_TEST_ID = 'overtime-trends-indication';

export const getIconName = ({ difference }: { difference: number }) => {
  if (difference < 0) {
    return 'arrow-down';
  }
  return 'arrow-up';
};

export const getTextColor = ({ difference, inScoreWidget }: { difference: number; inScoreWidget: boolean }) => {
  if (!inScoreWidget) return styles.neutral;
  if (difference < 0) return styles.negative;
  if (difference > 0) return styles.positive;
  return styles.neutral;
};
