export enum AnalyticEventNames {
  SURVEYS_MAP_AREA_LEVEL_CHANGED = 'surveys-map-area-level-changed',
  SURVEYS_MAP_AREA_SELECTED = 'surveys-map-area-selected',
  SURVEYS_NEW_QUESTION_OR_ASPECT_SELECTED = 'surveys-new-question-or-aspect-selected',
  SURVEYS_CTA_CLICKED = 'surveys-cta-clicked',
  SURVEYS_CROSS_TAB_MODE_CHANGED = 'surveys-cross-tab-mode-changed',
  SURVEYS_CROSS_TAB_ANALYZED = 'surveys-cross-tab-analyzed',
  SURVEYS_DASHBOARD_TAB_SWITCHED = 'surveys-dashboard-tab-switched',
  SURVEYS_TOOLTIP_VIEWED = 'surveys-tooltip-viewed',
  SURVEYS_WIDGET_EXPANDED = 'surveys-widget-expanded',
  SURVEYS_WIDGET_FILTER_CHANGED = 'surveys-widget-filter-changed',
  SURVEYS_FEED_SCROLLED = 'surveys-feed-scrolled',
  SURVEYS_FEED_TOPIC_SELECTED = 'surveys-feed-topic-selected',
  SURVEYS_FILTER_CHANGED = 'surveys-filter-changed',
}

export enum AnalyticOriginPropertyValues {
  BREADCRUMBS = 'breadcrumbs',
  DOUBLE_CLICK = 'double-click',
  MAP_CLICK = 'map-click',
  WIDGET_CLICK = 'widget-click',
  TOPICS_WIDGET = 'topics-widget',
  FILTER = 'filter',
  COMMENT = 'comment',
}

export enum AnalyticFilterPropertyValues {
  LEVEL = 'level',
  LATEST_RESULTS = 'latest-results',
  COMPLETED_CYCLE = 'completed-cycle',
}

export enum AnalyticTypePropertyValues {
  QUESTION = 'question',
  ASPECT = 'aspect',
}

export enum AnalyticEventsChangeTypes {
  TRUE = 'true',
  FALSE = 'false',
  NONE = 'none',
  IRRELEVANT = 'irrelevant',
  NONE_ACTIVATED = 'none-activated',
  UNKNOWN = 'unknown',
  NONE_EXPANDED = 'none-expanded',
  SHOW = 'show',
  HIDE = 'hide',
  OPEN = 'open',
  CLOSE = 'close',
  EXPAND = 'expand',
  COLLAPSE = 'collapse',
}

export enum AnalyticFilterNames {
  DATA = 'data',
  PAST_DATA = 'past-data',
  COMPARISON = 'comparison',
  GEOGRAPHIC = 'geographic',
  DEMOGRAPHIC = 'demographic',
}

export enum AnalyticWidgetNames {
  TOP_CONCERNS_WIDGET = 'top-concerns-widget',
  RESULTS_BY_QUESTION_WIDGET = 'results-by-question-widget',
  GEOGRAPHIC_BREAKDOWN_WIDGET = 'geographic-breakdown-widget',
  DISCOURSE_WIDGET = 'discourse-widget',
  EXPERT_INSIGHTS_WIDGET = 'expert-insights-widget',
  REQUEST_SURVEY_WIDGET = 'request-survey-widget',
  CREATE_PROJECT_WIDGET = 'create-project-widget',
  TOTAL_SUBMISSIONS_WIDGET = 'total-submissions-widget',
  DEMOGRAPHIC_BREAKDOWN_WIDGET = 'demographic-breakdown-widget',
  LEADING_TOPICS_WIDGET = 'leading-topics-widget',
  RATING_BY_DEMOGRAPHIC_WIDGET = 'rating-by-demographic-widget',
  METHODOLOGY_WIDGET = 'methodology-widget',
}

export enum AnalyticTooltipNames {
  ASPECT_TAB = 'aspect-tab',
  TOTAL_SUBMISSIONS = 'total-submissions',
}
