import React, { ReactElement } from 'react';
import { SatisfactionScore } from '../../types/score';
import styles from './BreakdownRow.module.scss';
import { BreakdownRowDistribution } from './BreakdownRowDistribution/BreakdownRowDistribution';
import { BreakdownRowTitle } from './BreakdownRowTitle/BreakdownRowTitle';

interface Props {
  text: string;
  satisfactionScore?: SatisfactionScore | null;
  showUnrepresentativeIndicator?: boolean;
  totalSubmissions?: number;
  comparisonScore?: SatisfactionScore | null;
  totalSubmissionsToCompare?: number;
  hideOvertimeTrends?: boolean;
}

export const BreakdownRow = (props: Props): ReactElement => {
  const {
    text,
    satisfactionScore,
    showUnrepresentativeIndicator,
    totalSubmissions,
    comparisonScore,
    totalSubmissionsToCompare,
    hideOvertimeTrends,
  } = props;

  return (
    <div className={styles.breakdownRow} data-testid="breakdown-row">
      <BreakdownRowTitle
        text={text}
        showUnrepresentativeIndicator={showUnrepresentativeIndicator}
        totalSubmissions={totalSubmissions}
      />
      <BreakdownRowDistribution
        satisfactionScore={satisfactionScore}
        totalSubmissions={totalSubmissions}
        showUnrepresentativeIndicator={showUnrepresentativeIndicator}
        comparisonScore={comparisonScore}
        totalSubmissionsToCompare={totalSubmissionsToCompare}
        hideOvertimeTrends={hideOvertimeTrends}
      />
    </div>
  );
};
