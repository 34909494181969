import { divisionsMock } from 'mocks/data/divisions.mock';
import { divisionsTreeMock } from 'mocks/data/divisionsTree.mock';
import { DivisionTree, DivisionWithPolygon, SurveyType } from '@zencity/survey-types';
import { createAxiosInstance } from 'utils/Api';
import { getClientId } from 'utils/client';
import path from 'utils/path';
import { sleep } from 'utils/utils';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.divisions));

export const fetchDivisionsTree = async (surveyType: SurveyType, zcClientId?: string): Promise<DivisionTree[]> => {
  if (config.shouldMockRequests) {
    return Promise.resolve(divisionsTreeMock) as Promise<DivisionTree[]>;
  }
  const response = await axiosInstance.get<DivisionTree[]>(`tree/`, {
    params: {
      zc_client_id: zcClientId,
      survey_type: surveyType,
    },
  });
  return response.data;
};

interface DivisionsWithPolygonsRequestParams {
  surveyType: SurveyType;
}

export const fetchDivisionsWithPolygons = async ({
  surveyType,
}: DivisionsWithPolygonsRequestParams): Promise<DivisionWithPolygon[]> => {
  if (config.shouldMockRequests) {
    await sleep(2000);
    return Promise.resolve(divisionsMock) as Promise<DivisionWithPolygon[]>;
  }

  const clientId = getClientId();
  const response = await axiosInstance.get<DivisionWithPolygon[]>('polygons/', {
    params: {
      zc_client_id: clientId,
      survey_type: surveyType,
    },
  });
  return response.data;
};
