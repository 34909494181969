import { DateRangeFilterOption } from 'contexts/DateRangeContext';
import { isScoreLatestResult } from 'utils/dateRange';

interface GetDateRangeComparison {
  selectedDateRange?: DateRangeFilterOption;
  completedCyclesDateRanges?: (undefined | DateRangeFilterOption)[];
}

export function getDateRangeComparison({
  selectedDateRange,
  completedCyclesDateRanges,
}: GetDateRangeComparison): DateRangeFilterOption | undefined {
  // In case there is more then one completed cycle and according comparison logic:
  // if the latest cycle selected, the comparison cycle is the last completed cycle - 1, completedCyclesDateRanges[1];
  // In any other case (not latest cycle selected), the comparison cycle is the latest completed cycle, completedCyclesDateRanges[0];
  if (selectedDateRange && completedCyclesDateRanges && completedCyclesDateRanges?.length > 1) {
    const isLatestResult = isScoreLatestResult(selectedDateRange);
    if (isLatestResult || selectedDateRange?.key === completedCyclesDateRanges[0]?.key) {
      return completedCyclesDateRanges[1];
    }
    return completedCyclesDateRanges[0];
  }
  return undefined;
}
