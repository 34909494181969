import { TypeformExample } from '@zencity/survey-types';

export const mockPayload: TypeformExample[] = [
  {
    id: 24,
    typeform_survey_id: 'y5pqLyX7',
    title: 'Hurricane Preparedness',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Hurricane+-+new.png',
    description: 'Bringing in resident voices',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 15,
    typeform_survey_id: 'hQwJ78QB',
    title: 'New Policy Initiative',
    thumbnail_image_url:
      'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/New+Policy+Initiative.png',
    description: 'Assessing public perception, bringing in resident voices',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 19,
    typeform_survey_id: 'BCkX8Ndb',
    title: 'New Public Facilities',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/New+public+facility.png',
    description: 'Assessing public perception, bringing in resident voices',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 16,
    typeform_survey_id: 'x3Lf8Jnu',
    title: 'Communication Preferences',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Communication.png',
    description: 'Bringing in resident voices',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 18,
    typeform_survey_id: 'GUetdNfF',
    title: 'Event Feedback',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Feedback.png',
    description: 'Measuring engagement, evaluating performance',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 21,
    typeform_survey_id: 'QQNfeEIc',
    title: 'Transportation Planning',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Transportation.png',
    description: 'Assessing public perception, bringing in resident voices',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 20,
    typeform_survey_id: 'ixOotGLG',
    title: 'Recycling',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Recycling.png',
    description: 'Assessing public perception, evaluating performance, bringing in resident voices',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 22,
    typeform_survey_id: 'htb1sy7W',
    title: 'Bike Lanes',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Bike+lanes.png',
    description: 'Assessing public perception, bringing in resident voices',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 23,
    typeform_survey_id: 'e7T6zuzD',
    title: 'Volunteering',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Volunteering.png',
    description: 'Measuring engagement',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
  {
    id: 17,
    typeform_survey_id: 'IgIh9YhN',
    title: 'COVID-19 Mask Requirement',
    thumbnail_image_url: 'https://community-asks.s3.amazonaws.com/typeform_examples-thumbnails/Mask.png',
    description: 'Assessing public perception, evaluating performance',
    number_of_questions: 2,
    est_time_to_complete: '1-3 min',
  },
];
