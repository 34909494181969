import { Box, HorizontalSeparator, ZCDSpinner } from '@zencity/common-ui';
import BreakdownRow from 'components/BreakdownRow';
import { MapView } from 'components/MapView/MapView';
import { MapRenderTypes } from 'constants/map';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { MapsContext } from 'contexts/MapsContext';
import useScoreDateRangeFilter from 'hooks/score/dateRangeFilter';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getScorePerDateRange } from 'utils/dateRange';
import { AreaBreakdownErrorBoundary } from 'widgets/AreaBreakdown/components/AreaBreakdownErrorBoundary/AreaBreakdownErrorBoundary';
import styles from './AreaBreakdown.module.scss';

export const AreaBreakdown: React.FC = () => {
  const { t: translate } = useTranslation();
  const { areasWithScoresData, sortedAreaIdsByAreaName, isLoading } = useContext(MapsContext);
  const { dateRangeFilter } = useScoreDateRangeFilter();
  const { comparisonDateRange } = useContext(DateRangeContext);

  if (!dateRangeFilter) {
    return <></>;
  }

  return (
    <Box.Wrapper>
      <Box.Header className={styles.header}>{translate('areaBreakdown.title')}</Box.Header>
      {isLoading && (
        <div className={styles.loadingSpinner}>
          <ZCDSpinner />
        </div>
      )}
      <Box.Content className={styles.areaBreakdown}>
        {!isLoading && (
          <AreaBreakdownErrorBoundary
            title={translate('areaBreakdown.mapError.title')}
            description={translate('areaBreakdown.mapError.description')}
          >
            <section>
              <ol>
                {sortedAreaIdsByAreaName.map((areaId, index) => {
                  const areaWithScore = areasWithScoresData[areaId];
                  const selectedFilterScores = getScorePerDateRange({
                    scorePerDateRange: areaWithScore.scoresPerPeriod,
                    dateRangeFilter,
                  });
                  // Adding comparison score to display in the breakdown.
                  const comparisonScore = getScorePerDateRange({
                    scorePerDateRange: areaWithScore.scoresPerPeriod,
                    dateRangeFilter: {
                      endDate: comparisonDateRange?.endDate ?? '',
                      startDate: comparisonDateRange?.startDate ?? '',
                    },
                  });

                  return (
                    <React.Fragment key={areaId}>
                      <BreakdownRow
                        text={areaWithScore.name}
                        satisfactionScore={selectedFilterScores?.scores}
                        totalSubmissions={selectedFilterScores?.totalSubmissions}
                        showUnrepresentativeIndicator
                        comparisonScore={comparisonScore?.scores}
                        totalSubmissionsToCompare={comparisonScore?.totalSubmissions}
                      />
                      {index < sortedAreaIdsByAreaName.length - 1 && <HorizontalSeparator />}
                    </React.Fragment>
                  );
                })}
              </ol>
            </section>
            <section className={styles.map}>
              <MapView renderType={MapRenderTypes.WIDGET} />
            </section>
          </AreaBreakdownErrorBoundary>
        )}
      </Box.Content>
    </Box.Wrapper>
  );
};
