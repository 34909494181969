import {
  MAX_UNREPRESENTATIVE_INDICATOR,
  MIN_UNREPRESENTATIVE_INDICATOR,
} from 'components/UnrepresentativeIndicator/constants';
import { UnrepresentativeIndicator } from 'components/UnrepresentativeIndicator/UnrepresentativeIndicator';
import React from 'react';
import styles from '../BreakdownRow.module.scss';

interface Props {
  text: string;
  showUnrepresentativeIndicator?: boolean;
  totalSubmissions?: number;
}

export const BreakdownRowTitle = (props: Props): React.ReactElement => {
  const { text, showUnrepresentativeIndicator, totalSubmissions = 0 } = props;
  return (
    <div className={styles.flexCenter}>
      <p className={styles.rowText}>{text}</p>
      {showUnrepresentativeIndicator &&
        totalSubmissions >= MIN_UNREPRESENTATIVE_INDICATOR &&
        totalSubmissions < MAX_UNREPRESENTATIVE_INDICATOR && (
          <UnrepresentativeIndicator totalSubmissions={totalSubmissions} />
        )}
    </div>
  );
};
