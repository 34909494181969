/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { ZCDIcon } from '@zencity/common-ui';
import { QuestionType } from '@zencity/survey-types';
import classNames from 'classnames';
import { CrossQuestionTable } from 'components/Analysis/CrossQuestionTable/CrossQuestionTable';
import AnalysisBreakdownTable from 'components/Analysis/AnalysisBreakdownTable/AnalysisBreakdownTable';
import AnalysisQuestionSummary from 'components/Analysis/AnalysisQuestionSummary/AnalysisQuestionSummary';
import { Spacer } from 'components/Spacer/Spacer';
import { AnalyticEventsChangeTypes, AnalyticTypePropertyValues } from 'constants/analytics';

import { DashboardContext } from 'contexts/DashboardContext';
import { AnalysisContext } from 'contexts/AnalysisContext';
import { useClientNameTokenReplacement } from 'hooks/useClientNameTokenReplacement/useClientNameTokenReplacement';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { useContext, useEffect, useState } from 'react';
import { analyticsService } from 'services/analytics/analytics';
import { Question } from 'types/questionsScores';
import { calculateSatisfactionScore, getTopQuestionChoice } from 'utils/analysis';
import styles from './AnalysisQuestionBreakdown.module.scss';
import { RowElement } from './RowElement/RowElement';

export interface Props {
  question: Question;
  totalSubmissionsOverall: number;
}

/**
 * A question row which show a satisfaction distribution bar or a link to the answers.
 */
export const AnalysisQuestionBreakdown: React.FC<Props> = function ResultsQuestionsBreakdown(
  props: Props,
): React.ReactElement | null {
  const { question, totalSubmissionsOverall } = props;
  const { questionText, questionType, totalSubmissions, questionChoices, questionIndex } = question;
  const [isExpanded, setIsExpanded] = useState(false);
  const { expandedQuestionId, setExpandedQuestionId, isCrossModeEnabled, crossSelectedQuestionId } =
    useContext(DashboardContext);
  const { comparisonQuestionsScores, hideOvertimeTrends } = useContext(AnalysisContext);
  const comparisonQuestion = comparisonQuestionsScores?.questionScores?.find(
    (comparisonQuestionItem) => comparisonQuestionItem.genericQuestionId === question.genericQuestionId,
  );
  const activeFilters = useFilterNames();

  // Replace the question client token in case is needed.
  const { clientNameTokenReplacement } = useClientNameTokenReplacement();
  const formattedQuestionText = clientNameTokenReplacement(questionText);
  // In case there is no score for any of the choices, and  the question is not open ended, is "no scores" question.
  const isNoScoresQuestion =
    questionChoices.every((questionChoice) => !questionChoice.numericValue && questionChoice.numericValue !== 0) &&
    questionType !== QuestionType.OPEN_ENDED;

  const { satisfactionScore, comparisonSatisfactionScore } = calculateSatisfactionScore(
    questionChoices,
    comparisonQuestion,
    isNoScoresQuestion,
  );

  const topQuestionChoice = getTopQuestionChoice(questionChoices);

  const setExpand = (): void => {
    if (questionType !== QuestionType.OPEN_ENDED) {
      setIsExpanded(!isExpanded);
    }
    if (isExpanded) {
      setExpandedQuestionId(undefined);
    } else if (questionType !== QuestionType.OPEN_ENDED) {
      setExpandedQuestionId(question.genericQuestionId);
    }

    analyticsService.crossProductEvents.questionOrAspectSelected({
      type: AnalyticTypePropertyValues.QUESTION,
      aspectId: AnalyticEventsChangeTypes.IRRELEVANT,
      aspectText: AnalyticEventsChangeTypes.IRRELEVANT,
      isCrossTabMode: isCrossModeEnabled,
      questionId: question.genericQuestionId,
      questionText: question.questionText,
      activeFilters,
    });
  };

  // Only one question can be expanded at a time
  useEffect(() => {
    setIsExpanded(expandedQuestionId === question.genericQuestionId);
  }, [expandedQuestionId, question.genericQuestionId]);

  return (
    <div className={styles.resultsQuestionsBreakdown}>
      <div
        className={classNames(styles.header, {
          [styles.expanded]: isExpanded,
        })}
      >
        <button
          type="button"
          onClick={setExpand}
          className={classNames(styles.header, {
            [styles.openEndedButton]: questionType === QuestionType.OPEN_ENDED,
          })}
        >
          <span className={styles.questionNumber}>Q{questionIndex}</span>
          <RowElement
            comparisonSatisfactionScore={comparisonSatisfactionScore}
            satisfactionScore={satisfactionScore}
            questionType={questionType}
            questionText={formattedQuestionText}
            totalSubmissions={totalSubmissions}
            comparisonTotalSubmissions={comparisonQuestion?.totalSubmissions}
            isNoScoresQuestion={isNoScoresQuestion}
            topQuestionChoice={topQuestionChoice}
            hideOvertimeTrends={hideOvertimeTrends}
          />
          {questionType !== QuestionType.OPEN_ENDED && (
            <>
              <Spacer orientation="horizontal" size="small" />
              <div
                className={classNames(styles.expandButton, {
                  [styles.expanded]: isExpanded,
                })}
              >
                <ZCDIcon name="chevron-down" />
              </div>
            </>
          )}
        </button>
      </div>
      {isExpanded && (
        <div className={styles.expandedSection}>
          {satisfactionScore && (
            <AnalysisQuestionSummary
              questionScore={satisfactionScore}
              comparisonScore={comparisonSatisfactionScore}
              totalQuestionSubmissions={totalSubmissions}
              totalSubmissions={totalSubmissionsOverall}
              isNoScoresQuestion={isNoScoresQuestion}
              topQuestionChoice={topQuestionChoice}
            />
          )}
          <Spacer orientation="vertical" size="medium" />
          {isCrossModeEnabled && crossSelectedQuestionId ? (
            <CrossQuestionTable
              questionChoices={questionChoices}
              comparingChoices={comparisonQuestion?.questionChoices}
              isNoScoreQuestion={isNoScoresQuestion}
            />
          ) : (
            <AnalysisBreakdownTable
              isNoScoreQuestion={isNoScoresQuestion}
              questionChoices={questionChoices}
              comparingChoices={comparisonQuestion?.questionChoices}
            />
          )}
        </div>
      )}
    </div>
  );
};
