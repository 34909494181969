import { ZCDIconColor } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { HoverTimeoutWrapper } from 'components/HoverTimeoutWrapper/HoverTimeoutWrapper';
import { IconWithTooltip } from 'components/IconWithTooltip/IconWithTooltip';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { Spacer } from 'components/Spacer/Spacer';
import { MIN_UNREPRESENTATIVE_INDICATOR } from 'components/UnrepresentativeIndicator/constants';
import { AnalyticTooltipNames, AnalyticWidgetNames } from 'constants/analytics';
import { DateRangeContext } from 'contexts/DateRangeContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { isFeedLatestResult } from 'utils/dateRange';
import { formatToStringifiedNumbersWithCommas } from 'utils/formatters';
import styles from './TotalSubmissionsWidget.module.scss';
import { useTotalSubmissionsWidgetData } from './useTotalSubmissionsWidgetData';

interface Props {
  onUnrepresentativeData?: () => void;
}

export const TotalSubmissionsWidget = ({ onUnrepresentativeData }: Props): JSX.Element => {
  const { selectedDateRange, completedCyclesDateRanges } = useContext(DateRangeContext);
  const { t: translate } = useTranslation();
  const isLatestResult = isFeedLatestResult(selectedDateRange);
  // In case selected date range is latest result, we need to get the first completed cycle date range.
  const selectedCycle = isLatestResult
    ? completedCyclesDateRanges?.[0]
    : completedCyclesDateRanges?.find(
        (dateRange) =>
          dateRange?.startDate === selectedDateRange?.startDate && dateRange?.endDate === selectedDateRange?.endDate,
      );
  const { widgetData: selectedDateWidgetData, loading } = useTotalSubmissionsWidgetData({
    startDate: selectedCycle?.startDate,
    endDate: selectedCycle?.endDate,
  });

  useEffect(() => {
    if (selectedDateWidgetData && selectedDateWidgetData?.total_valid_submissions < MIN_UNREPRESENTATIVE_INDICATOR) {
      onUnrepresentativeData?.();
    }
  }, [onUnrepresentativeData, selectedDateWidgetData]);

  const handleOnHoverAnalytics = () => {
    analyticsService.crossProductEvents.tooltipViewed({
      tooltipName: AnalyticTooltipNames.TOTAL_SUBMISSIONS,
      widgetName: AnalyticWidgetNames.TOTAL_SUBMISSIONS_WIDGET,
    });
  };

  return (
    <Flex className={styles.totalSubmissionsWidget} flexDirection="column" justifyContent="space-between">
      <Flex>
        <div className={styles.title}>{translate('blockwiseSummaryTab.overallWidgets.totalSubmissions.title')}</div>
        <Spacer orientation="horizontal" size="xsmall" />
        <HoverTimeoutWrapper onHover={handleOnHoverAnalytics}>
          <IconWithTooltip
            tooltipText={translate('blockwiseSummaryTab.overallWidgets.totalSubmissions.tooltip')}
            iconName="info"
            iconColor={ZCDIconColor.GRAY}
          />
        </HoverTimeoutWrapper>
      </Flex>
      <LoaderMask className={styles.loaderMask} isLoading={loading}>
        <Flex alignItems="flex-end" justifyContent="space-between">
          <Flex flexDirection="column">
            <span className={styles.totalSubmissions}>
              {selectedDateWidgetData?.total_submissions &&
                formatToStringifiedNumbersWithCommas(selectedDateWidgetData?.total_valid_submissions)}
            </span>
            <Spacer orientation="vertical" size="xsmall" />
            <span className={styles.comparisonTitle}>{selectedCycle?.label}</span>
          </Flex>
        </Flex>
      </LoaderMask>
    </Flex>
  );
};
