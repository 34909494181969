import { TreeNode, TreeSelectSelectionKeys, ZCDTreeSelect } from '@zencity/common-ui';
import { ClassificationSubTopic, ClassificationTopic } from '@zencity/survey-types';
import { convertOptionKeysToTreeSelectSelectionKey } from 'components/ClassificationSubTopicsFilter/ClassificationSubTopicsFilter.helpers';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { AnalyticOriginPropertyValues } from 'constants/analytics';
import { FiltersContext } from 'contexts/FiltersContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { TreeSelectSelectionKeysType } from 'utils/blockwise/filters';
import styles from './ClassificationSubTopicsFilter.module.scss';

const SUBTOPIC_FILTER_KEY = 'sub-topic';

export const ClassificationSubTopicsFilter: React.FC = function ClassificationSubTopicsFilter(): ReactElement {
  const {
    classificationTopicsOptions,
    isClassificationTopicsLoading,
    selectedClassificationTopicsKeys,
    setSelectedClassificationTopicsKeys,
  } = useContext(FiltersContext);

  const { t: translate } = useTranslation();
  const [selectedClassificationTopics, setSelectedClassificationTopics] = useState<TreeSelectSelectionKeysType>();

  const isFilterDisabled = isClassificationTopicsLoading || !classificationTopicsOptions.length;

  useEffect(() => {
    // Convert selectedClassificationTopicsKeys to format { [key: string]: TreeSelectSelectionKeysType. }
    const selectedClassifications = convertOptionKeysToTreeSelectSelectionKey(
      classificationTopicsOptions,
      selectedClassificationTopicsKeys,
    );
    setSelectedClassificationTopics(selectedClassifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClassificationTopicsKeys, classificationTopicsOptions]);

  const handleOnFilterSelect = (event: { value?: TreeSelectSelectionKeys }) => {
    const selectedClassificationTopicKeys = Object.keys(event.value ?? {});
    setSelectedClassificationTopicsKeys(selectedClassificationTopicKeys);

    const areNoneSelected = event.value === undefined;
    const areAllSelected =
      selectedClassificationTopicKeys.filter((filterKey) => !filterKey.includes(SUBTOPIC_FILTER_KEY)).length ===
      classificationTopicsOptions.filter((option) => option.children?.length).length;
    if (areNoneSelected || areAllSelected) {
      const scopeType = event.value === undefined ? 'none' : 'all';
      const actionType = event.value === undefined ? 'deselect' : 'select';
      analyticsService.crossProductEvents.feedTopicSelected({
        origin: AnalyticOriginPropertyValues.FILTER,
        type: scopeType,
        actionType,
        topicDisplayName: scopeType,
        subTopicsDisplayNames: scopeType,
      });
    }
  };

  const handleOnNodeSelect = ({ node }: { node: TreeNode }) => {
    const isTopic = node.children?.length;
    const { data: nodeData } = node as {
      // eslint-disable-next-line id-denylist
      data: { classificationTopic: ClassificationTopic; classificationSubTopics: ClassificationSubTopic[] };
    };
    analyticsService.crossProductEvents.feedTopicSelected({
      origin: AnalyticOriginPropertyValues.FILTER,
      type: isTopic ? 'topic' : 'subtopic',
      actionType: 'select',
      topicDisplayName: nodeData.classificationTopic.value,
      subTopicsDisplayNames: nodeData.classificationSubTopics.map((subTopic) => subTopic.value),
    });
  };

  const handleOnNodeDeselect = ({ node }: { node: TreeNode }) => {
    const isTopic = node.children?.length;
    const { data: nodeData } = node as {
      // eslint-disable-next-line id-denylist
      data: { classificationTopic: ClassificationTopic; classificationSubTopics: ClassificationSubTopic[] };
    };
    analyticsService.crossProductEvents.feedTopicSelected({
      origin: AnalyticOriginPropertyValues.FILTER,
      type: isTopic ? 'topic' : 'subtopic',
      actionType: 'deselect',
      topicDisplayName: nodeData.classificationTopic.value,
      subTopicsDisplayNames: nodeData.classificationSubTopics.map((subTopic) => subTopic.value),
    });
  };

  return (
    <LoaderMask isLoading={isClassificationTopicsLoading}>
      <div className={styles.classificationSubTopicsFilter}>
        <ZCDTreeSelect
          disabled={isFilterDisabled}
          options={classificationTopicsOptions}
          placeholder={translate('classificationTopicsFilter.placeholder')}
          showSearch
          showSelectAll
          onNodeSelect={handleOnNodeSelect}
          onNodeUnselect={handleOnNodeDeselect}
          onChange={handleOnFilterSelect}
          value={selectedClassificationTopics}
          selectedPrefix={translate('classificationTopicsFilter.selectedPrefix')}
        />
      </div>
    </LoaderMask>
  );
};
