import React, { ReactElement } from 'react';
import styles from './ContentEmptyState.module.scss';

interface Props {
  title: string;
  description: string;
  borderless?: boolean;
  iconComponent?: React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
}

export const ContentEmptyState = ({
  title,
  description,
  borderless = false,
  iconComponent,
  children,
}: Props): ReactElement => (
  <div className={`${styles.contentEmptyState} ${borderless ? styles.borderless : ''}`}>
    {iconComponent}
    <h2>{title}</h2>
    <p>{description}</p>
    {children}
  </div>
);
