import React, { ReactElement } from 'react';
import styles from './ContentPlaceholder.module.scss';

interface Props {
  title: string;
  description: string;
  borderless?: boolean;
  iconComponent?: React.ReactElement;
}

// eslint-disable-next-line i18next/no-literal-string
export const ContentPlaceholder = ({ title, description, borderless = false, iconComponent }: Props): ReactElement => (
  <div className={`${styles.contentPlaceholder} ${borderless ? styles.borderless : ''}`}>
    {iconComponent}
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);
