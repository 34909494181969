/* eslint-disable id-denylist */
import { TreeNode } from '@zencity/common-ui';
import { ClassificationSubTopic, ClassificationTopic } from '@zencity/survey-types';
import { TreeSelectSelectionKeysType } from 'utils/blockwise/filters';
import { formatTitle } from 'utils/formatters';

export const CLASSIFICATION_TOPIC_FILTER_KEY = 'classification-topic';
export const CLASSIFICATION_SUB_TOPIC_FILTER_KEY = 'classification-sub-topic';

export interface ClassificationTreeNodeData {
  classificationTopic: ClassificationTopic;
  classificationSubTopics: ClassificationSubTopic[];
}

export const getUniqueClassificationTopics = (
  classificationSubTopics: ClassificationSubTopic[],
): ClassificationTopic[] =>
  Object.values(
    classificationSubTopics.reduce(
      (classificationTopicsObject, classificationSubTopic) => ({
        ...classificationTopicsObject,
        [classificationSubTopic.classification_topic.id]: classificationSubTopic.classification_topic,
      }),
      {},
    ),
  );

export const reduceClassificationSubTopicsToTreeNodes = (
  classificationSubTopics: ClassificationSubTopic[],
): TreeNode[] => {
  const uniqueClassificationTopics = getUniqueClassificationTopics(classificationSubTopics);
  const sortedUniqueClassificationTopics = uniqueClassificationTopics.sort(
    (firstClassificationTopic, secondClassificationTopic) =>
      firstClassificationTopic.value.localeCompare(secondClassificationTopic.value, undefined, { numeric: true }),
  );

  return sortedUniqueClassificationTopics.reduce<TreeNode[]>((classificationTopicTreeNodes, classificationTopic) => {
    const classificationSubTopicsForTopic = classificationSubTopics.filter(
      (classificationSubTopic) => classificationSubTopic.classification_topic.id === classificationTopic.id,
    );
    if (classificationSubTopicsForTopic.length === 1) {
      // Only filter by the topic if there is one Classification Sub Topic.
      // Note that the `ClassificationSubTopic` is the filter key, as
      // it will be used for filtering, but with the `ClassificationTopic`'s label/value.
      const classificationData: ClassificationTreeNodeData = {
        classificationTopic,
        classificationSubTopics: classificationSubTopicsForTopic,
      };
      classificationTopicTreeNodes.push({
        key: `${CLASSIFICATION_SUB_TOPIC_FILTER_KEY}-${classificationSubTopicsForTopic[0].id}`,
        label: formatTitle(classificationTopic.value),
        data: classificationData,
      });
      return classificationTopicTreeNodes;
    }

    const sortedClassificationSubTopicsByValue = classificationSubTopicsForTopic.sort(
      (firstClassificationSubTopic, secondClassificationSubTopic) =>
        firstClassificationSubTopic.value.localeCompare(secondClassificationSubTopic.value, undefined, {
          numeric: true,
        }),
    );

    const classificationTopicData: ClassificationTreeNodeData = {
      classificationTopic,
      classificationSubTopics: classificationSubTopicsForTopic,
    };

    classificationTopicTreeNodes.push({
      key: `${CLASSIFICATION_TOPIC_FILTER_KEY}-${classificationTopic.id}`,
      label: formatTitle(classificationTopic.value),
      data: classificationTopicData,
      children: sortedClassificationSubTopicsByValue.map((classificationSubTopic) => {
        const classificationSubTopicData: ClassificationTreeNodeData = {
          classificationTopic,
          classificationSubTopics: [classificationSubTopic],
        };
        return {
          key: `${CLASSIFICATION_SUB_TOPIC_FILTER_KEY}-${classificationSubTopic.id}`,
          label: formatTitle(classificationSubTopic.value),
          data: classificationSubTopicData,
        };
      }),
    });

    return classificationTopicTreeNodes;
  }, []);
};

export const convertOptionKeysToTreeSelectSelectionKey = (
  classificationOptions: TreeNode[],
  selectedClassificationTopicKeys: string[],
): TreeSelectSelectionKeysType => {
  const selectedClassifications = selectedClassificationTopicKeys.reduce<TreeSelectSelectionKeysType>(
    (classificaitionTopicSelectionKeys, classificationTopicKey) => {
      const classificationTopicOption = classificationOptions.find((option) => option.key === classificationTopicKey);
      if (classificationTopicOption) {
        if (!classificationTopicOption.children) {
          return {
            ...classificaitionTopicSelectionKeys,
            [classificationTopicKey]: {
              checked: true,
              partialChecked: false,
            },
          };
        }
        const areAllSubTopicsSelected = classificationTopicOption.children?.every((subTopicOption) => {
          const { key: subTopicKey } = subTopicOption;
          return selectedClassificationTopicKeys.includes(subTopicKey as string);
        });
        return {
          ...classificaitionTopicSelectionKeys,
          [classificationTopicKey]: {
            checked: areAllSubTopicsSelected,
            partialChecked: !areAllSubTopicsSelected,
          },
        };
      }
      return {
        ...classificaitionTopicSelectionKeys,
        [classificationTopicKey]: {
          checked: true,
          partialChecked: false,
        },
      };
    },
    {},
  );
  return selectedClassifications;
};
