import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// eslint-disable-next-line import/no-mutable-exports
let sentryClient: Sentry.BrowserClient;

if (process.env.SENTRY_DSN) {
  sentryClient = new Sentry.BrowserClient({
    environment: process.env.NODE_ENV,
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: require('../../package.json').version, // eslint-disable-line
    tracesSampleRate: 1.0,
  });
}

export { sentryClient };
