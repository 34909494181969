/* eslint-disable max-lines, max-statements */
import { DateRangeContext } from 'contexts/DateRangeContext';
import { MapsContext } from 'contexts/MapsContext';
import useScoreDateRangeFilter from 'hooks/score/dateRangeFilter';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchDemographicScore, fetchGenericQuestionsScore } from 'services/score';
import { ScoreFilters } from 'types/dateRange';
import { logger } from 'utils/community-survey/logger';
import { doAreasHaveEnoughDimensionsToShowMap } from 'utils/Map/helpers';
import { AreaBreakdown } from 'widgets/AreaBreakdown/AreaBreakdown';
import { DemographicBreakdown } from 'widgets/DemographicBreakdown';
import { QuestionBreakdown } from 'widgets/QuestionsBreakdown';
import { DashboardContext } from 'contexts/DashboardContext';
import { config } from '../../../../../../config/config';
import styles from '../../Summary.module.scss';
import { initialState, reducer } from './reducer';
import { ActionTypes } from './types';
import { remappingDemographics, remappingQuestions } from './utils';

/**
 * A defined threshold where beneath it, the demographic score is not displayed.
 */
const DEMOGRAPHIC_SUBMISSIONS_DISPLAY_THRESHOLD = 15;

// eslint-disable-next-line max-lines-per-function
export const BreakdownSection = (): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t: translate } = useTranslation();
  const { dateRangeFilter } = useScoreDateRangeFilter();
  const { comparisonDateRange } = useContext(DateRangeContext);
  const { currentSurveyGroup } = useContext(DashboardContext);
  const { areasWithScoresData } = useContext(MapsContext);
  const shouldShowMap = doAreasHaveEnoughDimensionsToShowMap(areasWithScoresData);

  const remappedTopQuestionsScores = useMemo(
    () => remappingQuestions(state.questions.top, state.questionsToCompare),
    [state.questions.top, state.questionsToCompare],
  );
  const remappedBottomQuestionsScores = useMemo(
    () => remappingQuestions(state.questions.bottom, state.questionsToCompare),
    [state.questions.bottom, state.questionsToCompare],
  );

  // Remapping demographic to adding comparisonScore to display over time trends.
  const remappedTopDemographics = useMemo(
    () => remappingDemographics(state.demographics.top, state.demographicsToCompare),
    [state.demographics.top, state.demographicsToCompare],
  );
  const remappedBottomDemographics = useMemo(
    () => remappingDemographics(state.demographics.bottom, state.demographicsToCompare),
    [state.demographics.bottom, state.demographicsToCompare],
  );

  const fetchAndSetDemographicScores = useCallback(
    async (
      dateRange: ScoreFilters,
      successAction: ActionTypes.DEMOGRAPHICS_FETCHED | ActionTypes.DEMOGRAPHICS_TO_COMPARE_FETCHED,
    ): Promise<void> => {
      if (successAction === ActionTypes.DEMOGRAPHICS_FETCHED) {
        dispatch({ type: ActionTypes.DEMOGRAPHICS_LOADING });
      }
      try {
        const demographicsScores = await fetchDemographicScore(currentSurveyGroup.client.id, dateRange);

        const filteredScores = demographicsScores.filter(
          (demographicScore) =>
            // Since a start and end date were provided to the request, only 1 score is expected to be returned.
            demographicScore.scores.length > 0 &&
            demographicScore.scores[0].totalSubmissions >= DEMOGRAPHIC_SUBMISSIONS_DISPLAY_THRESHOLD,
        );
        dispatch({
          type: successAction,
          payload: {
            demographicScores: filteredScores,
          },
        });
      } catch (error) {
        logger.error(`[Breakdown Section] - Error fetching data ${error}`);
        dispatch({ type: ActionTypes.DEMOGRAPHICS_FAILED });
      }
    },
    [currentSurveyGroup.client.id],
  );

  const fetchAndSetQuestionScore = useCallback(
    async (
      dateRange: ScoreFilters,
      successAction: ActionTypes.QUESTIONS_FETCHED | ActionTypes.QUESTIONS_TO_COMPARE_FETCHED,
    ) => {
      dispatch({ type: ActionTypes.QUESTIONS_LOADING });
      try {
        const genericQuestionScores = await fetchGenericQuestionsScore(currentSurveyGroup.client.id, dateRange);
        dispatch({
          type: successAction,
          payload: {
            genericQuestionScores,
          },
        });
      } catch (error) {
        logger.error(`[Breakdown Section] - Error fetching data ${error}`);
        dispatch({ type: ActionTypes.QUESTIONS_FAILED });
      }
    },
    [currentSurveyGroup.client.id],
  );

  useEffect(() => {
    if (!dateRangeFilter) return;

    try {
      fetchAndSetDemographicScores(dateRangeFilter, ActionTypes.DEMOGRAPHICS_FETCHED);
      fetchAndSetQuestionScore(dateRangeFilter, ActionTypes.QUESTIONS_FETCHED);
    } catch (error) {
      logger.error(`[Breakdown Section] - error fetching score ${error}`);
    }
  }, [dateRangeFilter, fetchAndSetDemographicScores, fetchAndSetQuestionScore]);

  useEffect(() => {
    if (!comparisonDateRange) return;

    try {
      fetchAndSetDemographicScores(comparisonDateRange, ActionTypes.DEMOGRAPHICS_TO_COMPARE_FETCHED);
      fetchAndSetQuestionScore(comparisonDateRange, ActionTypes.QUESTIONS_TO_COMPARE_FETCHED);
    } catch (error) {
      logger.error(`[Breakdown Section] - error fetching score ${error}`);
    }
  }, [comparisonDateRange, fetchAndSetDemographicScores, fetchAndSetQuestionScore]);

  return (
    <section className={styles.breakdown}>
      <h2>{translate('summaryScreen.breakdosnSectionTitle')}</h2>
      <article className={styles.topDemographics}>
        <DemographicBreakdown
          demographicScores={remappedTopDemographics}
          isLoading={state.demographics.isLoading}
          title={translate('demographicsBreakdown.titleTop')}
        />
      </article>
      <article className={styles.bottomDemographics}>
        <DemographicBreakdown
          demographicScores={remappedBottomDemographics}
          isLoading={state.demographics.isLoading}
          title={translate('demographicsBreakdown.titleBottom')}
        />
      </article>
      {shouldShowMap && config.environment !== 'testing' ? (
        <article className={styles.areaBreakdown}>
          <AreaBreakdown />
        </article>
      ) : null}
      <article className={styles.questions}>
        <QuestionBreakdown
          title={translate('questionsBreakdown.titleTop')}
          genericQuestionScores={remappedTopQuestionsScores}
          isLoading={state.questions.isLoading}
        />
      </article>
      <article className={styles.questions}>
        <QuestionBreakdown
          title={translate('questionsBreakdown.titleBottom')}
          genericQuestionScores={remappedBottomQuestionsScores}
          isLoading={state.questions.isLoading}
        />
      </article>
    </section>
  );
};
