import { ScreenStickyHeader } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './WorkspaceHeader.module.scss';

/**
 * "Customer Experience" workspace header.
 */
export const WorkspaceHeader: React.FC = function WorkspaceHeader(): ReactElement {
  const { t: translate } = useTranslation();
  return (
    <ScreenStickyHeader bottomPadding={false}>
      <div className={styles.workspaceHeader}>
        <h1>{translate('header.customerExperienceTitle')}</h1>
      </div>
    </ScreenStickyHeader>
  );
};
