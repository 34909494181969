import React, { ReactElement, useContext } from 'react';
import { CustomerExperienceContext } from 'contexts/CustomerExperienceContext';
import { WorkspaceHeader } from './components/WorkspaceHeader/WorkspaceHeader';
import { SurveyGroupList } from './components/SurveyGroupList/SurveyGroupList';

/**
 * The main "Customer Experience" screen.
 */
export const Workspace: React.FC = function Workspace(): ReactElement {
  const { surveyGroups } = useContext(CustomerExperienceContext);

  return (
    <>
      <WorkspaceHeader />
      <SurveyGroupList surveyGroups={surveyGroups} />
    </>
  );
};
