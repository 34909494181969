import React, { ReactElement } from 'react';
import { Card, HorizontalSeparator } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import { DateRangesHeader } from 'components/DateRangesHeader/DateRangesHeader';
import styles from './WidgetBox.module.scss';

interface Props {
  children: React.ReactNode;
}

/**
 * wrapper for the map graph widget.
 */
export const WidgetBox: React.FC<Props> = function WidgetBox({ children }: Props): ReactElement {
  return (
    <Card.Wrapper className={styles.widgetBox}>
      <DateRangesHeader />
      <HorizontalSeparator />
      <Spacer orientation="vertical" size="small" />
      {children}
    </Card.Wrapper>
  );
};
