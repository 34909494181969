import { Question } from '@zencity/survey-types';
import React, { ReactElement } from 'react';
import styles from '../Question.module.scss';

interface Props {
  content: Question;
}

const Title = ({ content }: Props): ReactElement => <h3 className={styles.questionTitle}>{content.text}</h3>;
export default Title;
