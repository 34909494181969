import { DemographicsChoicesResponse } from 'types/demographicFilters';

export const demographicFilters = {
  Age: [
    {
      id: 1248,
      label: '0-17',
    },
    {
      id: 89,
      label: '18-34',
    },
    {
      id: 90,
      label: '35-54',
    },
    {
      id: 91,
      label: '55+',
    },
  ],
  Gender: [
    {
      id: 25,
      label: 'Female',
    },
    {
      id: 26,
      label: 'Male',
    },
    {
      id: 78,
      label: 'Prefer to self-describe',
    },
    {
      id: 31,
      label: 'Other',
    },
  ],
  Ethnicity: [
    {
      id: 32,
      label: 'Asian',
    },
    {
      id: 33,
      label: 'Black or African American',
    },
    {
      id: 34,
      label: 'Native American or Alaskan Native',
    },
    {
      id: 35,
      label: 'Native Hawaiian or Other Pacific Islander',
    },
    {
      id: 36,
      label: 'White',
    },
    {
      id: 28,
      label: 'Prefer not to answer',
    },
    {
      id: 31,
      label: 'Other',
    },
  ],
  Education: [
    {
      id: 37,
      label: 'Less than high school',
    },
    {
      id: 38,
      label: 'Some high school',
    },
    {
      id: 39,
      label: 'High school degree or GED',
    },
    {
      id: 40,
      label: 'Some college',
    },
    {
      id: 41,
      label: "Associate's degree",
    },
    {
      id: 42,
      label: "Bachelor's degree",
    },
    {
      id: 43,
      label: 'Graduate degree',
    },
  ],
  Income: [
    {
      id: 44,
      label: '$14,999 or less',
    },
    {
      id: 45,
      label: '$15,000-$29,999',
    },
    {
      id: 46,
      label: '$30,000-$49,999',
    },
    {
      id: 47,
      label: '$50,000-$74,999',
    },
    {
      id: 48,
      label: '$75,000-$99,999',
    },
    {
      id: 49,
      label: '$100,000-$124,999',
    },
    {
      id: 50,
      label: '$125,000-$149,999',
    },
    {
      id: 51,
      label: '$150,000-$199,999',
    },
    {
      id: 52,
      label: '$200,000-$299,999',
    },
    {
      id: 53,
      label: '$300,000 or more',
    },
  ],
} as DemographicsChoicesResponse;
