import { DateRangeContext } from 'contexts/DateRangeContext';
import { MapsContext } from 'contexts/MapsContext';
import React, { useContext, useEffect } from 'react';
import { reduceDivisionsToAreasResponse } from 'screens/blockwise/BlockwiseDashboard.helpers';
import { AreasWithScoresObject } from 'types/areas';
import { SummaryAspectWidget } from 'types/summaryAspectWidget';
import {
  formatAspectScoresToDivisionScoresByAspect,
  matchAndFormatDivisionWithScoreByAspect,
} from 'utils/Map/AreaHelpers';

interface Props {
  selectedDateWidgetData?: SummaryAspectWidget;
  activeTab?: number;
}
export const useDivisionWithScoreByAspect = ({
  selectedDateWidgetData,
  activeTab,
}: Props): AreasWithScoresObject | undefined => {
  const { divisions } = useContext(MapsContext);
  const { scoreDateRangeFilters } = useContext(DateRangeContext);
  const [divisionWithScoreByAspect, setDivisionWithScoreByAspect] = React.useState<AreasWithScoresObject>();

  useEffect(() => {
    if (!selectedDateWidgetData || !divisions || !scoreDateRangeFilters || (!activeTab && activeTab !== 0)) return;
    // Format aspect scores to division scores by aspect.
    const divisionAreas = reduceDivisionsToAreasResponse(divisions.filter((division) => division.depth <= 1));
    const dateRanges = scoreDateRangeFilters?.map((dateRangeFilterOption) => ({
      start_date: dateRangeFilterOption.startDate,
      end_date: dateRangeFilterOption.endDate,
    }));
    const aspectScoresToDivisionScoresByAspect = formatAspectScoresToDivisionScoresByAspect(selectedDateWidgetData);
    const formattedDivisionWithScoreByAspect = matchAndFormatDivisionWithScoreByAspect({
      aspectScoresByDivisionDateRange: aspectScoresToDivisionScoresByAspect,
      dateRangeOptionsLength: dateRanges?.length,
      divisionAreas,
    });
    const aspectAreasWithScores = formattedDivisionWithScoreByAspect?.[activeTab]?.divisionScores;
    setDivisionWithScoreByAspect(aspectAreasWithScores);
  }, [selectedDateWidgetData, divisions, scoreDateRangeFilters, activeTab]);

  return divisionWithScoreByAspect;
};
