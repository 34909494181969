import { SurveyType } from '@zencity/survey-types';
import classnames from 'classnames';
import { ErrorBoundaryWithIcon } from 'components/ErrorBoundaryWithIcon/ErrorBoundaryWithIcon';
import { FullScreenEmptyState } from 'components/FullScreenEmptyState/FullScreenEmptyState';
import { ProductNames } from 'constants/products';
import { AccountsContext } from 'contexts/AccountsContext';
import { DashboardContext } from 'contexts/DashboardContext';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { FiltersContext } from 'contexts/FiltersContext';
import { MapsContext } from 'contexts/MapsContext';
import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import { QuestionsFeedContext } from 'contexts/QuestionsFeedContext';
import { useDashboardDateRanges } from 'hooks/dashboardDateRange';
import { useFiltersData } from 'hooks/dataFilters';
import { useGeographicData } from 'hooks/geographicData';
import { useOpenEndedQuestions } from 'hooks/openEndedQuestions';
import { useCrossQuestion } from 'hooks/useCrossQuestion';
import { useSurveysInitialLoad } from 'hooks/useSurveysInitialLoad';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { PulseRoutes } from 'screens/pulse/router';
import { Client } from 'types/client';
import { User } from 'types/user';
import { initLogger } from 'utils/pulse/logger';
import { config } from '../../../config/config';
import '../../i18n';
import '../../index.scss';
import { useObserverResize } from '../Screens.helpers';
import styles from './Pulse.module.scss';

interface Props {
  user: User;
  client: Client;
}
// eslint-disable-next-line max-lines-per-function,max-statements,complexity
const PulseDashboard = function PulseDashboard({ user, client }: Props): React.ReactElement {
  const { t: translate } = useTranslation();
  const { dateRangeDataContextData, currentSurveyGroup } = useDashboardDateRanges(SurveyType.PULSE);
  const { filtersContextData } = useFiltersData(currentSurveyGroup);
  const crossSectionStates = useCrossQuestion();
  const { client: leanSurveyClient } = currentSurveyGroup ?? {};
  useSurveysInitialLoad(client, user, initLogger, ProductNames.PULSE);
  const [currentLayout, onContentRender] = useObserverResize();
  const { openEndedQuestions, areOpenEndedQuestionsLoading } = useOpenEndedQuestions(currentSurveyGroup);
  const {
    mapsDataIsLoading,
    setMapsDataIsLoading,
    areasWithScores,
    selectedAreaIds,
    setSelectedAreaIds,
    sortedAreaIdsByAreaName,
    divisionsDictionary,
    divisions,
    hoveredAreaId,
    setHoveredAreaId,
  } = useGeographicData(currentSurveyGroup);
  if (!dateRangeDataContextData.isContextLoaded) {
    return <></>;
  }
  if (!currentSurveyGroup) {
    return (
      <FullScreenEmptyState
        iconName="card-bars-bulb.svg"
        description={translate('emptyState.noSurveyGroup.description')}
        title={translate('emptyState.noSurveyGroup.title')}
      />
    );
  }
  return dateRangeDataContextData.isContextLoaded ? (
    <div className={classnames(styles.app, currentLayout)} ref={onContentRender}>
      <DashboardContext.Provider
        value={{
          ...crossSectionStates,
          currentSurveyGroup,
        }}
      >
        <AccountsContext.Provider value={{ client, user, leanSurveyClient }}>
          <QuestionsFeedContext.Provider value={{ surveyType: SurveyType.PULSE }}>
            <OpenEndedQuestionFeedContext.Provider value={{ openEndedQuestions, areOpenEndedQuestionsLoading }}>
              <DateRangeContext.Provider value={dateRangeDataContextData}>
                <FiltersContext.Provider value={filtersContextData}>
                  <MapsContext.Provider
                    value={{
                      isLoading: mapsDataIsLoading,
                      setIsLoading: setMapsDataIsLoading,
                      areasWithScoresData: areasWithScores,
                      selectedAreaIds,
                      setSelectedAreaIds,
                      hoveredAreaId,
                      setHoveredAreaId,
                      sortedAreaIdsByAreaName,
                      divisionAreaScoresByQuestion: {},
                      divisions,
                      selectedGenericQuestionId: '',
                      setSelectedGenericQuestionId: () => {},
                      setAreasWithScoresData: () => {},
                      divisionsDictionary,
                    }}
                  >
                    <BrowserRouter basename={config.pulse.baseRouterUrl}>
                      <ErrorBoundaryWithIcon
                        errorTitle={translate('errorBoundary.title')}
                        errorDescription={translate('errorBoundary.description')}
                      >
                        {currentSurveyGroup && (
                          <PulseRoutes basename={config.pulse.baseRouterUrl} currentSurveyGroup={currentSurveyGroup} />
                        )}
                      </ErrorBoundaryWithIcon>
                    </BrowserRouter>
                  </MapsContext.Provider>
                </FiltersContext.Provider>
              </DateRangeContext.Provider>
            </OpenEndedQuestionFeedContext.Provider>
          </QuestionsFeedContext.Provider>
        </AccountsContext.Provider>
      </DashboardContext.Provider>
    </div>
  ) : (
    <></>
  );
};

export default PulseDashboard;
