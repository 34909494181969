import { noScoresEmptyStateSvg } from 'assets/svgIcons';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import emptyStateWithCustomIconStyles from '../EmptyStateWithCustomIcon/EmptyStateWithCustomIcon.module.scss';

/**
 * Empty state for when there are no scores.
 *
 * Using the same styling rules defined for `EmptyStateWithCustomIcon` component.
 */
export const FeedEmptyState = (): ReactElement => {
  const { t: translate } = useTranslation();
  // eslint-disable-next-line i18next/no-literal-string
  const baseTranslationPath = 'communitySurvey.noScoresEmptyState';

  return (
    <div className={emptyStateWithCustomIconStyles.emptyStateWithCustomIcon}>
      {noScoresEmptyStateSvg}
      <div className={emptyStateWithCustomIconStyles.content}>
        <h2>{translate(`${baseTranslationPath}.title`)}</h2>
      </div>
    </div>
  );
};
