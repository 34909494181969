import { Interaction } from "types/volumeOfInteractions";

export const volumeOfInteractionsMock: Interaction[] = [
  {
    date: '2022-07-19T07:00:00.000Z',
    categories: { total: { positiveSentiment: 1207, negativeSentiment: 234, neutralSentiment: 4691 } },
  },
  {
    date: '2022-07-20T07:00:00.000Z',
    categories: { total: { positiveSentiment: 1149, negativeSentiment: 567, neutralSentiment: 6700 } },
  },
  {
    date: '2022-07-21T07:00:00.000Z',
    categories: { total: { positiveSentiment: 2108, negativeSentiment: 332, neutralSentiment: 5616 } },
  },
  {
    date: '2022-07-22T07:00:00.000Z',
    categories: { total: { positiveSentiment: 1098, negativeSentiment: 390, neutralSentiment: 6314 } },
  },
  {
    date: '2022-07-23T07:00:00.000Z',
    categories: { total: { positiveSentiment: 944, negativeSentiment: 289, neutralSentiment: 2999 } },
  },
  {
    date: '2022-07-24T07:00:00.000Z',
    categories: { total: { positiveSentiment: 364, negativeSentiment: 219, neutralSentiment: 1391 } },
  },
  {
    date: '2022-07-25T07:00:00.000Z',
    categories: { total: { positiveSentiment: 419, negativeSentiment: 245, neutralSentiment: 2065 } },
  },
  {
    date: '2022-07-26T07:00:00.000Z',
    categories: { total: { positiveSentiment: 76, negativeSentiment: 40, neutralSentiment: 450 } },
  },
];
