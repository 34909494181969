import classnames from 'classnames';
import { AnalyticOriginPropertyValues } from 'constants/analytics';
import { FiltersContext } from 'contexts/FiltersContext';
import React, { ReactElement, useContext } from 'react';
import {
  findMatchingClassificationSubTopicOption,
  findMatchingClassificationTopicOption,
} from 'screens/OpenQuestionFeed/components/Answer/components/Topic/Topic.helpers';
import { analyticsService } from 'services/analytics/analytics';
import { formatTitle } from 'utils/formatters';
import styles from '../../Answer.module.scss';

interface Props {
  topic: string;
}

const Topic = ({ topic }: Props): ReactElement => {
  const { classificationTopicsOptions, setSelectedClassificationTopicsKeys } = useContext(FiltersContext);

  const matchingClassificationTopic = findMatchingClassificationTopicOption(classificationTopicsOptions, topic);

  const matchingClassificationSubTopic =
    matchingClassificationTopic && findMatchingClassificationSubTopicOption(matchingClassificationTopic, topic);

  const handleOnSubTopicClick = () => {
    setSelectedClassificationTopicsKeys([
      matchingClassificationTopic?.key as string,
      ...(matchingClassificationSubTopic ? [matchingClassificationSubTopic?.key as string] : []),
    ]);

    if (matchingClassificationTopic?.label) {
      analyticsService.crossProductEvents.feedTopicSelected({
        origin: AnalyticOriginPropertyValues.COMMENT,
        type: 'subtopic',
        actionType: 'select',
        topicDisplayName: matchingClassificationTopic.label ?? matchingClassificationSubTopic?.label,
        subTopicsDisplayNames: [matchingClassificationSubTopic?.label ?? matchingClassificationTopic.label],
      });
    }
  };

  const displayLabelOnly = !matchingClassificationTopic;

  if (displayLabelOnly) {
    return <span className={styles.topic}>{formatTitle(topic)}</span>;
  }

  return (
    <button type="button" className={classnames(styles.topic, styles.clickable)} onClick={handleOnSubTopicClick}>
      {formatTitle(topic)}
    </button>
  );
};
export default Topic;
