import { ProgressBar } from '@zencity/common-ui';
import classnames from 'classnames';
import { AnalyticOriginPropertyValues } from 'constants/analytics';
import { FiltersContext } from 'contexts/FiltersContext';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  findMatchingClassificationSubTopicOption,
  findMatchingClassificationTopicOption,
} from 'screens/OpenQuestionFeed/components/Answer/components/Topic/Topic.helpers';
import { analyticsService } from 'services/analytics/analytics';
import { formatTitle } from 'utils/formatters';
import styles from './LeadingTopic.module.scss';

interface Props {
  id: number;
  value: string;
  responses: number;
  fill: number;
  isClassificationTopic: boolean;
}

export const LeadingTopic = (props: Props): ReactElement => {
  const { value, id, responses, fill, isClassificationTopic } = props;
  const { t: translate } = useTranslation();

  const { classificationTopicsOptions, setSelectedClassificationTopicsKeys } = useContext(FiltersContext);

  const matchingClassificationTopicOption = findMatchingClassificationTopicOption(classificationTopicsOptions, value);
  const matchingClassificationSubTopicOption =
    matchingClassificationTopicOption &&
    findMatchingClassificationSubTopicOption(matchingClassificationTopicOption, value);

  const handleOnTopicClick = () => {
    if (isClassificationTopic) {
      // Select all classification sub topics for the classification topic.
      setSelectedClassificationTopicsKeys([
        matchingClassificationTopicOption?.key as string,
        ...(matchingClassificationTopicOption?.children?.map((child) => child.key as string) || []),
      ]);
    } else {
      setSelectedClassificationTopicsKeys([
        matchingClassificationTopicOption?.key as string,
        ...(!isClassificationTopic && matchingClassificationTopicOption
          ? [matchingClassificationSubTopicOption?.key as string]
          : []),
      ]);
    }

    if (
      matchingClassificationTopicOption?.label &&
      matchingClassificationTopicOption.children &&
      matchingClassificationSubTopicOption?.label
    ) {
      analyticsService.crossProductEvents.feedTopicSelected({
        origin: AnalyticOriginPropertyValues.TOPICS_WIDGET,
        type: isClassificationTopic ? 'topic' : 'subtopic',
        actionType: 'select',
        topicDisplayName: matchingClassificationTopicOption.label,
        subTopicsDisplayNames: isClassificationTopic
          ? matchingClassificationTopicOption.children.map((child) => child.label ?? '')
          : [matchingClassificationSubTopicOption.label],
      });
    }
  };

  const displayLabelOnly = !matchingClassificationTopicOption;

  return (
    <div key={id} className={styles.topicSection} data-testid="topic-section">
      <button
        type="button"
        className={classnames(styles.topic, { [styles.disabled]: displayLabelOnly })}
        disabled={displayLabelOnly}
        onClick={handleOnTopicClick}
      >
        {formatTitle(value)}
      </button>
      <div className={styles.responses}>{translate('leadingTopics.responses', { responses })}</div>
      <ProgressBar fill={fill} />
    </div>
  );
};
