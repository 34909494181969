import { Flex } from 'components/Flex/Flex';
import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import React, { useContext } from 'react';
import { AspectWidgetTab } from './AspectWidgetTab/AspectWidgetTab';
import styles from './AspectWidgetTabs.module.scss';

export const AspectWidgetTabs = (): JSX.Element => {
  const { selectedDateWidgetData, activeTab } = useContext(SummaryAspectWidgetContext);
  return (
    <div className={styles.aspectWidgetTabs}>
      <Flex width="100%" className={styles.tabs} alignItems="center">
        {selectedDateWidgetData?.aspect_scores.map((item) => (
          <AspectWidgetTab key={item.aspect_name} aspectScores={item} isActive={item.aspect_id === activeTab} />
        ))}
      </Flex>
    </div>
  );
};
