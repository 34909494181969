import { ZCDSearchBar } from '@zencity/common-ui';
import { FiltersContext } from 'contexts/FiltersContext';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * A component filter the open ended answers by search term.
 */
export const FeedAnswerSearch: React.FC = function FeedAnswerSearch(): ReactElement {
  const { answerSearchTerm, setAnswerSearchTerm } = useContext(FiltersContext);
  const { t: translate } = useTranslation();
  return (
    <ZCDSearchBar
      value={answerSearchTerm}
      onChange={setAnswerSearchTerm}
      placeholder={translate('feedSearchFilter.placeholder')}
      shouldClearInput={!answerSearchTerm}
    />
  );
};
