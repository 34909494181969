import { answerMock } from 'mocks/data/answer.mock';
import { answersSummary } from 'mocks/data/answerSummary.mock';
import { AnswerFilterParams, AnswerResponse, AnswerSummaryData } from 'types/answer';
import { Answer, VaultPaginatedResponse } from '@zencity/survey-types';
import { ExtraFiltersParamsType } from 'types/misc';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.answers));

/**
 * Get answers for a question with segmentation.
 */
export async function fetchAllResponsesOfQuestion(params: AnswerFilterParams): Promise<AnswerResponse> {
  if (config.shouldMockRequests) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          items: answerMock,
          count: answerMock.length,
        });
      }, 3000);
    });
  }
  const response = await axiosInstance.get<VaultPaginatedResponse<Answer>>('', { params });

  return { items: response.data.results, count: response.data.count };
}

/**
 * Fetch summarized data of all answers for a question.
 */
export async function fetchAnswersSummary(
  questionId: number,
  extraParams?: ExtraFiltersParamsType,
): Promise<AnswerSummaryData> {
  if (config.shouldMockRequests) {
    return Promise.resolve(answersSummary[0]);
  }
  const response = await axiosInstance.get<{ results: AnswerSummaryData }>('summary/', {
    params: {
      question_id: questionId,
      ...extraParams,
    },
  });

  return response.data.results;
}
