import { config } from '../../config/config';
import { createAxiosInstance } from '../utils/Api';

const axiosInstance = createAxiosInstance(config.apiUrls.reportsLibrary);

interface ReportData {
  id: string;
  reportViewUrl: string;
  reportDownloadUrl: string;
}

/**
 * Get a report metadata
 * from an endpoints in Core-Platform.
 * @param params
 * @return Promise<ReportData>
 */
export async function fetchReportForSurvey(params: { id: string }): Promise<ReportData> {
  const result = await axiosInstance.get<ReportData>(params.id, {});
  return result.data;
}
