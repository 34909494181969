import { getMapCenterByArea } from 'components/MapView/MapView.helpers';
import { Feature } from 'geojson';
import { useEffect, useState } from 'react';
import { AreasWithScoresObject, FEATURE_COLLECTION } from 'types/areas';

interface Props {
  areasWithScores: AreasWithScoresObject;
}

export const useMapLabelData = (props: Props) => {
  const { areasWithScores } = props;
  const [labelData, setLabelData] = useState<GeoJSON.FeatureCollection>();

  useEffect(() => {
    const featureList = Object.values(areasWithScores).reduce<Feature[]>((featuresList, areaWithScoresData) => {
      if (areaWithScoresData?.area) {
        return [...featuresList, getMapCenterByArea(areaWithScoresData)];
      }
      return featuresList;
    }, []);
    setLabelData({ type: FEATURE_COLLECTION, features: featureList });
  }, [areasWithScores]);

  return { labelData };
};
