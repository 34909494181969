import { HorizontalSeparator, ZCDIconButton } from '@zencity/common-ui';
import { LeadingTopic } from 'components/LeadingTopicsWidget/components/LeadingTopic';
import React, { ReactElement, useRef, useState } from 'react';
import { DisplayableGroupedLeadingTopic } from 'types/leadingTopics';
import styles from './LeadingClassificationTopic.module.scss';

interface Props {
  classificationTopic: DisplayableGroupedLeadingTopic;
}

export const LeadingClassificationTopic = (props: Props): ReactElement => {
  const { classificationTopic } = props;
  const [showLeadingClassificationSubTopics, setShowLeadingClassificationSubTopics] = useState(false);
  const showSubTopicsIcon = showLeadingClassificationSubTopics ? 'chevron-up' : 'chevron-down';

  const zcdIconButtonRef = useRef<HTMLDivElement>(null);

  const handleOnShowLeadingClassificationSubTopics = () => {
    setShowLeadingClassificationSubTopics(!showLeadingClassificationSubTopics);
  };

  return (
    <div className={styles.topicSection}>
      <div className={styles.leadingClassificationTopic}>
        {!classificationTopic.leadingClassificationSubTopics.length && (
          <div className={styles.zcdIconButtonContainerWidth} />
        )}
        {classificationTopic.leadingClassificationSubTopics.length > 0 && (
          <div ref={zcdIconButtonRef}>
            <ZCDIconButton
              icon={showSubTopicsIcon}
              onClick={handleOnShowLeadingClassificationSubTopics}
              type="button"
              variant="menu"
            />
          </div>
        )}
        <LeadingTopic
          id={classificationTopic.classificationTopicId}
          value={classificationTopic.classificationTopicValue}
          responses={classificationTopic.responses}
          fill={classificationTopic.fill}
          isClassificationTopic
        />
      </div>
      {showLeadingClassificationSubTopics && <HorizontalSeparator />}
      {showLeadingClassificationSubTopics &&
        classificationTopic.leadingClassificationSubTopics.map((classificationSubTopic, index) => (
          <div key={classificationSubTopic.classificationSubTopicId} className={styles.leadingClassificationSubTopics}>
            <LeadingTopic
              id={classificationSubTopic.classificationSubTopicId}
              value={classificationSubTopic.classificationSubTopicValue}
              responses={classificationSubTopic.responses}
              fill={classificationSubTopic.fill}
              isClassificationTopic={false}
            />
            {index < classificationTopic.leadingClassificationSubTopics.length - 1 && <HorizontalSeparator />}
          </div>
        ))}
    </div>
  );
};
