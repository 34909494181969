import { Button, Icon } from '@zencity/common-ui';
import { SurveyRequest } from '@zencity/survey-types';
import classnames from 'classnames';
import { SurveyRequestStatusBadge } from 'components/SurveyRequestStatusBadge/SurveyRequestStatusBadge';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { getRequestTitle } from 'utils/community-asks/surveyRequest';
import { formatDate } from 'utils/formatters';
import { ConditionalLink } from '../ConditionalLink/ConditionalLink';
import styles from './SurveyRequestItem.module.scss';

export interface Props {
  surveyRequest: SurveyRequest;
}

/**
 * The Community Asks Survey Request item, to be shown on the Workspace screen.
 *
 * @props surveyRequest - The Vault survey request object.
 */
export const SurveyRequestItem: React.FC<Props> = function SurveyRequestItem({ surveyRequest }: Props): ReactElement {
  const { survey_group: surveyGroup, status, created_at: createdAt } = surveyRequest;
  const { t: translate } = useTranslation();

  // eslint-disable-next-line i18next/no-literal-string
  const baseTranslationPath = 'communityAsks.surveyRequests';

  // Use a conditional link component to render either a Link or a div component based off of
  // if there is a survey group.
  const className = classnames(styles.surveyRequestItem, styles[status]);

  return (
    <ConditionalLink
      condition={!!surveyGroup}
      link={`/survey/${surveyGroup?.id}`}
      className={className}
      onLinkClick={() =>
        analyticsService.communityAsksEvents.viewAskPage(
          getRequestTitle(surveyRequest, translate(`${baseTranslationPath}.placeholderTitle`)),
        )
      }
    >
      <div className={classnames(styles.iconContainer, styles[status])}>
        <Icon name="topic-illustrations/text-bubbles.svg" width={83} height={53} />
      </div>
      <div className={styles.surveyRequestContent}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <h4 className={styles.requestTitle}>
          {getRequestTitle(surveyRequest, translate(`${baseTranslationPath}.placeholderTitle`))}
        </h4>
        <div className={styles.statusDateContainer}>
          <p className={styles.requestDate}>
            {surveyGroup
              ? `${translate('communityAsks.published')}: ${formatDate(surveyGroup.updated_at)}`
              : `${translate('communityAsks.created')}: ${formatDate(createdAt)}`}
          </p>
          <SurveyRequestStatusBadge status={status} />
        </div>
        {surveyGroup && (
          <div className={styles.results}>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <Button variant="secondary" size="sm">
              {translate(`${baseTranslationPath}.resultsButton`)}
            </Button>
          </div>
        )}
      </div>
    </ConditionalLink>
  );
};
