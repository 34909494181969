/* eslint-disable i18next/no-literal-string */
import { Card, Icon, Status, StatusBox } from '@zencity/common-ui';
import { QuestionType } from '@zencity/survey-types';
import { getTabPath, TabKeys } from 'constants/tabs';
import { useClientNameTokenReplacement } from 'hooks/useClientNameTokenReplacement/useClientNameTokenReplacement';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { analyticsService } from 'services/analytics/analytics';
import { fetchAnswersSummary } from 'services/answer';
import { AnswerSummaryData } from 'types/answer';
import { QuestionSummaryData } from 'types/question';
import { logger } from 'utils/community-asks/logger';
import { AnswerChoicesBreakdown } from '../AnswerChoicesBreakdown/AnswerChoicesBreakdown';
import styles from './QuestionSummary.module.scss';

interface Props {
  surveyGroupId: string;
  totalRespondents: number;
  question: QuestionSummaryData;
  /**
   * position of this question in the summary-questions-list
   */
  position: number;
}

/**
 * A summary of the answers of a question.
 */
// eslint-disable-next-line complexity
export const QuestionSummary: React.FC<Props> = function QuestionSummary(props: Props): ReactElement {
  const { surveyGroupId, totalRespondents, question, position } = props;
  const { t: translate } = useTranslation();
  const [loadingStatus, setLoadingStatus] = useState<Status>(Status.LOADING);
  const [answers, setAnswers] = useState<AnswerSummaryData | null>(null);

  const summaryIsSupported = useMemo(() => {
    // List the QuestionTypes which are supported by the Answers' "summary"
    // endpoint.
    const supportedAnswerSummary = [
      QuestionType.OPEN_ENDED,
      QuestionType.SCALE,
      QuestionType.YES_NO,
      QuestionType.CHOICES_OPTIONS,
    ];

    return supportedAnswerSummary.includes(question.type as QuestionType);
  }, [question]);

  useEffect(() => {
    if (question) {
      if (!summaryIsSupported) {
        // Skip the request to the server for non-supported types.
        setLoadingStatus(Status.OKAY);
        return;
      }

      fetchAnswersSummary(question.id)
        .then((results) => {
          setAnswers(results);
          setLoadingStatus(Status.OKAY);
        })
        .catch((error) => {
          logger.error(error);
          setLoadingStatus(Status.ERROR);
        });
    }
  }, [question, summaryIsSupported]);

  const isScaleQuestionWithDescription = question.type === QuestionType.SCALE && !!question.description;
  const hasMoreThan100Precent = answers && answers.total_responses > question.total_answers;
  const questionText = question.overridden_text || question.text;
  const { clientNameTokenReplacement } = useClientNameTokenReplacement();
  const formattedQuestionText = clientNameTokenReplacement(questionText);
  return (
    <div className={styles.questionSummary} data-testid="question-summary">
      <StatusBox status={loadingStatus}>
        <div className={styles.iconSide}>
          <span className={styles.positionLabel}>
            <Icon
              name={question.type === QuestionType.OPEN_ENDED ? 'two-lines.svg' : 'three-bars.svg'}
              width={13}
              height={10}
            />
            {position}
          </span>
        </div>
        <div className={styles.contentSide}>
          <h3>{formattedQuestionText}</h3>
          {summaryIsSupported && (
            <>
              {isScaleQuestionWithDescription && <p className={styles.scaleDescription}>{question.description}</p>}
              <h5>
                {translate('communityAsksManagement.summary.question.respondentsTitle', {
                  numberOfRespondents: question.total_answers,
                  totalRespondents,
                })}
              </h5>
              <div>
                {question.type === QuestionType.OPEN_ENDED ? (
                  <Card.Link>
                    <Link
                      to={getTabPath(
                        TabKeys.FEED,
                        generatePath(CommunityAsksRouterPaths.SURVEY_PAGE, { surveyGroupId }),
                      )}
                      onClick={() => analyticsService.communityAsksEvents.openInFeedButtonClicked()}
                    >
                      {translate('communityAsksManagement.summary.question.openInFeed')}
                    </Link>
                  </Card.Link>
                ) : (
                  answers && <AnswerChoicesBreakdown answerChoices={answers.choices} total={question.total_answers} />
                )}
              </div>
              {hasMoreThan100Precent && (
                <div className={styles.footNote}>{translate('communityAsksManagement.summary.footnote')}</div>
              )}
            </>
          )}
        </div>
      </StatusBox>
    </div>
  );
};
