import { Button, Icon } from '@zencity/common-ui';
import { CommunityAsksContext } from 'contexts/CommunityAsksContext';
import { CommunityAsksToastContext } from 'contexts/CommunityAsksToastContext';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { submitSurveyRequest } from 'services/surveyRequest';
import { SurveyType } from '@zencity/survey-types';
import { SurveyRequestPayload } from 'types/surveyRequest';
import { logger } from 'utils/community-asks/logger';
import styles from './RequestFormHeader.module.scss';

/**
 * This header will only render when the client does not have any Asks yet.
 * This is the initial header for the Workspace screen.
 */
export const RequestFormHeader = (): ReactElement => {
  const { setSurveyRequests } = useContext(CommunityAsksContext);
  const { toastSuccess, toastError } = useContext(CommunityAsksToastContext);
  const { t: translate } = useTranslation();
  const [requestText, setRequestText] = useState('');
  const [submitRequestLoading, setSubmitRequestLoading] = useState(false);
  // eslint-disable-next-line i18next/no-literal-string
  const translationBaseKey = 'communityAsks.requestFormHeader';

  /**
   * Send a request to Vault to create a Survey Request for a "Community Asks".
   *
   * @param event - The form event.
   */
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setSubmitRequestLoading(true);
    analyticsService.communityAsksEvents.requestCustomSentInitialState();
    const payload: SurveyRequestPayload = {
      surveyType: SurveyType.COMMUNITY_ASKS,
      requestText,
      // TODO: Get the CSM info from insights (`/api/slack/userInfo`).
      csmSlack: '',
    };

    submitSurveyRequest(payload)
      .then((surveyRequest) => {
        setSurveyRequests((prevSurveyRequests) => [...prevSurveyRequests, surveyRequest]);
        setSubmitRequestLoading(false);
        // Display a success message to the user.
        toastSuccess();
      })
      .catch((error) => {
        logger.error(error);
        setSubmitRequestLoading(false);
        // Display an error message to the user.
        toastError();
      });
  };

  const handleOnTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRequestText(event.target.value);
  };

  return (
    <div className={styles.requestFormHeader}>
      <div className={styles.iconContainer}>
        <Icon name="person-in-phone.svg" width={313.5} height={397} className={styles.icon} />
      </div>
      <div className={styles.requestForm}>
        <h1>{translate(`${translationBaseKey}.header`)}</h1>
        <h4 className={styles.subHeader}>{translate(`${translationBaseKey}.subTitle`)}</h4>
        <form onSubmit={handleOnSubmit}>
          <textarea
            placeholder={translate(`${translationBaseKey}.textareaPlaceholder`)}
            value={requestText}
            onChange={handleOnTextareaChange}
          />

          <div className={styles.actionsContainer}>
            <a
              href="https://help.zencity.io/hc/en-us/articles/5047561442705-Zencity-Community-Asks"
              target="_blank"
              className={styles.helpArticleLink}
              rel="noopener noreferrer"
              onClick={() => analyticsService.communityAsksEvents.helpCenterClicked()}
            >
              {translate(`${translationBaseKey}.helpArticleLink`)}
            </a>
            <Button type="submit" disabled={submitRequestLoading}>
              {translate('communityAsks.requestButton')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
