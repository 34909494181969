import { CircularProgressbar } from 'components/BlockwiseSummaryTab/CircularProgressbar/CircularProgressbar';
import { ZCDChangeIndicatorHOC } from 'components/BlockwiseSummaryTab/ZCDChangeIndicatorHOC/ZCDChangeIndicatorHOC';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { AspectDemographicsSegment } from 'types/summaryAspectWidget';
import {
  MAX_UNREPRESENTATIVE_INDICATOR,
  MIN_UNREPRESENTATIVE_INDICATOR,
} from 'components/UnrepresentativeIndicator/constants';
import { UnrepresentativeIndicator } from 'components/UnrepresentativeIndicator/UnrepresentativeIndicator';
import { useTranslation } from 'react-i18next';
import styles from './DemographicGroupItem.module.scss';

interface Props {
  demographicGroupIem: AspectDemographicsSegment;
  comparisonDemographicGroupItem?: AspectDemographicsSegment;
}

export const DemographicGroupItem = (props: Props): JSX.Element => {
  const { demographicGroupIem, comparisonDemographicGroupItem } = props;
  const { total_submissions: totalSubmissions, score } = demographicGroupIem;
  const { t: translate } = useTranslation();

  if (totalSubmissions < MIN_UNREPRESENTATIVE_INDICATOR)
    return (
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <span>{demographicGroupIem.label}</span>
        <Flex alignItems="center">
          <span className={styles.noScoreTitle}>{translate('breakdownWidget.noScore')}</span>
          <Spacer orientation="horizontal" size="xsmall" />
          <UnrepresentativeIndicator totalSubmissions={demographicGroupIem.total_submissions} />
        </Flex>
      </Flex>
    );

  return (
    <Flex className={styles.demographicGroupItem} alignItems="center" justifyContent="space-between" width="100%">
      <Flex alignItems="center">
        <CircularProgressbar value={score} pathColor={styles.pathColor} innerText={`${score.toFixed(0)}%`} />
        <Spacer orientation="horizontal" size="small" />
        <span>{demographicGroupIem.label}</span>
        {totalSubmissions < MAX_UNREPRESENTATIVE_INDICATOR && (
          <>
            <Spacer orientation="horizontal" size="small" />
            <UnrepresentativeIndicator totalSubmissions={demographicGroupIem.total_submissions} />
          </>
        )}
      </Flex>
      <ZCDChangeIndicatorHOC grayscale currentScore={score} comparisonScore={comparisonDemographicGroupItem?.score} />
    </Flex>
  );
};
