import classNames from 'classnames';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import { DateRangeContext } from 'contexts/DateRangeContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isFeedLatestResult } from 'utils/dateRange';
import styles from './OverallWidgets.module.scss';
import { OverallWidgetWrapper } from './OverallWidgetWrapper/OverallWidgetWrapper';
import { TopConcernsWidget } from './TopConcernsWidget/TopConcernsWidget';
import { TotalSubmissionsWidget } from './TotalSubmissionsWidget/TotalSubmissionsWidget';
import { MethodologyCTA } from './MethodologyCTA/MethodologyCTA';

interface Props {
  setIsEmptyState?: (isEmptyState: boolean) => void;
}

export const OverallWidgets = ({ setIsEmptyState }: Props): JSX.Element => {
  const { selectedDateRange, completedCyclesDateRanges } = useContext(DateRangeContext);
  const { t: translate } = useTranslation();
  const isLatestResult = isFeedLatestResult(selectedDateRange);

  const dateLabel = isLatestResult
    ? completedCyclesDateRanges?.[0]?.label
    : completedCyclesDateRanges?.find(
        (dateRange) =>
          dateRange?.startDate === selectedDateRange?.startDate && dateRange?.endDate === selectedDateRange?.endDate,
      )?.label;

  const onUnrepresentativeData = () => {
    setIsEmptyState?.(true);
  };

  return (
    <div className={styles.overallWidgets}>
      <Flex alignItems="center">
        <span className={styles.title}>{translate('blockwiseSummaryTab.overallWidgets.title')}</span>
        <Spacer orientation="horizontal" size="small" />
        <span className={classNames(styles.selectedDate, styles.title)}>{dateLabel}</span>
      </Flex>
      <div className={styles.widgetsGrid}>
        <OverallWidgetWrapper>
          <TotalSubmissionsWidget onUnrepresentativeData={onUnrepresentativeData} />
        </OverallWidgetWrapper>
        <OverallWidgetWrapper>
          <TopConcernsWidget />
        </OverallWidgetWrapper>
        <OverallWidgetWrapper>
          <MethodologyCTA />
        </OverallWidgetWrapper>
      </div>
    </div>
  );
};
