import React, { ReactElement } from 'react';
import { SubmissionCxData } from '@zencity/survey-types';
import styles from '../Answer.module.scss';
import { generateCXCaseContextUrl } from './Description';

export const CXSurveyDataContextLink = ({ submissionCxData }: { submissionCxData: SubmissionCxData }): ReactElement => {
  const { case_id: caseId, case_context: caseContext } = submissionCxData;
  const cxCaseContextUrl = generateCXCaseContextUrl(caseId, caseContext);
  return cxCaseContextUrl ? (
    <a href={cxCaseContextUrl} target="_blank" rel="noreferrer" className={styles.contextLink}>
      {caseContext}
    </a>
  ) : (
    <>{caseContext}</>
  );
};
