import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { config } from '../../../config/config';
import styles from './LocalFeatureSelection.module.scss';

const LocalFeatureSelection: React.FC = function LocalFeatureSelection(): React.ReactElement {
  return (
    <BrowserRouter basename={config.localFeatureSelection.baseRouterUrl}>
      <div className={styles.wrapper}>
        <a href={config.communityAsks.baseRouterUrl}>Community Asks</a>
        <a href={config.communitySurveys.baseRouterUrl}>Community Survey</a>
        <a href={config.blockwise.baseRouterUrl}>Blockwise</a>
        <a href={config.customerExperience.baseRouterUrl}>Customer(Resident) Experience</a>
        <a href={config.pulse.baseRouterUrl}>Pulse</a>
      </div>
    </BrowserRouter>
  );
};

export default LocalFeatureSelection;
