import { LayerProps } from 'react-map-gl';
import { ColorDictionary } from '../Map/ColorLegend/ColorLegend.helper';

/**
 * Fills the polygons color based off its positive score.
 * The colors and their starting (low) numbers are directly alligned with
 * the COLOR_MAP used in the Color Legend. Any changes to them should also
 * be reflected there as well.
 */
export const POLYGON_LAYER: LayerProps = {
  id: 'polygon-satisfaction',
  type: 'fill',
  paint: {
    'fill-color': [
      'interpolate',
      ['linear'],
      ['get', 'positiveScore'],
      -1,
      ColorDictionary.NOT_AVAILABLE_GRAY,
      0,
      ColorDictionary.RED_1,
      26,
      ColorDictionary.RED_2,
      36,
      ColorDictionary.RED_3,
      46,
      ColorDictionary.GRAY,
      61,
      ColorDictionary.GREEN_3,
      71,
      ColorDictionary.GREEN_2,
      86,
      ColorDictionary.GREEN_1,
    ],
  },
};

/** Adds labels to a polygon. */
export const LABEL_LAYER: LayerProps = {
  id: 'area-label',
  type: 'symbol',
  source: 'labelData',
  layout: {
    'text-field': '{name}',
    'text-allow-overlap': false,
    'text-font': ['Inter SemiBold', 'Open Sans Regular'],
    'text-size': 14,
  },
  paint: {
    'text-color': '#6D6D6D',
    'text-halo-color': '#FFFFFF',
    'text-halo-width': 1,
  },
};

/** Adds a border on a polygon to show that it is selected. */
export const SELECTED_AREAS_LAYER: LayerProps = {
  id: 'selected-areas',
  type: 'line',
  source: 'areaId',
  paint: {
    'line-color': '#3463FE',
    'line-width': 3,
  },
};

/** Adds a border on a polygon to show that it is selected. */
export const HOVERED_AREA_LAYER: LayerProps = {
  id: 'hovered-area',
  type: 'line',
  source: 'areaId',
  paint: {
    'line-color': '#98A5FE',
    'line-width': 3,
  },
};

/** Adds a strong gray border to areas that are marked as N/A. */
export const NOT_AVAILABLE_BORDER_LAYER: LayerProps = {
  id: 'not-available-border',
  type: 'line',
  paint: {
    'line-color': ['interpolate', ['linear'], ['get', 'positiveScore'], -1, '#D8D8D8', 0, '#FFFFFF'],
    'line-width': ['interpolate', ['linear'], ['get', 'positiveScore'], -1, 2, 0, 1],
  },
};
