import { ChangeTypes, ZCDChangeIndicator } from '@zencity/common-ui';
import classNames from 'classnames';
import { Flex } from 'components/Flex/Flex';
import { getTextColor } from 'components/OvertimeTrendsIndication/helpers';
import { SCORE_PERCENTAGE_POSTFIX } from 'constants/score';
import React from 'react';
import { calculateScoreComparisonDifference } from 'utils/analysis';
import styles from './ZCDChangeIndicatorHOC.module.scss';

interface BaseProps {
  grayscale?: boolean;
}

interface ComparisonProps extends BaseProps {
  currentScore: number;
  comparisonScore?: number;
  value?: never;
  showPercentagePostfix?: boolean;
}

interface StrictProps extends BaseProps {
  value: number;
  currentScore?: never;
  comparisonScore?: never;
  showPercentagePostfix?: boolean;
}

export const getChangeType = (percentageChange: number): ChangeTypes => {
  if (percentageChange > 0) {
    return ChangeTypes.INCREASE;
  }
  if (percentageChange < 0) {
    return ChangeTypes.DECREASE;
  }
  return ChangeTypes.NONE;
};

export const ZCDChangeIndicatorHOC = (props: StrictProps | ComparisonProps): JSX.Element => {
  const { value, currentScore, comparisonScore, grayscale, showPercentagePostfix = true } = props;
  const changeValue =
    value ?? (comparisonScore ? calculateScoreComparisonDifference(currentScore, comparisonScore) : 0);
  const changeType = getChangeType(changeValue);
  return (
    <Flex alignItems="center" className={classNames({ [styles.grayscale]: grayscale })}>
      <ZCDChangeIndicator changeType={changeType} percentage={Math.abs(changeValue)} isColorized />
      <span
        style={{
          color: getTextColor({
            difference: changeValue,
            inScoreWidget: !grayscale,
          }),
        }}
      >
        {showPercentagePostfix && SCORE_PERCENTAGE_POSTFIX}
      </span>
    </Flex>
  );
};
