import React, { ReactElement } from 'react';
import { Icon } from '@zencity/common-ui';
import styles from './FullScreenEmptyState.module.scss';

interface Props {
  title: string;
  description: string;
  iconName: string;
  children?: React.ReactNode;
}

export const FullScreenEmptyState = (props: Props): ReactElement => {
  const { title, description, iconName, children } = props;

  return (
    <div className={styles.fullScreenEmptyState}>
      <Icon name={iconName} height={54} width={54} />
      <div className={styles.content}>
        <h2>{title}</h2>
        <p className={styles.description}>{description}</p>
        {children}
      </div>
    </div>
  );
};
