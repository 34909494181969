import { TooltipInfo } from '@zencity/common-ui';
import React from 'react';
import styles from './TableSubheader.module.scss';

interface Props {
  subheader: string;
  width?: number;
}

function isEllipsisActive(element: HTMLElement): boolean {
  if (!element) return false;
  return element.scrollWidth > element.clientWidth;
}

export const TableSubheader = (props: Props) => {
  const { subheader, width = 60 } = props;
  const [displayTooltip, setDisplayTooltip] = React.useState(false);

  return (
    <TooltipInfo
      position="top"
      tooltipText={subheader}
      renderButton={(buttonRef, buttonProps): React.ReactNode => (
        <div
          ref={displayTooltip ? buttonRef : undefined}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          // The inline style is needed because we want to override the
          // inline style of cursor: pointer that comes from the `buttonProps`.
          style={{ cursor: 'auto' }}
        >
          <span
            ref={(spanRef) => {
              if (spanRef) {
                setDisplayTooltip(isEllipsisActive(spanRef));
              }
            }}
            className={styles.subheader}
            style={{ width }}
          >
            {subheader}
          </span>
        </div>
      )}
    />
  );
};
