export enum ReactQueryKeys {
  // Summary Tab.
  ASPECT_WIDGET = 'aspectWidget',
  DEMOGRAPHIC_WIDGET_FILTER = 'demographicWidgetFilter',
  OVERALL_SATISFACTION_WIDGET = 'overallSatisfactionWidget',
  SUBMISSIONS_WIDGET = 'submissionsWidget',
  TOP_CONCERNS_WIDGET = 'topConcernsWidget',

  // Blockwise dashboard.
  SURVEY_GROUP_DIVISIONS_SCORE = 'surveyGroupDivisionsScore',
  DIVISIONS_WITH_POLYGONS = 'divisionsWithPolygons',
  DASHBOARD_DATE_RANGES = 'dashboardDateRanges',

  // Analysis Tab.
  SURVEY_GROUP_SUBMISSIONS = 'surveyGroupSubmissions',
  QUESTION_SCORES = 'questionScores',
  COMPARISON_QUESTION_SCORES = 'comparisonQuestionScores',
  CROSS_QUESTIONS = 'crossQuestions',
  CROSS_QUESTIONS_SCORES = 'crossQuestionsScores',
  COMPARISON_CROSS_QUESTIONS_SCORES = 'comparisonCrossQuestionsScores',
}
