import { divisionScoresByQuestionMock } from 'mocks/data/divisionScoresByQuestion.mock';
import { surveyGroupMock, SurveyGroupSubmissions, surveyGroupSummary } from 'mocks/data/surveyGroup.mock';
import { ServerDateRangeParams } from 'types/dateRange';
import { VaultPaginatedResponse, SurveyType, SurveyGroup, SurveyGroupSummary } from '@zencity/survey-types';
import { DivisionScoresByGenericQuestion } from 'types/score';
import { SurveyGroupTotalSubmissions } from 'types/surveyGroup';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { SurveyGroupDemographicTypes } from 'types/demographicFilters';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.surveyGroup));

export async function fetchSurveyGroupSummary(surveyGroupId: number): Promise<SurveyGroupSummary> {
  if (config.shouldMockRequests) {
    return Promise.resolve(surveyGroupSummary);
  }
  const response = await axiosInstance.get<SurveyGroupSummary>('summary/', {
    params: {
      survey_group_id: surveyGroupId,
    },
  });

  return response.data;
}

/**
 * Fetch a client's survey groups by survey type.
 */
export async function fetchSurveyGroups(surveyType: SurveyType): Promise<VaultPaginatedResponse<SurveyGroup>> {
  if (config.shouldMockRequests) {
    return Promise.resolve({
      results: [surveyGroupMock],
      count: 1,
    });
  }
  const response = await axiosInstance.get<VaultPaginatedResponse<SurveyGroup>>('', {
    params: {
      survey_type: surveyType,
    },
  });

  return response.data;
}
/**
 * Fetch a client's "customer experience" survey groups.
 */
export async function fetchCustomerExperienceSurveyGroups(): Promise<VaultPaginatedResponse<SurveyGroup>> {
  const response = await axiosInstance.get<VaultPaginatedResponse<SurveyGroup>>('', {
    params: {
      survey_type: SurveyType.CUSTOMER_EXPERIENCE,
    },
  });

  return response.data;
}

/**
 * Get the division scores for all questions in a survey.
 *
 * The request method is POST, as the request has with lists of date ranges and division ID's to filter,
 * though it does not store anything.
 */
export async function fetchSurveyGroupDivisionsScore({
  surveyGroupId,
  dateRanges,
}: {
  surveyGroupId: number;
  dateRanges: ServerDateRangeParams[];
}): Promise<DivisionScoresByGenericQuestion[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve(divisionScoresByQuestionMock);
  }

  const response = await axiosInstance.post<DivisionScoresByGenericQuestion[]>(`${surveyGroupId}/division_scores/`, {
    date_ranges: dateRanges,
  });

  return response.data;
}

interface FetchSurveyGroupTotalSubmissionsParams {
  surveyGroupId: number;
  startDate: string;
  endDate: string;
}

export async function fetchSurveyGroupTotalSubmissions({
  surveyGroupId,
  startDate,
  endDate,
}: FetchSurveyGroupTotalSubmissionsParams): Promise<SurveyGroupTotalSubmissions> {
  if (config.shouldMockRequests) {
    return Promise.resolve(SurveyGroupSubmissions);
  }

  const response = await axiosInstance.get<SurveyGroupTotalSubmissions>(`${surveyGroupId}/total_submissions/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

  return response.data;
}
export async function fetchSurveyGroupDemographicQuestionsTypes({
  surveyGroupId,
}: {
  surveyGroupId: number;
}): Promise<SurveyGroupDemographicTypes> {
  const response = await axiosInstance.get<SurveyGroupDemographicTypes>(
    `${surveyGroupId}/demographic_questions_types/`,
  );

  return response.data;
}
