import { ZCDIcon, ZCDSelect } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import { AnalyticEventsChangeTypes } from 'constants/analytics';
import { DashboardContext } from 'contexts/DashboardContext';
import React, { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { FilteredQuestion } from 'types/questionsScores';
import { SelectOption } from 'types/selectOption';
import styles from './CrossQuestionMenu.module.scss';

interface Props {
  questions?: FilteredQuestion[];
}
const CrossQuestionMenu = (props: Props): ReactElement => {
  const { questions } = props;
  const { t: translate } = useTranslation();
  const {
    setIsCrossModeEnabled,
    crossSelectedQuestionId,
    setCrossSelectedQuestionId,
    crossTabQuestions,
    expandedQuestionId,
  } = useContext(DashboardContext);

  const options = crossTabQuestions
    .filter((question) => question.generic_question !== expandedQuestionId)
    .map((question) => ({ label: question.text, value: question.generic_question }));
  const selectedQuestion = options.find((selectOption) => selectOption.value === crossSelectedQuestionId);

  const handleOnClose = (): void => {
    setIsCrossModeEnabled(false);
    setCrossSelectedQuestionId(undefined);

    analyticsService.crossProductEvents.crossTabModeChanged({
      changeValue: AnalyticEventsChangeTypes.OPEN,
      questionId: expandedQuestionId ?? AnalyticEventsChangeTypes.NONE_EXPANDED,
      questionText:
        questions?.find((question) => question.genericQuestionId === expandedQuestionId)?.questionText ??
        AnalyticEventsChangeTypes.NONE_EXPANDED,
    });
  };

  const handleOnQuestionChange = (selectedOption: SelectOption) => {
    setCrossSelectedQuestionId(selectedOption.value as number);
    analyticsService.crossProductEvents.surveysCrossTabAnalyzed({
      crossQuestionId: selectedOption.value as number,
      crossQuestionText: selectedOption.label,
      questionId: expandedQuestionId || AnalyticEventsChangeTypes.UNKNOWN,
      questionText:
        questions?.find((question) => question.genericQuestionId === expandedQuestionId)?.questionText ||
        AnalyticEventsChangeTypes.UNKNOWN,
    });
  };

  useEffect(() => {
    if (expandedQuestionId === crossSelectedQuestionId) {
      setCrossSelectedQuestionId(undefined);
    }
  }, [crossSelectedQuestionId, expandedQuestionId, setCrossSelectedQuestionId]);

  return (
    <div className={styles.crossQuestionMenu}>
      <Flex justifyContent="space-between">
        <Flex>
          <ZCDIcon name="rating" />
          <Spacer orientation="horizontal" size="xsmall" />
          <span className={styles.menuTitle}>{translate('resultsScreen.crossMenu.title')}</span>
        </Flex>
        <button type="button" className={styles.closeBtn} onClick={handleOnClose}>
          <ZCDIcon name="close-medium" />
        </button>
      </Flex>
      <span className={styles.menuSubtitle}>{translate('resultsScreen.crossMenu.subTitle')}</span>
      <Spacer orientation="vertical" size="medium" />
      {expandedQuestionId ? (
        <ZCDSelect
          key={crossSelectedQuestionId}
          isSearchable={false}
          isClearable={false}
          onChange={(selectedOption) => handleOnQuestionChange(selectedOption as SelectOption)}
          value={selectedQuestion}
          options={options}
        />
      ) : (
        <span className={styles.noQuestionTitle}>{translate('resultsScreen.crossMenu.noSelectedQuestionTitle')}</span>
      )}
    </div>
  );
};

export default CrossQuestionMenu;
