/* eslint-disable i18next/no-literal-string */
import React from 'react';
import ReactDOM from 'react-dom';
import { FeatureFlag } from 'types/featureFlag';
import { TabKeys } from './constants/tabs';
import { DashboardSelection } from './DashboardSelection';
import { Client } from './types/client';
import { User } from './types/user';

const devMockUser: User = {
  id: process.env.X_ZC_USER_ID || '602d819ef8f61190877881a0',
  firstName: 'Super',
  lastName: 'Mario',
  persona: 'city-manager',
  fullTitle: 'God of omeletes',
  decisionMaker: false,
  roles: ['superadmin'],
};
const devCommunitySurveyMockClient: Client = {
  id: process.env.X_ZC_CLIENT_ID || '5f7cbee0e92b821b29eea4d2',
  name: 'Clayton County, GA',
  locale: 'en',
  clientType: 'county',
  timezone: 'US/Eastern',
  segmentExperience: {
    surveys: {
      defaultTab: TabKeys.SUMMARY,
    },
  },
  effectiveInsightsFeatures: {
    [FeatureFlag.BLOCKWISE]: false,
    [FeatureFlag.BLOCKWISE_SUMMARY_TAB]: false,
  },
};

const devBlockwiseMockClient: Client = {
  id: process.env.X_ZC_CLIENT_ID_BLOCKWISE || '631a0e77b20381a264bc01cf',
  name: 'London MET, UK',
  locale: 'en',
  clientType: 'county',
  timezone: 'US/Eastern',
  segmentExperience: {
    surveys: {
      defaultTab: TabKeys.SUMMARY,
    },
  },
  effectiveInsightsFeatures: {
    [FeatureFlag.BLOCKWISE_SUMMARY_TAB]: true,
    [FeatureFlag.BLOCKWISE]: true,
  },
};

sessionStorage.setItem(
  'clientId',
  process.env.BLOCKWISE_ENABLED === 'true' ? devBlockwiseMockClient.id : devCommunitySurveyMockClient.id,
);

const devMockClient = process.env.BLOCKWISE_ENABLED === 'true' ? devBlockwiseMockClient : devCommunitySurveyMockClient;

/*
 * You must manually navigate to the route of the dashboard you want to work on locally!
 *
 * This is because React Router v6 does not support nested routers, and since we need the
 * routers in the dashboards themselves, we removed the BrowserRouter that used to be here.
 */
ReactDOM.render(
  <React.StrictMode>
    <DashboardSelection user={devMockUser} client={devMockClient} />
  </React.StrictMode>,
  document.getElementById('root'),
);
