/* eslint-disable i18next/no-literal-string */
import { OrganicDiscourseCard } from 'components/OrganicDiscourseCard/OrganicDiscourseCard';
import ScoreCardHeader from 'components/ScoreCardHeader/ScoreCardHeader';
import { Spacer } from 'components/Spacer/Spacer';
import { Status } from 'constants/state';
import { AccountsContext } from 'contexts/AccountsContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchVolumeOfInteractions, sumUpInteractions } from 'services/volumeOfInteractions';
import { logger } from 'utils/community-survey/logger';
import { ScoreCard } from './ScoreCard';
import styles from './ScorecardSection.module.scss';

export const ScorecardSection: React.FC = function ScorecardSection(): ReactElement {
  const { client } = useContext(AccountsContext);
  const [interactionsStatus, setInteractionsStatus] = useState(Status.LOADING);
  const [interactions, setInteractions] = useState(0);

  const { t: translate } = useTranslation();

  useEffect(() => {
    fetchVolumeOfInteractions(client.id, client.timezone).then((result) => {
      try {
        const totalInteractions = sumUpInteractions(result.interactions ?? []);
        setInteractions(totalInteractions);
        setInteractionsStatus(Status.OKAY);
      } catch (error) {
        logger.error(error);
        setInteractionsStatus(Status.ERROR);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.scoreCardSection}>
      <ScoreCardHeader>
        <h2>{translate('summaryScreen.scorecardSectionTitle')}</h2>
      </ScoreCardHeader>
      <div className={styles.scoreCardSectionContent}>
        <div className={styles.overallSatisfaction}>
          <ScoreCard />
        </div>
        <div className={styles.organicDiscourse}>
          <OrganicDiscourseCard fetchStatus={interactionsStatus} interactions={interactions} />
        </div>
      </div>
      <Spacer orientation="vertical" size="medium" />
    </div>
  );
};
