import { Icon } from '@zencity/common-ui';
import React, { ErrorInfo, ReactElement } from 'react';
import styles from './ErrorBoundaryWithIcon.module.scss';

interface Props {
  children: React.ReactNode;
  errorTitle: string;
  errorDescription: string;
}

interface State {
  hasError: boolean;
}

/**
 * Error wrapper component with an icon.
 * Wrap this over a component to show this screen when a component fails to render.
 *
 * @param title - The title of the error screen.
 * @param description - The description of the error screen.
 */
export class ErrorBoundaryWithIcon extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false } as State;
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error);
    console.error(errorInfo);
  }

  render(): ReactElement {
    const { hasError } = this.state;
    const { children, errorTitle, errorDescription } = this.props;

    if (hasError) {
      return (
        <div className={styles.errorScreen}>
          <Icon name="error-cards.svg" height={71} width={62} className={styles.icon} />
          <div className={styles.content}>
            <h2>{errorTitle}</h2>
            <p className={styles.description}>{errorDescription}</p>
          </div>
        </div>
      );
    }

    return <>{children}</>;
  }
}
