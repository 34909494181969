import { TooltipInfo, ZCDIcon, ZCDIconColor, ZCDSpinner } from '@zencity/common-ui';
import classNames from 'classnames';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import { MIN_UNREPRESENTATIVE_INDICATOR } from 'components/UnrepresentativeIndicator/constants';
import { SCORE_PERCENTAGE_POSTFIX } from 'constants/score';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SatisfactionScore } from 'types/score';
import { calculateScoreComparisonDifference } from 'utils/analysis';
import { getIconName, getTextColor, OVERTIME_TRENDS_INDICATION_TEST_ID } from './helpers';
import styles from './OvertimeTrendsIndication.module.scss';

interface Props {
  currentScore: SatisfactionScore;
  comparisonScore?: SatisfactionScore | null;
  isAspectQuestion?: boolean;
  inScoreWidget?: boolean;
  totalSubmissionsToCompare?: number;
  showSpinnerOnChange?: boolean;
}

const getIconColor = ({ difference, inScoreWidget }: { difference: number; inScoreWidget: boolean }) => {
  if (!inScoreWidget) return ZCDIconColor.GRAY;
  if (difference < 0) return ZCDIconColor.RED;
  if (difference > 0) return ZCDIconColor.DARK_GREEN;
  return ZCDIconColor.GRAY;
};

export const OvertimeTrendsIndication = (props: Props): ReactElement | null => {
  const { showSpinnerOnChange = true, currentScore, comparisonScore, totalSubmissionsToCompare, inScoreWidget } = props;
  const { t: translate } = useTranslation();
  const [showSpinner, setShowSpinner] = React.useState(showSpinnerOnChange);
  const currentPositive = currentScore.positive;
  const previousPositive = comparisonScore?.positive ?? 0;
  const scoreComparisonDifference = calculateScoreComparisonDifference(currentPositive, previousPositive);
  const zeroDifference = scoreComparisonDifference === 0;
  // In case there is no comparisonScore or total submissions to compare is < MIN_UNREPRESENTATIVE_INDICATOR, show info indication.
  const showInfoIndication =
    !comparisonScore || (totalSubmissionsToCompare && totalSubmissionsToCompare < MIN_UNREPRESENTATIVE_INDICATOR);
  const iconName = getIconName({ difference: scoreComparisonDifference });
  const textColor = getTextColor({ difference: scoreComparisonDifference, inScoreWidget: !!inScoreWidget });
  const iconColor = getIconColor({ difference: scoreComparisonDifference, inScoreWidget: !!inScoreWidget });
  const tooltip = showInfoIndication ? translate('breakdownWidget.overtimeTrendsIndication.infoIndication') : '';

  // This way we prevent quick UI changes when the score changes.
  useEffect(() => {
    if (!showSpinnerOnChange) return;
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
  }, [scoreComparisonDifference, showSpinnerOnChange]);

  return (
    <TooltipInfo
      // We using key here to enforce re-rendering of the component when the state of showInfoIndication changes.
      key={showInfoIndication?.toString()}
      position="top"
      tooltipText={tooltip}
      renderButton={(buttonRef, buttonProps): React.ReactNode => (
        <div
          data-testid={OVERTIME_TRENDS_INDICATION_TEST_ID}
          ref={showInfoIndication ? buttonRef : undefined}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          className={styles.overtimeTrendsIndication}
          style={{ cursor: showInfoIndication ? 'pointer' : 'default' }}
        >
          <div className={classNames(styles.flexCenter, styles.content)}>
            {!inScoreWidget && <span className={styles.middot} />}
            {showSpinner ? (
              <div className={styles.spinnerContainer}>
                <ZCDSpinner size={19} />
              </div>
            ) : (
              <>
                {zeroDifference || showInfoIndication ? (
                  <span className={styles.zeroDifference}>--</span>
                ) : (
                  <ZCDIcon name={iconName} color={iconColor} />
                )}
                {!showInfoIndication && (
                  <Flex
                    className={styles.label}
                    style={{
                      color: textColor,
                    }}
                  >
                    {Math.abs(scoreComparisonDifference)}%{SCORE_PERCENTAGE_POSTFIX}
                    <Spacer orientation="horizontal" size="xsmall" />
                  </Flex>
                )}
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};
