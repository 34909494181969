import {
  DisplayableGroupedLeadingTopic,
  DisplayableLeadingTopic,
  GroupedLeadingTopic,
  LeadingTopic,
} from 'types/leadingTopics';

export type SortableLeadingTopic = GroupedLeadingTopic | LeadingTopic;

export const sortTopics = (leadingTopics: SortableLeadingTopic[]): SortableLeadingTopic[] =>
  [...leadingTopics]
    .sort((firstTopic, secondTopic) => secondTopic.responses - firstTopic.responses)
    .filter((topic) => Boolean(topic.responses));

const calculateFill = (topicResponses: number, baseResponses: number): number =>
  Math.round((topicResponses * 100) / baseResponses);

export const prepareLeadingTopics = (
  leadingTopics: LeadingTopic[] = [],
  baseResponses: number,
): DisplayableLeadingTopic[] => {
  if (!leadingTopics.length) {
    return [];
  }

  const sortedLeadingTopics = sortTopics(leadingTopics) as LeadingTopic[];
  const [first, ...rest] = sortedLeadingTopics;

  return [
    {
      ...first,
      fill: calculateFill(first.responses, baseResponses),
    },
    ...rest.map((topic) => ({
      ...topic,
      fill: calculateFill(topic.responses, baseResponses),
    })),
  ];
};

export const prepareGroupedLeadingTopics = (
  groupedLeadingTopics: GroupedLeadingTopic[] = [],
): DisplayableGroupedLeadingTopic[] => {
  if (!groupedLeadingTopics.length) {
    return [];
  }

  const sortedGroupedLeadingTopics = sortTopics(groupedLeadingTopics) as GroupedLeadingTopic[];
  const [first, ...rest] = sortedGroupedLeadingTopics;
  const { responses: baseResponses } = first;

  return [
    {
      ...first,
      fill: 100,
      leadingClassificationSubTopics:
        first.leadingClassificationSubTopics.length > 1
          ? prepareLeadingTopics(first.leadingClassificationSubTopics, first.responses)
          : [],
    },
    ...rest.map((topic) => ({
      ...topic,
      fill: calculateFill(topic.responses, baseResponses),
      leadingClassificationSubTopics:
        topic.leadingClassificationSubTopics.length > 1
          ? prepareLeadingTopics(topic.leadingClassificationSubTopics, topic.responses)
          : [],
    })),
  ];
};

export const groupLeadingTopicsByClassificationTopic = (leadingTopics: LeadingTopic[]): GroupedLeadingTopic[] =>
  leadingTopics.reduce<GroupedLeadingTopic[]>((groupedLeadingTopics, classificationSubTopic) => {
    const existingClassificationTopicGroup = groupedLeadingTopics.find(
      (classificationTopicGroup) =>
        classificationTopicGroup.classificationTopicId === classificationSubTopic.classificationTopicId,
    );
    if (existingClassificationTopicGroup) {
      return [
        ...groupedLeadingTopics.filter(
          (classificationTopicGroup) =>
            classificationTopicGroup.classificationTopicId !== classificationSubTopic.classificationTopicId,
        ),
        {
          ...existingClassificationTopicGroup,
          responses: existingClassificationTopicGroup.responses + classificationSubTopic.responses,
          leadingClassificationSubTopics: [
            ...existingClassificationTopicGroup.leadingClassificationSubTopics,
            classificationSubTopic,
          ],
        },
      ];
    }
    return [
      ...groupedLeadingTopics,
      {
        classificationTopicId: classificationSubTopic.classificationTopicId,
        classificationTopicValue: classificationSubTopic.classificationTopicValue,
        responses: classificationSubTopic.responses,
        leadingClassificationSubTopics: [classificationSubTopic],
      },
    ];
  }, []);
