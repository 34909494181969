import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay: number): { debouncedValue: T; setDebouncedValue: (value: T) => void } {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return { debouncedValue, setDebouncedValue };
}
