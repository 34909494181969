import { Status } from '@zencity/common-ui';
import { SurveyGroup } from '@zencity/survey-types';
import classNames from 'classnames';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { ProductNames } from 'constants/products';
import { AccountsContext } from 'contexts/AccountsContext';
import { CommunityAsksToastContextProvider } from 'contexts/CommunityAsksToastContext';
import { CustomerExperienceContext } from 'contexts/CustomerExperienceContext';
import { useSurveysInitialLoad } from 'hooks/useSurveysInitialLoad';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CustomerExperienceRoutes } from 'screens/customer-experience/router';
import { fetchCustomerExperienceSurveyGroups } from 'services/surveyGroup';
import { Client } from 'types/client';
import { Nullable } from 'types/misc';
import { User } from 'types/user';
import { initLogger } from 'utils/customer-experience/logger';
import { config } from '../../../config/config';
import '../../i18n';
import { getLayout, Layout } from '../Screens.helpers';

interface Props {
  user: User;
  client: Client;
}

/**
 * The Community Asks router component.
 *
 * @param user: The core platform user.
 * @param client: The core platform client the user is aligned to.
 * @param baseUrl: The parent will tell surveys in which URL it does not live.
 */
/* eslint-disable max-lines-per-function */
/* eslint-disable i18next/no-literal-string */
// eslint-disable-next-line max-statements
const CustomerExperienceDashboard: React.FC<Props> = function CustomerExperienceDashboard({
  user,
  client,
}: Props): ReactElement {
  const [currentLayout, setCurrentLayout] = useState<Layout>(Layout.SCREEN_SMALL);
  const [surveyGroups, setSurveyGroups] = useState<SurveyGroup[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<Status>(Status.LOADING);
  const [selectedServiceTypeId, setSelectedServiceTypeId] = React.useState<Nullable<number>>(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState<Nullable<number>>(null);
  useSurveysInitialLoad(client, user, initLogger, ProductNames.EXPERIENCE_SURVEYS);

  const setCXServiceTypeIdFilter = (selectedOption: Nullable<number>) => {
    setSelectedServiceTypeId(selectedOption);
  };

  const setCXTimePeriodFilter = (selectedOption: Nullable<number>) => {
    setSelectedTimePeriod(selectedOption);
  };

  // eslint-disable-next-line complexity
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries[0]?.target) {
          const divElm = entries[0].target as HTMLElement;
          const width = divElm.scrollWidth;
          setCurrentLayout(getLayout(width));
        }
      }),
    [],
  );

  useEffect(
    () =>
      function cleanup() {
        resizeObserver.disconnect();
      },
    [resizeObserver],
  );

  useEffect(() => {
    const fetchClientSurveys = async () => {
      try {
        const response = await fetchCustomerExperienceSurveyGroups();

        setSurveyGroups(response.results);
        setLoadingStatus(Status.OKAY);
      } catch (error) {
        setLoadingStatus(Status.ERROR);
      }
    };
    fetchClientSurveys();
  }, []);

  const onContentRender = useCallback(
    (node) => {
      if (node !== null) {
        resizeObserver.disconnect();
        // Will respond to width change.
        resizeObserver.observe(node);
      }
    },
    [resizeObserver],
  );

  return (
    <div className={classNames(currentLayout)} ref={onContentRender}>
      <BrowserRouter basename={config.customerExperience.baseRouterUrl}>
        <AccountsContext.Provider value={{ client, user }}>
          <CommunityAsksToastContextProvider>
            <CustomerExperienceContext.Provider
              value={{
                surveyGroups,
                setCXServiceTypeIdFilter,
                cxServiceTypeIdFilterBy: selectedServiceTypeId,
                setCXTimePeriodFilter,
                cxTimePeriodFilterBy: selectedTimePeriod,
              }}
            >
              <ErrorBoundary>
                <LoaderMask isLoading={loadingStatus === Status.LOADING}>
                  <CustomerExperienceRoutes basename={config.customerExperience.baseRouterUrl} />
                </LoaderMask>
              </ErrorBoundary>
            </CustomerExperienceContext.Provider>
          </CommunityAsksToastContextProvider>
        </AccountsContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default CustomerExperienceDashboard;
