import { SurveyRequest, SurveyRequestStatus } from '@zencity/survey-types';
import { surveyGroupMock } from './surveyGroup.mock';

export const surveyRequest = {
  id: 1,
  status: SurveyRequestStatus.ACTIVE,
  survey_type: 'community-asks',
  created_at: '2022-01-01 00:00:00.000000+03',
  survey_group: surveyGroupMock,
} as SurveyRequest;
