import { Button, Icon } from '@zencity/common-ui';
import { AskRequestHeader } from 'components/AskRequestHeader/AskRequestHeader';
import { CommunityAsksContext } from 'contexts/CommunityAsksContext';
import { CommunityAsksToastContext } from 'contexts/CommunityAsksToastContext';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { analyticsService } from 'services/analytics/analytics';
import { submitSurveyRequest } from 'services/surveyRequest';
import { SurveyType } from '@zencity/survey-types';
import { SurveyRequestPayload } from 'types/surveyRequest';
import { logger } from 'utils/community-asks/logger';
import styles from './CustomAskRequest.module.scss';

/**
 * This screen is for a client to create a Community Ask request not from a Typeform template.
 */
export const CustomAskRequest = (): ReactElement => {
  const { t: translate } = useTranslation();
  const { setSurveyRequests } = useContext(CommunityAsksContext);
  const { toastSuccess, toastError } = useContext(CommunityAsksToastContext);
  const navigate = useNavigate();
  const [requestText, setRequestText] = useState('');
  const [submitRequestLoading, setSubmitRequestLoading] = useState(false);
  // eslint-disable-next-line i18next/no-literal-string
  const baseTranslationPath = 'communityAsks.customAskRequestScreen';

  const handleOnFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // TODO: Survey request service.
    event.preventDefault();
    setSubmitRequestLoading(true);
    analyticsService.communityAsksEvents.requestCustomSentActivatedState();

    const payload: SurveyRequestPayload = {
      surveyType: SurveyType.COMMUNITY_ASKS,
      requestText,
      // TODO: Get the CSM info from insights (`/api/slack/userInfo`).
      csmSlack: '',
    };

    submitSurveyRequest(payload)
      .then((surveyRequest) => {
        setSurveyRequests((prevSurveyRequests) => [...prevSurveyRequests, surveyRequest]);
        setSubmitRequestLoading(false);
        navigate(CommunityAsksRouterPaths.WORKSPACE);

        // Display a success message to the user.
        toastSuccess();
      })
      .catch((error) => {
        logger.error(error);
        setSubmitRequestLoading(false);
        // Display an error message to the user.
        toastError();
      });
  };

  const handleOnTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRequestText(event.target.value);
  };

  return (
    <>
      <AskRequestHeader title={translate(`${baseTranslationPath}.title`)} />
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleOnFormSubmit}>
          <h2>{translate(`${baseTranslationPath}.heading`)}</h2>
          <h4 className={styles.subHeading}>{translate(`${baseTranslationPath}.subTitle`)}</h4>
          <textarea
            className={styles.textarea}
            placeholder={translate(`${baseTranslationPath}.textareaPlaceholder`)}
            onChange={handleOnTextareaChange}
            value={requestText}
          />
          <Button type="submit" className={styles.button} disabled={submitRequestLoading}>
            {translate('communityAsks.requestButton')}
          </Button>
        </form>
        <Icon className={styles.icon} name="person-in-phone-with-background.svg" width={56} height={56} />
      </div>
    </>
  );
};
