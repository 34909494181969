import { TooltipInfo, ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MAX_UNREPRESENTATIVE_INDICATOR,
  MIN_UNREPRESENTATIVE_INDICATOR,
  UNREPRESENTATIVE_ICON_TEST_ID,
} from './constants';
import styles from './UnrepresentativeIndicator.module.scss';

interface Props {
  totalSubmissions?: number;
}

export const UnrepresentativeIndicator = (props: Props): React.ReactElement => {
  const { totalSubmissions = 0 } = props;
  const { t: translate } = useTranslation();
  const color =
    totalSubmissions >= MIN_UNREPRESENTATIVE_INDICATOR && totalSubmissions < MAX_UNREPRESENTATIVE_INDICATOR
      ? ZCDIconColor.RED
      : ZCDIconColor.BLUE;

  const tooltipText =
    totalSubmissions >= MIN_UNREPRESENTATIVE_INDICATOR && totalSubmissions < MAX_UNREPRESENTATIVE_INDICATOR
      ? translate('breakdownWidget.unrepresentativeResultTooltip')
      : translate('breakdownWidget.lowUnrepresentativeResultTooltip');

  return (
    <TooltipInfo
      position="top"
      tooltipText={tooltipText}
      renderButton={(buttonRef, buttonProps): React.ReactNode => (
        <div
          ref={buttonRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          className={styles.flexCenter}
          data-testid={UNREPRESENTATIVE_ICON_TEST_ID}
        >
          <ZCDIcon name="warning-outline" color={color} />
        </div>
      )}
    />
  );
};
