/* eslint-disable max-statements */
import { Question, QuestionType, SurveyGroup } from '@zencity/survey-types';
import { MAX_PAGE_SIZE } from 'constants/requests';
import { useEffect, useState } from 'react';
import { fetchGroupedQuestions } from 'services/question';
import { QuestionFilterParams } from 'types/question';

export const useOpenEndedQuestions = (
  surveyGroup?: SurveyGroup,
): { openEndedQuestions: Question[]; areOpenEndedQuestionsLoading: boolean } => {
  const [openEndedQuestions, setOpenEndedQuestions] = useState<Question[]>([]);
  const [areOpenEndedQuestionsLoading, setAreOpenEndedQuestionsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchQuestions(): Promise<void> {
      if (!surveyGroup) {
        return;
      }
      try {
        setAreOpenEndedQuestionsLoading(true);
        const questionFilterParams = {
          survey_type: surveyGroup.type,
          page_size: MAX_PAGE_SIZE,
          question_type: QuestionType.OPEN_ENDED,
          hide_from_live_feed: false,
          survey_group: surveyGroup.id,
        } as QuestionFilterParams;
        const questionsData = await fetchGroupedQuestions(questionFilterParams);
        setOpenEndedQuestions(questionsData);
      } catch (error) {
        console.error(error);
      } finally {
        setAreOpenEndedQuestionsLoading(false);
      }
    }
    fetchQuestions();
  }, [surveyGroup]);
  return { openEndedQuestions, areOpenEndedQuestionsLoading };
};
