import { SurveyRequest } from '@zencity/survey-types';

/**
 * Determines the title of a Survey Request.
 */
export const getRequestTitle = (surveyRequest: SurveyRequest, placeholderTitle = ''): string => {
  const { survey_group: surveyGroup, typeform_example: typeformExample } = surveyRequest;
  if (surveyGroup) return surveyGroup.title_for_display;
  if (typeformExample) return typeformExample.title;
  return placeholderTitle;
};
