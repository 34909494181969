import { AccountsContext } from 'contexts/AccountsContext';
import { useContext } from 'react';

export const CLIENT_TOKEN = '%client_name%';

const clientNameNotFoundToken = 'TOKEN_NOT_FOUND';

/*
 * A helper hook to replace the client token name with the actual client name.
 */
export const useClientNameTokenReplacement = (): {
  clientNameTokenReplacement: (content: string) => string;
} => {
  const { client: zencityFullClient = { name: clientNameNotFoundToken }, leanSurveyClient } =
    useContext(AccountsContext);
  const { name: clientName } = leanSurveyClient ?? zencityFullClient;

  const clientNameTokenReplacement = (content: string) =>
    content.replaceAll(CLIENT_TOKEN, clientName ?? clientNameNotFoundToken);

  return { clientNameTokenReplacement };
};
