export enum TabKeys {
  DASHBOARD = 'dashboard',
  SUMMARY = 'summary',
  FEED = 'feed',
  MAP = 'map',
  QUESTIONS = 'questions',
  REPORT = 'report',
  SUGGESTIONS = 'suggestions',
  ANALYSIS = 'analysis',
}

/**
 * Get the path of the given tab.
 */
export const getTabPath = (tabKey: TabKeys, basePath = ''): string => `${basePath}/${tabKey}`;
