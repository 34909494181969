import { ZCDTable } from '@zencity/common-ui';
import classNames from 'classnames';
import useCompareDateRangeScoreLabel from 'hooks/score/compareDateRangeLabel';
import useDateRangeScoreLabel from 'hooks/score/dateRangeLabel';
import React, { useMemo } from 'react';
import { QuestionChoice } from 'types/questionsScores';
import { formatQuestionChoices } from 'utils/analysis';
import { getResultBreakdownColumns } from './columns';
import styles from './AnalysisBreakdownTable.module.scss';
import tableStyles from '../AnalysisTable.module.scss';

interface Props {
  questionChoices: QuestionChoice[];
  comparingChoices?: QuestionChoice[];
  isNoScoreQuestion?: boolean;
}

const MAX_CHOICES_LIMIT = 50;

const getMaxScore = (choices: QuestionChoice[]): number =>
  choices
    .map((choice) => (choice?.score ? choice.score : 0))
    .reduce((firstChoice, secondChoice) => Math.max(Number(firstChoice), Number(secondChoice)), 0);

const AnalysisBreakdownTable: React.FC<Props> = function AnalysisBreakdownTable(props: Props): React.ReactElement {
  const { questionChoices, comparingChoices, isNoScoreQuestion } = props;
  const { dateRangeLabel } = useDateRangeScoreLabel();
  const { comparisonDateRangeLabel } = useCompareDateRangeScoreLabel();

  const maxScoreCurrentCycle = getMaxScore(questionChoices);
  const maxScoreComparedCycle = comparingChoices && getMaxScore(comparingChoices);
  const mappedQuestionChoices = formatQuestionChoices(questionChoices, comparingChoices);

  const tableData = useMemo(() => mappedQuestionChoices, [mappedQuestionChoices]) || [];

  const columns = useMemo(
    () =>
      getResultBreakdownColumns({
        dateRangeLabel: dateRangeLabel as string,
        maxScoreCurrentCycle,
        comparisonDateRangeLabel,
        maxScoreComparedCycle,
        isNoScoreQuestion,
      }),
    [comparisonDateRangeLabel, dateRangeLabel, maxScoreComparedCycle, maxScoreCurrentCycle, isNoScoreQuestion],
  );

  return (
    <div className={classNames(tableStyles.tableWrapper, styles.breakdownTable)}>
      <ZCDTable tableData={tableData} columnArray={columns} maxItemsLimit={MAX_CHOICES_LIMIT} />
    </div>
  );
};

export default AnalysisBreakdownTable;
