import React from 'react';
import { Icon, ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import styles from './TakeAnAction.module.scss';

interface ActionCardProps {
  handleOnClick: () => void;
  title: string;
  description: string;
  linkText: string;
  redirectUrl: string;
  iconName: string;
}
export const ActionCard = (props: ActionCardProps): React.ReactElement => {
  const { handleOnClick, title, description, linkText, redirectUrl, iconName } = props;
  return (
    <a href={redirectUrl} onClick={handleOnClick} className={styles.actionCard}>
      <div className={styles.actionCardContent}>
        <Icon name={iconName} width={40} height={40} />
        <h3>{title}</h3>
        <p>{description}</p>
        <div className={styles.linkText}>
          <span>{linkText}</span>
          <ZCDIcon name="arrow-right" color={ZCDIconColor.BLUE} />
        </div>
      </div>
    </a>
  );
};
