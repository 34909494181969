import { Location } from 'history';
import { useEffect, useState } from 'react';
import { matchRoutes, RouteObject, useLocation } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import { RouterRedirectState } from 'types/router';
import { Logger } from 'utils/_loggers';

const stripWrappedSlashes = (value: string) => value.replace(/^\/|\/$/g, '');

/**
 * A helper function to resolve the route by the given location.
 *
 * Unfortunately, `useLocation()` doesn't provide any of its "route"
 * information, thus, we need to resolve it manually.
 *
 * @param basename
 *   The `basename` as passed to the `BrowserRouter` component.
 * @param routes
 *   The registered routes.
 * @param byLocation
 *   The `location` object received by the `useLocation()` hook.
 */
export const resolveRoute = (basename: string, routes: RouteObject[], byLocation: Partial<Location>): string => {
  const routeMatches = matchRoutes(routes, byLocation);

  if (!routeMatches) {
    return 'ROUTE-NOT-FOUND';
  }

  // Since some routes may be children of other routes, we'll need to iterate
  // the matches and collect each path.
  const routeCollectedPaths: string[] = [];

  // Set the basename as the first collected path.
  routeCollectedPaths.push(stripWrappedSlashes(basename));

  routeMatches.forEach((routeMatch) => {
    // Some routes are not defined with a `path` (usually when defined as an
    // `index`), thus, fallback to an empty string.
    const potentialPath = routeMatch.route.path || '';

    // Collect the potential path after striping its wrapped slashes.
    routeCollectedPaths.push(stripWrappedSlashes(potentialPath));
  });

  // Build the route by the collected paths (filter out empty strings).
  return routeCollectedPaths.filter(Boolean).join('/');
};

/**
 * A custom hook to track and log the user's navigation events within the app.
 *
 * @param routes
 *   The known routes to find a match in.
 * @param basename
 *   The basename of the router.
 * @param logger
 *   The logger instance of the relevant dashboard.
 */
export const useScreenViewedEvent = (routes: RouteObject[], basename: string, logger: Logger): void => {
  const location = useLocation();
  const [previousRouterLocation, setPreviousRouterLocation] = useState<Partial<Location>>({ ...location });

  // eslint-disable-next-line max-statements
  useEffect(() => {
    try {
      // The key of the initial `location` is "default", meaning, the user just
      // landed in the app.
      const initialLocation = location.key === 'default';

      const newRoute = resolveRoute(basename, routes, location);
      const newUrl = `${window.location.origin}/${basename}${location.pathname}`;
      // TODO: Search for specific query params on the "initial location" because
      //  the user may got here via an email/push notification.
      let prevRoute = initialLocation ? '' : resolveRoute(basename, routes, previousRouterLocation);

      // A screen can't refer to itself, hence, it means that the user probably
      // clicked on the core's sidebar menu item (or redirected by another app).
      // To avoid confusions in the analytics side, change the value to indicate
      // about the mismatch.
      if (newRoute === prevRoute) {
        prevRoute = 'ROUTE-MISMATCH';
      }

      const autoRedirect = Boolean((location.state as RouterRedirectState)?.redirected);

      analyticsService.screenViewedEvent(newRoute, newUrl, prevRoute, autoRedirect);

      // Keep the "previous" state for the next event (copy the objects to avoid
      // loosing previous data due to the references which may get updated).
      setPreviousRouterLocation({ ...location });
    } catch (error) {
      logger.error(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};
