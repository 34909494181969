import { ZCDButton } from '@zencity/common-ui';
import { Spacer } from 'components/Spacer/Spacer';
import { SurveyRequestItem } from 'components/SurveyRequestItem/SurveyRequestItem';
import { CommunityAsksContext } from 'contexts/CommunityAsksContext';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SurveyRequestsList.module.scss';

/**
 * The Survey Request list to be displayed on the Workspace screen. This contains a list of the component SurveyRequestItem.
 */
// This threshold is used to determine how many survey requests to display in initial view and on each "Load more" click.
const surveysDisplayThreshold = 2;
const scrollIntoViewId = 'scroll-into-view';

export const SurveyRequestsList: React.FC = function SurveyRequestsList(): ReactElement {
  const { surveyRequests } = useContext(CommunityAsksContext);
  const { t: translate } = useTranslation();
  const [surveysToDisplayCount, setSurveysToDisplayCount] = useState(surveysDisplayThreshold);
  const surveysToMap = surveyRequests.slice(0, surveysToDisplayCount);

  const handleLoadMore = () => {
    setSurveysToDisplayCount((prev) => prev + surveysDisplayThreshold);
    // Scroll to the bottom of the list when the user clicks the "Load More" button.
    const element = document.getElementById(scrollIntoViewId);
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{translate('communityAsks.surveyRequests.listHeading')}</h2>
      <div className={styles.surveyRequestsList}>
        {surveysToMap.map((surveyRequest, index) => {
          // set id to first element from the last element group thar render, so that we can scroll to the "bottom"
          // of the list when the user clicks the "Load More" button.
          const isScrollIntoViewElement =
            surveysToMap.length - surveysDisplayThreshold === index ? scrollIntoViewId : undefined;
          return (
            <div className={styles.surveyRequestsItem} key={surveyRequest.id} id={isScrollIntoViewElement}>
              <SurveyRequestItem surveyRequest={surveyRequest} />
            </div>
          );
        })}
      </div>
      <div />
      {surveysToDisplayCount < surveyRequests.length && (
        <>
          <Spacer orientation="vertical" size="small" />
          <ZCDButton
            onClick={handleLoadMore}
            text={translate('communityAsks.surveyRequests.loadMoreButton')}
            variant="secondary"
          />
        </>
      )}
    </div>
  );
};
