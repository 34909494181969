import { demographicFilters } from 'mocks/data/demographicFilters.mock';
import { DemographicsChoicesResponse } from 'types/demographicFilters';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.surveyGroup));

export async function fetchDemographicsQuestionChoices(surveyGroupId: number): Promise<DemographicsChoicesResponse> {
  if (config.shouldMockRequests) {
    return Promise.resolve(demographicFilters);
  }
  const response = await axiosInstance.get<DemographicsChoicesResponse>(
    `${surveyGroupId}/demographic_question_choices/`,
  );

  return response.data;
}
