import { TopConcernsWidgetConcernRow } from 'components/BlockwiseSummaryTab/OverviewWidget/TopConcernsWidget/components/TopConcernsWidgetConcernRow/TopConcernsWidgetConcernRow';
import { TopConcernsWidgetHeader } from 'components/BlockwiseSummaryTab/OverviewWidget/TopConcernsWidget/components/TopConcernsWidgetHeader/TopConcernsWidgetHeader';
import { Flex } from 'components/Flex/Flex';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { Spacer } from 'components/Spacer/Spacer';
import { AccountsContext } from 'contexts/AccountsContext';
import { DateRangeContext } from 'contexts/DateRangeContext';
import useFeedDateRangeFilter from 'hooks/feed/dateRangeFilter';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { fetchGroupedQuestions } from 'services/question';
import { FeatureFlag } from 'types/featureFlag';
import { DisplayableGroupedLeadingTopic } from 'types/leadingTopics';
import { ExtraFiltersParams } from 'types/misc';
import { QuestionFilterParams } from 'types/question';
import { SurveyGroupOutletContext } from 'types/surveyGroup';
import { userHasCorePlatformFeature } from 'utils/user';
import { Question, QuestionType } from '@zencity/survey-types';
import styles from './TopConcernsWidget.module.scss';
import { useTopConcernsWidgetData } from './useTopConcernsWidgetData';

const MAX_CONCERNS_TO_DISPLAY = 2;

const calculateTotalResponsesForTopics = (widgetData?: DisplayableGroupedLeadingTopic[]) =>
  widgetData?.reduce((totalResponses, classificationTopic) => totalResponses + classificationTopic.responses, 0);

export const TopConcernsWidget = (): JSX.Element => {
  const { comparisonDateRange } = useContext(DateRangeContext);
  const { user, client } = useContext(AccountsContext);

  const { dateRangeFilter } = useFeedDateRangeFilter();

  const hasBlockwiseAccess = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);
  const shouldDisplayWidget = hasBlockwiseAccess;
  const { surveyGroup } = useOutletContext<SurveyGroupOutletContext & ExtraFiltersParams>();

  const [surveyGroupQuestions, setSurveyGroupQuestions] = useState<Question[]>([]);
  useEffect(() => {
    const fetchQuestions = async () => {
      const questionFilterParams = {
        question_type: QuestionType.OPEN_ENDED,
        hide_from_live_feed: false,
        survey_group: surveyGroup.id,
        ...dateRangeFilter,
      } as QuestionFilterParams;
      const questions = await fetchGroupedQuestions(questionFilterParams);
      setSurveyGroupQuestions(questions);
    };

    fetchQuestions();
  }, [dateRangeFilter, surveyGroup.id]);

  // Only display the classification topic responses for the first generic question, as
  // it will match the feed on click.
  // TODO: Need a question selector for the user to match the feed for the selected question.
  const { widgetData: selectedDateWidgetData, loading: dataLoading } = useTopConcernsWidgetData({
    startDate: dateRangeFilter?.start_date,
    endDate: dateRangeFilter?.end_date,
    genericQuestionId: surveyGroupQuestions?.[0]?.generic_question,
  });
  const { widgetData: comparisonDateWidgetData, loading: comparisonDataLoading } = useTopConcernsWidgetData({
    startDate: comparisonDateRange?.startDate,
    endDate: comparisonDateRange?.endDate,
    genericQuestionId: surveyGroupQuestions?.[0]?.generic_question,
  });

  const concernsToDisplay = selectedDateWidgetData?.slice(0, MAX_CONCERNS_TO_DISPLAY);

  const totalSelectedResponses = calculateTotalResponsesForTopics(selectedDateWidgetData);
  const totalComparisonResponses = calculateTotalResponsesForTopics(comparisonDateWidgetData);

  return (
    <Flex className={styles.topConcernsWidget} flexDirection="column" justifyContent="space-between">
      <TopConcernsWidgetHeader />
      <Spacer orientation="vertical" size="small" />
      {shouldDisplayWidget ? (
        <LoaderMask className={styles.loaderMask} isLoading={dataLoading || comparisonDataLoading}>
          {concernsToDisplay?.map((concern, index) => {
            const comparisonConcern = comparisonDateWidgetData?.find(
              (item) => item.classificationTopicId === concern.classificationTopicId,
            );
            return (
              <Fragment key={concern.classificationTopicId}>
                <TopConcernsWidgetConcernRow
                  concern={concern}
                  comparisonConcern={comparisonConcern}
                  totalComparisonResponses={totalComparisonResponses}
                  totalSelectedResponses={totalSelectedResponses}
                />
                {index !== concernsToDisplay.length - 1 && <Spacer orientation="vertical" size="small" />}
              </Fragment>
            );
          })}
        </LoaderMask>
      ) : (
        // TODO: This is a naive placeholder until the Answer Sub Topic Classificaiton V2 pipeline
        // is finalized and a feature flag is no longer necessary. ETA 2022-02-01 at the latest.
        <b>Coming soon</b>
      )}
    </Flex>
  );
};
