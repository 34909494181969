import { useEffect, useState } from 'react';
import { logger } from 'utils/community-survey/logger';
import { FiltersContextInterface, FilterOption } from 'contexts/FiltersContext';
import { TreeNode, TreeSelectSelectionKeys } from '@zencity/common-ui';
import { fetchDemographicsQuestionChoices } from 'services/demographicFilters';
import { DemographicFilters, DemographicsChoicesResponse } from 'types/demographicFilters';
import { reduceClassificationSubTopicsToTreeNodes } from 'components/ClassificationSubTopicsFilter/ClassificationSubTopicsFilter.helpers';
import { fetchClassificationSubTopics } from 'services/classificationSubTopic';
import { ClassificationSubTopic, SurveyType, SurveyGroup } from '@zencity/survey-types';
import { useDebounce } from './useDebounce';

const convertDemographicsToFilterOptions = (demographics: DemographicsChoicesResponse): FilterOption[] => {
  const keys = Object.keys(demographics) as (keyof typeof DemographicFilters)[];
  return keys.map((key) => {
    const keyName = DemographicFilters[key];
    const demographic = demographics[key];
    return {
      key,
      label: key,
      children: demographic.map((choice) => ({
        key: `${keyName}-${choice.id.toString()}`,
        label: choice.label,
      })),
    };
  });
};

const fetchPaginatedClassificationSubTopics = async (
  surveyType: SurveyType,
  page = 1,
): Promise<ClassificationSubTopic[]> => {
  const fetchResponse = await fetchClassificationSubTopics(surveyType, page);
  const { results, next } = fetchResponse;

  if (!next) return results;

  const pageResults = await fetchPaginatedClassificationSubTopics(surveyType, page + 1);

  return [...results, ...pageResults];
};

export const useFiltersData = (
  surveyGroup?: SurveyGroup,
): {
  filtersContextData: FiltersContextInterface;
} => {
  const [dashboardDemographics, setDashboardDemographics] = useState<FilterOption[]>([]);
  const [selectedDemographics, setSelectedDemographics] = useState<TreeSelectSelectionKeys>([]);

  const [selectedDivisionId, setSelectedDivisionId] = useState<number>();
  const [selectedDivisionIds, setSelectedDivisionIds] = useState<number[]>([]);

  const [classificationTopics, setClassificationTopics] = useState<TreeNode[]>([]);
  const [selectedClassificationTopics, setSelectedClassificationTopics] = useState<string[]>([]);
  const [isClassificationTopicsLoading, setIsClassificationTopicsLoading] = useState(false);

  const [answerSearchTerm, setAnswerSearchTerm] = useState<string>('');
  const { debouncedValue: answerDebounceSearchTerm } = useDebounce(answerSearchTerm, 400);

  const clearFilters = () => {
    setSelectedDemographics({});
    setSelectedDivisionIds([]);
    setSelectedDivisionId(undefined);
    setAnswerSearchTerm('');
    setSelectedClassificationTopics([]);
  };

  useEffect(() => {
    async function fetchDemographicsData() {
      try {
        if (surveyGroup) {
          const demographicsResponse = await fetchDemographicsQuestionChoices(surveyGroup.id);
          const demographicsFilters = convertDemographicsToFilterOptions(demographicsResponse);
          setDashboardDemographics(demographicsFilters);
        }
      } catch (error) {
        logger.error(error);
      }
    }
    fetchDemographicsData();
  }, [surveyGroup]);

  useEffect(() => {
    const getClassificationSubTopics = async (): Promise<void> => {
      if (!surveyGroup) return;
      const { type: surveyType } = surveyGroup;
      try {
        setIsClassificationTopicsLoading(true);
        const fetchResponse = await fetchPaginatedClassificationSubTopics(surveyType);

        const filterOptions = reduceClassificationSubTopicsToTreeNodes(fetchResponse);
        setClassificationTopics(filterOptions);
      } catch (error) {
        logger.error(error);
      } finally {
        setIsClassificationTopicsLoading(false);
      }
    };

    if (!surveyGroup) {
      setClassificationTopics([]);
      return;
    }
    getClassificationSubTopics();
  }, [surveyGroup]);

  return {
    filtersContextData: {
      selectedDemographics,
      setSelectedDemographics,
      dashboardDemographics,
      setDashboardDemographics,
      selectedDivisionId,
      setSelectedDivisionId,
      selectedDivisionIds,
      setSelectedDivisionIds,
      classificationTopicsOptions: classificationTopics,
      setClassificationTopicsOptions: setClassificationTopics,
      selectedClassificationTopicsKeys: selectedClassificationTopics,
      setSelectedClassificationTopicsKeys: setSelectedClassificationTopics,
      isClassificationTopicsLoading,
      answerSearchTerm: answerDebounceSearchTerm,
      setAnswerSearchTerm,
      clearFilters,
    },
  };
};
