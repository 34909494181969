import React, { ReactElement } from 'react';
import { Page } from 'react-pdf';
import styles from '../CommunityAsksReport.module.scss';

const DEFAULT_SCREEN_WIDTH = 710;

interface Props {
  pageNumber: number;
  numPages: number;
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
}

export const PageRender = (props: Props): ReactElement => {
  const { pageNumber, numPages, isLoading, setIsLoading } = props;

  const onPageRenderSuccess = () => {
    if (pageNumber === numPages) {
      setIsLoading(false);
    }
  };

  const renderError = () => {
    throw Error(`Failed to render page #${pageNumber} of the PDF report.`);
  };

  return (
    <Page
      pageNumber={pageNumber}
      renderAnnotationLayer={false}
      className={`${isLoading ? styles.initialCommunityAsksReportCanvas : styles.communityAsksReportCanvas}`}
      width={DEFAULT_SCREEN_WIDTH}
      onRenderSuccess={onPageRenderSuccess}
      error={renderError}
    />
  );
};
