/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-multi-comp */
import { Flex } from 'components/Flex/Flex';
import i18next from 'i18next';
import React from 'react';
import { CrossQuestionTable, FormattedCrossQuestion } from 'utils/analysis';
import { CellWrapper } from '../AnalysisBreakdownTable/ColumnsComponents/CellWrapper/CellWrapper';
import { ChoiceHeader, ChoiceHeaderProps } from '../AnalysisBreakdownTable/ColumnsComponents/ChoiceHeader/ChoiceHeader';
import { Score } from '../AnalysisBreakdownTable/ColumnsComponents/Score/Score';
import styles from '../AnalysisTable.module.scss';
import { extractAndSortChoicesColumns, extractAndSortChoicesColumnsFromCompareCycle } from './columnUtils';
import { TableSubheader } from './TableSubheader/TableSubheader';

interface GetCrossTableColumnsPayload {
  tableData: CrossQuestionTable[];
  dateRangeLabel: string;
  comparisonDateRangeLabel: string;
  crossQuestionData: FormattedCrossQuestion[];
  comparisonCrossData?: FormattedCrossQuestion[];
  isNoScoreQuestion?: boolean;
}
export const getCrossTableColumns = ({
  tableData,
  dateRangeLabel,
  comparisonDateRangeLabel,
  crossQuestionData,
  comparisonCrossData,
  isNoScoreQuestion,
}: GetCrossTableColumnsPayload) => {
  const choiceHeaderColumn = {
    Header: () => (
      <CellWrapper>
        <span className={styles.baseHeader}>{i18next.t('resultsScreen.breakdownTable.choices')}</span>
      </CellWrapper>
    ),
    accessor: 'choiceHeader',
    Cell: ({ value }: ChoiceHeaderProps) => (
      <CellWrapper>
        <ChoiceHeader value={value} isNoScoreQuestion={isNoScoreQuestion} />
      </CellWrapper>
    ),
  };

  const crossQuestionColumns =
    crossQuestionData.length > 0
      ? extractAndSortChoicesColumns(tableData).map((key) => {
          if (key === 'choiceScore') {
            return {
              id: `cross-${key}`,
              Header: (
                <CellWrapper alignItems="center">
                  <TableSubheader subheader={i18next.t('resultsScreen.crossTable.all')} />
                </CellWrapper>
              ),
              accessor: key,
              Cell: ({ value }: { value: number }) => (
                <CellWrapper>
                  <Score value={value ?? 0} isBold />
                </CellWrapper>
              ),
            };
          }
          return {
            id: `cross-${key}`,
            Header: (
              <CellWrapper alignItems="center">
                <TableSubheader subheader={key} />
              </CellWrapper>
            ),
            accessor: key,
            Cell: ({ value }: { value: number }) => (
              <CellWrapper>
                <Score value={value ?? 0} />
              </CellWrapper>
            ),
          };
        })
      : [];

  const compareQuestionColumns =
    comparisonCrossData && comparisonCrossData.length > 0
      ? extractAndSortChoicesColumnsFromCompareCycle(tableData).map((key, index) => {
          if (key === 'choiceScore') {
            return {
              id: `compare-${key}`,
              Header: (
                <CellWrapper borderLeft alignItems="center">
                  <TableSubheader subheader={i18next.t('resultsScreen.crossTable.all')} />
                </CellWrapper>
              ),
              accessor: `compareChoice.${key}`,
              Cell: ({ value }: { value: number }) => (
                <CellWrapper borderLeft={index === 0}>
                  <Score value={value ?? 0} isBold />
                </CellWrapper>
              ),
            };
          }
          return {
            id: `compare-${key}`,
            Header: (
              <CellWrapper alignItems="center">
                <TableSubheader subheader={key} />
              </CellWrapper>
            ),
            accessor: `compareChoice.${key}`,
            Cell: ({ value }: { value: number }) => (
              <CellWrapper borderLeft={index === 0}>
                <Score value={value ?? 0} />
              </CellWrapper>
            ),
          };
        })
      : [];

  const columns = crossQuestionColumns.length
    ? [
        choiceHeaderColumn,
        {
          id: 'crossColumns',
          Header: () => (
            <CellWrapper>
              <Flex alignItems="flex-start" flexDirection="column" width="100%">
                <span className={styles.cycleHeader}>{i18next.t('resultsScreen.breakdownTable.selectedCycle')}</span>
                <span className={styles.baseHeader}>{dateRangeLabel}</span>
              </Flex>
            </CellWrapper>
          ),
          columns: crossQuestionColumns,
        },
        {
          id: 'compareColumns',
          Header: () => (
            <CellWrapper borderLeft>
              <Flex alignItems="flex-start" flexDirection="column" width="100%">
                <span className={styles.cycleHeader}>{i18next.t('resultsScreen.breakdownTable.comparedTo')}</span>
                <span className={styles.baseHeader}>{comparisonDateRangeLabel}</span>
              </Flex>
            </CellWrapper>
          ),
          columns: compareQuestionColumns,
        },
      ]
    : [];

  return columns;
};
