import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZCDSelect } from '@zencity/common-ui';
import { SelectOption } from 'types/selectOption';
import styles from './ClientFilter.module.scss';

interface Props {
  setGlobalFilter: (filterValue: string) => void;
  options: SelectOption[];
}

export const ClientFilter: React.FC<Props> = ({ setGlobalFilter, options }) => {
  const { t: translate } = useTranslation();

  const [selectedOption, setSelectedOption] = React.useState<SelectOption>();

  const clientOnChange = (option: SelectOption) => {
    if (option) {
      const { value } = option;
      setGlobalFilter(value as string);
      setSelectedOption(option);
    } else {
      setGlobalFilter('');
      setSelectedOption(undefined);
    }
  };

  return (
    <div className={styles.clientFilter}>
      <div className={styles.filterWidth} data-testid="filter">
        <ZCDSelect
          isSearchable={false}
          isClearable
          options={options}
          onChange={(newValue) => clientOnChange(newValue as SelectOption)}
          value={selectedOption}
          placeholder={translate('communityAsks.clientFilter.placeholder')}
        />
      </div>
    </div>
  );
};
