import { Nullable } from '@zencity/survey-types/lib/types/misc';
import { SatisfactionScore } from 'types/score';

export interface CxScoreTrendBreakdown {
  count: number;
  score: number;
  submitted_at: string;
}

export interface CxScoreTrend {
  end_date: string;
  start_date: string;
  breakdown: CxScoreTrendBreakdown[];
}

export interface CxSurveyScoreData {
  average_score: number;
  satisfaction_distribution: SatisfactionScore;
  trend: CxScoreTrend;
}

export interface CxServiceTypeData {
  id: number;
  label: string;
  score?: CxSurveyScoreData;
}

export interface CxSurveyAspectScore {
  label: string;
  sentence: string;
  score: Nullable<CxSurveyScoreData>;
}

export enum CxDashboardDataKeys {
  SUBMISSIONS_COUNT = 'submissions_count',
  FILTERED_SUBMISSIONS_COUNT = 'filtered_submissions_count',
  PERFORMANCE = 'performance',
  SERVICE_TYPES = 'service_types',
}

type AspectLabel = string;

export type CxDashboardData = {
  [CxDashboardDataKeys.SUBMISSIONS_COUNT]: number;
  [CxDashboardDataKeys.FILTERED_SUBMISSIONS_COUNT]: number;
  [CxDashboardDataKeys.PERFORMANCE]: CxSurveyAspectScore;
  [CxDashboardDataKeys.SERVICE_TYPES]: CxServiceTypeData[];
} & { [key: AspectLabel]: CxSurveyAspectScore };
