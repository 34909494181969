import { IconName, ZCDIcon, ZCDScreenStickyHeader } from '@zencity/common-ui';
import { SurveyGroup } from '@zencity/survey-types';
import { DateRangeCompareFilter } from 'components/DateRangeCompareFilter/DateRangeCompareFilter';
import { DateRangeFilter } from 'components/DateRangeFilter/DateRangeFilter';
import { FeedAnswerSearch } from 'components/FeedAnswerSearch/FeedAnswerSearch';
import { HeaderTabs } from 'components/HeaderTabs/HeaderTabs';
import { TabKeys } from 'constants/tabs';
import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './Header.module.scss';

interface HeaderProps {
  tabs: TabKeys[];
  headerTitle: string;
  headerIcon: IconName;
  currentSurveyGroup?: SurveyGroup;
  extraFilterComponents?: (ReactElement | null)[];
  supportDefaultFilters?: boolean;
  shouldDisplaySearchBar?: boolean;
  onTabSwitch?: (tab: TabKeys) => void;
}

/**
 * The common header component.
 */
export const Header = (props: HeaderProps): ReactElement => {
  const {
    tabs,
    currentSurveyGroup,
    headerTitle,
    extraFilterComponents = [],
    headerIcon,
    onTabSwitch,
    supportDefaultFilters = true,
    shouldDisplaySearchBar = false,
  } = props;

  const defaultHeaderFilters: ReactElement[] = supportDefaultFilters
    ? [<DateRangeFilter key="date-range-filter" />, <DateRangeCompareFilter key="date-range-compare-filter" />]
    : [];
  const headerFilters = [...defaultHeaderFilters, ...extraFilterComponents];
  return (
    <>
      <div className={styles.headerContainer}>
        <ZCDScreenStickyHeader bottomPadding={false}>
          <div className={styles.header}>
            <div className={styles.icon}>
              <ZCDIcon name={headerIcon} />
            </div>
            <h1 className={styles.title}>{headerTitle}</h1>
          </div>
          <div className={styles.headerContent}>
            <div className={styles.filters}>
              {headerFilters.map(
                (filterComponent) =>
                  filterComponent && (
                    <div key={filterComponent?.key} className={styles.filter}>
                      {filterComponent}
                    </div>
                  ),
              )}
            </div>
            {shouldDisplaySearchBar && <FeedAnswerSearch />}
          </div>
          <HeaderTabs tabs={tabs} onTabSwitch={onTabSwitch} />
        </ZCDScreenStickyHeader>
      </div>
      {/* Outlet is used to render the tabs as children components in React Router v6 */}
      <Outlet context={{ surveyGroup: currentSurveyGroup }} />
    </>
  );
};
