import classNames from 'classnames';
import { Flex, FlexProps } from 'components/Flex/Flex';
import React from 'react';
import styles from './CellWrapper.module.scss';

interface Props {
  children?: React.ReactNode;
  borderLeft?: boolean;
  borderRight?: boolean;
  width?: FlexProps['width'];
  alignItems?: FlexProps['alignItems'];
  flexDirection?: FlexProps['flexDirection'];
  justifyContent?: FlexProps['justifyContent'];
}

export const CellWrapper = (props: Props) => {
  const {
    borderLeft,
    borderRight,
    children,
    width = '100%',
    alignItems = 'flex-start',
    flexDirection = 'column',
    justifyContent = 'center',
  } = props;
  return (
    <Flex
      className={classNames(styles.cellWrapper, {
        [styles.borderLeft]: borderLeft,
        [styles.borderRight]: borderRight,
      })}
      width={width}
      alignItems={alignItems}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
    >
      {children}
    </Flex>
  );
};
