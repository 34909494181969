import { ZCDButton } from '@zencity/common-ui';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { AnalyticWidgetNames } from 'constants/analytics';
import { TabKeys } from 'constants/tabs';
import { MapsContext } from 'contexts/MapsContext';
import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import { DivisionList } from './DivisionList/DivisionList';
import styles from './GeographicBreakdown.module.scss';
import { MapWidget } from './MapWidget/MapWidget';

export const GeographicBreakdown = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { divisions } = useContext(MapsContext);
  const { selectedDateWidgetData } = useContext(SummaryAspectWidgetContext);
  const activeFilters = useFilterNames();
  const navigate = useNavigate();

  const handleRedirectToMap = (): void => {
    navigate(`/${TabKeys.MAP}`);

    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('blockwiseSummaryTab.geographicBreakdown.goToMap'),
      widgetName: AnalyticWidgetNames.GEOGRAPHIC_BREAKDOWN_WIDGET,
      activeFilters,
    });
  };

  return (
    <div className={styles.geographicBreakdown}>
      <div className={styles.header}>
        {translate('blockwiseSummaryTab.geographicBreakdown.title')}
        <ZCDButton
          variant="link"
          text={translate('blockwiseSummaryTab.geographicBreakdown.goToMap')}
          onClick={handleRedirectToMap}
        />
      </div>
      <div className={styles.content}>
        <LoaderMask className={styles.loaderMask} isLoading={!divisions.length || !selectedDateWidgetData}>
          <MapWidget />
          <DivisionList />
        </LoaderMask>
      </div>
    </div>
  );
};
