import { Answer as AnswerType } from '@zencity/survey-types';
import React, { ReactElement } from 'react';
import styles from './Answer.module.scss';
import Content from './components/Content';
import { CXSurveyData } from './components/CXSurveyData';
import Description from './components/Description';
import TopicList from './components/TopicList';

interface Props {
  answer: AnswerType;
}

export function resolveAnswerValue(answer: AnswerType): string {
  /*
  The answer won't include more than 2 translations and one of them is the English translation.
  The `value` key is populated with the data of the default translation language (English),
  meaning one of the translations keys and thus, it's the default value for the accumulator.
  If the `value` key is not empty it will always contain the English translation.
  */
  const answersDataKeys = Object.keys(answer) as (keyof AnswerType)[];
  const resolvedAnswerValue = answersDataKeys.reduce<undefined | null | string>((accumulator, current) => {
    if (current.includes('value_')) {
      return accumulator ?? (answer[current] as string | null | undefined);
    }
    return accumulator;
  }, answer.value);
  return resolvedAnswerValue || '';
}

const Answer = ({ answer }: Props): ReactElement => {
  const { sub_topics, submission } = answer;
  const content = resolveAnswerValue(answer);

  const topics = sub_topics ?? [submission.cx_data?.case_service_type?.label];
  return (
    <div className={styles.answer}>
      <Description submission={submission} />
      <Content content={content} />
      <TopicList topics={topics} />
      <CXSurveyData submission={submission} />
    </div>
  );
};
export default Answer;
