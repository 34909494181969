import { subDays } from 'date-fns';
import { volumeOfInteractionsMock } from 'mocks/data/volumeOfInteractions.mock';
import { clientTimezoneEndOfDay, clientTimezoneStartOfDay } from 'utils/time';
import { config } from '../../config/config';
import { Interaction, Options, VolumeOfInteractionsData } from '../types/volumeOfInteractions';
import { createAxiosInstance } from '../utils/Api';

const reportAxios = createAxiosInstance(config.apiUrls.volumeOfInteractions);

const volumeOfInteractionsOptions = (clientId: string, timezone: string): Options => {
  const fromDate = clientTimezoneStartOfDay(subDays(new Date(), 7), timezone).toISOString();
  const toDate = clientTimezoneEndOfDay(new Date(), timezone).toISOString();
  return {
    aggregatedMetrics: {
      // eslint-disable-next-line id-denylist
      data: {
        filters: {
          client: clientId,
          dateRange: 'week',
        },
        dimensions: ['client'],
      },
      params: {
        requestOrigin: 'VolumeOfInteractionsTimeline',
      },
    },
    report: {
      // eslint-disable-next-line id-denylist
      data: {
        client: clientId,
        dateFrom: fromDate,
        dateTo: toDate,
        dimension: 'total',
        sentimentBreakdown: 'both',
        timeResolution: 'day',
      },
    },
    timelineMarkerPeriod: {
      params: {
        periodStartDate: fromDate,
        periodEndDate: toDate,
      },
    },
  };
};

/**
 * Get a survey's open questions
 * @param filters
 */
export async function fetchVolumeOfInteractions(clientId: string, timezone: string): Promise<VolumeOfInteractionsData> {
  if (config.shouldMockRequests) {
    return Promise.resolve({ interactions: volumeOfInteractionsMock });
  }
  if (!clientId) {
    console.error('No client provided!');
    return {
      aggregatedInteractions: undefined,
      interactions: undefined,
      events: undefined,
    };
  }

  const apiParams = volumeOfInteractionsOptions(clientId, timezone);

  try {
    const report = await reportAxios.post('', apiParams.report.data);

    return {
      aggregatedInteractions: undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      interactions: report.data as any,
      events: undefined,
    };
  } catch (error) {
    console.error(error);
    return {
      aggregatedInteractions: undefined,
      interactions: undefined,
      events: undefined,
    };
  }
}

/**
 * calculate the total interactions
 * @param interactions
 */
export function sumUpInteractions(interactions: Interaction[]): number {
  return interactions.reduce((accumulator, interaction) => {
    let totalInteractions = accumulator;
    totalInteractions += interaction.categories.total.positiveSentiment;
    totalInteractions += interaction.categories.total.negativeSentiment;
    totalInteractions += interaction.categories.total.neutralSentiment;
    return totalInteractions;
  }, 0);
}
