import { DemographicScore } from 'types/score';

const generateRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const mockDemographicsScores: DemographicScore[] = [
  // Setting this as the highest total submissions count
  // for testing purposes.
  {
    demographicType: 'age_group',
    demographicValue: 'age_group_2',
    demographicLabel: '35-54',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(300, 400),
        scores: { positive: 67, negative: 1, neutral: 32 },
      },
    ],
  },
  {
    demographicType: 'gender',
    demographicValue: 'female',
    demographicLabel: 'Female',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 72, negative: 1, neutral: 27 },
      },
    ],
  },
  {
    demographicType: 'income',
    demographicValue: 'income_group_1',
    demographicLabel: '$49,999 or less',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 73, negative: 1, neutral: 26 },
      },
    ],
  },
  {
    demographicType: 'education',
    demographicValue: 'education_group_1',
    demographicLabel: 'High school degree or less',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 76, negative: 1, neutral: 23 },
      },
    ],
  },
  {
    demographicType: 'ethnicity',
    demographicValue: 'black_or_african_american',
    demographicLabel: 'Black or African American',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 72, negative: 1, neutral: 27 },
      },
    ],
  },
  {
    demographicType: 'income',
    demographicValue: 'income_group_2',
    demographicLabel: '$50,000-$124,999',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 71, negative: 1, neutral: 28 },
      },
    ],
  },
  {
    demographicType: 'education',
    demographicValue: 'education_group_3',
    demographicLabel: 'Higher education degree',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 72, negative: 1, neutral: 27 },
      },
    ],
  },
  {
    demographicType: 'age_group',
    demographicValue: 'age_group_1',
    demographicLabel: '18-34',
    scores: [
      {
        endDate: '2020-12-31',
        startDate: '2020-01-01',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 78, negative: 0, neutral: 22 },
      },
    ],
  },
  {
    demographicType: 'gender',
    demographicValue: 'male',
    demographicLabel: 'Male',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 75, negative: 0, neutral: 25 },
      },
    ],
  },
  {
    demographicType: 'ethnicity',
    demographicValue: 'asian',
    demographicLabel: 'Asian',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 76, negative: 1, neutral: 23 },
      },
    ],
  },
  {
    demographicType: 'income',
    demographicValue: 'income_group_3',
    demographicLabel: '$125,000 or more',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 77, negative: 2, neutral: 21 },
      },
    ],
  },
  {
    demographicType: 'ethnicity',
    demographicValue: 'white',
    demographicLabel: 'White',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 73, negative: 1, neutral: 26 },
      },
    ],
  },
  {
    demographicType: 'age_group',
    demographicValue: 'age_group_3',
    demographicLabel: '55+',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 74, negative: 2, neutral: 24 },
      },
    ],
  },
  {
    demographicType: 'education',
    demographicValue: 'education_group_2',
    demographicLabel: 'Some college or college degree',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 71, negative: 2, neutral: 27 },
      },
    ],
  },
  // Setting this as the lowest total submissions count
  // for testing purposes
  {
    demographicType: 'origin',
    demographicValue: 'hispanic',
    demographicLabel: 'Hispanic',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(1, 10),
        scores: { positive: 75, negative: 1, neutral: 24 },
      },
    ],
  },
  {
    demographicType: 'ethnicity',
    demographicValue: 'native_american_or_alaskan_native',
    demographicLabel: 'Native American or Alaskan Native',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 46, negative: 7, neutral: 47 },
      },
    ],
  },
  {
    demographicType: 'ethnicity',
    demographicValue: 'native_hawaiian_or_other_pacific_islander',
    demographicLabel: 'Native Hawaiian or Other Pacific Islander',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-12-31',
        totalSubmissions: generateRandomNumber(100, 200),
        scores: { positive: 37, negative: 6, neutral: 57 },
      },
    ],
  },
];
