/* eslint-disable max-nested-callbacks,i18next/no-literal-string */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { StatusBox, Status, Button, Box } from '@zencity/common-ui';
import { useTranslation } from 'react-i18next';
import { FullScreenEmptyState } from 'components/FullScreenEmptyState/FullScreenEmptyState';
import { fetchQuestionsSummary } from 'services/question';
import { fetchSurveyGroupSummary } from 'services/surveyGroup';
import { logger } from 'utils/community-asks/logger';
import { SurveyRequestContext } from 'contexts/SurveyRequestContext';
import { QuestionSummary } from 'components/community-asks/QuestionSummary/QuestionSummary';
import { QuestionSummaryData } from 'types/question';
import styles from './Summary.module.scss';

/**
 * Community Asks survey summary screen.
 */
export const Summary = function Summary(): ReactElement {
  const { t: translate } = useTranslation();
  const surveyRequest = useContext(SurveyRequestContext);
  const [loadingStatus, setLoadingStatus] = useState<Status>(Status.LOADING);
  const [questions, setQuestions] = useState<QuestionSummaryData[] | null>(null);

  // Fallback to an invalid ID (since a number is required).
  const surveyGroupId = surveyRequest?.survey_group?.id ?? 0;

  const openZencityHelpArticle = () => {
    window.open(
      'https://help.zencity.io/hc/en-us/articles/5824929254545-Tips-for-driving-high-engagement-rates',
      '_blank',
    );
  };

  useEffect(() => {
    if (surveyRequest?.survey_group) {
      // eslint-disable-next-line max-statements
      const fetchData = async function fetchData() {
        try {
          const surveyGroupSummary = await fetchSurveyGroupSummary(surveyGroupId);

          // TODO - this hack bypasses react's rendring life-cycle!
          // updating the `SurveyRequestContext` is required, but it now holds child entities
          // of child entities and it's too messy.
          // see ticket: https://zencity.atlassian.net/browse/SR-853
          if (surveyRequest && surveyRequest.survey_group) {
            if (!surveyRequest.survey_group.summary) {
              // just a meaningless initialization
              surveyRequest.survey_group.summary = { total_respondents: 0 };
            }

            surveyRequest.survey_group.summary.total_respondents = surveyGroupSummary.total_respondents;
          }
          const questionsSummary = await fetchQuestionsSummary(surveyGroupId);
          setQuestions(questionsSummary);
          setLoadingStatus(Status.OKAY);
        } catch (error) {
          logger.error(error);
          setLoadingStatus(Status.ERROR);
        }
      };
      fetchData();
    }
  }, [surveyGroupId, surveyRequest]);

  return (
    <Box.Wrapper className={styles.summary}>
      <StatusBox status={loadingStatus}>
        {surveyRequest?.survey_group?.summary?.total_respondents && questions?.length ? (
          questions.map((question, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <QuestionSummary
              surveyGroupId={surveyGroupId.toString()}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              totalRespondents={surveyRequest.survey_group!.summary!.total_respondents}
              question={question}
              position={index + 1}
              key={question.id}
            />
          ))
        ) : (
          <FullScreenEmptyState
            iconName="survey-data.svg"
            title={translate('communityAsks.summaryScreen.emptyState.title')}
            description={translate('communityAsks.summaryScreen.emptyState.description')}
          >
            <Button onClick={openZencityHelpArticle}>
              {translate('communityAsks.summaryScreen.emptyState.button')}
            </Button>
          </FullScreenEmptyState>
        )}
      </StatusBox>
    </Box.Wrapper>
  );
};
