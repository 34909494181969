import { DemographicScore, GenericQuestionScore } from 'types/score';

export const remappingQuestions = (
  genericQuestionScores: GenericQuestionScore[],
  genericQuestionScoresToCompare: GenericQuestionScore[],
): GenericQuestionScore[] =>
  genericQuestionScores.map((genericQuestionScore) => {
    const comparisonGenericQuestionScore = genericQuestionScoresToCompare.find(
      (genericQuestionScoreToCompareElem) =>
        genericQuestionScoreToCompareElem.genericQuestionId === genericQuestionScore.genericQuestionId,
    );

    if (!comparisonGenericQuestionScore) return genericQuestionScore;

    // Since the Questions were fetched for a specific date range,
    // only 1 score is expected per question.
    const comparisonQuestionScore = comparisonGenericQuestionScore.scores[0] ?? null;
    const genericQuestionScoreWithComparison: GenericQuestionScore = {
      ...genericQuestionScore,
      comparisonScore: comparisonQuestionScore,
    };
    return genericQuestionScoreWithComparison;
  });

export const remappingDemographics = (
  demographicScores: DemographicScore[],
  demographicScoresToCompare: DemographicScore[],
): DemographicScore[] =>
  demographicScores.map((demographicScore) => {
    const comparisonDemographicScore = demographicScoresToCompare.find(
      (demographicScoreToCompareElem) =>
        demographicScoreToCompareElem.demographicType === demographicScore.demographicType &&
        demographicScoreToCompareElem.demographicLabel === demographicScore.demographicLabel,
    );

    if (!comparisonDemographicScore) return demographicScore;

    // Since the Demographics were fetched for a specific date range,
    // only 1 score is expected per demographic.
    const comparisonDemoScore = comparisonDemographicScore.scores[0] ?? null;
    const demographicScoreWithComparison: DemographicScore = {
      ...demographicScore,
      comparisonScore: comparisonDemoScore,
    };
    return demographicScoreWithComparison;
  });

export const sortDemographicScores = (satisfactionScores: DemographicScore[]): DemographicScore[] => {
  const copiedScores = [...satisfactionScores];
  return copiedScores.sort(
    (scoreA: DemographicScore, scoreB: DemographicScore) =>
      (scoreB.scores?.[0].scores?.positive ?? -1) - (scoreA.scores?.[0].scores?.positive ?? -1),
  );
};

export const sortAndSplitDemographicScores = (
  satisfactionScores: DemographicScore[],
): { highestScores: DemographicScore[]; lowestScores: DemographicScore[] } => {
  const sortedScores = sortDemographicScores(satisfactionScores);
  const half = Math.ceil(sortedScores.length / 2);
  return { highestScores: sortedScores.slice(0, half), lowestScores: sortedScores.slice(half).reverse() };
};

export const sortQuestionScores = (satisfactionScores: GenericQuestionScore[]): GenericQuestionScore[] => {
  const copiedScores = JSON.parse(JSON.stringify(satisfactionScores));
  return copiedScores.sort(
    (scoreA: GenericQuestionScore, scoreB: GenericQuestionScore) =>
      (scoreB.scores?.[0]?.scores?.positive ?? -1) - (scoreA.scores?.[0]?.scores?.positive ?? -1),
  );
};

export const sortAndSplitQuestionScores = (
  satisfactionScores: GenericQuestionScore[],
): { highestScores: GenericQuestionScore[]; lowestScores: GenericQuestionScore[] } => {
  const sortedScores = sortQuestionScores(satisfactionScores);
  const half = Math.ceil(sortedScores.length / 2);
  return { highestScores: sortedScores.slice(0, half), lowestScores: sortedScores.slice(half).reverse() };
};
