import { getMapFeatureByArea } from 'components/MapView/MapView.helpers';
import useScoreDateRangeFilter from 'hooks/score/dateRangeFilter';
import { useEffect, useState } from 'react';
import { AreasWithScoresObject, FEATURE_COLLECTION } from 'types/areas';
import { SatisfactionScore } from 'types/score';
import { getScorePerDateRange } from 'utils/dateRange';

interface Props {
  areasWithScores: AreasWithScoresObject;
}

export const useMapLayerData = (props: Props) => {
  const { areasWithScores } = props;
  const [layerData, setLayerData] = useState<GeoJSON.FeatureCollection>();
  const { dateRangeFilter } = useScoreDateRangeFilter();

  useEffect(() => {
    if (!dateRangeFilter) return;
    const featureList = Object.values(areasWithScores).map((areaWithScoresData) => {
      const selectedFilterScores =
        getScorePerDateRange({
          scorePerDateRange: areaWithScoresData?.scoresPerPeriod,
          dateRangeFilter,
        })?.scores || null;
      // Take the most recent period's positive score. For the map view, the score needs to be a number.
      const positiveScore = (selectedFilterScores as SatisfactionScore)?.positive || -1;
      return getMapFeatureByArea({ area: areaWithScoresData, positiveScore });
    });
    setLayerData({ type: FEATURE_COLLECTION, features: featureList });
  }, [areasWithScores, dateRangeFilter]);

  return { layerData };
};
