import React, { ReactElement, useContext } from 'react';
import { SurveyContext } from 'contexts/SurveyContext';
import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import Title from './components/Title';
import Counter from './components/Counter';
import styles from './Question.module.scss';

const Question = (): ReactElement => {
  const { currentQuestion, totalAnswers } = useContext(SurveyContext);
  const { openEndedQuestions: questions } = useContext(OpenEndedQuestionFeedContext);
  const question = questions[currentQuestion];

  return (
    <>
      {question && (
        <div className={styles.questionBox}>
          <Title content={question} />
          <Counter totalAnswersCount={totalAnswers} />
        </div>
      )}
    </>
  );
};

export default Question;
