/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading, i18next/no-literal-string, max-lines-per-function */
import React, { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useTable, usePagination, useGlobalFilter, HeaderGroup, ColumnInstance } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Button } from '@zencity/common-ui';
import { CopySurveyPublicLink } from 'components/CopySurveyPublicLink/CopySurveyPublicLink';
import { formatDate } from 'utils/formatters';
import { SurveyRequest, SurveyRequestStatus, SurveyGroup } from '@zencity/survey-types';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { config } from '../../../../../../config/config';
import styles from './ManagementTable.module.scss';
import { ClientFilter } from '../ClientFilter/ClientFilter';

interface Props {
  allSurveyRequests: SurveyRequest[];
}

/**
 * Table to allow internal users to view, publish, and edit Community Asks.
 */
export const ManagementTable: React.FC<Props> = function ManagementTable(props: Props): ReactElement {
  const { allSurveyRequests } = props;
  const { t: translate } = useTranslation();

  const tableData = React.useMemo(() => allSurveyRequests, [allSurveyRequests]) || [];
  const columns = React.useMemo(
    () => [
      {
        Header: translate('communityAsksManagement.headers.client'),
        accessor: 'client.name' as keyof SurveyRequest,
      },
      {
        Header: translate('communityAsksManagement.headers.requestText'),
        accessor: 'request_text' as keyof SurveyRequest,
      },
      {
        Header: translate('communityAsksManagement.headers.dateRequested'),
        accessor: 'created_at' as keyof SurveyRequest,
        Cell: ({ value }: { value: string }) => formatDate(value),
      },
      {
        Header: translate('communityAsksManagement.headers.status'),
        accessor: 'status' as keyof SurveyRequest,
      },
      {
        Header: translate('communityAsksManagement.headers.surveyManagerLink'),
        // eslint-disable-next-line id-length, react/no-multi-comp
        Cell: ({ row }: { row: { original: { id: string; status: SurveyRequestStatus } } }): JSX.Element => {
          const editableStatuses = [SurveyRequestStatus.REQUESTED, SurveyRequestStatus.DRAFTED];
          const surveyManagerLink =
            row.original.status === SurveyRequestStatus.REQUESTED
              ? `${config.surveyManager.domain}${config.surveyManager.newSurvey}?surveyRequestId=${row.original.id}`
              : config.surveyManager.domain;
          return (
            <Button
              variant="link"
              size="sm"
              className={styles.overrideBtnStyle}
              disabled={!editableStatuses.includes(row.original.status)}
            >
              <a target="_blank" rel="noreferrer" href={surveyManagerLink}>
                {translate('communityAsksManagement.goToSurveyManager')}
              </a>
            </Button>
          );
        },
      },
      {
        Header: translate('communityAsksManagement.headers.view'),
        // eslint-disable-next-line id-length, react/no-multi-comp
        Cell: ({ row }: { row: { original: { status: SurveyRequestStatus; survey_group: SurveyGroup } } }) => {
          if (!row.original.survey_group?.id) {
            return <></>;
          }
          const surveyPagePath = generatePath(CommunityAsksRouterPaths.SURVEY_PAGE, {
            surveyGroupId: row.original.survey_group?.id.toString(),
          });

          const surveyActive = row.original.status === SurveyRequestStatus.ACTIVE;
          const surveyCompleted = row.original.status === SurveyRequestStatus.COMPLETED;
          const surveyClosed = row.original.status === SurveyRequestStatus.CLOSED;
          return (
            (surveyActive || surveyCompleted || surveyClosed) && (
              <Button type="button" variant="secondary" size="sm">
                <Link className={styles.linkToSurveyPage} to={surveyPagePath}>
                  {translate('communityAsksManagement.view')}
                </Link>
              </Button>
            )
          );
        },
      },
      {
        Header: translate('communityAsksManagement.headers.surveyLink'),
        className: styles.copySurveyPublicLink,
        // eslint-disable-next-line id-length, react/no-multi-comp
        Cell: ({ row }: { row: { original: { status: SurveyRequestStatus; survey_group: SurveyGroup } } }) =>
          row.original.survey_group ? <CopySurveyPublicLink asIcon surveyGroup={row.original.survey_group} /> : <></>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
  } = useTable<SurveyRequest>(
    {
      columns,
      // eslint-disable-next-line id-denylist
      data: tableData,
      initialState: { pageSize: 20 },
    },
    useGlobalFilter,
    usePagination,
  );

  const clients = Array.from(
    new Set(
      tableData.reduce((result: string[], surveyRequest: SurveyRequest) => {
        const { name, state } = surveyRequest.client ?? {};
        if (name && state) {
          result.push(`${name}, ${state}`);
        } else if (name) {
          result.push(name);
        }
        return result;
      }, []),
    ),
  ).sort();
  const options = clients.map((client) => ({ label: client, value: client }));

  return (
    <>
      <ClientFilter setGlobalFilter={setGlobalFilter} options={options} />
      <table className={styles.asksManagementTable} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    className: (column as HeaderGroup<SurveyRequest> & { className: string }).className,
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((rowData) => {
            prepareRow(rowData);
            return (
              <tr {...rowData.getRowProps()}>
                {rowData.cells.map((cell) => (
                  <td
                    {...cell.getCellProps({
                      className: (cell.column as ColumnInstance<SurveyRequest> & { className: string }).className,
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          {translate('communityAsksManagement.page')} {pageIndex + 1} {translate('communityAsksManagement.of')}{' '}
          {pageOptions.length}
        </span>
      </div>
    </>
  );
};
