import classNames from 'classnames';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { AnalysisContext } from 'contexts/AnalysisContext';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionChoice } from 'types/questionsScores';
import { SatisfactionScore } from 'types/score';
import { SummaryWidgetType } from './misc';
import styles from './AnalysisQuestionSummary.module.scss';
import SummaryWidgetOverall from './SummaryWidget/SummaryWidgetOverall';
import SummaryWidgetSentiment from './SummaryWidget/SummaryWidgetSentiment';

interface Props {
  totalSubmissions: number;
  totalQuestionSubmissions: number;
  questionScore: SatisfactionScore;
  comparisonScore?: SatisfactionScore;
  isNoScoresQuestion?: boolean;
  topQuestionChoice?: QuestionChoice;
}
const AnalysisQuestionSummary = (props: Props): ReactElement => {
  const {
    topQuestionChoice,
    questionScore,
    comparisonScore,
    totalSubmissions,
    totalQuestionSubmissions,
    isNoScoresQuestion,
  } = props;
  const { comparisonDateRange } = useContext(DateRangeContext);
  const submissionsRatio = Math.round((totalQuestionSubmissions / totalSubmissions) * 100);
  const { hideOvertimeTrends } = useContext(AnalysisContext);

  const { t: translate } = useTranslation();

  return (
    <div
      className={classNames([styles.resultsQuestionSummary], {
        [styles.noScoresQuestionLayout]: isNoScoresQuestion,
      })}
    >
      <SummaryWidgetOverall
        score={submissionsRatio}
        label={translate('blockwiseResultsTab.answers')}
        totalQuestionSubmissions={totalQuestionSubmissions}
        postfix={translate('blockwiseResultsTab.ofAll')}
      />
      {isNoScoresQuestion && topQuestionChoice ? (
        <SummaryWidgetSentiment
          score={topQuestionChoice.score}
          comparisonScore={comparisonScore?.positive}
          widgetType={SummaryWidgetType.ANSWERS}
          comparisonLabel={comparisonDateRange?.label}
          label={`"${topQuestionChoice?.choiceText}"`}
        />
      ) : (
        <>
          <SummaryWidgetSentiment
            score={questionScore.positive}
            widgetType={SummaryWidgetType.POSITIVE}
            comparisonLabel={comparisonDateRange?.label}
            comparisonScore={comparisonScore?.positive}
            label={translate('blockwiseResultsTab.positive')}
            hideOvertimeTrends={hideOvertimeTrends}
          />
          <SummaryWidgetSentiment
            score={questionScore.negative}
            widgetType={SummaryWidgetType.NEGATIVE}
            comparisonLabel={comparisonDateRange?.label}
            comparisonScore={comparisonScore?.negative}
            label={translate('blockwiseResultsTab.negative')}
            hideOvertimeTrends={hideOvertimeTrends}
          />
        </>
      )}
    </div>
  );
};

export default AnalysisQuestionSummary;
