import { Flex } from 'components/Flex/Flex';
import { AnalyticWidgetNames } from 'constants/analytics';
import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import React, { Fragment, useContext, useEffect } from 'react';
import { analyticsService } from 'services/analytics/analytics';
import { SelectOption } from 'types/selectOption';
import { DemographicGroupHeader } from './DemographicGroupHeader/DemographicGroupHeader';
import { DemographicGroupItem } from './DemographicGroupItem/DemographicGroupItem';
import styles from './DemographicGroups.module.scss';
import { findAndSortDemographicGroupItems } from './DemographicGroupsUtils';

export const DemographicGroups = (): JSX.Element | null => {
  const { activeTab, selectedDateWidgetData, comparisonDateWidgetData, demographicTypes } =
    useContext(SummaryAspectWidgetContext);
  const [selectedDemographicType, setSelectedDemographicType] = React.useState<string>();

  useEffect(() => {
    if (!selectedDemographicType)
      setSelectedDemographicType(
        demographicTypes?.find((demographic) => demographic.value === 'ethnicity')?.value as string,
      );
  }, [selectedDemographicType, demographicTypes]);

  if (!activeTab && activeTab !== 0) return null;

  const highestScoresDemographics = selectedDateWidgetData?.aspect_scores.find(
    (aspect) => aspect.aspect_id === activeTab,
  )?.demographics.most_satisfied;

  const demographicsToDisplay =
    highestScoresDemographics && findAndSortDemographicGroupItems(highestScoresDemographics, selectedDemographicType);

  const handleDemographicTypeChange = (demographicType: SelectOption) => {
    setSelectedDemographicType(demographicType.value as string);

    analyticsService.crossProductEvents.widgetDisplayTabChanged({
      widgetName: AnalyticWidgetNames.RATING_BY_DEMOGRAPHIC_WIDGET,
      filterValue: demographicType.label,
    });
  };
  return (
    <div className={styles.demographicGroups}>
      <DemographicGroupHeader
        handleDemographicTypeChange={handleDemographicTypeChange}
        selectedDemographicType={selectedDemographicType}
      />
      <Flex className={styles.content} flexDirection="column" justifyContent="space-between">
        {demographicsToDisplay?.map((demographic, index) => {
          const comparisonDemographicGroupItem = comparisonDateWidgetData?.aspect_scores
            .find((aspect) => aspect.aspect_id === activeTab)
            ?.demographics.most_satisfied?.find(
              (item) => item.type === demographic.type && item.label === demographic.label,
            );
          return (
            <Fragment key={`${demographic.type}_${demographic.label}`}>
              <DemographicGroupItem
                demographicGroupIem={demographic}
                comparisonDemographicGroupItem={comparisonDemographicGroupItem}
              />
              {index !== demographicsToDisplay.length - 1 && <div className={styles.divider} />}
            </Fragment>
          );
        })}
      </Flex>
    </div>
  );
};
