import { ProductNames } from 'constants/products';
import { User } from 'types/user';
import { loggers } from '../_loggers';

const product = ProductNames.COMMUNITY_ASKS;
// currently an uninitiated logger. main component will initiate in (with `initLogger`)
// before other components will actually use it.
let logger = loggers.get(product); // eslint-disable-line import/no-mutable-exports

export const initLogger = function initLogger(user: User, clientName: string): void {
  loggers.set(product, user, clientName);
  logger = loggers.get(product);
};

export { logger };
