import { AreasResponse } from 'types/areas';
import { DivisionWithPolygon } from '@zencity/survey-types';

export const reduceDivisionsToAreasResponse = (divisions: DivisionWithPolygon[]): AreasResponse =>
  divisions.reduce<AreasResponse>((accumulate, division) => {
    const { geo_targeting_ref } = division;
    if (geo_targeting_ref) {
      // eslint-disable-next-line no-param-reassign
      accumulate[geo_targeting_ref.id] = geo_targeting_ref;
    }
    return accumulate;
  }, {});
