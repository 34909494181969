import { ScoresByArea } from 'types/score';

export const mockScores: ScoresByArea = {
  '5d5460917c65895e7b9dfce4': {
    polygonId: '5d5460917c65895e7b9dfce4',
    polygonName: 'West',
    scores: [
      {
        scores: {
          positive: 16.8,
          neutral: 65.2,
          negative: 21.9,
        },
        startDate: '',
        endDate: '',
        totalSubmissions: 5312,
      },
      {
        scores: {
          positive: 57.8,
          neutral: 24.2,
          negative: 22.9,
        },
        startDate: '',
        endDate: '',
        totalSubmissions: 3434,
      },
    ],
  },
  '614affa2add0c68a0423b522': {
    polygonId: '614affa2add0c68a0423b522',
    polygonName: 'East',
    scores: [
      {
        scores: null,
        startDate: '',
        endDate: '',
        totalSubmissions: 0,
      },
      {
        scores: {
          positive: 42.9,
          neutral: 24.2,
          negative: 32.9,
        },
        startDate: '',
        endDate: '',
        totalSubmissions: 3434,
      },
    ],
  },
  '614affa2add0c68a0423b523': {
    polygonId: '614affa2add0c68a0423b523',
    polygonName: 'South',
    scores: [
      {
        scores: {
          positive: 71.9,
          neutral: 16.8,
          negative: 15.2,
        },
        startDate: '',
        endDate: '',
        totalSubmissions: 5312,
      },
      {
        scores: {
          positive: 32.9,
          neutral: 17.8,
          negative: 54.2,
        },
        startDate: '',
        endDate: '',
        totalSubmissions: 3434,
      },
    ],
  },
  '614affa2add0c68a0423b524': {
    polygonId: '614affa2add0c68a0423b524',
    polygonName: 'North',
    scores: [
      {
        scores: {
          positive: 31.9,
          neutral: 26.8,
          negative: 45.2,
        },
        startDate: '',
        endDate: '',
        totalSubmissions: 5312,
      },
      {
        scores: {
          positive: 89.5,
          neutral: 3.4,
          negative: 7.1,
        },
        startDate: '',
        endDate: '',
        totalSubmissions: 3434,
      },
    ],
  },
};
