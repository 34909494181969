/* eslint-disable max-nested-callbacks,i18next/no-literal-string */
import React, { ReactElement, useEffect, useState } from 'react';
import { StatusBox, Status, Box } from '@zencity/common-ui';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FullScreenEmptyState } from 'components/FullScreenEmptyState/FullScreenEmptyState';
import { fetchQuestionsSummary } from 'services/question';
import { fetchSurveyGroupSummary } from 'services/surveyGroup';
import { logger } from 'utils/customer-experience/logger';
import { QuestionSummary } from 'components/customer-experience/QuestionSummary/QuestionSummary';
import { ExtraFiltersParams } from 'types/misc';
import { SurveyGroupOutletContext } from 'types/surveyGroup';
import { QuestionSummaryData } from 'types/question';
import styles from './Summary.module.scss';

/**
 * "Customer Experience" survey summary screen.
 */
export const Summary = function Summary(): ReactElement {
  const { t: translate } = useTranslation();
  const { surveyGroup, extraFiltersParams } = useOutletContext<SurveyGroupOutletContext & ExtraFiltersParams>();
  const [loadingStatus, setLoadingStatus] = useState<Status>(Status.LOADING);
  const [questions, setQuestions] = useState<QuestionSummaryData[] | null>(null);
  const [totalRespondents, setTotalRespondents] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line max-statements
    const fetchData = async function fetchData() {
      try {
        const surveyGroupSummary = await fetchSurveyGroupSummary(surveyGroup.id);

        setTotalRespondents(surveyGroupSummary.total_respondents);

        setLoadingStatus(Status.LOADING);
        const questionsSummary = await fetchQuestionsSummary(surveyGroup.id, extraFiltersParams);
        setQuestions(questionsSummary);
        setLoadingStatus(Status.OKAY);
      } catch (error) {
        logger.error(error);
        setLoadingStatus(Status.ERROR);
      }
    };
    fetchData();
  }, [extraFiltersParams, surveyGroup]);

  return (
    <Box.Wrapper className={styles.summary}>
      <StatusBox status={loadingStatus}>
        {totalRespondents && questions && questions.length > 0 ? (
          questions.map((question, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <QuestionSummary
              surveyGroupId={surveyGroup.id.toString()}
              totalRespondents={totalRespondents}
              question={question}
              position={index + 1}
              key={question.id}
              extraFiltersParams={extraFiltersParams}
            />
          ))
        ) : (
          <FullScreenEmptyState
            iconName="survey-data.svg"
            title={translate('communityAsks.summaryScreen.emptyState.title')}
            description={translate('communityAsks.summaryScreen.emptyState.description')}
          />
        )}
      </StatusBox>
    </Box.Wrapper>
  );
};
