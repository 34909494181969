/* eslint-disable i18next/no-literal-string */
import { SurveyGroup, SurveyType } from '@zencity/survey-types';
import { DateRangeCompareFilter } from 'components/DateRangeCompareFilter/DateRangeCompareFilter';
import { DateRangeFilter } from 'components/DateRangeFilter/DateRangeFilter';
import { DemographicFilter } from 'components/DemographicFilter/DemographicFilter';
import { GeographicDivisionsFilter } from 'components/GeographicDivisionsFilter/GeographicDivisionsFilter';
import { Header } from 'components/Header/Header';
import { NoScoresEmptyState } from 'components/NoScoresEmptyState/NoScoresEmptyState';
import { Analysis } from 'components/Analysis/Analysis';
import { TabKeys } from 'constants/tabs';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { MapsContext } from 'contexts/MapsContext';
import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import { useScreenViewedEvent } from 'hooks/screenViewedEvent';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { OpenQuestionFeed } from 'screens/OpenQuestionFeed/OpenQuestionFeed';
import { ZencityRoutesProps } from 'types/router';
import { logger } from 'utils/community-survey/logger';
import { hasScoreDataForAnyDateRange } from 'utils/dateRange';
import { autoRedirectState } from 'utils/router';
import '../../i18n';
import '../../index.scss';

interface Props extends ZencityRoutesProps {
  currentSurveyGroup: SurveyGroup;
}

// eslint-disable-next-line max-statements
export const PulseRoutes = ({
  basename,
  currentSurveyGroup,
}: Props): React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> | null => {
  const { dashboardDateRanges = [] } = useContext(DateRangeContext);
  const { areOpenEndedQuestionsLoading, openEndedQuestions } = useContext(OpenEndedQuestionFeedContext);
  const hasNoCycleData = !hasScoreDataForAnyDateRange(dashboardDateRanges);
  const hasOpenEndedQuestions = !areOpenEndedQuestionsLoading && openEndedQuestions.length > 0;
  const clientDefaultTab = hasNoCycleData && hasOpenEndedQuestions ? TabKeys.FEED : TabKeys.ANALYSIS;
  const [currentTab, setCurrentTab] = useState<TabKeys>(clientDefaultTab);
  const { divisions } = useContext(MapsContext);
  const displayGeographicDivisionsFilter = divisions.filter((division) => division.depth === 1).length > 1;
  const displayDateRangeFilter = dashboardDateRanges.length > 1;
  const displayDateRangeComparisonFilter = dashboardDateRanges.length > 2;

  // The Header component will render these tabs in the order they appear here.
  const tabs: TabKeys[] = [TabKeys.ANALYSIS];
  if (hasOpenEndedQuestions) {
    tabs.push(TabKeys.FEED);
  }

  useEffect(() => {
    if (window.location.href.includes(TabKeys.FEED)) {
      setCurrentTab(TabKeys.FEED);
    }
  }, []);

  const routes: RouteObject[] = [
    {
      path: '/',
      element: (
        <Header
          tabs={tabs}
          headerIcon="large-smiley-face-cards"
          currentSurveyGroup={currentSurveyGroup}
          headerTitle={currentSurveyGroup.title_for_display}
          supportDefaultFilters={false}
          extraFilterComponents={[
            displayDateRangeFilter ? <DateRangeFilter key="date-range-filter" /> : null,
            displayDateRangeComparisonFilter ? <DateRangeCompareFilter key="date-range-compare-filter" /> : null,
            <DemographicFilter key="demographics" />,
            displayGeographicDivisionsFilter ? (
              <GeographicDivisionsFilter surveyType={SurveyType.PULSE} key="divisions" />
            ) : null,
          ]}
          onTabSwitch={setCurrentTab}
          shouldDisplaySearchBar={currentTab === TabKeys.FEED}
        />
      ),
      children: [
        { index: true, element: <Navigate to={clientDefaultTab} state={autoRedirectState} /> },
        { path: TabKeys.FEED, element: <OpenQuestionFeed /> },
        { path: TabKeys.ANALYSIS, element: hasNoCycleData ? <NoScoresEmptyState /> : <Analysis /> },
      ],
    },
    { path: '*', element: <div>404 - Page Not Found</div> },
  ];

  useScreenViewedEvent(routes, basename, logger);

  return useRoutes(routes);
};
