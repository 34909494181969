/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { RouteObject, useRoutes, Navigate } from 'react-router-dom';
import { TabKeys } from 'constants/tabs';
import { useScreenViewedEvent } from 'hooks/screenViewedEvent';
import { AsksManagementPage } from 'screens/community-asks/AsksManagementPage/AsksManagementPage';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { CommunityAsksReport } from 'screens/community-asks/CommunityAsksReport/CommunityAsksReport';
import { CustomAskRequest } from 'screens/community-asks/CustomAskRequest/CustomAskRequest';
import { OpenQuestionFeed } from 'screens/OpenQuestionFeed/OpenQuestionFeed';
import { Summary } from 'screens/community-asks/Summary/Summary';
import { SurveyLayout } from 'screens/community-asks/SurveyLayout/SurveyLayout';
import { TypeformPreview } from 'screens/community-asks/TypeformPreview/TypeformPreview';
import { Workspace } from 'screens/community-asks/Workspace/Workspace';
import { ZencityRoutesProps } from 'types/router';
import { logger } from 'utils/community-asks/logger';
import { autoRedirectState } from 'utils/router';
import '../../i18n';
import '../../index.scss';

/*
 * This component generates the Routes for the CommunityAsksDashboard.
 * We do this using the useRoutes hook with a RouteObject, instead of
 * with the <Routes><Route></Routes> elements format.
 *
 * See an example from the docs here: https://stackblitz.com/github/
 * remix-run/react-router/tree/main/examples/route-objects?file=src%2FApp.tsx
 *
 * We use useRoutes here instead of directly in CommunitySurveyDashboard
 * component since the component that contains the BrowserRouter cannot
 * also use useRoutes. That root component with the BrowserRouter needs to
 * embed another element that uses useRoutes.
 */
export const CommunityAsksRoutes = ({
  basename,
}: ZencityRoutesProps): React.ReactElement<
  ZencityRoutesProps,
  string | React.JSXElementConstructor<ZencityRoutesProps>
> | null => {
  // For an Asks Survey Page, we want to show the Summary tab first.
  const defaultTab = TabKeys.SUMMARY;

  const routes: RouteObject[] = [
    { index: true, element: <Workspace /> },
    { path: CommunityAsksRouterPaths.TYPEFORM_PREVIEW, element: <TypeformPreview /> },
    { path: CommunityAsksRouterPaths.CUSTOM_REQUEST, element: <CustomAskRequest /> },
    {
      path: CommunityAsksRouterPaths.SURVEY_PAGE,
      element: <SurveyLayout />,
      children: [
        { index: true, element: <Navigate to={defaultTab} state={autoRedirectState} /> },
        { path: TabKeys.SUMMARY, element: <Summary /> },
        { path: TabKeys.FEED, element: <OpenQuestionFeed /> },
        { path: TabKeys.REPORT, element: <CommunityAsksReport /> },
      ],
    },
    { path: CommunityAsksRouterPaths.MANAGEMENT, element: <AsksManagementPage /> },
    /* eslint-disable-next-line i18next/no-literal-string */
    { path: '*', element: <div>404 - Page Not Found</div> },
  ];

  useScreenViewedEvent(routes, basename, logger);

  return useRoutes(routes);
};
