import { QuestionType } from '@zencity/survey-types';
import { CrossQuestionResponse, ResponseQuestions } from 'types/questionsScores';
import { getRandomNumber } from 'utils/randomization';

export const resultsQuestionsScoresRequestMock = (): ResponseQuestions => ({
  total_submissions: getRandomNumber(6000, 8000),
  question_scores: [
    {
      generic_question_id: 1,
      question_text: 'Q1 text, with very long text, and because of that it will be truncated%client_name%?',
      question_index: 1,
      question_type: QuestionType.SCALE,
      total_submissions: getRandomNumber(100, 1000),
      question_choices: [
        {
          text: 'Choice 1',
          score: getRandomNumber(1, 20),
          numeric_value: 1,
          sort_value: 1,
          total_submissions: getRandomNumber(100, 1000),
          id: 1,
        },
        {
          text: 'Choice 2',
          score: getRandomNumber(1, 20),
          numeric_value: 2,
          sort_value: 2,
          total_submissions: 0,
          id: 2,
        },
        {
          text: 'Choice 3',
          score: getRandomNumber(1, 20),
          numeric_value: 3,
          sort_value: 3,
          total_submissions: getRandomNumber(100, 1000),
          id: 3,
        },
        {
          text: 'Choice 4',
          score: getRandomNumber(1, 20),
          numeric_value: 4,
          sort_value: 4,
          total_submissions: getRandomNumber(100, 1000),
          id: 4,
        },
        {
          text: 'Choice 5',
          score: getRandomNumber(1, 20),
          numeric_value: 5,
          sort_value: 5,
          total_submissions: getRandomNumber(100, 1000),
          id: 5,
        },
      ],
    },
    {
      generic_question_id: 22,
      question_text:
        'Question without scores, but with choices, with very long text, and because of that it will be truncated %client_name%?',
      question_index: 22,
      question_type: QuestionType.DROPDOWN,
      total_submissions: getRandomNumber(4000, 6000),
      question_choices: [
        {
          text: 'Statement 1',
          score: getRandomNumber(1, 100),
          numeric_value: null,
          sort_value: 1,
          total_submissions: getRandomNumber(100, 1000),
          id: 1,
        },
        {
          text: 'Statement 2 with very long text, and because of that it will be truncated',
          score: getRandomNumber(1, 100),
          numeric_value: null,
          sort_value: 2,
          total_submissions: getRandomNumber(100, 1000),
          id: 2,
        },
        {
          text: 'Statement 3',
          score: getRandomNumber(1, 100),
          numeric_value: null,
          sort_value: 3,
          total_submissions: getRandomNumber(100, 1000),
          id: 3,
        },
        {
          text: 'Statement 4 with very long text, and because of that it will be truncated',
          score: getRandomNumber(1, 100),
          numeric_value: null,
          sort_value: 4,
          total_submissions: getRandomNumber(100, 1000),
          id: 4,
        },
      ],
    },
    {
      generic_question_id: 2,
      question_text: 'Q2 text with very long text, and because of that it will be truncated',
      question_index: 2,
      question_type: QuestionType.SCALE,
      total_submissions: getRandomNumber(100, 1000),
      question_choices: [
        {
          text: 'Choice 1',
          score: getRandomNumber(1, 20),
          numeric_value: 1,
          sort_value: 1,
          total_submissions: getRandomNumber(100, 1000),
          id: 1,
        },
        {
          text: 'Choice 2',
          score: getRandomNumber(1, 20),
          numeric_value: 2,
          sort_value: 2,
          total_submissions: getRandomNumber(100, 1000),
          id: 2,
        },
        {
          text: 'Choice 3',
          score: getRandomNumber(1, 20),
          numeric_value: 3,
          sort_value: 3,
          total_submissions: getRandomNumber(100, 1000),
          id: 3,
        },
        {
          text: 'Choice 4',
          score: getRandomNumber(1, 20),
          numeric_value: 4,
          sort_value: 4,
          total_submissions: getRandomNumber(100, 1000),
          id: 4,
        },
        {
          text: 'Choice 5',
          score: getRandomNumber(1, 20),
          numeric_value: 5,
          sort_value: 5,
          total_submissions: getRandomNumber(100, 1000),
          id: 5,
        },
      ],
    },
    {
      generic_question_id: 3,
      question_text: 'Q3 open ended with very long text, and because of that it will be truncated',
      question_index: 3,
      question_type: QuestionType.OPEN_ENDED,
      total_submissions: getRandomNumber(100, 1000),
      question_choices: [],
    },
  ],
});

export const crossQuestionsScoresRequestMock: CrossQuestionResponse = {
  total_submissions: 6,
  cross_choices_data: [
    {
      text: 'Choice 1',
      numeric_value: 1,
      choice_id: 1,
      sort_index: 1,
      breakdown_by_cross: [
        {
          text: 'Very long long long text here!!',
          total_submissions: 2,
          score: 25,
        },
        {
          text: 'No - With Very long long long text here!!',
          total_submissions: 0,
          score: 0,
        },
      ],
    },
    {
      text: 'Choice 2',
      numeric_value: 2,
      choice_id: 2,
      sort_index: 2,
      breakdown_by_cross: [
        {
          text: 'Very long long long text here!!',
          total_submissions: 0,
          score: 0,
        },
        {
          text: 'No - With Very long long long text here!!',
          total_submissions: 1,
          score: 25,
        },
      ],
    },
    {
      text: 'Choice 3',
      numeric_value: 3,
      choice_id: 3,
      sort_index: 3,
      breakdown_by_cross: [
        {
          text: 'Very long long long text here!!',
          total_submissions: 2,
          score: 25,
        },
        {
          text: 'No - With Very long long long text here!!',
          total_submissions: 1,
          score: 25,
        },
      ],
    },
    {
      text: 'Choice 4',
      numeric_value: 4,
      choice_id: 4,
      sort_index: 4,
      breakdown_by_cross: [
        {
          text: 'Very long long long text here!!',
          total_submissions: 2,
          score: 30,
        },
        {
          text: 'No - With Very long long long text here!!',
          total_submissions: 1,
          score: 25,
        },
      ],
    },
    {
      text: 'Choice 5',
      numeric_value: 5,
      choice_id: 5,
      sort_index: 5,
      breakdown_by_cross: [
        {
          text: 'Very long long long text here!!',
          total_submissions: 2,
          score: 20,
        },
        {
          text: 'No - With Very long long long text here!!',
          total_submissions: 1,
          score: 25,
        },
      ],
    },
  ],
};
