import React from 'react';
import { SurveyState } from 'screens/OpenQuestionFeed/state';

export interface Context extends SurveyState {
  selectQuestion: (questionId: number) => Promise<void>;
  fetchNextPageOfAnswers: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SurveyContext = React.createContext<Context>(null!);
