export enum AspectValue {
  QUALITY_OF_LIFE = 'quality_of_life',
  CHARACTERISTIC = 'characteristic',
  GENERAL = 'general',
  DEMOGRAPHICS = 'demographics',
  TEMP = 'temp',
}

interface AspectIconPath {
  [aspectValue: string]: string;
}

export const aspectIconPath: AspectIconPath = {
  [AspectValue.QUALITY_OF_LIFE]: 'house-with-tree-and-birds.svg',
  [AspectValue.CHARACTERISTIC]: 'card-stars.svg',
  [AspectValue.TEMP]: 'topic-illustrations/other.svg',
};
