/* eslint-disable max-statements */
import { ZCDCheckbox, ZCDCheckboxVariant, ZCDIcon, ZCDIconColor } from '@zencity/common-ui';
import { DivisionWithPolygon } from '@zencity/survey-types';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import { AnalyticEventsChangeTypes } from 'constants/analytics';
import { DashboardContext } from 'contexts/DashboardContext';
import { FiltersContext } from 'contexts/FiltersContext';
import { MapsContext } from 'contexts/MapsContext';
import useDateRangeScoreLabel from 'hooks/score/dateRangeLabel';
import i18next from 'i18next';
import React, { ReactElement, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { DemographicFilters, MappedDemographicFiltersType } from 'types/demographicFilters';
import { FilteredQuestion } from 'types/questionsScores';
import { mapDemographicFilters, TreeSelectSelectionKeysType } from 'utils/blockwise/filters';

import styles from './AnalysisHeader.module.scss';

interface Props {
  submissions?: number;
  surveyGroupSubmissions: number;
  questions?: FilteredQuestion[];
}

const getResultTitle = (
  selectedDemographics: Partial<MappedDemographicFiltersType>,
  selectedDivision?: DivisionWithPolygon,
) => {
  let resultTitle = '';
  const isAtLeastOneDemographicSelected = Object.values(selectedDemographics).filter(Boolean).length;
  if (selectedDivision || isAtLeastOneDemographicSelected) {
    resultTitle = i18next.t('resultsScreen.header.showingResults');
  }

  if (selectedDivision) {
    resultTitle += ` <strong>${i18next.t('resultsScreen.header.showingResultsFrom')} ${
      selectedDivision.geo_targeting_ref.name
    }</strong>`;
  }

  let moreThanOneDemographic = false;
  Object.entries(selectedDemographics).forEach(([demographic, value]) => {
    if (value.length) {
      if (!moreThanOneDemographic) {
        resultTitle += ` <strong> ${i18next.t('resultsScreen.header.showingResultsBy')} `;
        moreThanOneDemographic = true;
      } else {
        resultTitle += `<strong>, `;
      }
      resultTitle += `${demographic === DemographicFilters.Age ? 'age' : demographic.toLowerCase()}</strong>`;
    }
  });
  return resultTitle;
};

const AnalysisHeader = (props: Props): ReactElement => {
  const { questions, submissions, surveyGroupSubmissions } = props;
  const { t: translate } = useTranslation();
  const { dateRangeLabel } = useDateRangeScoreLabel();
  const { divisionsDictionary } = useContext(MapsContext);
  const { selectedDivisionId, selectedDemographics } = useContext(FiltersContext);
  const mappedDemographics = mapDemographicFilters(selectedDemographics as TreeSelectSelectionKeysType);
  const selectedDivision = selectedDivisionId ? divisionsDictionary?.[selectedDivisionId] : undefined;
  const { isCrossModeEnabled, setIsCrossModeEnabled, setCrossSelectedQuestionId, expandedQuestionId } =
    useContext(DashboardContext);
  const title = getResultTitle(mappedDemographics, selectedDivision) || translate('resultsScreen.header.summaryTitle');
  let totalSubmissionsTitle = '';
  if (surveyGroupSubmissions === 0) {
    totalSubmissionsTitle = `${submissions} ${translate('resultsScreen.header.submissions')}`;
  } else {
    totalSubmissionsTitle =
      submissions === surveyGroupSubmissions
        ? `${surveyGroupSubmissions} ${translate('resultsScreen.header.submissions')}`
        : `${submissions} ${translate('resultsScreen.header.of')} ${surveyGroupSubmissions} ${translate(
            'resultsScreen.header.submissions',
          )}`;
  }

  const handleOnClick = (): void => {
    if (isCrossModeEnabled) {
      setCrossSelectedQuestionId(undefined);
    }
    setIsCrossModeEnabled(!isCrossModeEnabled);

    analyticsService.crossProductEvents.crossTabModeChanged({
      changeValue: !isCrossModeEnabled ? AnalyticEventsChangeTypes.OPEN : AnalyticEventsChangeTypes.CLOSE,
      questionId: expandedQuestionId ?? AnalyticEventsChangeTypes.NONE_EXPANDED,
      questionText:
        questions?.find((question) => question.genericQuestionId === expandedQuestionId)?.questionText ??
        AnalyticEventsChangeTypes.NONE_EXPANDED,
    });
  };

  return (
    <Trans t={translate}>
      <Flex justifyContent="space-between" alignItems="center">
        <div>
          <div className={styles.header}>
            <Flex id="uptime--analysis-tab-title" className={styles.title}>
              {translate('resultsScreen.header.title')}
              <Spacer orientation="horizontal" size="small" />
              <span className={styles.label}>{dateRangeLabel}</span>
            </Flex>
            <div className={styles.summaryTitle}>
              {title}
              <span className={styles.separator}>⸱</span>
              <span className={styles.resultsSummarySubmissions}>{totalSubmissionsTitle}</span>
            </div>
          </div>
        </div>
        <Flex alignItems="center">
          <ZCDIcon name="rating" color={ZCDIconColor.BLUE} />
          <Spacer orientation="horizontal" size="xsmall" />
          <span className={styles.crossQuestionTitle}>{translate('resultsScreen.crossMenu.buttonTitle')}</span>
          <Spacer orientation="horizontal" size="xsmall" />
          <ZCDCheckbox
            variant={ZCDCheckboxVariant.TOGGLE}
            checked={isCrossModeEnabled}
            onChange={handleOnClick}
            // The component types require a label, but we don't want to show one.
            label=""
            hideLabel
          />
        </Flex>
      </Flex>
    </Trans>
  );
};

export default AnalysisHeader;
