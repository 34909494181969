import React from 'react';
import { QueryClientProviderHOC } from 'screens/blockwise/QueryClientProviderHOC';
import { Client } from 'types/client';
import { User } from 'types/user';
import CommunitySurveyDashboard from './CommunitySurveyDashboard';

interface Props {
  user: User;
  client: Client;
}

const CommunitySurveyDashboardHOC = (props: Props) => {
  const { client, user } = props;

  return (
    <QueryClientProviderHOC>
      <CommunitySurveyDashboard client={client} user={user} />
    </QueryClientProviderHOC>
  );
};

export default CommunitySurveyDashboardHOC;
