import { AnalyticEventsChangeTypes, AnalyticFilterNames, AnalyticFilterPropertyValues } from 'constants/analytics';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { FiltersContext } from 'contexts/FiltersContext';
import { useContext } from 'react';

// Hook to return list of active filter names or 'none active' (used from analytics).
export const useFilterNames = (): AnalyticFilterNames[] | [AnalyticEventsChangeTypes.NONE_ACTIVATED] => {
  const { selectedDemographics, selectedDivisionIds } = useContext(FiltersContext);
  const { selectedDateRange, dashboardDateRanges } = useContext(DateRangeContext);
  const latestStartDate = dashboardDateRanges
    ?.filter((dateRange) => !!dateRange)
    .sort((firstDateRange, secondDateRange) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firstDateRange!.startDate > secondDateRange!.startDate ? -1 : 1,
    )?.[0]?.startDate;
  const isLatestResults =
    selectedDateRange?.key === AnalyticFilterPropertyValues.LATEST_RESULTS ||
    selectedDateRange?.startDate === latestStartDate;
  const activeFilters: AnalyticFilterNames[] = [];
  if (
    selectedDemographics &&
    Object.keys(selectedDemographics)?.length > 0 &&
    !activeFilters.includes(AnalyticFilterNames.DEMOGRAPHIC)
  ) {
    activeFilters.push(AnalyticFilterNames.DEMOGRAPHIC);
  }
  if (selectedDivisionIds.length > 0 && !activeFilters.includes(AnalyticFilterNames.GEOGRAPHIC)) {
    activeFilters.push(AnalyticFilterNames.GEOGRAPHIC);
  }
  if (isLatestResults && !activeFilters.includes(AnalyticFilterNames.DATA)) {
    activeFilters.push(AnalyticFilterNames.DATA);
  }
  if (!isLatestResults && !activeFilters.includes(AnalyticFilterNames.PAST_DATA)) {
    activeFilters.push(AnalyticFilterNames.PAST_DATA);
  }

  return activeFilters.length > 0 ? activeFilters : [AnalyticEventsChangeTypes.NONE_ACTIVATED];
};
