import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ServerDateRangeParams } from 'types/dateRange';

export const getDateWithFormat = (date: string, formatType: string): string => {
  try {
    const formattedDate = new Date(date);
    return format(formattedDate, formatType);
  } catch (error) {
    return 'Invalid Date';
  }
};

export const clientTimezoneStartOfDay = (date: Date, timezone: string): Date =>
  zonedTimeToUtc(startOfDay(utcToZonedTime(date, timezone)), timezone);

export const clientTimezoneEndOfDay = (date: Date, timezone: string): Date =>
  zonedTimeToUtc(endOfDay(utcToZonedTime(date, timezone)), timezone);

export enum TimeFormat {
  MMM_D = 'MMM d',
  MMM_D_YYYY = 'MMM d, yyyy',
}

export const convertTimePeriodToDateRangeServerParams = (timePeriodInDays: number): ServerDateRangeParams => {
  // End date is always "today".
  const endDate = endOfDay(new Date());
  const startDate = startOfDay(subDays(endDate, timePeriodInDays));
  const serverFormat = 'yyyy-MM-dd';
  return {
    start_date: format(startDate, serverFormat),
    end_date: format(endDate, serverFormat),
  };
};
