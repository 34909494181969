import { dateFormats } from 'constants/time';
import React, { ReactElement } from 'react';
import { getDateWithFormat } from 'utils/time';
import styles from '../Answer.module.scss';
import { Props } from './Description';
import { CXSurveyDataContextLink } from './CXSurveyDataContextLink';

export const CXSurveyData = ({ submission }: Props): ReactElement =>
  submission.cx_data ? (
    <div className={styles.cxSurveyDataWrapper}>
      {submission.cx_data.case_context && (
        <div>
          Context: <CXSurveyDataContextLink submissionCxData={submission.cx_data} />
        </div>
      )}
      {submission.cx_data.case_submit_date && (
        <div>
          Request submitted: {getDateWithFormat(submission.cx_data?.case_submit_date, dateFormats.VERBAL_MONTH)}
        </div>
      )}
      {submission.cx_data.case_closed_date && (
        <div>Request closed: {getDateWithFormat(submission.cx_data?.case_closed_date, dateFormats.VERBAL_MONTH)}</div>
      )}
    </div>
  ) : (
    <></>
  );
