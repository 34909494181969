import { FiltersContext } from 'contexts/FiltersContext';
import { QuestionsFeedContext } from 'contexts/QuestionsFeedContext';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { useContext } from 'react';
import { fetchSummaryAspectWidget } from 'services/summaryWidgets';
import { SummaryAspectWidget } from 'types/summaryAspectWidget';
import { ReactQueryKeys } from 'utils/reactQueryKeys';

interface Props {
  startDate?: string;
  endDate?: string;
}

export const useAspectWidgetData = ({
  startDate,
  endDate,
}: Props): {
  widgetData: SummaryAspectWidget | undefined;
  loading: boolean;
} => {
  const { selectedDivisionId } = useContext(FiltersContext);
  const { surveyGroupId } = useContext(QuestionsFeedContext);
  // In case we missing any of the required params, we don't want to make the request.
  const enableRequest = !!(surveyGroupId && surveyGroupId !== 0 && startDate && endDate);
  const { isFetching, response } = useCustomQuery({
    params: {
      endDate: endDate as string,
      startDate: startDate as string,
      surveyGroupId: surveyGroupId as number,
      divisionId: selectedDivisionId,
    },
    requestFunction: fetchSummaryAspectWidget,
    enableRequest,
    queryKey: ReactQueryKeys.ASPECT_WIDGET,
  });

  return { widgetData: response, loading: isFetching };
};
