import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styles from './ExpandButton.module.scss';

interface Props {
  onClick?: () => void;
  expanded: boolean;
  className?: string;
}

export const ExpandButton: React.FC<Props> = function ExpandButton(props: Props): ReactElement {
  const { onClick, expanded, className } = props;
  const { t: translate } = useTranslation();

  return (
    <button data-testid="expand-button" type="button" onClick={onClick} className={styles.expandButton}>
      <span className={styles.arrowContainer}>
        <span className={classnames(styles.arrow, { [styles.down]: !expanded, [styles.up]: expanded }, className)} />
      </span>
      {expanded ? translate('expandButton.showLess') : translate('expandButton.showMore')}
    </button>
  );
};
