import React from 'react';
import { QueryClientProviderHOC } from 'screens/blockwise/QueryClientProviderHOC';
import { Client } from 'types/client';
import { User } from 'types/user';
import PulseDashboard from './PulseDashboard';

interface Props {
  user: User;
  client: Client;
}

const PulseDashboardHOC = (props: Props) => {
  const { client, user } = props;

  return (
    <QueryClientProviderHOC>
      <PulseDashboard client={client} user={user} />
    </QueryClientProviderHOC>
  );
};

export default PulseDashboardHOC;
