import { User } from 'types/user';
import { Client } from 'types/client';
import { FeatureFlag } from 'types/featureFlag';

export const userIsSuperAdmin = (user: User): boolean => user?.roles?.includes('superadmin') ?? false;

/**
 * Indicate whether the given user has a specific Core Platform Feature Flag.
 */
export const userHasCorePlatformFeature = (client: Client, user: User, featureFlag: FeatureFlag): boolean => {
  const userFeatureFlag = user.effectiveInsightsFeatures?.[featureFlag];

  /*
  If the feature flag has has an explicit value (meaning, not the flag's default value) in the user level - return it.
  Otherwise, return the value of the feature flag in the client level
  */
  if (userFeatureFlag !== undefined) {
    return userFeatureFlag;
  }

  return client.effectiveInsightsFeatures?.[featureFlag] ?? false;
};
