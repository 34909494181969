import React from 'react';
import { Question } from 'types/questionsScores';

export interface AnalysisContextPayload {
  surveyGroupSubmissions: number;
  comparisonQuestionsScores?: {
    questionScores: Question[];
    totalSubmissions: number;
  };
  hideOvertimeTrends?: boolean;
}

export const AnalysisContext = React.createContext<AnalysisContextPayload>({} as AnalysisContextPayload);
