import { QuestionSummaryData } from 'types/question';

export const questionsSummary: QuestionSummaryData[] = [
  {
    id: 1,
    index: 1,
    type: 'choices_options',
    text: 'What is your favorite ice cream?',
    overridden_text: 'What is your favorite ice cream?',
    total_answers: 4,
  },
  {
    id: 2,
    index: 2,
    type: 'scale',
    text: 'How much do you like living in %client_name%?',
    overridden_text: 'How much do you like living in %client_name%?',
    total_answers: 15,
  },
  {
    id: 3,
    index: 3,
    type: 'open_ended',
    text: 'Why do you like shopping?',
    overridden_text: 'Why do you like shopping?',
    total_answers: 3,
  },
  {
    id: 4,
    index: 4,
    type: 'yes_no',
    text: 'Do you own a car?',
    overridden_text: 'Do you own a car?',
    total_answers: 5,
  },
];
