import { ZCDCard } from '@zencity/common-ui';
import React from 'react';
import styles from './AnalysisWidgetWrapper.module.scss';

interface Props {
  children: React.ReactNode;
}
export const AnalysisWidgetWrapper = (props: Props): JSX.Element => {
  const { children } = props;

  return (
    <div className={styles.widgetWrapper}>
      <ZCDCard>
        <div className={styles.inner}>{children}</div>
      </ZCDCard>
    </div>
  );
};
