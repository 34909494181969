/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { logger } from 'utils/community-asks/logger';
import { userIsSuperAdmin } from 'utils/user';
import { SurveyRequest, SurveyType } from '@zencity/survey-types';
import { SurveyRequestFilterParams } from 'types/surveyRequest';
import { AccountsContext } from 'contexts/AccountsContext';
import { fetchSurveyRequests } from 'services/surveyRequest';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { ManagementTable } from './components/ManagementTable/ManagementTable';
import styles from './AsksManagementPage.module.scss';

/**
 * Internal screen to view and manage all Community Asks requests.
 */
export const AsksManagementPage = (): ReactElement => {
  const { t: translate } = useTranslation();
  const { user } = useContext(AccountsContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allSurveyRequests, setAllSurveyRequests] = useState<SurveyRequest[]>([]);

  const isSuperAdmin = userIsSuperAdmin(user);

  useEffect(() => {
    const fetchClientSurveyRequests = async () => {
      try {
        const params = {
          survey_type: SurveyType.COMMUNITY_ASKS,

          // We want CSMs and other internal Zencity users to see ALL survey requests in this screen.
          // The default behavior is to filter based on the active client in Core Platform. So, here
          // we tell Vault to override that filter.
          bypass_client_filter: true,
        } as SurveyRequestFilterParams;
        const clientSurveyRequests = await fetchSurveyRequests(params);

        setAllSurveyRequests(clientSurveyRequests);
      } catch (error) {
        logger.error(error);
      }
      setIsLoading(false);
    };
    fetchClientSurveyRequests();
  }, []);

  return (
    <>
      {isSuperAdmin ? (
        <div className={styles.asksManagementPage}>
          <h1>{translate('communityAsksManagement.title')}</h1>
          <LoaderMask isLoading={isLoading}>
            <ManagementTable allSurveyRequests={allSurveyRequests} />
          </LoaderMask>
        </div>
      ) : (
        <div>{translate('communityAsksManagement.permissionDenied')}</div>
      )}
    </>
  );
};
