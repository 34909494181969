import React from 'react';
import { Flex } from 'components/Flex/Flex';
import { useTranslation } from 'react-i18next';
import { ZCDButton } from '@zencity/common-ui';
import { analyticsService } from 'services/analytics/analytics';
import { AnalyticWidgetNames } from 'constants/analytics';
import { useFilterNames } from 'hooks/useFilterNames';
import styles from './MethodologyCTA.module.scss';

export const MethodologyCTA = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const activeFilters = useFilterNames();

  const openZencityMethodologyArticle = () => {
    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('blockwiseSummaryTab.overallWidgets.methodologyWidget.learnMoreCta'),
      widgetName: AnalyticWidgetNames.METHODOLOGY_WIDGET,
      activeFilters,
    });

    window.open('https://help.zencity.io/hc/en-us/articles/5047536050961-Survey-methodology-FAQs', '_blank');
  };

  return (
    <Flex className={styles.methodology} flexDirection="column" justifyContent="space-between">
      <div className={styles.title}>{translate('blockwiseSummaryTab.overallWidgets.methodologyWidget.title')}</div>
      <div className={styles.description}>
        {translate('blockwiseSummaryTab.overallWidgets.methodologyWidget.description')}
      </div>
      <Flex justifyContent="flex-end" width="100%">
        <ZCDButton
          variant="link"
          size="sm"
          text={translate('blockwiseSummaryTab.overallWidgets.methodologyWidget.learnMoreCta')}
          postIcon="arrow-right"
          onClick={openZencityMethodologyArticle}
        />
      </Flex>
    </Flex>
  );
};
