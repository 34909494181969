import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Status } from 'constants/state';
import { ScoreCardWidget } from 'components/ScoreCardWidget/ScoreCardWidget';
import { fetchOverallScore } from 'services/score';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { ScorePerDateRange } from 'types/score';
import { DashboardContext } from 'contexts/DashboardContext';

const MAX_SCORES_TO_DISPLAY = 6;

export const ScoreCard: React.FC = (): ReactElement => {
  const [fetchStatus, setFetchStatus] = useState(Status.LOADING);
  const { completedCyclesDateRanges } = useContext(DateRangeContext);
  const { currentSurveyGroup } = useContext(DashboardContext);
  const [overallScores, setOverallScores] = useState<ScorePerDateRange[]>([]);
  // Slicing the data to only show the last 6 cycles.
  const slicedScores = overallScores.slice(0, MAX_SCORES_TO_DISPLAY);

  const fetchAndSetOverallScore = async (): Promise<void> => {
    try {
      if (completedCyclesDateRanges?.length) {
        const fetchedOverallScores = await fetchOverallScore(currentSurveyGroup.client.id);
        setOverallScores(fetchedOverallScores);
        setFetchStatus(Status.OKAY);
      }
    } catch (error) {
      console.error(error);
      setFetchStatus(Status.ERROR);
    }
  };

  useEffect(() => {
    fetchAndSetOverallScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCyclesDateRanges]);

  return <ScoreCardWidget fetchStatus={fetchStatus} overallScores={slicedScores} />;
};
