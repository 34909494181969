import { SatisfactionDistribution } from 'components/SatisfactionDistribution/SatisfactionDistribution';
import { MIN_UNREPRESENTATIVE_INDICATOR } from 'components/UnrepresentativeIndicator/constants';
import { UnrepresentativeIndicator } from 'components/UnrepresentativeIndicator/UnrepresentativeIndicator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SatisfactionScore } from 'types/score';
import styles from '../BreakdownRow.module.scss';

interface Props {
  satisfactionScore?: SatisfactionScore | null;
  showUnrepresentativeIndicator?: boolean;
  totalSubmissions?: number;
  comparisonScore?: SatisfactionScore | null;
  totalSubmissionsToCompare?: number;
  hideOvertimeTrends?: boolean;
}

export const BreakdownRowDistribution = (props: Props): React.ReactElement => {
  const {
    satisfactionScore,
    showUnrepresentativeIndicator,
    totalSubmissionsToCompare,
    totalSubmissions,
    comparisonScore,
    hideOvertimeTrends,
  } = props;
  const { t: translate } = useTranslation();
  const displayUnrepresentativeIndicator =
    showUnrepresentativeIndicator && totalSubmissions && totalSubmissions < MIN_UNREPRESENTATIVE_INDICATOR;
  return (
    <div className={styles.flexCenter}>
      {!satisfactionScore || displayUnrepresentativeIndicator ? (
        <div className={styles.flexCenter}>
          <span className={styles.noScoreTitle}>{translate('breakdownWidget.noScore')}</span>
          <UnrepresentativeIndicator totalSubmissions={totalSubmissions} />
        </div>
      ) : (
        <SatisfactionDistribution
          negative={satisfactionScore.negative}
          neutral={satisfactionScore.neutral}
          positive={satisfactionScore.positive}
          comparisonScore={comparisonScore}
          totalSubmissionsToCompare={totalSubmissionsToCompare}
          hideOvertimeTrendsIndication={hideOvertimeTrends}
        />
      )}
    </div>
  );
};
