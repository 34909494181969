/* eslint-disable react/no-multi-comp */
import { ZCDChangeIndicatorHOC } from 'components/BlockwiseSummaryTab/ZCDChangeIndicatorHOC/ZCDChangeIndicatorHOC';
import { Flex } from 'components/Flex/Flex';
import i18next from 'i18next';
import React from 'react';
import { FormattedQuestionChoice } from 'utils/analysis';
import styles from '../AnalysisTable.module.scss';
import { CellWrapper } from './ColumnsComponents/CellWrapper/CellWrapper';
import { ChoiceHeader, ChoiceHeaderProps } from './ColumnsComponents/ChoiceHeader/ChoiceHeader';
import { Score } from './ColumnsComponents/Score/Score';

interface GetResultBreakdownColumnsPayload {
  dateRangeLabel: string;
  maxScoreCurrentCycle: number;
  comparisonDateRangeLabel?: string;
  maxScoreComparedCycle?: number;
  isNoScoreQuestion?: boolean;
}
export const getResultBreakdownColumns = ({
  dateRangeLabel,
  maxScoreCurrentCycle,
  comparisonDateRangeLabel,
  maxScoreComparedCycle,
  isNoScoreQuestion,
}: GetResultBreakdownColumnsPayload) =>
  comparisonDateRangeLabel
    ? [
        {
          Header: () => (
            <CellWrapper>
              <span className={styles.baseHeader}>{i18next.t('resultsScreen.breakdownTable.choices')}</span>
            </CellWrapper>
          ),
          accessor: 'choiceHeader' as keyof FormattedQuestionChoice,
          Cell: ({ value }: ChoiceHeaderProps) => (
            <CellWrapper>
              <ChoiceHeader value={value} isNoScoreQuestion={isNoScoreQuestion} />
            </CellWrapper>
          ),
        },
        {
          Header: () => (
            <CellWrapper>
              <span className={styles.cycleHeader}>{i18next.t('resultsScreen.breakdownTable.selectedCycle')}</span>
              <span className={styles.baseHeader}>{dateRangeLabel}</span>
            </CellWrapper>
          ),
          accessor: 'score' as keyof FormattedQuestionChoice,
          Cell: ({ value }: { value: number }) => (
            <CellWrapper>{Score({ value, maxScore: maxScoreCurrentCycle })}</CellWrapper>
          ),
        },
        {
          Header: () => (
            <CellWrapper>
              <span className={styles.cycleHeader}>{i18next.t('resultsScreen.breakdownTable.comparedTo')}</span>
              <span className={styles.baseHeader}>{comparisonDateRangeLabel}</span>
            </CellWrapper>
          ),
          accessor: 'compareChoice.score' as keyof FormattedQuestionChoice,
          Cell: ({ value }: { value: number }) => (
            <CellWrapper>{Score({ value, maxScore: maxScoreComparedCycle })}</CellWrapper>
          ),
        },
        {
          Header: (
            <CellWrapper>
              <span className={styles.baseHeader}>{i18next.t('resultsScreen.breakdownTable.change')}</span>
            </CellWrapper>
          ),
          accessor: 'change' as keyof FormattedQuestionChoice,
          Cell: ({ value }: { value: number }) => (
            <CellWrapper>
              <Flex alignItems="center" justifyContent="flex-end" width="100%" className={styles.changeValue}>
                <ZCDChangeIndicatorHOC grayscale value={value} />
              </Flex>
            </CellWrapper>
          ),
        },
      ]
    : [
        {
          Header: (
            <CellWrapper>
              <span className={styles.baseHeader}>{i18next.t('resultsScreen.breakdownTable.choices')}</span>
            </CellWrapper>
          ),
          accessor: 'choiceHeader' as keyof FormattedQuestionChoice,
          Cell: ({ value }: ChoiceHeaderProps) => (
            <CellWrapper>
              <ChoiceHeader value={value} isNoScoreQuestion={isNoScoreQuestion} />
            </CellWrapper>
          ),
        },
        {
          Header: () => (
            <CellWrapper>
              <span className={styles.cycleHeader}>{i18next.t('resultsScreen.breakdownTable.selectedCycle')}</span>
              <span className={styles.baseHeader}>{dateRangeLabel}</span>
            </CellWrapper>
          ),
          accessor: 'score' as keyof FormattedQuestionChoice,
          Cell: ({ value }: { value: number }) => (
            <CellWrapper>{Score({ value, maxScore: maxScoreCurrentCycle })}</CellWrapper>
          ),
        },
      ];
