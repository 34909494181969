/* eslint-disable complexity */
import { useEffect, useContext, useState } from 'react';
import { format } from 'date-fns';
import { DateRangeContext, DateRangeFilterOption } from 'contexts/DateRangeContext';
import { getStartDate, isFeedLatestResult } from 'utils/dateRange';

interface FeedDateRangeFilter {
  start_date: string;
  end_date?: string;
}

const useFeedDateRangeFilter = (): { dateRangeFilter?: FeedDateRangeFilter } => {
  const dateRangeContextValue = useContext(DateRangeContext);
  const { selectedDateRange, firstSubmissionDate, completedCyclesDateRanges, isContextLoaded } = dateRangeContextValue;
  const [dateRangeFilter, setDateRangeFilter] = useState<FeedDateRangeFilter>();

  useEffect(() => {
    const resolveLatestResultFilter = () => {
      if (completedCyclesDateRanges?.[0]) {
        const latestCompletedCycle = completedCyclesDateRanges[0];
        const startDate = format(getStartDate(latestCompletedCycle), 'yyyy-MM-dd');
        setDateRangeFilter({ start_date: startDate });
      } else if (firstSubmissionDate) {
        setDateRangeFilter({ start_date: firstSubmissionDate });
      }
    };

    if (!isContextLoaded) {
      return;
    }

    const isLatestResult = isFeedLatestResult(selectedDateRange);

    if (isLatestResult) {
      resolveLatestResultFilter();
    } else {
      setDateRangeFilter({
        start_date: <string>(<DateRangeFilterOption>selectedDateRange).startDate,
        end_date: <string>(<DateRangeFilterOption>selectedDateRange).endDate,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCyclesDateRanges, selectedDateRange, firstSubmissionDate, isContextLoaded]);
  return { dateRangeFilter };
};

export default useFeedDateRangeFilter;
