import { ProgressBar } from '@zencity/common-ui';
import classNames from 'classnames';
import { Flex } from 'components/Flex/Flex';
import React from 'react';
import styles from './ProgressBarWrapper.module.scss';

export enum ScoreType {
  PERCENTAGE,
  NUMBER,
}
interface Props {
  color?: string;
  score: number;
  ratio?: number;
  scoreType: ScoreType;
  postfix?: string;
}
export const ProgressBarWrapper = (props: Props): JSX.Element => {
  const { scoreType, score, color, ratio, postfix = '' } = props;
  return (
    <>
      <Flex alignItems="flex-end" justifyContent="flex-start" className={styles.barScore}>
        <span className={styles.value}>{scoreType === ScoreType.NUMBER ? ratio : `${score.toFixed(0)}%`}</span>
        {ratio && (
          <span className={styles.ratio}>
            {score}% {postfix}
          </span>
        )}
      </Flex>
      <div
        className={classNames({
          [styles.progressBarWrapper]: !!ratio,
        })}
      >
        <ProgressBar fill={score} barColor={color} />
      </div>
    </>
  );
};
