import { Status, StatusBox } from '@zencity/common-ui';
import { SurveyGroup, SurveyRequest, SurveyType } from '@zencity/survey-types';
import { CommunityAsksToastContext } from 'contexts/CommunityAsksToastContext';
import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import { SurveyRequestContext } from 'contexts/SurveyRequestContext';
import { useOpenEndedQuestions } from 'hooks/openEndedQuestions';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { SurveyPageSubTabs } from 'screens/community-asks/routerPaths';
import { fetchSurveyRequests } from 'services/surveyRequest';
import { SurveyRequestFilterParams } from 'types/surveyRequest';
import { logger } from 'utils/community-asks/logger';
import { QuestionsFeedContext } from '../../../contexts/QuestionsFeedContext';
import { Header } from './components/Header/Header';

/**
 * Implementation of a single Survey's layout page.
 */
export const SurveyLayout = (): React.ReactElement => {
  const { surveyGroupId } = useParams<{ surveyGroupId: string }>();
  const { toastError } = useContext(CommunityAsksToastContext);
  const [loadingStatus, setLoadingStatus] = useState<Status>(Status.LOADING);
  const [surveyRequest, setSurveyRequest] = useState<SurveyRequest>();
  const [currentSurveyGroup, setCurrentSurveyGroup] = useState<SurveyGroup>();
  const { openEndedQuestions, areOpenEndedQuestionsLoading } = useOpenEndedQuestions(currentSurveyGroup);

  useEffect(() => {
    const params = {
      survey_type: SurveyType.COMMUNITY_ASKS,
      survey_group: surveyGroupId,
    } as SurveyRequestFilterParams;
    fetchSurveyRequests(params)
      .then((result) => {
        if (!result || !result.length) {
          // TODO: For now, toast an error, but should probably be handled more nicely.
          //  This scenario will happen when the passed ID is invalid for the user (or doesn't exist).
          toastError();
          setLoadingStatus(Status.ERROR);
          return;
        }

        setSurveyRequest(result[0]);
        if (result[0].survey_group) {
          setCurrentSurveyGroup(result[0].survey_group);
        }

        setLoadingStatus(Status.OKAY);
      })
      .catch((error) => {
        logger.error(error);
        toastError();
      });
  }, [surveyGroupId]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!surveyGroupId) return <></>;
  return (
    <div>
      <StatusBox status={loadingStatus}>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <SurveyRequestContext.Provider value={surveyRequest!}>
          <OpenEndedQuestionFeedContext.Provider value={{ openEndedQuestions, areOpenEndedQuestionsLoading }}>
            <QuestionsFeedContext.Provider
              value={{ surveyType: SurveyType.COMMUNITY_ASKS, surveyGroupId: parseInt(surveyGroupId) }}
            >
              <Header tabs={SurveyPageSubTabs} surveyGroupId={parseInt(surveyGroupId)} />
              {/* Outlet is used to render the tabs as children components in React Router v6 */}
              <Outlet context={{ surveyGroup: currentSurveyGroup }} />
            </QuestionsFeedContext.Provider>
          </OpenEndedQuestionFeedContext.Provider>
        </SurveyRequestContext.Provider>
      </StatusBox>
    </div>
  );
};
