import { Question, SurveyGroup } from '@zencity/survey-types';
import React from 'react';

export interface DashboardContextInterface {
  currentSurveyGroup: SurveyGroup;
  isCrossModeEnabled: boolean;
  setIsCrossModeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  // FixMe: property should be renamed to `expandedGenericQuestionId`,
  expandedQuestionId?: number;
  setExpandedQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
  crossSelectedQuestionId?: number;
  setCrossSelectedQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
  crossTabQuestions: Question[];
  setCrossTabQuestions: React.Dispatch<React.SetStateAction<Question[]>>;
}
// This context is used to share state between the dashboard, community survey, and blockwise.
export const DashboardContext = React.createContext<DashboardContextInterface>({} as DashboardContextInterface);
