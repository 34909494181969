import { DashboardDateRangesResponse } from 'types/rakeWeighterExecution';

export const mockDateRanges: DashboardDateRangesResponse = {
  completed_cycles: [
    {
      start_date: '2022-04-01',
      end_date: '2022-06-30',
      total_submissions: 250,
    },
    {
      start_date: '2022-01-01',
      end_date: '2022-03-31',
      total_submissions: 20,
    },
    {
      start_date: '2021-10-01',
      end_date: '2021-12-31',
      total_submissions: 54,
    },
    {
      start_date: '2021-07-01',
      end_date: '2021-09-30',
      total_submissions: 1120,
    },
    {
      start_date: '2021-04-01',
      end_date: '2021-06-30',
      total_submissions: 11,
    },
  ],
  first_submission_date: '2022-04-01',
};
