import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import React, { useContext } from 'react';
import styles from './DivisionList.module.scss';
import { DivisionListItem } from './DivisionListItem/DivisionListItem';

export const DivisionList = (): JSX.Element | null => {
  const { comparisonDateWidgetData, selectedDateWidgetData, activeTab } = useContext(SummaryAspectWidgetContext);
  const selectedAspectScores = selectedDateWidgetData?.aspect_scores?.find((aspect) => aspect.aspect_id === activeTab);
  const comparisonAspectScores = comparisonDateWidgetData?.aspect_scores?.find(
    (aspect) => aspect.aspect_id === activeTab,
  );
  // Sort divisions by name and filter out division with depth !== 1.
  const selectedDivision = selectedAspectScores?.divisions
    .sort((elementA, elementB) =>
      elementA.division_polygon_name.localeCompare(elementB.division_polygon_name, undefined, { numeric: true }),
    )
    .filter((element) => element.depth === 1);
  const overallDivision = selectedAspectScores?.divisions?.find?.((element) => element.depth === 0);
  const overallComparisonDivision = comparisonAspectScores?.divisions?.find?.((element) => element.depth === 0);

  return (
    <div className={styles.divisionList}>
      {overallDivision && (
        <DivisionListItem division={overallDivision} comparisonDivision={overallComparisonDivision} />
      )}
      {selectedDivision?.map((division) => {
        const comparisonDivision = comparisonAspectScores?.divisions?.find(
          (item) => item.division_id === division.division_id,
        );
        return (
          <DivisionListItem key={division.division_id} division={division} comparisonDivision={comparisonDivision} />
        );
      })}
    </div>
  );
};
