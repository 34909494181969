import { MIN_UNREPRESENTATIVE_INDICATOR } from 'components/UnrepresentativeIndicator/constants';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import BreakdownWidget from '../components/BreakdownWidget';
import { BreakdownRowContent } from '../components/BreakdownWidget/BreakdownWidget';
import '../i18n';
import { DemographicScore } from '../types/score';

interface Props {
  title: string;
  demographicScores: DemographicScore[];
  isLoading: boolean;
  onToggleAll?: (value: boolean) => void;
}

export const DemographicBreakdown: FunctionComponent<Props> = (props: Props) => {
  const { i18n } = useTranslation();

  // TODO - refactor i18next global object so it will be inherited correctly
  //        and also make this custom options smoother for further use
  const i18nCustom = i18n.cloneInstance({
    parseMissingKeyHandler: (key) => {
      const path = key.split('.');
      let value = '';
      if (path.length) {
        value = path[path.length - 1];
      }
      const parsedValue = value.replace(/_/g, ' ');
      return parsedValue;
    },
  });

  const { isLoading, demographicScores, title, onToggleAll } = props;
  const displayData = demographicScores.length
    ? demographicScores
        // Filter out scores that are too low to be representative
        .filter(
          (demographicScore) =>
            demographicScore.scores.length > 0 &&
            demographicScore.scores[0].scores &&
            demographicScore.scores[0].totalSubmissions >= MIN_UNREPRESENTATIVE_INDICATOR,
        )
        .map<BreakdownRowContent>((demographicScore: DemographicScore) => {
          const { demographicType, demographicLabel, comparisonScore } = demographicScore;
          // Since only one score is expected to be returned, we can use the first element of the array.
          const { scores, totalSubmissions } = demographicScore.scores[0];
          const breakdownRowContent: BreakdownRowContent = {
            scores: scores ?? null,
            totalSubmissions,
            text: `${i18nCustom.t(`demographicsBreakdown.categories.${demographicType}`)}: ${demographicLabel}`,
            comparisonScore,
          };
          return breakdownRowContent;
        })
    : [];
  return (
    <BreakdownWidget
      rowItems={displayData}
      headerText={title}
      isLoading={isLoading}
      onToggleAll={onToggleAll}
      showFooter
      showUnrepresentativeIndicator
    />
  );
};
