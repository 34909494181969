import classnames from 'classnames';
import { PercentageDisplay, PercentageSize } from 'components/SatisfactionDistribution/components/PercentageDisplay';
import { VisualDisplay } from 'components/SatisfactionDistribution/components/VisualDisplay';
import styles from 'components/SatisfactionDistribution/SatisfactionDistribution.module.scss';
import React, { ReactElement } from 'react';
import { ScoreDisplay } from 'src/types/score';

export interface Props extends ScoreDisplay {
  score: number;
}

export const CXSatisfactionDistribution: React.FC<Props> = (props: Props): ReactElement => {
  const {
    score,
    positive,
    negative,
    neutral,
    className,
    noBar = false,
    percentageSize = PercentageSize.REGULAR,
  } = props;

  return (
    <div className={classnames(styles.satisfactionDistribution, className)}>
      <PercentageDisplay positivePercentage={score} size={percentageSize as PercentageSize} />
      {!noBar && <VisualDisplay positive={positive} negative={negative} neutral={neutral} />}
    </div>
  );
};
