import { dateFormats } from 'constants/time';
import { QuestionsFeedContext } from 'contexts/QuestionsFeedContext';
import React, { ReactElement, useContext } from 'react';
import { Submission, CxContext, SurveyType } from '@zencity/survey-types';
import { getDateWithFormat } from 'utils/time';
import styles from '../Answer.module.scss';
import { generateDemographicsDisplayLabel } from './Description.helper';

export interface Props {
  submission: Submission;
}

export const generateCXCaseContextUrl = (caseId: string, context?: CxContext): string => {
  if (context === CxContext.THREE11) {
    return `/admin/mario/requests/${caseId}`;
  }

  return '';
};

const Description = ({ submission }: Props): ReactElement => {
  const { surveyType } = useContext(QuestionsFeedContext);
  const demographicsCollected = [SurveyType.COMMUNITY_SURVEY, SurveyType.BLOCKWISE].includes(surveyType);
  const demographicsLabel = generateDemographicsDisplayLabel(submission.demographic_info);
  return (
    <div className={styles.description}>
      <span className={styles.date}>{getDateWithFormat(submission.submitted_at, dateFormats.VERBAL_MONTH)}</span>
      {demographicsCollected && <span className={styles.demographics}>{demographicsLabel}</span>}
    </div>
  );
};
export default Description;
