import React from 'react';
import { SelectOption } from 'types/selectOption';
import { SummaryAspectWidget } from 'types/summaryAspectWidget';

export interface SummaryAspectWidgetContextPayload {
  handleTabChange: (aspectId: number) => void;
  selectedDateWidgetData?: SummaryAspectWidget;
  comparisonDateWidgetData?: SummaryAspectWidget;
  activeTab?: number;
  demographicTypes?: SelectOption[];
}

export const SummaryAspectWidgetContext = React.createContext<SummaryAspectWidgetContextPayload>(
  {} as SummaryAspectWidgetContextPayload,
);
