import React from 'react';
import { DivisionsDictionary } from 'types/division';
import { DivisionWithPolygon } from '@zencity/survey-types';
import { AreasWithScoresObject, DivisionAreaScoresByQuestion } from '../types/areas';

export interface MapsContextInterface {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAreaIds: string[];
  setSelectedAreaIds: React.Dispatch<React.SetStateAction<string[]>>;
  hoveredAreaId: string;
  setHoveredAreaId: React.Dispatch<React.SetStateAction<string>>;
  sortedAreaIdsByAreaName: string[];
  areasWithScoresData: AreasWithScoresObject;
  divisionAreaScoresByQuestion: DivisionAreaScoresByQuestion;
  selectedGenericQuestionId: string;
  setSelectedGenericQuestionId: React.Dispatch<React.SetStateAction<string>>;
  divisions: DivisionWithPolygon[];
  baseDivisionId?: number;
  setBaseDivisionId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setAreasWithScoresData: React.Dispatch<React.SetStateAction<AreasWithScoresObject>>;
  divisionsDictionary?: DivisionsDictionary;
}

export const MapsContext = React.createContext<MapsContextInterface>({} as MapsContextInterface);
