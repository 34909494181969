import { useEffect, useContext, useState } from 'react';
import { FiltersContext } from 'contexts/FiltersContext';
import { CLASSIFICATION_SUB_TOPIC_FILTER_KEY } from 'components/ClassificationSubTopicsFilter/ClassificationSubTopicsFilter.helpers';

interface ClassificationSubTopicsFilter {
  classification_sub_topic: string;
}

const DEFAULT_CLASSIFICATION_SUB_TOPICS_FILTER: ClassificationSubTopicsFilter = {
  classification_sub_topic: '',
};

const useClassificationSubTopicsFilter = (): ClassificationSubTopicsFilter => {
  const { classificationTopicsOptions, isClassificationTopicsLoading, selectedClassificationTopicsKeys } =
    useContext(FiltersContext);
  const [classificationSubTopicsFilter, setClassificationSubTopicsFilter] = useState<ClassificationSubTopicsFilter>(
    DEFAULT_CLASSIFICATION_SUB_TOPICS_FILTER,
  );

  useEffect(() => {
    if (!isClassificationTopicsLoading && !classificationTopicsOptions.length) {
      return;
    }

    if (!selectedClassificationTopicsKeys) {
      setClassificationSubTopicsFilter(DEFAULT_CLASSIFICATION_SUB_TOPICS_FILTER);
      return;
    }

    const filteredClassificationSubTopicIds = selectedClassificationTopicsKeys.filter((filterKey) =>
      filterKey.includes(CLASSIFICATION_SUB_TOPIC_FILTER_KEY),
    );
    setClassificationSubTopicsFilter({
      classification_sub_topic: filteredClassificationSubTopicIds
        .map((key) => key.replace(`${CLASSIFICATION_SUB_TOPIC_FILTER_KEY}-`, ''))
        .join(','),
    });
  }, [selectedClassificationTopicsKeys, classificationTopicsOptions, isClassificationTopicsLoading]);

  return classificationSubTopicsFilter;
};

export default useClassificationSubTopicsFilter;
