import { CircularProgressbar } from 'components/BlockwiseSummaryTab/CircularProgressbar/CircularProgressbar';
import { ZCDChangeIndicatorHOC } from 'components/BlockwiseSummaryTab/ZCDChangeIndicatorHOC/ZCDChangeIndicatorHOC';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { DisplayableGroupedLeadingTopic } from 'types/leadingTopics';
import { formatTitle } from 'utils/formatters';
import styles from './TopConcernsWidgetConcernRow.module.scss';

interface Props {
  concern: DisplayableGroupedLeadingTopic;
  comparisonConcern?: DisplayableGroupedLeadingTopic;
  totalSelectedResponses?: number;
  totalComparisonResponses?: number;
}

const calculateResponsesPercentage = (responses: number, totalResponses: number) =>
  Math.round((responses / totalResponses) * 100);

export const TopConcernsWidgetConcernRow = (props: Props) => {
  const { concern, totalComparisonResponses, totalSelectedResponses, comparisonConcern } = props;
  const currentResponsesPercentage = calculateResponsesPercentage(concern?.responses ?? 0, totalSelectedResponses ?? 1);
  const comparisonResponsesPercentage = calculateResponsesPercentage(
    comparisonConcern?.responses ?? 0,
    totalComparisonResponses ?? 1,
  );

  const formattedConcernValue = formatTitle(concern.classificationTopicValue);
  return (
    <Flex width="100%" alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <CircularProgressbar
          value={currentResponsesPercentage}
          innerText={`${currentResponsesPercentage}%`}
          pathColor={styles.pathColor}
        />
        <Spacer orientation="horizontal" size="small" />
        <div className={styles.concernLabel}>{formattedConcernValue}</div>
      </Flex>
      <ZCDChangeIndicatorHOC
        currentScore={currentResponsesPercentage}
        comparisonScore={comparisonResponsesPercentage}
        grayscale
        showPercentagePostfix={false}
      />
    </Flex>
  );
};
