import { QueryClientProviderHOC } from 'screens/blockwise/QueryClientProviderHOC';
import React from 'react';
import { Client } from 'types/client';
import { User } from 'types/user';
import BlockwiseDashboard from './BlockwiseDashboard';

interface Props {
  user: User;
  client: Client;
}

const BlockwiseDashboardHOC = (props: Props) => {
  const { client, user } = props;

  return (
    <QueryClientProviderHOC>
      <BlockwiseDashboard client={client} user={user} />
    </QueryClientProviderHOC>
  );
};

export default BlockwiseDashboardHOC;
