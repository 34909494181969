import { DemographicScore, GenericQuestionScore } from 'src/types/score';
import { ActionTypes } from './types';
import { sortAndSplitDemographicScores, sortAndSplitQuestionScores } from './utils';

interface State {
  demographics: {
    top: DemographicScore[];
    bottom: DemographicScore[];
    isLoading: boolean;
  };
  questions: {
    top: GenericQuestionScore[];
    bottom: GenericQuestionScore[];
    isLoading: boolean;
  };
  demographicsToCompare: DemographicScore[];
  questionsToCompare: GenericQuestionScore[];
}

interface Action {
  type: ActionTypes;
  payload?: {
    demographicScores?: DemographicScore[];
    genericQuestionScores?: GenericQuestionScore[];
  };
}
export const initialState = {
  demographics: {
    top: [],
    bottom: [],
    isLoading: true,
  },
  questions: {
    top: [],
    bottom: [],
    isLoading: true,
  },
  demographicsToCompare: [],
  questionsToCompare: [],
};

// eslint-disable-next-line complexity
export const reducer = (state: State, action: Action): State => {
  if (!action.payload) return { ...state };
  const { highestScores: highestDemographicScores, lowestScores: lowestDemographicScores } =
    sortAndSplitDemographicScores(action.payload.demographicScores ?? []);
  const { highestScores: highestQuestionScores, lowestScores: lowestQuestionScores } = sortAndSplitQuestionScores(
    action.payload.genericQuestionScores ?? [],
  );

  switch (action.type) {
    case ActionTypes.DEMOGRAPHICS_LOADING:
      return {
        ...state,
        demographics: {
          ...state.demographics,
          isLoading: true,
        },
      };

    case ActionTypes.DEMOGRAPHICS_FETCHED:
      return {
        ...state,
        demographics: {
          top: highestDemographicScores,
          bottom: lowestDemographicScores,
          isLoading: false,
        },
      };

    case ActionTypes.DEMOGRAPHICS_FAILED:
      return {
        ...state,
        demographics: {
          top: [],
          bottom: [],
          isLoading: false,
        },
      };

    case ActionTypes.QUESTIONS_LOADING:
      return {
        ...state,
        questions: {
          ...state.questions,
          isLoading: true,
        },
      };

    case ActionTypes.QUESTIONS_FETCHED:
      return {
        ...state,
        questions: {
          top: highestQuestionScores,
          bottom: lowestQuestionScores,
          isLoading: false,
        },
      };

    case ActionTypes.QUESTIONS_FAILED:
      return {
        ...state,
        questions: {
          top: [],
          bottom: [],
          isLoading: false,
        },
      };

    case ActionTypes.QUESTIONS_TO_COMPARE_FETCHED:
      return {
        ...state,
        questionsToCompare: [...highestQuestionScores, ...lowestQuestionScores],
      };
    case ActionTypes.DEMOGRAPHICS_TO_COMPARE_FETCHED:
      return {
        ...state,
        demographicsToCompare: [...highestDemographicScores, ...lowestDemographicScores],
      };
    default:
      return state;
  }
};
