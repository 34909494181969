import { format } from 'date-fns';
import { titleCase } from 'title-case';

const million = 1000000;
const thousand = 1000;

// eslint-disable-next-line max-statements
export function formatInteractions(interactions: number): string {
  if (interactions > 100 * million) {
    // no digits after decimal point
    const formatted = Math.round(interactions / million);
    return `${formatted}M`;
  }
  if (interactions > 10 * million) {
    // one digit after decimal point
    const formatted = Math.round(interactions / (million / 10)) / 10;
    return `${formatted}M`;
  }
  if (interactions > million) {
    // two digits after decimal point
    const formatted = Math.round(interactions / (million / 100)) / 100;
    return `${formatted}M`;
  }
  if (interactions > 100 * thousand) {
    // no digits after decimal point
    const formatted = Math.round(interactions / thousand);
    return `${formatted}K`;
  }
  if (interactions > 10 * thousand) {
    // two digit after decimal point
    const formatted = Math.round(interactions / (thousand / 100)) / 100;
    return `${formatted}K`;
  }

  return interactions.toString();
}

export function formatTitle(topic: string): string {
  return titleCase(topic).replace(/-/g, ' ');
}

export function notEmptyStringOrNull(value: string): string | null {
  if (value === '') {
    return null;
  }

  return value;
}

export function formatToStringifiedNumbersWithCommas(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatDate = (date: string): string => format(new Date(date), 'LLL d, yyyy');
