import React from 'react';
import { ScreenStickyHeader } from '@zencity/common-ui';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { BackLink } from '../BackLink/BackLink';

interface Props {
  title: string;
}
/**
 * Header for the request pages of Community Asks.
 * For example, the TypeformPreview screen.
 * @param title Descriptive name of the example survey shown in page
 */
export const AskRequestHeader: React.FC<Props> = function AskRequestHeader(props: Props): React.ReactElement {
  const { title } = props;

  return (
    <ScreenStickyHeader>
      <BackLink href={CommunityAsksRouterPaths.WORKSPACE} />
      <h4>{title}</h4>
    </ScreenStickyHeader>
  );
};
