import { FiltersContext } from 'contexts/FiltersContext';
import { QuestionsFeedContext } from 'contexts/QuestionsFeedContext';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { useContext } from 'react';
import { fetchOverallSatisfactionWidget } from 'services/summaryWidgets';
import { SummaryOverallSatisfactionWidget } from 'types/summaryOverallSatisfactionWidget';
import { ReactQueryKeys } from 'utils/reactQueryKeys';

export const useOverallSatisfactionData = (
  startDate?: string,
  endDate?: string,
): {
  widgetData: SummaryOverallSatisfactionWidget[] | undefined;
  loading: boolean;
} => {
  const { selectedDivisionId } = useContext(FiltersContext);
  const { surveyGroupId } = useContext(QuestionsFeedContext);
  // In case we missing any of the required params, we don't want to make the request.
  const enableRequest = !!(surveyGroupId && surveyGroupId !== 0);

  let queryKey = `${ReactQueryKeys.OVERALL_SATISFACTION_WIDGET}`;
  if (startDate) {
    queryKey += `_${startDate}`;
  }
  if (endDate) {
    queryKey += `_${endDate}`;
  }

  const { isFetching, response } = useCustomQuery({
    params: {
      surveyGroupId: surveyGroupId as number,
      divisionId: selectedDivisionId,
    },
    requestFunction: fetchOverallSatisfactionWidget,
    enableRequest,
    queryKey,
  });

  return { widgetData: response, loading: isFetching };
};
