import { Flex } from 'components/Flex/Flex';
import { TableSubheader } from 'components/Analysis/CrossQuestionTable/TableSubheader/TableSubheader';
import { Spacer } from 'components/Spacer/Spacer';
import { StatusIndicatorDot } from 'components/StatusIndicatorDot/StatusIndicatorDot';
import React from 'react';
import { FormattedQuestionChoice } from 'utils/analysis';
import styles from './ChoiceHeader.module.scss';

export interface ChoiceHeaderProps {
  value: FormattedQuestionChoice['choiceHeader'];
  isNoScoreQuestion?: boolean;
}

export const ChoiceHeader = (props: ChoiceHeaderProps) => {
  const { value, isNoScoreQuestion } = props;
  const { choiceText, choiceType } = value;

  return (
    <Flex alignItems="center" className={styles.choiceHeader}>
      {!isNoScoreQuestion && (
        <>
          <StatusIndicatorDot type={choiceType} />
          <Spacer orientation="horizontal" size="small" />
        </>
      )}
      <TableSubheader subheader={choiceText} width={200} />
    </Flex>
  );
};
