/* eslint-disable i18next/no-literal-string,  max-lines-per-function */
import { Button, ScreenStickyHeader, ZCDSelect } from '@zencity/common-ui';
import { wideAngelDownArrowIconSvg } from 'assets/svgIcons';
import classNames from 'classnames';
import { BackLink } from 'components/BackLink/BackLink';
import { ExportSubmissions } from 'components/ExportSubmissions/ExportSubmissions';
import { HeaderTabs } from 'components/HeaderTabs/HeaderTabs';
import { TabKeys } from 'constants/tabs';
import { CustomerExperienceContext } from 'contexts/CustomerExperienceContext';
import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { CustomerExperienceRouterPaths } from 'screens/customer-experience/routerPaths';
import { Nullable } from 'types/misc';
import { SurveyGroup } from '@zencity/survey-types';
import { formatDate } from 'utils/formatters';
import styles from './Header.module.scss';

type SelectOptionObject = {
  value: number;
  label: string;
};

type SelectOption = Nullable<SelectOptionObject>;

interface HeaderProps {
  tabs: TabKeys[];
  surveyGroup: SurveyGroup;
}

/**
 * The header component of a single Survey page for "Customer Experience".
 *
 * @param props:
 *          tabs - Array of TabKeys to be rendered as tabs in the Header.
 *          surveyGroup - The current "Survey Group" instance.
 */
// eslint-disable-next-line max-statements, complexity
export const Header = (props: HeaderProps): ReactElement => {
  const [toggleActions, setToggleActions] = useState(false);
  const { t: translate } = useTranslation();
  const { tabs, surveyGroup } = props;
  const { cxServiceTypeIdFilterBy, setCXServiceTypeIdFilter, cxTimePeriodFilterBy, setCXTimePeriodFilter } =
    useContext(CustomerExperienceContext);

  const cxServiceTypeOptions = surveyGroup.cx_service_types?.map((cxServiceType) => ({
    value: cxServiceType.id,
    label: cxServiceType.label,
  }));

  const cxServiceTypeIdFilterByValue = cxServiceTypeOptions?.find((option) => cxServiceTypeIdFilterBy === option.value);

  const handleServiceTypeIdChange = (newValue: SelectOption): void => {
    setCXServiceTypeIdFilter(newValue === null ? newValue : newValue.value);
  };

  const timePeriodOptions = [28, 90, 180].map((daysQuantity) => ({
    value: daysQuantity,
    label: `Last ${daysQuantity} days`,
  }));

  const cxTimePeriodFilterByValue = timePeriodOptions.find((option) => cxTimePeriodFilterBy === option.value);

  const handleTimePeriodChange = (newValue: SelectOption): void => {
    setCXTimePeriodFilter(newValue === null ? newValue : newValue.value);
  };

  // FixMe: Select the typeform ID of the relevant language.
  // Currently, arbitrary take the ID of the first Typeform Survey.
  const typeformId = surveyGroup.survey?.[0]?.typeform_survey?.[0]?.typeform_id;

  const closeActionsDropdown = () => setToggleActions(false);

  // Used in HeaderTabs to create NavLinks to the specific subpaths for this surveyGroupId.
  const basePath = generatePath(CustomerExperienceRouterPaths.SURVEY_PAGE, {
    surveyGroupId: surveyGroup.id.toString(),
  });

  return (
    <>
      <ScreenStickyHeader bottomPadding={false}>
        <BackLink href={CustomerExperienceRouterPaths.WORKSPACE} />
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>{surveyGroup.title_for_display}</h1>
          </div>
          <div className={styles.date}>{formatDate(surveyGroup.created_at)}</div>
        </div>
        <div className={styles.tabsAndActions}>
          <HeaderTabs tabs={tabs} basePath={basePath} />
          {typeformId && (
            <div className={styles.actionButtons}>
              <div>
                <ZCDSelect
                  isSearchable={false}
                  isClearable
                  options={timePeriodOptions}
                  onChange={(newValue) => handleTimePeriodChange(newValue as SelectOption)}
                  value={cxTimePeriodFilterByValue}
                  placeholder="Select a time period"
                />
              </div>
              <div>
                <ZCDSelect
                  isSearchable
                  isClearable
                  options={cxServiceTypeOptions}
                  onChange={(newValue) => handleServiceTypeIdChange(newValue as SelectOption)}
                  value={cxServiceTypeIdFilterByValue}
                  placeholder="Select a Service Type"
                />
              </div>
              <div
                className={classNames(styles.shareResultsWrapper, {
                  [`${styles.toggled}`]: toggleActions,
                })}
              >
                <Button type="button" onClick={() => setToggleActions(!toggleActions)} variant="primary" size="lg">
                  {translate('communityAsks.shareResults.ctaLabel')}
                  {wideAngelDownArrowIconSvg}
                </Button>

                {toggleActions && (
                  <div className={styles.shareResultsDropdown}>
                    <ExportSubmissions surveyGroup={surveyGroup} closeActionsDropdown={closeActionsDropdown} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </ScreenStickyHeader>
    </>
  );
};
