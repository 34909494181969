import { MapsContext } from 'contexts/MapsContext';
import { useContext } from 'react';

export const useFilteredAreaNames = (): { filteredAreaIds: string[] } => {
  const { areasWithScoresData, sortedAreaIdsByAreaName, baseDivisionId, divisions } = useContext(MapsContext);

  if (!baseDivisionId)
    return {
      filteredAreaIds: sortedAreaIdsByAreaName,
    };

  // Get current division.
  const baseDivision = divisions?.find((division) => division.id === baseDivisionId);
  // Get current division children.
  const baseDivisionChildren = divisions?.filter((division) => division.parent_division === baseDivisionId);
  // Filter sortedAreaIdsByAreaName by base division or children ids.
  const filteredAreaIds = sortedAreaIdsByAreaName.filter((areaId) => {
    const area = areasWithScoresData[areaId];
    return baseDivisionChildren && baseDivisionChildren.map((child) => child.geo_targeting_ref.id).includes(area?.id);
  });

  // Sort filtered areas by name.
  filteredAreaIds.sort((elementA, elementB) => {
    const areaA = areasWithScoresData[elementA];
    const areaB = areasWithScoresData[elementB];
    return areaA?.name.localeCompare(areaB?.name, undefined, { numeric: true });
  });

  if (baseDivision) {
    // Add parent division to the list.
    filteredAreaIds.unshift(baseDivision.geo_targeting_ref.id);

    const levelZeroDivision = divisions?.find((division) => division.depth === 0);
    if (baseDivision.id !== levelZeroDivision?.id && levelZeroDivision) {
      // Add level zero division to the list.
      filteredAreaIds.unshift(levelZeroDivision?.geo_targeting_ref.id);
    }
  }

  return { filteredAreaIds };
};
