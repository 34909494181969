import { ZCDCard } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from './ColorLegend.helper';
import styles from './ColorLegend.module.scss';

// According the product requirements, we need to display color between 0 and 100.
const COLORS_TO_DISPLAY = COLOR_MAP.slice(1);

export const ColorLegend = () => {
  const { t: translate } = useTranslation();

  return (
    <ZCDCard elevated standardBodyPadding={false}>
      <div className={styles.container}>
        <span>% {translate('map.legendTitle')}</span>
        <Flex className={styles.barsContainer} flexDirection="row" justifyContent="space-between" alignItems="center">
          {COLORS_TO_DISPLAY.map((colorElement) => (
            <div
              key={colorElement.low}
              className={styles.colorLegend}
              style={{
                backgroundImage: `linear-gradient(to right, ${colorElement.color.low}, ${colorElement.color.high})`,
              }}
            />
          ))}
        </Flex>
        <Flex justifyContent="space-between" width="100%">
          <span className={styles.legendText}>{COLORS_TO_DISPLAY[0].low}</span>
          <span className={styles.legendText}>{COLORS_TO_DISPLAY[COLORS_TO_DISPLAY.length - 1].high}</span>
        </Flex>
      </div>
    </ZCDCard>
  );
};
