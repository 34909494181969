import { SurveyRequest, SurveyRequestStatus } from '@zencity/survey-types';
import React from 'react';

// The SurveyLayout component will never try to render before a survey-request is fetched
// from the server, but in case it happens then use this placeholder instead of a white-screen-of-death
const surveyRequestPlaceholder: SurveyRequest = {
  id: 0,
  status: SurveyRequestStatus.CANCELED,
  survey_type: 'community-asks',
  created_at: '2022-01-01 00:00:00.000000+03',
};

export const SurveyRequestContext = React.createContext<SurveyRequest>(surveyRequestPlaceholder);
