import React, { ReactElement } from 'react';
import useDateRangeScoreLabel from 'hooks/score/dateRangeLabel';
import styles from './ScoreCardHeader.module.scss';

interface Props {
  children?: React.ReactNode;
}

const ScoreCardHeader = (props: Props): ReactElement => {
  const { children } = props;
  const { dateRangeLabel } = useDateRangeScoreLabel();

  return (
    <div className={styles.container}>
      {children}
      <span className={styles.label}>{dateRangeLabel}</span>
    </div>
  );
};

export default ScoreCardHeader;
