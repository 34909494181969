import { DivisionWithPolygon } from '@zencity/survey-types';
import { GeoTargetPreset } from '@zencity/survey-types';
import { SurveyType } from '@zencity/survey-types';
import { londonAreasMock } from './londonAreas.mock';

export const getPolygonsById = (id: string): GeoTargetPreset => {
  return londonAreasMock.find((area) => area.id === id) as GeoTargetPreset;
};

export const divisionsMock: DivisionWithPolygon[] = [
  {
    id: 1,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 0,
    geo_targeting_ref: {
      ...getPolygonsById('634e5a8a95350a5d3321308f'),
    },
    parent_division: null,
  },
  {
    id: 2,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6023'),
    },
    parent_division: 38,
  },
  {
    id: 3,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6049'),
    },
    parent_division: 40,
  },
  {
    id: 4,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6044'),
    },
    parent_division: 39,
  },
  {
    id: 5,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac603f'),
    },
    parent_division: 36,
  },
  {
    id: 6,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6047'),
    },
    parent_division: 40,
  },
  {
    id: 7,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6045'),
    },
    parent_division: 37,
  },
  {
    id: 8,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac601b'),
    },
    parent_division: 46,
  },
  {
    id: 9,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6040'),
    },
    parent_division: 37,
  },
  {
    id: 10,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac604a'),
    },
    parent_division: 35,
  },
  {
    id: 11,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac601a'),
    },
    parent_division: 46,
  },
  {
    id: 12,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6013'),
    },
    parent_division: 45,
  },
  {
    id: 13,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6016'),
    },
    parent_division: 42,
  },
  {
    id: 14,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6012'),
    },
    parent_division: 35,
  },
  {
    id: 15,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6018'),
    },
    parent_division: 42,
  },
  {
    id: 16,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6015'),
    },
    parent_division: 42,
  },
  {
    id: 17,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6048'),
    },
    parent_division: 40,
  },
  {
    id: 18,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac603e'),
    },
    parent_division: 44,
  },
  {
    id: 19,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6019'),
    },
    parent_division: 46,
  },
  {
    id: 20,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac604b'),
    },
    parent_division: 35,
  },
  {
    id: 21,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6046'),
    },
    parent_division: 39,
  },
  {
    id: 22,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac601e'),
    },
    parent_division: 43,
  },
  {
    id: 23,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6024'),
    },
    parent_division: 45,
  },
  {
    id: 24,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac601d'),
    },
    parent_division: 41,
  },
  {
    id: 25,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac601f'),
    },
    parent_division: 43,
  },
  {
    id: 26,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6014'),
    },
    parent_division: 45,
  },
  {
    id: 27,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac601c'),
    },
    parent_division: 41,
  },
  {
    id: 28,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6025'),
    },
    parent_division: 35,
  },
  {
    id: 29,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6017'),
    },
    parent_division: 38,
  },
  {
    id: 30,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac603c'),
    },
    parent_division: 38,
  },
  {
    id: 31,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6021'),
    },
    parent_division: 36,
  },
  {
    id: 32,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6020'),
    },
    parent_division: 43,
  },
  {
    id: 33,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 2,
    geo_targeting_ref: {
      ...getPolygonsById('634fbed03e7b2a65aaac6022'),
    },
    parent_division: 44,
  },
  {
    id: 35,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f0'),
    },
    parent_division: 1,
  },
  {
    id: 36,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f7'),
    },
    parent_division: 1,
  },
  {
    id: 37,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9fb'),
    },
    parent_division: 1,
  },
  {
    id: 38,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f3'),
    },
    parent_division: 1,
  },
  {
    id: 39,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9fa'),
    },
    parent_division: 1,
  },
  {
    id: 40,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f9'),
    },
    parent_division: 1,
  },
  {
    id: 41,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f5'),
    },
    parent_division: 1,
  },
  {
    id: 42,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f2'),
    },
    parent_division: 1,
  },
  {
    id: 43,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f6'),
    },
    parent_division: 1,
  },
  {
    id: 44,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f8'),
    },
    parent_division: 1,
  },
  {
    id: 45,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f1'),
    },
    parent_division: 1,
  },
  {
    id: 46,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9f4'),
    },
    parent_division: 1,
  },
  {
    id: 47,
    division_layer: {
      id: 1,
      client: {
        id: 346,
        name: 'London Metropolitan Police',
        state: null,
        machine_name: 'london_metropolitan_police',
        zc_client_id: '631a0e77b20381a264bc01cf',
      },
      survey_type: SurveyType.COMMUNITY_SURVEY,
    },
    depth: 1,
    geo_targeting_ref: {
      ...getPolygonsById('634fbdf08cfb9d593dcfd9fc'),
    },
    parent_division: 1,
  },
];
