import React, { ErrorInfo, ReactElement } from 'react';
import styles from './ErrorBoundary.module.scss';

interface Props {
  children: React.ReactNode;
}
interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false } as State;
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error);
    console.error(errorInfo);
  }

  render(): ReactElement {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // eslint-disable-next-line i18next/no-literal-string
      return <h1 className={styles.errorBoundary}>An Error Occurred</h1>;
    }

    return <>{children}</>;
  }
}
