import { DateRangeContext } from 'contexts/DateRangeContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isScoreLatestResult } from 'utils/dateRange';
import styles from './DateRangesHeader.module.scss';

/**
 * The date ranges header for the Maps widget.
 */
export const DateRangesHeader: React.FC = function DateRangesHeader(): ReactElement {
  const { t: translate } = useTranslation();
  const { selectedDateRange, completedCyclesDateRanges = [] } = useContext(DateRangeContext);
  const [resultsRange, setResultsRange] = useState<string>();

  useEffect(() => {
    if (selectedDateRange && isScoreLatestResult(selectedDateRange) && completedCyclesDateRanges.length) {
      setResultsRange(completedCyclesDateRanges[0]?.label);
    } else {
      setResultsRange(selectedDateRange?.label);
    }
  }, [completedCyclesDateRanges, selectedDateRange]);
  return (
    <h4 className={styles.dateRangesHeader}>
      {translate('map.dateRangeHeader', {
        dateRange: resultsRange,
      })}
    </h4>
  );
};
