/* eslint-disable i18next/no-literal-string */
import { SurveyType } from '@zencity/survey-types';
import { AnalyticWidgetNames } from 'constants/analytics';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config/config';
import { analyticsService } from '../../services/analytics/analytics';
import { ActionCard } from './ActionCard';
import styles from './TakeAnAction.module.scss';

interface Props {
  surveyType: SurveyType;
}

/**
 * Take and action deck with cards inside
 */
export const TakeAnAction: React.FC<Props> = function TakeAnAction(): ReactElement {
  const { t: translate } = useTranslation();
  const activeFilters = useFilterNames();

  const onClickInsightRequest = (): void => {
    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('takeAnActionDeck.insightCard.linkText'),
      widgetName: AnalyticWidgetNames.EXPERT_INSIGHTS_WIDGET,
      activeFilters,
    });
  };

  const onClickSurveyRequest = (): void => {
    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('takeAnActionDeck.communityAsksCard.linkText'),
      widgetName: AnalyticWidgetNames.REQUEST_SURVEY_WIDGET,
      activeFilters,
    });
  };

  const onClickCreateProject = (): void => {
    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('takeAnActionDeck.projectCard.linkText'),
      widgetName: AnalyticWidgetNames.CREATE_PROJECT_WIDGET,
      activeFilters,
    });
  };

  return (
    <div className={styles.takeAnAction}>
      <h2 className={styles.actionDeckTitle}>{translate('takeAnActionDeck.title')}</h2>
      <div className={styles.cardsArea}>
        <ActionCard
          handleOnClick={onClickInsightRequest}
          redirectUrl={`${config.insights.domain}/admin/insights/request`}
          title={translate('takeAnActionDeck.insightCard.title')}
          description={translate('takeAnActionDeck.insightCard.paragraph')}
          linkText={translate('takeAnActionDeck.insightCard.linkText')}
          iconName="insights.svg"
        />
        <ActionCard
          handleOnClick={onClickSurveyRequest}
          redirectUrl={`${config.insights.domain}/admin/surveys/community-asks`}
          title={translate('takeAnActionDeck.communityAsksCard.title')}
          description={translate('takeAnActionDeck.communityAsksCard.paragraph')}
          linkText={translate('takeAnActionDeck.communityAsksCard.linkText')}
          iconName="digest.svg"
        />
        <ActionCard
          handleOnClick={onClickCreateProject}
          redirectUrl={`${config.insights.domain}/admin/projects/create`}
          title={translate('takeAnActionDeck.projectCard.title')}
          description={translate('takeAnActionDeck.projectCard.paragraph')}
          linkText={translate('takeAnActionDeck.projectCard.linkText')}
          iconName="project.svg"
        />
      </div>
    </div>
  );
};
