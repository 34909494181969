import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Icon } from '@zencity/common-ui';
import styles from './EmptyStateWithCustomIcon.module.scss';

interface Props {
  title: string;
  description: string;
  iconPath?: string;
  iconClassName?: string;
  ctaComponent?: ReactElement;
}

/**
 * Empty state for the maps tab when there is an error rendering the MapView component.
 *
 * @param title - The title of the empty state placeholder.
 * @param description - The description of the empty state placeholder.
 */
export const EmptyStateWithCustomIcon = ({
  title,
  description,
  iconPath = 'empty-state.svg',
  iconClassName = '',
  ctaComponent,
}: Props): ReactElement => (
  <div className={styles.emptyStateWithCustomIcon}>
    {/* TODO: convert to zcdicon */}
    <Icon name={iconPath} height={80} width={80} className={classnames(styles.icon, iconClassName)} />
    <div className={styles.content}>
      <h2>{title}</h2>
      <p className={styles.description}>{description}</p>
    </div>
    {ctaComponent}
  </div>
);
