import { ZCDSelect } from '@zencity/common-ui';
import { AnalyticEventsChangeTypes, AnalyticTypePropertyValues } from 'constants/analytics';

import { MapsContext } from 'contexts/MapsContext';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { analyticsService } from 'services/analytics/analytics';
import { SelectOption } from 'types/selectOption';
import styles from './DivisionQuestionDropdown.module.scss';

/**
 * Selects the question for Division Map scores.
 */
export const DivisionQuestionDropdown: React.FC = function DivisionQuestionDropdown(): ReactElement {
  const {
    divisionAreaScoresByQuestion,
    selectedGenericQuestionId,
    setSelectedGenericQuestionId,
    setAreasWithScoresData,
  } = useContext(MapsContext);

  const [selectedQuestionOption, setSelectedQuestionOption] = useState<SelectOption>();
  const activeFilters = useFilterNames();

  const questionSelectOptions = useMemo(
    () =>
      Object.values(divisionAreaScoresByQuestion)
        .sort(
          (firstQuestionDivisionScore, secondQuestionDivisionScore) =>
            firstQuestionDivisionScore.questionIndex - secondQuestionDivisionScore.questionIndex,
        )
        .map((questionDivisionScores) => ({
          label: `Q${questionDivisionScores.questionIndex}: ${questionDivisionScores.questionText}`,
          value: questionDivisionScores.genericQuestionId,
        })),
    [divisionAreaScoresByQuestion],
  );

  useEffect(() => {
    const firstSelectedQuestionOption = questionSelectOptions.find(
      (selectOption) => selectOption.value === selectedGenericQuestionId,
    );
    setSelectedQuestionOption(firstSelectedQuestionOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnQuestionChange = (selectedOption: SelectOption) => {
    setSelectedGenericQuestionId(selectedOption.value as string);
    setSelectedQuestionOption(selectedOption);
    setAreasWithScoresData(divisionAreaScoresByQuestion[selectedOption.value].divisionScores);

    analyticsService.crossProductEvents.questionOrAspectSelected({
      type: AnalyticTypePropertyValues.QUESTION,
      aspectId: AnalyticEventsChangeTypes.IRRELEVANT,
      aspectText: AnalyticEventsChangeTypes.IRRELEVANT,
      isCrossTabMode: AnalyticEventsChangeTypes.IRRELEVANT,
      questionId: +selectedOption.value,
      questionText: selectedOption.label,
      activeFilters,
    });
  };

  return (
    <div className={styles.divisionQuestionDropdown}>
      <ZCDSelect
        isSearchable={false}
        isClearable={false}
        onChange={(selectedOption) => handleOnQuestionChange(selectedOption as SelectOption)}
        value={selectedQuestionOption}
        options={questionSelectOptions}
      />
    </div>
  );
};
