import { Reducer } from 'react';
import { LeadingTopic } from 'types/leadingTopics';
import { Answer, Question } from '@zencity/survey-types';
import { SurveyState } from './state';

export enum ActionTypes {
  /**
   * Set question data
   */
  SET_QUESTIONS = 'SET_QUESTIONS',
  /**
   * Set answers to selected question
   */
  SET_ANSWERS = 'SET_ANSWERS',
  /**
   * Add answers on top of the ones that are already there
   */
  APPEND_ANSWERS = 'APPEND_ANSWERS',
  /**
   * Select a question
   */
  SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION',
  /**
   * Toggle loading state
   */
  SET_LOADING = 'SET_LOADING',
  /**
   * Set the loading for questions.
   */
  SET_LOADING_QUESTIONS = 'SET_LOADING_QUESTIONS',
  /**
   * Toggle loading state specifically for Leading Topics.
   */
  SET_LOADING_LEADING_TOPICS = 'SET_LOADING_LEADING_TOPICS',
  /**
   * Set the current page number
   */
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  /**
   * Set the leading topics
   */
  SET_LEADING_TOPICS = 'SET_LEADING_TOPICS',
}

export type Action<T> =
  | { type: ActionTypes.SET_QUESTIONS; payload: T }
  | { type: ActionTypes.SET_CURRENT_QUESTION; payload: T }
  | { type: ActionTypes.SET_LOADING; payload: T }
  | { type: ActionTypes.SET_ANSWERS; payload: T }
  | { type: ActionTypes.APPEND_ANSWERS; payload: T }
  | { type: ActionTypes.SET_CURRENT_PAGE; payload: T }
  | { type: ActionTypes.SET_LEADING_TOPICS; payload: T }
  | { type: ActionTypes.SET_LOADING_LEADING_TOPICS; payload: T }
  | { type: ActionTypes.SET_LOADING_QUESTIONS; payload: T };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Handler = (state: SurveyState, action: Action<any>) => SurveyState;

type Handlers = Record<ActionTypes, Handler>;

export const actionHandlers: Handlers = {
  [ActionTypes.SET_QUESTIONS]: (state: SurveyState, { payload }: Action<Question[]>) => ({
    ...state,
    questions: payload,
  }),
  [ActionTypes.SET_ANSWERS]: (
    state: SurveyState,
    { payload }: Action<{ answers: Answer[]; totalAnswers: number }>,
  ) => ({
    ...state,
    ...payload,
  }),
  [ActionTypes.SET_CURRENT_QUESTION]: (state, { payload }: Action<number>) => ({
    ...state,
    currentQuestion: payload,
  }),
  [ActionTypes.SET_LOADING]: (state, { payload }: Action<boolean>) => ({
    ...state,
    loading: payload,
  }),
  [ActionTypes.APPEND_ANSWERS]: (state, { payload }: Action<Answer[]>) => ({
    ...state,
    answers: [...state.answers, ...payload],
  }),
  [ActionTypes.SET_CURRENT_PAGE]: (state, { payload }: Action<number>) => ({
    ...state,
    currentPage: payload,
  }),
  [ActionTypes.SET_LEADING_TOPICS]: (state, { payload }: Action<LeadingTopic[]>) => ({
    ...state,
    leadingTopics: payload,
  }),
  [ActionTypes.SET_LOADING_LEADING_TOPICS]: (state, { payload }: Action<boolean>) => ({
    ...state,
    loadingLeadingTopics: payload,
  }),
  [ActionTypes.SET_LOADING_QUESTIONS]: (state, { payload }: Action<boolean>) => ({
    ...state,
    loadingQuestions: payload,
  }),
};

export function createReducer(handlers: Handlers): Handler {
  return function reducer(state, action): SurveyState {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }

    return state;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SurveyReducer = Reducer<SurveyState, Action<any>>;

export const reducer = createReducer(actionHandlers);
