import { ProductNames } from 'constants/products';
import { useEffect } from 'react';
import { analyticsService } from 'services/analytics/analytics';
import { Client } from 'types/client';
import { User } from 'types/user';

export function useSurveysInitialLoad(
  client: Client,
  user: User,
  initLogger: (user: User, clientName: string) => void,
  productName: ProductNames,
): void {
  useEffect(() => {
    initLogger(user, client.name as string);
  }, [user, client, initLogger]);

  useEffect(() => {
    analyticsService.init(process.env.AMPLITUDE_API_KEY ?? '', user, productName);

    return function cleanup() {
      analyticsService.clear();
    };
  }, [user, client, productName]);
}
