import {
  groupLeadingTopicsByClassificationTopic,
  prepareGroupedLeadingTopics,
} from 'components/LeadingTopicsWidget/LeadingTopicsWidget.helpers';
import { FiltersContext } from 'contexts/FiltersContext';
import { QuestionsFeedContext } from 'contexts/QuestionsFeedContext';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { useContext } from 'react';
import { fetchLeadingTopics } from 'services/leadingTopics';
import { DisplayableGroupedLeadingTopic } from 'types/leadingTopics';
import { mapFilters } from 'utils/blockwise/filters';
import { ReactQueryKeys } from 'utils/reactQueryKeys';

interface Props {
  genericQuestionId: number;
  startDate?: string;
  endDate?: string;
}

export const useTopConcernsWidgetData = ({
  startDate,
  endDate,
  genericQuestionId,
}: Props): {
  widgetData: DisplayableGroupedLeadingTopic[] | undefined;
  loading: boolean;
} => {
  const { surveyGroupId } = useContext(QuestionsFeedContext);
  const { selectedDivisionIds, selectedDemographics } = useContext(FiltersContext);
  const { mappedDemographics, mappedDivisions } = mapFilters(selectedDemographics, selectedDivisionIds);

  // In case we're missing missing any of the required params, we don't want to make the request.
  const enableRequest = !!(surveyGroupId && surveyGroupId !== 0 && startDate && genericQuestionId);

  const { isFetching, response } = useCustomQuery({
    params: {
      end_date: endDate,
      start_date: startDate as string,
      survey_group: surveyGroupId as number,
      generic_question: genericQuestionId,
      divisions: mappedDivisions,
      ...mappedDemographics,
    },
    requestFunction: fetchLeadingTopics,
    enableRequest,
    queryKey: ReactQueryKeys.TOP_CONCERNS_WIDGET,
  });

  const groupedLeadingTopics = groupLeadingTopicsByClassificationTopic(response ?? []);
  const displayableGroupedLeadingTopics = prepareGroupedLeadingTopics(groupedLeadingTopics);

  return { widgetData: displayableGroupedLeadingTopics, loading: isFetching };
};
