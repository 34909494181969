import { ZCDChangeIndicatorHOC } from 'components/BlockwiseSummaryTab/ZCDChangeIndicatorHOC/ZCDChangeIndicatorHOC';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { AspectDivision } from 'types/summaryAspectWidget';
import styles from './DivisionListItem.module.scss';

interface Props {
  comparisonDivision?: AspectDivision;
  division: AspectDivision;
}

export const DivisionListItem = (props: Props): JSX.Element => {
  const { comparisonDivision, division } = props;

  return (
    <div className={styles.divisionListItem}>
      <div className={styles.typography}>{division.division_polygon_name}</div>
      <Flex alignItems="center">
        <Spacer orientation="horizontal" size="small" />
        <div className={styles.typography}>{division.score?.toFixed(0)}%</div>
        <Spacer orientation="horizontal" size="small" />
        <ZCDChangeIndicatorHOC currentScore={division.score} comparisonScore={comparisonDivision?.score} />
      </Flex>
    </div>
  );
};
