import { TreeSelectChangeParams, ZCDTreeSelect } from '@zencity/common-ui';
import { AnalyticEventsChangeTypes, AnalyticFilterNames } from 'constants/analytics';
import { FiltersContext } from 'contexts/FiltersContext';
import React, { ReactElement, useContext } from 'react';
import { analyticsService } from 'services/analytics/analytics';
import { TreeSelectSelectionKeysType } from 'utils/blockwise/filters';
import { formatTreeSelection } from 'utils/utils';

interface Props {
  isDisabled?: boolean;
}

/**
 * A component to select demographic data to filter the open ended answers.
 */
export const DemographicFilter: React.FC<Props> = function DemographicFilter({
  isDisabled = false,
}: Props): ReactElement {
  const { dashboardDemographics, selectedDemographics, setSelectedDemographics } = useContext(FiltersContext);

  const handleChange = (event: TreeSelectChangeParams) => {
    setSelectedDemographics(event.value);
    const selectedDemographic = event.value as TreeSelectSelectionKeysType;
    const formattedDemographics = formatTreeSelection(dashboardDemographics, selectedDemographic);
    const demographicKeys = Object.keys(formattedDemographics);
    analyticsService.crossProductEvents.filterChanged({
      filterName: AnalyticFilterNames.DEMOGRAPHIC,
      filterValue: demographicKeys.length > 0 ? demographicKeys : AnalyticEventsChangeTypes.NONE,
      ageValue: formattedDemographics?.age || AnalyticEventsChangeTypes.IRRELEVANT,
      ethnicityValue: formattedDemographics?.ethnicity || AnalyticEventsChangeTypes.IRRELEVANT,
      genderValue: formattedDemographics?.gender || AnalyticEventsChangeTypes.IRRELEVANT,
      cycleNumber: AnalyticEventsChangeTypes.IRRELEVANT,
      areaValue: AnalyticEventsChangeTypes.IRRELEVANT,
    });
  };

  return (
    <div>
      {dashboardDemographics && (
        <ZCDTreeSelect
          disabled={isDisabled}
          options={dashboardDemographics}
          value={isDisabled ? undefined : selectedDemographics}
          onChange={handleChange}
          selectedPrefix="Demographics"
          placeholder="Demographics: Any"
          showSearch
          searchPlaceholder="Search for a demographic group"
          showSelectAll
          onClearValue={() => setSelectedDemographics([])}
        />
      )}
    </div>
  );
};
