import { computeBounds, getFullCoordinateList } from 'components/MapView/MapView.helpers';
import { MapRenderTypes } from 'constants/map';
import { useEffect, useState } from 'react';
import { WebMercatorViewport } from 'react-map-gl';
import { AreasWithScoresObject, US_CENTER } from 'types/areas';

const HEIGHT_DICTIONARY = {
  [MapRenderTypes.WIDGET]: 323,
  [MapRenderTypes.SUMMARY_WIDGET]: 376,
  [MapRenderTypes.SCREEN]: 663,
};

const WIDTH_DICTIONARY = {
  [MapRenderTypes.WIDGET]: 669,
  [MapRenderTypes.SUMMARY_WIDGET]: 669,
  [MapRenderTypes.SCREEN]: 1008,
};

const ZOOM_DICTIONARY = {
  [MapRenderTypes.WIDGET]: 0,
  [MapRenderTypes.SUMMARY_WIDGET]: 0.4,
  [MapRenderTypes.SCREEN]: 0,
};

interface Props {
  renderType: MapRenderTypes;
  areasWithScores: AreasWithScoresObject;
  mapRef: React.RefObject<HTMLDivElement>;
}

const PADDING_THRESHOLD = 60;
const PADDING_OFFSET = 0.3;
export const useMapViewPort = (props: Props) => {
  const { renderType, areasWithScores, mapRef } = props;
  const [viewport, setViewport] = useState({
    latitude: US_CENTER.lat,
    longitude: US_CENTER.lon,
    zoom: 10,
    width: WIDTH_DICTIONARY[renderType],
    height: HEIGHT_DICTIONARY[renderType],
  });
  const renderAsWidget = renderType !== MapRenderTypes.SCREEN;

  useEffect(() => {
    if (!areasWithScores) return;

    const coordinateList = getFullCoordinateList(areasWithScores);
    const mapBounds = computeBounds(coordinateList);
    const PADDING_RIGHT_WIDTH = mapRef.current ? mapRef.current.offsetWidth * PADDING_OFFSET : PADDING_THRESHOLD;

    setViewport((prevViewport) => {
      const { longitude, latitude, zoom } = new WebMercatorViewport(prevViewport).fitBounds(mapBounds, {
        padding: renderAsWidget
          ? PADDING_THRESHOLD * PADDING_OFFSET
          : {
              top: PADDING_THRESHOLD,
              bottom: PADDING_THRESHOLD,
              left: PADDING_THRESHOLD,
              right: PADDING_RIGHT_WIDTH,
            },
      });
      return {
        ...prevViewport,
        longitude,
        latitude,
        zoom: zoom - ZOOM_DICTIONARY[renderType],
      };
    });
  }, [areasWithScores, mapRef, renderAsWidget, renderType]);

  return { viewport, setViewport };
};
