import { fetchCrossQuestions, fetchQuestionsScores } from 'services/analysisScores';
import { MappedQuestions } from 'types/questionsScores';
import { formatDemographicToString, mapDemographicFilters, TreeSelectSelectionKeysType } from 'utils/blockwise/filters';
import { mapChoice, mapQuestion } from 'utils/analysis';

interface FetchQuestionsScoresDataParams {
  demographics: TreeSelectSelectionKeysType;
  startDate: string;
  endDate: string;
  division?: number;
  surveyGroupId: number;
}

export async function fetchQuestionsScoresData({
  demographics,
  startDate,
  endDate,
  division,
  surveyGroupId,
}: FetchQuestionsScoresDataParams) {
  const mappedDemographics = mapDemographicFilters(demographics);
  const demographicsStringFormat = formatDemographicToString(mappedDemographics);
  const questionsWithScores = await fetchQuestionsScores(
    {
      start_date: startDate,
      end_date: endDate,
      division_id: division,
    },
    demographicsStringFormat.slice(0, -1), // remove last &
    surveyGroupId,
  );
  const mappedQuestions: MappedQuestions = {
    totalSubmissions: questionsWithScores.total_submissions,
    questionScores: [],
  };
  const questionsMap = questionsWithScores.question_scores.map((questionScore) => {
    const mappedQuestionChoices = questionScore.question_choices?.map((questionChoice) => mapChoice(questionChoice));
    return mapQuestion(questionScore, mappedQuestionChoices);
  });
  return { ...mappedQuestions, questionScores: questionsMap };
}

interface FetchCrossQuestionsScoresDataParams {
  surveyGroupId: number;
  questionId: number;
  crossQuestionId: number;
  startDate: string;
  endDate: string;
  demographics: TreeSelectSelectionKeysType;
  division?: number;
}

// eslint-disable-next-line max-params
export async function fetchCrossQuestionsData({
  surveyGroupId,
  questionId,
  crossQuestionId,
  startDate,
  endDate,
  demographics,
  division,
}: FetchCrossQuestionsScoresDataParams) {
  const mappedDemographics = mapDemographicFilters(demographics);
  const demographicsStringFormat = formatDemographicToString(mappedDemographics);
  const crossQuestion = await fetchCrossQuestions(
    {
      start_date: startDate,
      end_date: endDate,
      question_id: questionId,
      cross_question_id: crossQuestionId,
      division_id: division,
    },
    demographicsStringFormat.slice(0, -1), // remove last &
    surveyGroupId,
  );

  return crossQuestion;
}
