import React, { ReactElement, useContext } from 'react';
import { TypeformExampleCardList } from 'components/TypeformExampleCardList/TypeformExampleCardList';
import { CommunityAsksContext } from 'contexts/CommunityAsksContext';
import { WorkspaceHeader } from './components/WorkspaceHeader/WorkspaceHeader';
import { RequestFormHeader } from './components/RequestFormHeader/RequestFormHeader';
import { SurveyRequestsList } from './components/SurveyRequestsList/SurveyRequestsList';

/**
 * The main Community Asks screen.
 */
export const Workspace: React.FC = function Workspace(): ReactElement {
  const { surveyRequests } = useContext(CommunityAsksContext);

  return (
    <>
      <WorkspaceHeader />
      {surveyRequests.length === 0 ? <RequestFormHeader /> : <SurveyRequestsList />}
      <TypeformExampleCardList />
    </>
  );
};
