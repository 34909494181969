import { TreeSelectSelectionKeys } from '@zencity/common-ui';
import { DemographicFilters, MappedDemographicFiltersType } from 'types/demographicFilters';

export interface TreeSelectSelectionKeysType {
  [key: string]: { checked?: boolean; partialChecked?: boolean };
}

export const mapDemographicFilters = (
  selectedDemographics?: TreeSelectSelectionKeysType,
): Partial<MappedDemographicFiltersType> => {
  if (!selectedDemographics) {
    return {};
  }
  const mappedFilters: Record<DemographicFilters, string[]> = {
    age_group: [],
    ethnicity: [],
    gender: [],
    education: [],
    income: [],
  };
  Object.entries(selectedDemographics as TreeSelectSelectionKeysType).forEach(([key, value]) => {
    const demographicGroup = key.split('-')[0] as DemographicFilters;
    const demographicId = key.split('-')[1];
    if (demographicId && value.checked) {
      mappedFilters[demographicGroup]?.push(demographicId);
    }
  });
  const mappedFiltersStringified: Partial<MappedDemographicFiltersType> = {};
  const keys = Object.keys(mappedFilters) as DemographicFilters[];
  keys.forEach((key) => {
    mappedFiltersStringified[key] = mappedFilters[key].join(',');
  });
  return mappedFiltersStringified;
};

export const mapDivisionsFilters = (selectedDivisionIds: number[]): string => {
  if (!selectedDivisionIds.length) {
    return '';
  }
  return selectedDivisionIds.join(',');
};

export const formatDemographicToString = (mappedDemographics: Partial<MappedDemographicFiltersType>) =>
  Object.entries(mappedDemographics).reduce((urlString, [key, value]) => {
    if (!value) return urlString;
    const demographicValue = value.split(',');
    let demographicString = '';
    demographicValue.forEach((demographic) => {
      demographicString += `${key}_id=${demographic}&`;
    });
    return urlString + demographicString;
  }, '');

export const mapFilters = (selectedDemographics: TreeSelectSelectionKeys, selectedDivisionIds: number[]) => {
  const mappedDemographics = mapDemographicFilters(selectedDemographics as TreeSelectSelectionKeysType);
  const mappedDivisions = mapDivisionsFilters(selectedDivisionIds);
  return { mappedDemographics, mappedDivisions };
};
