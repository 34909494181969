import { SurveyRequest, SurveyType, TypeformExample } from '@zencity/survey-types';
import classNames from 'classnames';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { ManagementPageLink } from 'components/ManagementPageLink/ManagementPageLink';
import { ProductNames } from 'constants/products';
import { AccountsContext } from 'contexts/AccountsContext';
import { CommunityAsksContext } from 'contexts/CommunityAsksContext';
import { CommunityAsksToastContextProvider } from 'contexts/CommunityAsksToastContext';
import { useSurveysInitialLoad } from 'hooks/useSurveysInitialLoad';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CommunityAsksRoutes } from 'screens/community-asks/router';
import { fetchSurveyRequests } from 'services/surveyRequest';
import { fetchAllTypeformExamples } from 'services/typeformExample';
import { Client } from 'types/client';
import { SurveyRequestFilterParams } from 'types/surveyRequest';
import { User } from 'types/user';
import { initLogger } from 'utils/community-asks/logger';
import { config } from '../../../config/config';
import '../../i18n';
import { getLayout, Layout } from '../Screens.helpers';

interface Props {
  user: User;
  client: Client;
}

/**
 * The Community Asks router component.
 *
 * @param user: The core platform user.
 * @param client: The core platform client the user is aligned to.
 * @param baseUrl: The parent will tell surveys in which URL it does not live.
 */
/* eslint-disable max-lines-per-function */
/* eslint-disable i18next/no-literal-string */
// eslint-disable-next-line max-statements
const CommunityAsksDashboard: React.FC<Props> = function CommunityAsksDashboard({ user, client }: Props): ReactElement {
  const [currentLayout, setCurrentLayout] = useState<Layout>(Layout.SCREEN_SMALL);
  const [typeformExamples, setTypeformExamples] = useState<TypeformExample[]>([]);
  const [surveyRequests, setSurveyRequests] = useState<SurveyRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useSurveysInitialLoad(client, user, initLogger, ProductNames.COMMUNITY_ASKS);

  // eslint-disable-next-line complexity
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries[0]?.target) {
          const divElm = entries[0].target as HTMLElement;
          const width = divElm.scrollWidth;
          setCurrentLayout(getLayout(width));
        }
      }),
    [],
  );

  useEffect(
    () =>
      function cleanup() {
        resizeObserver.disconnect();
      },
    [resizeObserver],
  );

  useEffect(() => {
    const fetchTypeformExamples = async () => {
      try {
        const examples = await fetchAllTypeformExamples();
        setTypeformExamples(examples);
      } catch (error) {
        // TODO: Toast error here
      }
    };

    fetchTypeformExamples();
  }, []);

  useEffect(() => {
    const fetchClientSurveyRequests = async () => {
      try {
        const params = {
          survey_type: SurveyType.COMMUNITY_ASKS,
        } as SurveyRequestFilterParams;
        const clientSurveyRequests = await fetchSurveyRequests(params);
        setSurveyRequests(clientSurveyRequests);
      } catch (error) {
        // TODO: Toast error here
      }
      setIsLoading(false);
    };
    fetchClientSurveyRequests();
  }, []);

  const onContentRender = useCallback(
    (node) => {
      if (node !== null) {
        resizeObserver.disconnect();
        resizeObserver.observe(node); // will respond to width change
      }
    },
    [resizeObserver],
  );

  return (
    <div className={classNames(currentLayout)} ref={onContentRender}>
      <BrowserRouter basename={config.communityAsks.baseRouterUrl}>
        <AccountsContext.Provider value={{ client, user }}>
          <CommunityAsksToastContextProvider>
            <CommunityAsksContext.Provider value={{ typeformExamples, surveyRequests, setSurveyRequests }}>
              <ErrorBoundary>
                <LoaderMask isLoading={isLoading}>
                  <ManagementPageLink />
                  <CommunityAsksRoutes basename={config.communityAsks.baseRouterUrl} />
                </LoaderMask>
              </ErrorBoundary>
            </CommunityAsksContext.Provider>
          </CommunityAsksToastContextProvider>
        </AccountsContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default CommunityAsksDashboard;
