import { ZCDButton, ZCDIcon } from '@zencity/common-ui';
import { ContentEmptyState } from 'components/ContentEmptyState/ContentEmptyState';
import { FiltersContext } from 'contexts/FiltersContext';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  cleanupAction?: () => void;
}

/**
 * Shows empty state and resets filters.
 */
export const DashboardContentEmptyState: React.FC<Props> = function DashboardContentEmptyState({
  cleanupAction,
}: Props): ReactElement {
  const { clearFilters } = useContext(FiltersContext);
  const handleFiltersReset = () => {
    clearFilters();
    cleanupAction?.();
  };
  const { t: translate } = useTranslation();
  return (
    <ContentEmptyState
      iconComponent={<ZCDIcon name="large-empty-state-report" />}
      title={translate('answers.filtersPlaceholder.title')}
      description={translate('answers.filtersPlaceholder.explanation')}
    >
      <ZCDButton
        onClick={handleFiltersReset}
        text={translate('answers.filtersPlaceholder.ctaLabel')}
        variant="secondary"
      />
    </ContentEmptyState>
  );
};
