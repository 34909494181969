/* eslint-disable max-lines, max-statements, max-lines-per-function */
import { HorizontalSeparator, ZCDSpinner } from '@zencity/common-ui';
import { QuestionType, SurveyType } from '@zencity/survey-types';
import { DashboardContentEmptyState } from 'components/DashboardContentEmptyState/DashboardContentEmptyState';
import { MIN_UNREPRESENTATIVE_INDICATOR } from 'components/UnrepresentativeIndicator/constants';
import { DashboardContext } from 'contexts/DashboardContext';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { FiltersContext } from 'contexts/FiltersContext';
import { AnalysisContext } from 'contexts/AnalysisContext';
import useScoreDateRangeFilter from 'hooks/score/dateRangeFilter';
import { useCustomQuery } from 'hooks/useCustomQuery';
import React, { ReactElement, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { fetchGroupedQuestions } from 'services/question';
import { fetchSurveyGroupTotalSubmissions } from 'services/surveyGroup';
import { ExtraFiltersParams } from 'types/misc';
import { SurveyGroupOutletContext } from 'types/surveyGroup';
import { TreeSelectSelectionKeysType } from 'utils/blockwise/filters';
import { isScoreLatestResult } from 'utils/dateRange';
import { ReactQueryKeys } from 'utils/reactQueryKeys';
import CrossQuestionMenu from './CrossQuestionMenu/CrossQuestionMenu';
import { fetchQuestionsScoresData } from './helpers';
import styles from './Analysis.module.scss';
import AnalysisHeader from './AnalysisHeader/AnalysisHeader';
import { AnalysisWidgetWrapper } from './AnalysisQuestionSummary/AnalysisWidgetWrapper/AnalysisWidgetWrapper';
import { useFilterScoreQuestions } from './useFilterScoreQuestions';
import { AnalysisQuestionBreakdown } from './AnalysisQuestionBreakdown/AnalysisQuestionBreakdown';

const CROSS_QUESTION_TYPES = [
  QuestionType.CHOICES_OPTIONS,
  QuestionType.SCALE,
  QuestionType.YES_NO,
  QuestionType.DROPDOWN,
];

const MAX_PAGE_SIZE = 100;

export const Analysis = (): ReactElement => {
  const { dateRangeFilter } = useScoreDateRangeFilter();
  const {
    comparisonDateRange,
    dashboardDateRanges = [],
    selectedDateRange,
    completedCyclesDateRanges,
  } = useContext(DateRangeContext);
  const { selectedDemographics, selectedDivisionId } = useContext(FiltersContext);
  const { surveyGroup } = useOutletContext<SurveyGroupOutletContext & ExtraFiltersParams>();
  const { isCrossModeEnabled, setCrossTabQuestions, crossTabQuestions } = useContext(DashboardContext);

  const isLatestResult = selectedDateRange && isScoreLatestResult(selectedDateRange);
  const selectedCycle = isLatestResult ? completedCyclesDateRanges?.[0] : selectedDateRange;

  const { response: surveyGroupSubmissions } = useCustomQuery({
    params: {
      surveyGroupId: surveyGroup.id,
      startDate: selectedCycle?.startDate as string,
      endDate: selectedCycle?.endDate as string,
    },
    queryKey: ReactQueryKeys.SURVEY_GROUP_SUBMISSIONS,
    requestFunction: fetchSurveyGroupTotalSubmissions,
    enableRequest: !!selectedCycle,
  });

  const {
    response: comparisonQuestionsScores,
    isFetching: areComparisonQuestionScoresLoading,
    error: comparisonQuestionsScoresError,
  } = useCustomQuery({
    params: {
      surveyGroupId: surveyGroup.id,
      startDate: comparisonDateRange?.startDate as string,
      endDate: comparisonDateRange?.endDate as string,
      demographics: selectedDemographics as TreeSelectSelectionKeysType,
      division: selectedDivisionId,
    },
    queryKey: ReactQueryKeys.COMPARISON_QUESTION_SCORES,
    requestFunction: fetchQuestionsScoresData,
    enableRequest: !!(surveyGroup.id && comparisonDateRange?.startDate && comparisonDateRange?.endDate),
  });

  const {
    response: questionsScores,
    isFetching: areQuestionScoresLoading,
    error: questionsScoresError,
  } = useCustomQuery({
    params: {
      demographics: selectedDemographics as TreeSelectSelectionKeysType,
      division: selectedDivisionId,
      surveyGroupId: surveyGroup.id,
      startDate: dateRangeFilter?.startDate as string,
      endDate: dateRangeFilter?.endDate as string,
    },
    queryKey: ReactQueryKeys.QUESTION_SCORES,
    requestFunction: fetchQuestionsScoresData,
    enableRequest: !!(surveyGroup.id && dateRangeFilter?.startDate && dateRangeFilter?.endDate),
  });

  const { isFetching: areCrossQuestionsLoading, error: crossQuestionsError } = useCustomQuery({
    params: {
      survey_group: surveyGroup.id,
      survey_type: surveyGroup.type,
      start_date: dateRangeFilter?.startDate,
      end_date: dateRangeFilter?.endDate,
      hide_from_live_feed: false,
      hide_is_ignored: true,
      page_size: MAX_PAGE_SIZE,
    },
    queryKey: ReactQueryKeys.CROSS_QUESTIONS,
    requestFunction: fetchGroupedQuestions,
    enableRequest: dateRangeFilter && isCrossModeEnabled && !crossTabQuestions.length,
    onSuccess: (questions) => {
      setCrossTabQuestions(
        questions.filter((question) =>
          Object.values(CROSS_QUESTION_TYPES).includes(question.question_type as QuestionType),
        ),
      );
    },
  });

  const { filteredQuestions } = useFilterScoreQuestions(questionsScores?.questionScores);
  const hasError = questionsScoresError || comparisonQuestionsScoresError || crossQuestionsError;
  if (hasError) console.error(hasError);
  const isLoading = areQuestionScoresLoading || areCrossQuestionsLoading || areComparisonQuestionScoresLoading;
  const isEmptyState =
    (!isLoading && questionsScores && Object.keys(questionsScores?.questionScores || []).length === 0) ||
    (questionsScores && questionsScores?.totalSubmissions < MIN_UNREPRESENTATIVE_INDICATOR);
  const hideOvertimeTrends = surveyGroup.type === SurveyType.PULSE && dashboardDateRanges.length <= 1;

  if (isEmptyState) {
    return <DashboardContentEmptyState />;
  }

  if (isLoading) {
    return (
      <div className={styles.loadingSpinner}>
        <ZCDSpinner size={48} />
      </div>
    );
  }

  return (
    <AnalysisContext.Provider
      value={{
        surveyGroupSubmissions: surveyGroupSubmissions?.total_valid_submissions || 0,
        comparisonQuestionsScores,
        hideOvertimeTrends,
      }}
    >
      <div className={styles.screen}>
        {isCrossModeEnabled && <CrossQuestionMenu questions={filteredQuestions} />}
        <div className={styles.questions}>
          <AnalysisHeader
            submissions={questionsScores?.totalSubmissions}
            surveyGroupSubmissions={surveyGroupSubmissions?.total_valid_submissions || 0}
            questions={filteredQuestions}
          />
          <AnalysisWidgetWrapper>
            {questionsScores &&
              filteredQuestions?.map((question, index) => (
                <React.Fragment key={question.genericQuestionId}>
                  <AnalysisQuestionBreakdown
                    question={question}
                    totalSubmissionsOverall={questionsScores.totalSubmissions}
                  />
                  {index < filteredQuestions.length - 1 && <HorizontalSeparator />}
                </React.Fragment>
              ))}
          </AnalysisWidgetWrapper>
        </div>
      </div>
    </AnalysisContext.Provider>
  );
};
