import React, { ReactElement } from 'react';
import { SatisfactionScore as Props } from 'src/types/score';
import styles from '../SatisfactionDistribution.module.scss';

const calculateWidth = (percentageValue: number): string => `${Math.round(percentageValue)}%`;

// eslint-disable-next-line complexity
export const VisualDisplay = (props: Props): ReactElement => {
  const { positive, negative, neutral } = props;
  const nonSubstantiative = 100 - Math.round(positive + negative + neutral);
  const satisfactionOptions = {
    positive,
    neutral,
    negative,
    nonSubstantiative: nonSubstantiative > 0 ? nonSubstantiative : 0,
  };
  return (
    <div className={styles.visualDisplay}>
      {Object.entries(satisfactionOptions).map(
        ([satisfactionKey, satisfactionOption]) =>
          !!satisfactionOption && (
            <span
              className={`${styles[satisfactionKey]} ${satisfactionOption === 100 ? styles.fullWidth : ''}`}
              style={{ width: calculateWidth(satisfactionOption) }}
              key={satisfactionKey}
            />
          ),
      )}
    </div>
  );
};
