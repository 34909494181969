import { mockLeanClient } from 'mocks/data/client.mock';
import { ClassificationSubTopic, SurveyType } from '@zencity/survey-types';

export const mockClassificationSubTopics: ClassificationSubTopic[] = [
  {
    id: 1,
    value: 'Sub Topic 1',
    classification_topic: {
      id: 1,
      value: 'Topic 1',
    },
    survey_type: SurveyType.BLOCKWISE,
    client: mockLeanClient,
  },
  {
    id: 2,
    value: 'Sub Topic 2',
    classification_topic: {
      id: 1,
      value: 'Topic 1',
    },
    survey_type: SurveyType.BLOCKWISE,
    client: mockLeanClient,
  },
  {
    id: 3,
    value: 'Sub Topic 3',
    classification_topic: {
      id: 2,
      value: 'Topic 2',
    },
    survey_type: SurveyType.BLOCKWISE,
    client: mockLeanClient,
  },
  {
    id: 4,
    value: 'Sub Topic 4',
    classification_topic: {
      id: 2,
      value: 'Topic 2',
    },
    survey_type: SurveyType.BLOCKWISE,
    client: mockLeanClient,
  },
  {
    id: 5,
    value: 'Sub Topic 5',
    classification_topic: {
      id: 3,
      value: 'Topic 3',
    },
    survey_type: SurveyType.BLOCKWISE,
    client: mockLeanClient,
  },
];
