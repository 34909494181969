import React, { ReactElement, useContext } from 'react';
import { liveIconSvg } from 'assets/svgIcons';
import useFeedDateRangeLabel from 'hooks/feed/dateRangeLabel';
import { FeatureFlag } from 'types/featureFlag';
import { userHasCorePlatformFeature } from 'utils/user';
import { AccountsContext } from 'contexts/AccountsContext';
import styles from './FeedDateRangeLabel.module.scss';

interface Props {
  children?: React.ReactNode;
}

const FeedDateRangeLabel = (props: Props): ReactElement => {
  const { children } = props;
  const { user, client } = useContext(AccountsContext);

  const { dateRangeLabel, liveIcon } = useFeedDateRangeLabel();
  const hasLondonMetDashboardAccess = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);
  return (
    <div className={styles.container}>
      <div>
        {children}
        <p className={styles.label}>{dateRangeLabel}</p>
      </div>
      {!hasLondonMetDashboardAccess && liveIcon && liveIconSvg}
    </div>
  );
};

export default FeedDateRangeLabel;
