import React, { ReactElement } from 'react';
import styles from '../Answer.module.scss';
import { createTopicComponentList } from './helpers/topicList';

interface Props {
  topics: (string | undefined)[];
}

const TopicList = ({ topics }: Props): ReactElement => {
  if (!topics?.length) {
    return <></>;
  }
  return <div className={styles.topicList}>{createTopicComponentList(topics as string[])}</div>;
};
export default TopicList;
