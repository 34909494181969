/* eslint-disable id-denylist */
import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Tooltip } from 'chart.js';
import { GraphEmptyState } from 'components/ScoreCardWidget/components/GraphEmptyState/GraphEmptyState';
import styles from 'components/ScoreCardWidget/components/OverallScoreGraph/OverallScoreGraph.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
  getCXScoreChartChartConfig,
  getDateDataForTrend,
  makeGranularBreakdown,
  makeScoreLabelFromDate,
} from 'screens/customer-experience/helpers';
import { CxScoreTrend } from 'types/cx_survey';
import { ChartJsDataset } from 'utils/chartWidget';

Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Tooltip);

interface Props {
  trendData: CxScoreTrend;
}

export const CXOverallScoreGraph: React.FC<Props> = (props: Props) => {
  const { trendData } = props;
  const { start_date: startDate, end_date: endDate, breakdown } = trendData;
  const [hasError, setHasError] = useState(false);
  const chartConfig = getCXScoreChartChartConfig();
  const dateDataForTrend = getDateDataForTrend({ startDate, endDate });
  const { startDate: parsedStartDate, endDate: parsedEndDate, granularity, labelDateFormat } = dateDataForTrend;
  const dataExists = Object.keys(trendData).length > 0;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (canvasRef.current && dataExists) {
      const chartData = () => {
        const scoreTrend = makeGranularBreakdown(breakdown, granularity);
        const datasets: ChartJsDataset[] = [
          {
            label: '',
            data: scoreTrend,
            backgroundColor: styles.green,
            borderColor: styles.green,
            pointRadius: 3,
          },
        ];
        const labels = Array(scoreTrend.length).fill(['', '']);
        try {
          labels[0] = makeScoreLabelFromDate(parsedStartDate, labelDateFormat).split(' ');
          labels[labels.length - 1] = makeScoreLabelFromDate(parsedEndDate, labelDateFormat).split(' ');
        } catch (error) {
          setHasError(true);
        }
        return { labels, datasets };
      };
      const config = {
        ...chartConfig,
        data: chartData(),
      };
      chartRef.current = new Chart(canvasRef.current, config);
      return () => {
        chartRef.current?.destroy();
      };
    }
  }, [chartConfig, dataExists, breakdown, granularity, parsedStartDate, parsedEndDate, labelDateFormat]);

  return (
    <>
      {hasError && <GraphEmptyState />}
      {!hasError && (
        <div className={styles.overallScoreGraph}>
          <canvas ref={canvasRef} />
        </div>
      )}
    </>
  );
};
