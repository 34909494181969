import classnames from 'classnames';
import { OvertimeTrendsIndication } from 'components/OvertimeTrendsIndication/OvertimeTrendsIndication';
import { Spacer } from 'components/Spacer/Spacer';
import React, { ReactElement } from 'react';
import { SatisfactionScore, ScoreDisplay } from 'src/types/score';
import { PercentageDisplay, PercentageSize } from './components/PercentageDisplay';
import { VisualDisplay } from './components/VisualDisplay';
import styles from './SatisfactionDistribution.module.scss';

interface Props extends ScoreDisplay {
  comparisonScore?: SatisfactionScore | null;
  percentageTitle?: string;
  totalSubmissionsToCompare?: number;
  hideOvertimeTrendsIndication?: boolean;
}

export const SatisfactionDistribution: React.FC<Props> = (props: Props): ReactElement => {
  const {
    positive,
    negative,
    neutral,
    className,
    noBar = false,
    percentageSize = PercentageSize.REGULAR,
    comparisonScore,
    totalSubmissionsToCompare,
    percentageTitle,
    hideOvertimeTrendsIndication,
  } = props;

  return (
    <div className={classnames(styles.satisfactionDistribution, className)}>
      <PercentageDisplay positivePercentage={positive} size={percentageSize as PercentageSize} />
      {percentageTitle && <span>{percentageTitle}</span>}
      {!percentageTitle && <Spacer orientation="horizontal" size="xsmall" />}
      {!hideOvertimeTrendsIndication && (
        <OvertimeTrendsIndication
          currentScore={{
            positive,
            negative,
            neutral,
          }}
          comparisonScore={comparisonScore}
          totalSubmissionsToCompare={totalSubmissionsToCompare}
        />
      )}
      <Spacer orientation={percentageTitle ? 'vertical' : 'horizontal'} size="xsmall" />
      {!noBar && <VisualDisplay positive={positive} negative={negative} neutral={neutral} />}
    </div>
  );
};
