/* eslint-disable max-statements */
import { DivisionWithPolygon, SurveyGroup } from '@zencity/survey-types';
import { DateRangeFilterOption } from 'contexts/DateRangeContext';
import { useEffect, useState } from 'react';
import { fetchDivisionsWithPolygons } from 'services/division';
import { fetchAreaScore, fetchOverallScore } from 'services/score';
import { AGGREGATED_OVERALL_SCORE_KEY, AreasResponse, AreasWithScoresObject } from 'types/areas';
import { logger } from 'utils/community-asks/logger';
import { getDashboardDateRanges, hasScoreDataForAnyDateRange } from 'utils/dateRange';
import { matchAreaWithScore } from 'utils/Map/AreaHelpers';

// eslint-disable-next-line max-lines-per-function
export const useGeographicData = (surveyGroup?: SurveyGroup) => {
  const [areasWithScores, setAreasWithScores] = useState<AreasWithScoresObject>({});
  const [sortedAreaIdsByAreaName, setSortedAreaIdsByAreaName] = useState<string[]>([]);
  const [divisions, setDivisions] = useState<DivisionWithPolygon[]>([]);
  const [mapsDataIsLoading, setMapsDataIsLoading] = useState<boolean>(false);
  const [selectedAreaIds, setSelectedAreaIds] = useState<string[]>([]);
  const [hoveredAreaId, setHoveredAreaId] = useState<string>('');

  const getAndSetAreaScores = (
    dateRangeFilterOptions: DateRangeFilterOption[],
    divisionsWithPolygons: DivisionWithPolygon[],
  ) => {
    if (!surveyGroup) return;
    Promise.all([fetchAreaScore(surveyGroup.client.id), fetchOverallScore(surveyGroup.client.id)])
      .then(([areaScores, overallScores]) => {
        const areas = divisionsWithPolygons.reduce<AreasResponse>((areasResponse, division) => {
          if (division.depth === 0) return areasResponse;

          return {
            ...areasResponse,
            [division.geo_targeting_ref.id]: {
              id: division.geo_targeting_ref.id,
              name: division.geo_targeting_ref.name,
              other: division.geo_targeting_ref.other,
              area: division.geo_targeting_ref.area,
            },
          };
        }, {});
        const areasWithScore = matchAreaWithScore({
          scoresByArea: areaScores,
          areas,
          overallScores,
          dateRangeOptionsLength: dateRangeFilterOptions.length,
        });
        const sortedAreaIds = Object.values(areasWithScore)
          .sort((area1, area2) => {
            // Keep 'overall_score' as the first item.
            if (area1.id === AGGREGATED_OVERALL_SCORE_KEY) return -1;
            if (area2.id === AGGREGATED_OVERALL_SCORE_KEY) return 1;
            return area1.name.localeCompare(area2.name, undefined, { numeric: true });
          })
          .map((areaWithScore) => areaWithScore.id);
        if (areasWithScore.overall_score) {
          setSelectedAreaIds([areasWithScore.overall_score.id]);
        }
        setSortedAreaIdsByAreaName(sortedAreaIds);
        setAreasWithScores(areasWithScore);
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const divisionsDictionary = divisions ? Object.fromEntries(divisions.map((division) => [division.id, division])) : {};

  useEffect(() => {
    async function fetchData() {
      try {
        setMapsDataIsLoading(true);
        if (surveyGroup) {
          const { scoreDateRangeFilterOptions } = await getDashboardDateRanges(surveyGroup);

          const divisionsWithPolygons = await fetchDivisionsWithPolygons({
            surveyType: surveyGroup.type,
          });
          setDivisions(divisionsWithPolygons);

          const hasFeedOnlyAccess = !hasScoreDataForAnyDateRange(scoreDateRangeFilterOptions);
          if (!hasFeedOnlyAccess) {
            getAndSetAreaScores(scoreDateRangeFilterOptions, divisionsWithPolygons);
          }
        }
      } catch (error) {
        logger.error(error);
      } finally {
        setMapsDataIsLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyGroup]);
  return {
    mapsDataIsLoading,
    setMapsDataIsLoading,
    areasWithScores,
    selectedAreaIds,
    setSelectedAreaIds,
    sortedAreaIdsByAreaName,
    divisionsDictionary,
    divisions,
    hoveredAreaId,
    setHoveredAreaId,
  };
};
