import path from 'utils/path';
import { createAxiosInstance } from 'utils/Api';
import { SurveyType, ClassificationSubTopic, VaultPaginatedResponse } from '@zencity/survey-types';
import { mockClassificationSubTopics } from 'mocks/data/classificationSubTopic';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.classificationSubTopics),
);

export const fetchClassificationSubTopics = async (
  surveyType: SurveyType,
  page?: number,
): Promise<VaultPaginatedResponse<ClassificationSubTopic>> => {
  if (config.shouldMockRequests) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          count: mockClassificationSubTopics.length,
          results: mockClassificationSubTopics,
        });
      }, 3000);
    });
  }
  const response = await axiosInstance.get(``, {
    params: {
      survey_type: surveyType,
      page_size: 100,
      ...(page && { page }),
    },
  });
  return response.data;
};
