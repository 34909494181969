import { useEffect, useState } from 'react';

// Invoke callbackFunction after timeout if the element is still hovered.
export function useTimeoutHover(
  elementRef: React.RefObject<HTMLDivElement>,
  callbackFunction: () => void,
  timeout: number,
) {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setIsHovering(false);
    };

    const handleMouseMove = () => {
      if (!isHovering) {
        setIsHovering(true);
      }
    };

    const timeoutId = setTimeout(() => {
      if (isHovering) {
        callbackFunction();
      }
    }, timeout);

    const element = elementRef.current;
    element?.addEventListener('mouseenter', handleMouseEnter);
    element?.addEventListener('mouseleave', handleMouseLeave);
    element?.addEventListener('mousemove', handleMouseMove);

    return () => {
      clearTimeout(timeoutId);
      element?.removeEventListener('mouseenter', handleMouseEnter);
      element?.removeEventListener('mouseleave', handleMouseLeave);
      element?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [elementRef, callbackFunction, isHovering, timeout]);
}
