/* eslint-disable i18next/no-literal-string */
import { BlockwiseSummaryTab } from 'components/BlockwiseSummaryTab/BlockwiseSummaryTab';
import { DemographicFilter } from 'components/DemographicFilter/DemographicFilter';
import { GeographicDivisionsFilter } from 'components/GeographicDivisionsFilter/GeographicDivisionsFilter';
import { Header } from 'components/Header/Header';
import { Map } from 'components/Map/Map';
import { NoScoresEmptyState } from 'components/NoScoresEmptyState/NoScoresEmptyState';
import { TabKeys } from 'constants/tabs';
import { AccountsContext } from 'contexts/AccountsContext';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { useScreenViewedEvent } from 'hooks/screenViewedEvent';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { OpenQuestionFeed } from 'screens/OpenQuestionFeed/OpenQuestionFeed';
import { FeatureFlag } from 'types/featureFlag';
import { ZencityRoutesProps } from 'types/router';
import { SurveyType, SurveyGroup } from '@zencity/survey-types';
import { logger } from 'utils/blockwise/logger';
import { hasScoreDataForAnyDateRange } from 'utils/dateRange';
import { autoRedirectState } from 'utils/router';
import { userHasCorePlatformFeature } from 'utils/user';
import { Analysis } from '../../components/Analysis/Analysis';
import '../../i18n';
import '../../index.scss';

interface Props extends ZencityRoutesProps {
  currentSurveyGroup: SurveyGroup;
}

const BASE_TABS = [TabKeys.FEED];
interface GenerateTabs {
  shouldShowMap: boolean;
  shouldShowSummaryTab: boolean;
}
function generateTabs({ shouldShowMap }: GenerateTabs) {
  const returnTabs = [];
  returnTabs.push(TabKeys.SUMMARY);
  if (shouldShowMap) {
    returnTabs.push(TabKeys.MAP);
  }
  returnTabs.push(TabKeys.ANALYSIS);
  return returnTabs.concat(BASE_TABS);
}
/*
 * This component generates the Routes for the BlockwiseDashboard.
 * We do this using the useRoutes hook with a RouteObject, instead of
 * with the <Routes><Route></Routes> elements format.
 *
 * See an example from the docs here: https://stackblitz.com/github/
 * remix-run/react-router/tree/main/examples/route-objects?file=src%2FApp.tsx
 *
 * Note that we use the useRoutes hook here instead of directly in the
 * BlockwiseDashboard component since the BrowserRouter component
 * needs to embed the element that uses useRoutes. It cannot use useRoutes
 * in the same component without throwing an error.
 */

export const BlockwiseDashboardRoutes = ({ basename, currentSurveyGroup }: Props): React.ReactElement | null => {
  const { dashboardDateRanges = [] } = useContext(DateRangeContext);
  const { user, client } = useContext(AccountsContext);
  const hasLondonMetDashboardAccess = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);
  const shouldShowSummaryTab = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE_SUMMARY_TAB);
  const hasFeedOnlyAccess = !hasScoreDataForAnyDateRange(dashboardDateRanges);
  const defaultTab = shouldShowSummaryTab ? TabKeys.SUMMARY : TabKeys.FEED;
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const shouldShowMap = true;
  const disableDemographicFilter = currentTab === TabKeys.MAP || currentTab === TabKeys.SUMMARY;

  useEffect(() => {
    if (window.location.href.includes(TabKeys.FEED)) {
      setCurrentTab(TabKeys.FEED);
    }
  }, []);

  // The Header component will render these tabs in the order they appear here.
  const tabs: TabKeys[] = generateTabs({
    shouldShowSummaryTab,
    shouldShowMap,
  });
  const routes: RouteObject[] = [
    {
      path: '/',
      element: (
        <Header
          tabs={tabs}
          currentSurveyGroup={currentSurveyGroup}
          headerIcon="large-smiley-face-cards"
          headerTitle={currentSurveyGroup.client.name}
          extraFilterComponents={[
            <DemographicFilter isDisabled={disableDemographicFilter} key="demographics" />,
            <GeographicDivisionsFilter surveyType={SurveyType.BLOCKWISE} key="divisions" />,
          ]}
          onTabSwitch={setCurrentTab}
          shouldDisplaySearchBar={hasLondonMetDashboardAccess && currentTab === TabKeys.FEED}
        />
      ),
      children: [
        { index: true, element: <Navigate to={defaultTab} state={autoRedirectState} /> },
        { path: TabKeys.SUMMARY, element: hasFeedOnlyAccess ? <NoScoresEmptyState /> : <BlockwiseSummaryTab /> },
        { path: TabKeys.FEED, element: <OpenQuestionFeed /> },
        shouldShowMap ? { path: TabKeys.MAP, element: hasFeedOnlyAccess ? <NoScoresEmptyState /> : <Map /> } : {},
        { path: TabKeys.ANALYSIS, element: hasFeedOnlyAccess ? <NoScoresEmptyState /> : <Analysis /> },
      ],
    },
    { path: '*', element: <div>404 - Page Not Found</div> },
  ];

  useScreenViewedEvent(routes, basename, logger);

  return useRoutes(routes);
};
