import { TypeformExample } from '@zencity/survey-types';
import { createAxiosInstance } from 'utils/Api';
import { logger } from 'utils/community-asks/logger';
import path from 'utils/path';
import { config } from '../../config/config';
import { mockPayload as surveyExamplesRequest } from '../mocks/data/surveyTypeformExample.mock';

const axiosInstance = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.typeformExamples),
);

/**
 * Get typeform example surveys for community asks
 * @return Promise<TypeformExample[]>
 */
export async function fetchAllTypeformExamples(): Promise<TypeformExample[]> {
  try {
    const response = await axiosInstance.get<TypeformExample[]>('');
    return response?.data ?? [];
  } catch (error) {
    if (config.shouldMockRequests) {
      return surveyExamplesRequest;
    }
    logger.error(error);
    throw error;
  }
}
