import React from 'react';
import { BreakdownSection } from './components/BreakdownSection/BreakdownSection';
import { NextSteps } from './components/NextStepsSection';
import { ScorecardSection } from './components/ScorecardSection/ScorecardSection';
import styles from './Summary.module.scss';

export const Summary = (): React.ReactElement => (
  <div className={styles.screen}>
    <main className={styles.summary}>
      <ScorecardSection />
      <BreakdownSection />
      <NextSteps />
    </main>
  </div>
);
