import { Button, Card, Icon } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { analyticsService } from 'services/analytics/analytics';
import styles from './CustomRequestCard.module.scss';

/**
 * This card will only appear in the TypeformExampleCardList
 * when there is at least 1 Community Asks survey request.
 */
export const CustomRequestCard: React.FC = function CustomRequestCard(): ReactElement {
  const { t: translate } = useTranslation();
  // eslint-disable-next-line i18next/no-literal-string
  const baseTranslationPath = 'communityAsks.customRequestCard';

  return (
    <Link
      to={CommunityAsksRouterPaths.CUSTOM_REQUEST}
      onClick={() => analyticsService.communityAsksEvents.requestCustomClicked()}
    >
      <Card.Wrapper className={styles.wrapperOverride}>
        <div className={styles.customRequestCard}>
          <Icon className={styles.icon} name="survey-data.svg" width={51} height={50} />
          <div className={styles.content}>
            <h4>{translate(`${baseTranslationPath}.heading`)}</h4>
            <p className={styles.description}>{translate(`${baseTranslationPath}.description`)}</p>
          </div>
          <Button>{translate(`communityAsks.requestButton`)}</Button>
        </div>
      </Card.Wrapper>
    </Link>
  );
};
