import React, { ReactElement } from 'react';
import { Box } from '@zencity/common-ui';
import styles from './CXDashboardBox.module.scss';

interface Props {
  articleClassName: string;
  boxClassName: string;
  title: string;
  children: React.ReactNode;
  isTitleLarge?: boolean;
}

export const CXDashboardBox: React.FC<Props> = function CXDashboardBox(props: Props): ReactElement {
  const { articleClassName, boxClassName, children, isTitleLarge = false, title } = props;
  return (
    <article className={articleClassName}>
      <Box.Wrapper className={boxClassName}>
        <Box.Header>{isTitleLarge ? <h2>{title}</h2> : <h3>{title}</h3>}</Box.Header>
        <Box.Content className={styles.boxContent}>{children}</Box.Content>
      </Box.Wrapper>
    </article>
  );
};
