/* eslint-disable no-continue,complexity,no-plusplus,max-statements,no-param-reassign */
const extractScheme = function extractScheme(path: string): { scheme: string; path: string } {
  if (path.indexOf('http://') === 0) {
    return { scheme: 'http://', path: path.slice(7) };
  }

  if (path.indexOf('https://') === 0) {
    return { scheme: 'https://', path: path.slice(8) };
  }

  return { scheme: '', path };
};

/**
 * Turns a long path to a normalized UNIX-like path.
 * @param rawPath - full path, like "/foo/bar/baz/../../lol/" or "lol/./bar/foo/"
 * @returns a normalized string like "/foo/lol" or "lol/bar/foo"
 */
const normalize = function normalize(path: string): string {
  const parts = path.split('/');
  let normalizedPath = '';
  const filteredParts = [];

  for (let index = 0; index < parts.length; index++) {
    const part = parts[index];

    if (part === '' || part === '.') {
      continue;
    } else if (part === '..') {
      const prevPart = filteredParts[filteredParts.length - 1];
      if (prevPart !== undefined && prevPart !== '..') {
        filteredParts.pop();
        continue;
        // in any other case, ".." will be preserved
      }
    }

    filteredParts.push(part);
  }

  if (path[0] === '/') {
    normalizedPath += '/';
  }
  normalizedPath += filteredParts.join('/');

  return normalizedPath;
};

/**
 * Joins several strings into one UNIX-like path.
 * @param paths (arguments) - all parts of a path, like: ('foo','/bar','..','/lol/')
 * @returns a normalized string like "foo/lol"
 */
const join = function join(...paths: string[]): string {
  return normalize(paths.join('/'));
};

/**
 * Joins several strings into one URL.
 * Notice: trailing slash will be preserved.
 * @param ...arguments - all parts of a URL, like: ('https://foo','/bar','baz/','/lol/')
 * @returns a normalized string like "https://foo/bar/baz/lol/"
 */
const joinURL = function joinURL(...paths: string[]): string {
  const { scheme, path: firstPath } = extractScheme(paths[0]);
  if (firstPath) {
    paths[0] = firstPath;
  } else {
    paths.shift();
  }

  let url = scheme + normalize(paths.join('/'));

  const lastChar = paths[paths.length - 1].slice(-1);
  if (lastChar === '/') {
    url += '/';
  }

  return url;
};

export default {
  normalize,
  join,
  joinURL,
};
