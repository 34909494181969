import { Box, HorizontalSeparator, ZCDSpinner } from '@zencity/common-ui';
import { AnalyticEventsChangeTypes, AnalyticWidgetNames } from 'constants/analytics';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import '../../i18n';
import { ScorePerDateRange } from 'types/score';
import BreakdownRow from '../BreakdownRow';

import styles from './BreakdownWidget.module.scss';

export interface BreakdownRowContent extends Omit<ScorePerDateRange, 'startDate' | 'endDate'> {
  text: string;
  comparisonScore?: Omit<ScorePerDateRange, 'startDate' | 'endDate'>;
}
interface Props {
  showFooter?: boolean;
  headerText: React.ReactNode;
  rowItems: BreakdownRowContent[];
  isLoading: boolean;
  rowAmount?: number;
  headerLink?: {
    translationKey: string;
    path: string;
  };
  onToggleAll?: (value: boolean) => void;
  showUnrepresentativeIndicator?: boolean;
}

export const BreakdownWidget = (props: Props): ReactElement => {
  const {
    showFooter = false,
    headerText,
    rowItems,
    isLoading,
    rowAmount = 3,
    headerLink,
    onToggleAll,
    showUnrepresentativeIndicator,
  } = props;
  const [rowsToDisplay, setRowsToDisplay] = useState([] as BreakdownRowContent[]);
  const [isShowingAll, setIsShowingAll] = useState(false);
  useEffect(() => {
    if (rowItems.length) {
      setRowsToDisplay(rowItems.slice(0, rowAmount));
    }
  }, [rowItems, rowAmount]);
  const hasError = !isLoading && !rowsToDisplay.length;

  const { t: translate } = useTranslation();

  const toggleAll = () => {
    if (typeof onToggleAll === 'function') {
      onToggleAll(!isShowingAll);
    }
    if (isShowingAll) {
      setRowsToDisplay(rowItems.slice(0, rowAmount));
      setIsShowingAll(false);
    } else {
      setRowsToDisplay([...rowItems]);
      setIsShowingAll(true);
    }

    analyticsService.crossProductEvents.widgetExpanded({
      widgetName: AnalyticWidgetNames.DEMOGRAPHIC_BREAKDOWN_WIDGET,
      changeType: isShowingAll ? AnalyticEventsChangeTypes.COLLAPSE : AnalyticEventsChangeTypes.EXPAND,
    });
  };

  return (
    <Box.Wrapper className={styles.breakdownWidget}>
      <Box.Header className={styles.header}>
        {headerText}
        {headerLink && <Link to={headerLink.path}>{translate(headerLink.translationKey)}</Link>}
      </Box.Header>
      <Box.Content
        className={`${styles.content} ${isLoading ? styles.loading : ''} ${isShowingAll ? styles.scrollable : ''}`}
      >
        {hasError && <h5 className={styles.errorText}>{translate('breakdownWidget.error')}</h5>}
        {isLoading ? (
          <ZCDSpinner />
        ) : (
          <ol>
            {rowsToDisplay.map((rowItem, index) => (
              <React.Fragment key={index.toString()}>
                <BreakdownRow
                  text={rowItem.text}
                  satisfactionScore={rowItem.scores}
                  totalSubmissions={rowItem.totalSubmissions}
                  showUnrepresentativeIndicator={showUnrepresentativeIndicator}
                  comparisonScore={rowItem.comparisonScore?.scores}
                  totalSubmissionsToCompare={rowItem.comparisonScore?.totalSubmissions}
                />
                {index < rowsToDisplay.length - 1 && <HorizontalSeparator />}
              </React.Fragment>
            ))}
          </ol>
        )}
      </Box.Content>
      {showFooter && (
        <Box.Footer className={styles.footer}>
          <button onClick={toggleAll} type="button">
            <span className={styles.arrowContainer}>
              <span className={`${styles.arrow} ${isShowingAll ? styles.up : styles.down}`} />
            </span>
            {isShowingAll ? translate('breakdownWidget.showLess') : translate('breakdownWidget.showMore')}
          </button>
        </Box.Footer>
      )}
    </Box.Wrapper>
  );
};
