import { DateRangeContext, DateRangeFilterOption } from 'contexts/DateRangeContext';
import { useContext, useEffect, useState } from 'react';
import { isScoreLatestResult } from 'utils/dateRange';

const useCompareDateRangeScoreLabel = (): { comparisonDateRangeLabel: string } => {
  const { completedCyclesDateRanges, comparisonDateRange } = useContext(DateRangeContext);
  const [comparisonDateRangeLabel, setComparisonDateRangeLabel] = useState<string>('');

  // gets the date range label for the selected comparison date range
  useEffect(() => {
    if (!completedCyclesDateRanges || !comparisonDateRange || completedCyclesDateRanges.length === 0) {
      return;
    }

    const isLatestResult = isScoreLatestResult(comparisonDateRange);
    if (isLatestResult) {
      const latestCompletedCycle = (completedCyclesDateRanges as DateRangeFilterOption[])?.[0];
      setComparisonDateRangeLabel(latestCompletedCycle.label);
    } else {
      setComparisonDateRangeLabel(comparisonDateRange.label);
    }
  }, [completedCyclesDateRanges, comparisonDateRange]);

  return { comparisonDateRangeLabel };
};

export default useCompareDateRangeScoreLabel;
