/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable id-denylist,i18next/no-literal-string,react-hooks/exhaustive-deps,max-nested-callbacks */
import { MapsContext } from 'contexts/MapsContext';
import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
// note - chartjs is tree shakable. if any more elements are needed to be imported then they'll be found at:
// https://www.chartjs.org/docs/latest/getting-started/integration.html
import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement } from 'chart.js';
import { GraphEmptyState } from 'components/ScoreCardWidget/components/GraphEmptyState/GraphEmptyState';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { chartConfig, ChartJsDataset, convertDateRangeToLabels } from '../../../utils/chartWidget';
import styles from './OverallScoreByArea.module.scss';

Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale);

/**
 * Map graph chart component.
 */
export const OverallScoreByArea: React.FC = function OverallScoreByArea(): ReactElement {
  const { areasWithScoresData, selectedAreaIds } = useContext(MapsContext);
  const { clientCadence, completedCyclesDateRanges } = useContext(DateRangeContext);
  const [hasError, setHasError] = useState(false);

  const chartData = useMemo(() => {
    // An array of dateRange labels. If an item is a string then it will be a plain label,
    // but if an item is another array then the sub-items will act as a multiline label.
    const labelsForXAxis: string[][] = [];

    // Get the start date and end date of the period from any valid area to use as X axis labels.
    // A valid area is any area that matches the core platform areas.
    const validAreas = Object.values(areasWithScoresData).filter((areaWithScore) =>
      areaWithScore.scoresPerPeriod.some((score) => score.endDate !== ''),
    );
    if (validAreas.length) {
      try {
        const validArea = validAreas[0];
        // Slicing the data to only show the last 6 cycles.
        validArea.scoresPerPeriod.slice(-6).forEach((scorePerPeriod) => {
          const { startDate, endDate } = scorePerPeriod;
          const dateLabel = convertDateRangeToLabels({ startDate, endDate }, clientCadence as string).split(' ');
          labelsForXAxis.push(dateLabel);
        });
      } catch (error) {
        setHasError(true);
      }
    }

    const datasets = [] as ChartJsDataset[];

    selectedAreaIds.forEach((areaId) => {
      const areaWithScore = areasWithScoresData[areaId];
      const scoreData: number[] = [];

      // we will show only positive scores on the graph
      areaWithScore?.scoresPerPeriod?.forEach((scorePerPeriod) => {
        scoreData.push(scorePerPeriod.scores ? scorePerPeriod?.scores?.positive : 0);
      });

      datasets.push({
        label: areaWithScore?.name,
        // The scores data is from most recent to least recent,
        // so the score data is reversed to display chronilogiaclly on the chart.
        data: scoreData.reverse(),
        backgroundColor: areaWithScore?.color,
        borderColor: areaWithScore?.color,
        pointRadius: 3,
      });
    });

    // The scores data is from most recent to least recent,
    // so the labels data is reversed to display chronilogiaclly on the chart.
    labelsForXAxis.reverse();

    return { labels: labelsForXAxis, datasets };
  }, [areasWithScoresData, selectedAreaIds]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (canvasRef.current) {
      const config = {
        ...chartConfig,
        data: chartData, // eslint-disable-line id-denylist
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current = new Chart(canvasRef.current, config);

      return () => {
        chartRef.current?.destroy();
      };
    }
  }, [chartData]);

  if (!clientCadence) {
    return <></>;
  }

  return (
    <div className={styles.overallScoreByArea}>
      {hasError || (completedCyclesDateRanges && completedCyclesDateRanges?.length < 2) ? (
        <GraphEmptyState />
      ) : (
        <canvas ref={canvasRef} />
      )}
    </div>
  );
};
