import React from 'react';
import { CXDashboardBox } from 'screens/customer-experience/Dashboard/components/CXDashboardBox';
import { CXSatisfactionScoreCard } from 'screens/customer-experience/Dashboard/components/CXSatisfactionScoreCard';
import { CxDashboardData, CxDashboardDataKeys, CxSurveyAspectScore } from 'types/cx_survey';
import styles from '../Dashboard.module.scss';

export const DashboardBottomSection = ({ dashboardData }: { dashboardData?: CxDashboardData }): React.ReactElement => {
  const aspects = Object.entries(dashboardData || {})
    .filter(
      ([key]) =>
        key !== CxDashboardDataKeys.SUBMISSIONS_COUNT &&
        key !== CxDashboardDataKeys.FILTERED_SUBMISSIONS_COUNT &&
        key !== CxDashboardDataKeys.PERFORMANCE &&
        key !== CxDashboardDataKeys.SERVICE_TYPES,
    )
    .map(([, aspectData]) => ({
      aspectData: aspectData as CxSurveyAspectScore,
    }));

  return (
    <section>
      {aspects.map(({ aspectData }) =>
        aspectData.score ? (
          <CXDashboardBox articleClassName={styles.rating} boxClassName={styles.ratingBox} title={aspectData.label}>
            <CXSatisfactionScoreCard scoreData={aspectData.score} description={aspectData.sentence} />
          </CXDashboardBox>
        ) : (
          <></>
        ),
      )}
    </section>
  );
};
