/* eslint-disable max-lines */
/* eslint-disable i18next/no-literal-string, max-statements */
import { ZCDSpinner } from '@zencity/common-ui';
import { Answer, SurveyType } from '@zencity/survey-types';
import { DashboardContentEmptyState } from 'components/DashboardContentEmptyState/DashboardContentEmptyState';
import { FeedEmptyState } from 'components/FeedEmptyState/FeedEmptyState';
import LeadingTopicsWidget from 'components/LeadingTopicsWidget';
import { FiltersContext } from 'contexts/FiltersContext';
import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import { SurveyContext } from 'contexts/SurveyContext';
import useClassificationSubTopicsFilter from 'hooks/feed/classificationSubTopicsFilter';
import useFeedDateRangeFilter from 'hooks/feed/dateRangeFilter';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { ReactElement, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import AnswerList from 'screens/OpenQuestionFeed/components/AnswerList';
import Question from 'screens/OpenQuestionFeed/components/Question/Question';
import QuestionMenu from 'screens/OpenQuestionFeed/components/QuestionMenu';
import styles from 'screens/OpenQuestionFeed/OpenQuestionFeed.module.scss';
import { ActionTypes, initialData, reducer, SurveyReducer } from 'screens/OpenQuestionFeed/state';
import { analyticsService } from 'services/analytics/analytics';
import { fetchAllResponsesOfQuestion } from 'services/answer';
import { fetchLeadingTopics } from 'services/leadingTopics';
import { AnswerFilterParams } from 'types/answer';
import { LeadingTopicFilterParams } from 'types/leadingTopics';
import { ExtraFiltersParams } from 'types/misc';
import { SurveyGroupOutletContext } from 'types/surveyGroup';
import { mapFilters } from 'utils/blockwise/filters';
import { logger as blockwiseLogger } from 'utils/blockwise/logger';
import { logger as communitySurveyLogger } from 'utils/community-survey/logger';
import { QUESTION_FEED_SCROLLABLE_DIV_ID } from './components/AnswerList/AnswerList';

const SURVEY_TYPES_LEADING_TOPICS = [SurveyType.COMMUNITY_SURVEY, SurveyType.BLOCKWISE];
const SURVEY_TYPES_SUPPORT_FEED_DATERANGE_FILTER = [
  SurveyType.COMMUNITY_SURVEY,
  SurveyType.BLOCKWISE,
  SurveyType.PULSE,
];

const TEN_SECONDS = 10000;

// eslint-disable-next-line max-lines-per-function
export const OpenQuestionFeed = (): ReactElement => {
  const [state, dispatch] = useReducer<SurveyReducer>(reducer, initialData);
  const { loading, leadingTopics } = state;

  const [answersFeed, setAnswersFeed] = useState<Answer[] | null>(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);

  const { dateRangeFilter } = useFeedDateRangeFilter();
  const classificationSubTopicsFilter = useClassificationSubTopicsFilter();

  const { selectedDemographics, selectedDivisionIds, answerSearchTerm } = useContext(FiltersContext);
  const { surveyGroup, extraFiltersParams } = useOutletContext<SurveyGroupOutletContext & ExtraFiltersParams>();
  const { openEndedQuestions, areOpenEndedQuestionsLoading } = useContext(OpenEndedQuestionFeedContext);
  const activeFilters = useFilterNames();

  const getLeadingTopics = useCallback(
    async (genericQuestionId: number) => {
      if (SURVEY_TYPES_LEADING_TOPICS.includes(surveyGroup.type)) {
        try {
          dispatch({ type: ActionTypes.SET_LOADING_LEADING_TOPICS, payload: true });
          const { mappedDemographics, mappedDivisions } = mapFilters(selectedDemographics, selectedDivisionIds);

          const leadingTopicsFilters: LeadingTopicFilterParams = {
            generic_question: genericQuestionId,
            survey_group: surveyGroup.id,
            ...(dateRangeFilter && { ...dateRangeFilter }),
            ...mappedDemographics,
            divisions: mappedDivisions,
          };

          const leadingTopicsData = await fetchLeadingTopics(leadingTopicsFilters);
          dispatch({ type: ActionTypes.SET_LEADING_TOPICS, payload: leadingTopicsData });
        } catch (error) {
          if (surveyGroup.type === SurveyType.COMMUNITY_SURVEY) {
            communitySurveyLogger.error(`Error fetching leading topics: ${error}`);
          } else if (surveyGroup.type === SurveyType.BLOCKWISE) {
            blockwiseLogger.error(`Error fetching leading topics: ${error}`);
          }
        } finally {
          dispatch({ type: ActionTypes.SET_LOADING_LEADING_TOPICS, payload: false });
        }
      }
    },
    [dateRangeFilter, surveyGroup, selectedDemographics, selectedDivisionIds],
  );

  const selectQuestion = useCallback(
    async (questionIndex: number, questionId?: number) => {
      dispatch({ type: ActionTypes.SET_CURRENT_PAGE, payload: 1 });
      dispatch({ type: ActionTypes.SET_LOADING, payload: true });
      setSelectedQuestionIndex(questionIndex);
      const genericQuestionId = questionId ?? openEndedQuestions[questionIndex].generic_question;
      const isFirstLoad = questionId !== undefined;
      const { mappedDemographics, mappedDivisions } = mapFilters(selectedDemographics, selectedDivisionIds);

      const answersFilterParams: AnswerFilterParams = {
        generic_question: genericQuestionId,
        page: 1,
        survey_group: surveyGroup.id,
        divisions: mappedDivisions,
        value: answerSearchTerm,
        ...mappedDemographics,
        ...dateRangeFilter,
        ...classificationSubTopicsFilter,
        ...extraFiltersParams,
      };

      dispatch({ type: ActionTypes.SET_CURRENT_QUESTION, payload: questionIndex });
      if (!isFirstLoad) {
        if (surveyGroup.type === SurveyType.COMMUNITY_ASKS) {
          analyticsService.communityAsksEvents.asksFeedQuestionSelected(
            genericQuestionId,
            openEndedQuestions[questionIndex].text,
          );
        }
      }
      await getLeadingTopics(genericQuestionId);
      try {
        const { items: answers, count: total } = await fetchAllResponsesOfQuestion(answersFilterParams);
        setAnswersFeed(answers);
        dispatch({ type: ActionTypes.SET_ANSWERS, payload: { answers, totalAnswers: total } });
      } catch (error) {
        // TODO: handle errors
        console.error(error);
      } finally {
        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
      }
    },
    [
      openEndedQuestions,
      surveyGroup.id,
      surveyGroup.type,
      dateRangeFilter,
      extraFiltersParams,
      selectedDemographics,
      selectedDivisionIds,
      answerSearchTerm,
      classificationSubTopicsFilter,
      getLeadingTopics,
    ],
  );

  const fetchNextPageOfAnswers = useCallback(async () => {
    const { currentPage, currentQuestion } = state;
    const { generic_question: genericQuestionId } = openEndedQuestions[currentQuestion];
    const nextPage = currentPage + 1;
    const { mappedDemographics, mappedDivisions } = mapFilters(selectedDemographics, selectedDivisionIds);

    const answersFilterParams = {
      generic_question: genericQuestionId,
      page: nextPage,
      survey_group: surveyGroup.id,
      divisions: mappedDivisions,
      ...{ value: answerSearchTerm },
      ...mappedDemographics,
      ...dateRangeFilter,
      ...classificationSubTopicsFilter,
      ...extraFiltersParams,
    } as AnswerFilterParams;

    try {
      const { items } = await fetchAllResponsesOfQuestion(answersFilterParams);

      dispatch({ type: ActionTypes.APPEND_ANSWERS, payload: items });
      dispatch({ type: ActionTypes.SET_CURRENT_PAGE, payload: nextPage });
    } catch (error) {
      console.error(error);
    }
  }, [
    state,
    openEndedQuestions,
    selectedDemographics,
    selectedDivisionIds,
    surveyGroup.id,
    answerSearchTerm,
    dateRangeFilter,
    classificationSubTopicsFilter,
    extraFiltersParams,
  ]);

  useEffect(() => {
    async function fetchAnswers(): Promise<void> {
      if (!dateRangeFilter && SURVEY_TYPES_SUPPORT_FEED_DATERANGE_FILTER.includes(surveyGroup.type)) return;
      try {
        if (openEndedQuestions.length) {
          const relevantQuestion = openEndedQuestions?.[selectedQuestionIndex];
          const { generic_question: genericQuestion } = relevantQuestion;
          await selectQuestion(selectedQuestionIndex, genericQuestion);
        } else if (!openEndedQuestions.length && !areOpenEndedQuestionsLoading) {
          // Clear the store.
          dispatch({ type: ActionTypes.SET_ANSWERS, payload: { answers: [], totalAnswers: 0 } });
          dispatch({ type: ActionTypes.SET_LEADING_TOPICS, payload: [] });
          dispatch({ type: ActionTypes.SET_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openEndedQuestions,
    areOpenEndedQuestionsLoading,
    extraFiltersParams,
    selectedDemographics,
    selectedDivisionIds,
    answerSearchTerm,
    classificationSubTopicsFilter,
    dateRangeFilter,
  ]);

  const lastScrollTime = useRef(0);
  const handleAnswersScroll = () => {
    const currentScrollTime = Date.now();
    if (currentScrollTime - lastScrollTime.current > TEN_SECONDS) {
      analyticsService.crossProductEvents.feedScrolled({
        activeFilters,
      });
      lastScrollTime.current = currentScrollTime;
    }
  };

  const hideAnswers =
    (selectedDemographics || selectedDivisionIds || answerSearchTerm) && answersFeed && answersFeed.length === 0;

  const displayLeadingTopicsWidget = leadingTopics.length > 0;
  if (openEndedQuestions.length === 0 && areOpenEndedQuestionsLoading === false) {
    return <FeedEmptyState />;
  }
  return (
    <SurveyContext.Provider value={{ ...state, selectQuestion, fetchNextPageOfAnswers }}>
      <div className={styles.feedWrapper}>
        <QuestionMenu />

        {loading ? (
          <div className={styles.loader}>
            <ZCDSpinner size={32} />
          </div>
        ) : (
          <>
            {!hideAnswers ? (
              <>
                <div
                  className={styles.openQuestionFeed}
                  id={QUESTION_FEED_SCROLLABLE_DIV_ID}
                  onScroll={handleAnswersScroll}
                >
                  <Question />
                  <AnswerList />
                </div>
                {displayLeadingTopicsWidget && (
                  <div className={styles.leadingTopicsWrapper}>
                    <div className={styles.topSpacer} />
                    <LeadingTopicsWidget leadingTopics={leadingTopics} isLoading={state.loadingLeadingTopics} />
                  </div>
                )}
              </>
            ) : (
              <DashboardContentEmptyState />
            )}
          </>
        )}
      </div>
    </SurveyContext.Provider>
  );
};
