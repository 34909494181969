import { DemographicInfo } from '@zencity/survey-types';

function getDefaultLabel(field: string): string {
  return `${field} not provided`;
}

function isDemographicsIsEmpty(demographicInfo: DemographicInfo): boolean {
  const { ethnicity, gender, age_group: ageGroup } = demographicInfo;
  if ((ethnicity && ethnicity.length) || gender || ageGroup) {
    return false;
  }
  return true;
}

export function generateDemographicsDisplayLabel(demographicInfo: DemographicInfo): string {
  if (isDemographicsIsEmpty(demographicInfo)) return 'No demographics available';
  const ethnicityLabels = demographicInfo.ethnicity?.map((ethnicity) => ethnicity.label).join('/');
  const demographics = [
    demographicInfo.gender?.label || getDefaultLabel('Gender'),
    demographicInfo.age_group?.label || getDefaultLabel('Age'),
    ethnicityLabels || getDefaultLabel('Ethnicity'),
  ];
  const displayedDemographics = demographics.reduce(
    (accumulator: string, demographic, index, originalDemographics): string => {
      if (index !== originalDemographics.length - 1) {
        return accumulator.concat(`${demographic}, `);
      }
      return accumulator.concat(demographic);
    },
    '',
  );
  return displayedDemographics;
}
