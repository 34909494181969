import { groupQuestions } from 'mocks/data/groupQuestions.mock';
import { questionsSummary } from 'mocks/data/questionsSummary.mock';
import { Question, VaultPaginatedResponse } from '@zencity/survey-types';
import { ExtraFiltersParamsType } from 'types/misc';
import { QuestionFilterParams, QuestionSummaryData } from 'types/question';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstanceGroupedQuestions = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.groupedQuestions),
);

export async function fetchGroupedQuestions(params: QuestionFilterParams): Promise<Question[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve(groupQuestions);
  }
  const result = await axiosInstanceGroupedQuestions.get<VaultPaginatedResponse<Question>>('', { params });

  return result.data.results;
}

const axiosInstanceQuestions = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.questions),
);

export async function fetchQuestionsSummary(
  surveyGroupId: number,
  extraParams?: ExtraFiltersParamsType,
): Promise<QuestionSummaryData[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve(questionsSummary);
  }
  const response = await axiosInstanceQuestions.get<VaultPaginatedResponse<QuestionSummaryData>>('summary/', {
    params: {
      survey_group_id: surveyGroupId,
      ...extraParams,
    },
  });

  return response.data.results;
}
