import { ClassificationSubTopicsFilter } from 'components/ClassificationSubTopicsFilter/ClassificationSubTopicsFilter';
import FeedDateRangeLabel from 'components/FeedDateRangeLabel/FeedDateRangeLabel';
import { Spacer } from 'components/Spacer/Spacer';
import { AnalyticEventsChangeTypes, AnalyticTypePropertyValues } from 'constants/analytics';

import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import { SurveyContext } from 'contexts/SurveyContext';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MenuHeader from 'screens/OpenQuestionFeed/components/QuestionMenu/components/MenuHeader';
import MenuOption from 'screens/OpenQuestionFeed/components/QuestionMenu/components/MenuOption';
import { analyticsService } from 'services/analytics/analytics';
import styles from './QuestionMenu.module.scss';

const QuestionMenu = (): ReactElement => {
  const { currentQuestion: currentQuestionIndex, selectQuestion } = useContext(SurveyContext);
  const { openEndedQuestions: questions } = useContext(OpenEndedQuestionFeedContext);
  const { t: translate } = useTranslation();
  const activeFilters = useFilterNames();

  const handleClick = (index: number) => {
    selectQuestion(index);

    analyticsService.crossProductEvents.questionOrAspectSelected({
      type: AnalyticTypePropertyValues.QUESTION,
      aspectId: AnalyticEventsChangeTypes.IRRELEVANT,
      aspectText: AnalyticEventsChangeTypes.IRRELEVANT,
      isCrossTabMode: AnalyticEventsChangeTypes.IRRELEVANT,
      questionId: questions[index].id,
      questionText: questions[index].text,
      activeFilters,
    });
  };

  return (
    <div className={`${styles.verticalMenu} ${styles.fixed}`}>
      <FeedDateRangeLabel>
        <MenuHeader headerTitle={translate('questions.header')} />
      </FeedDateRangeLabel>
      <ClassificationSubTopicsFilter />
      <Spacer orientation="vertical" size="small" />
      <ul>
        {questions?.map((option, index) => (
          <li key={option.id}>
            <MenuOption
              isSelected={index === currentQuestionIndex}
              option={option}
              onClick={() => handleClick(index)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuestionMenu;
