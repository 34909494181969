import { useQuery } from 'react-query';

interface Props<T, K> {
  params: K;
  requestFunction: ((params: K) => Promise<T>) | (() => Promise<T>);
  enableRequest?: boolean;
  staleTime?: number;
  keepPreviousData?: boolean;
  queryKey: string;
  onSuccess?: (response: T) => void;
}

/*
 * Custom hook to use react-query for API calls, created to avoid code duplication,
 * to make it easier to change the library in the future, and set default values in one place.
 */
export function useCustomQuery<T, K>(props: Props<T, K>) {
  const {
    queryKey,
    params,
    requestFunction,
    enableRequest,
    // 10 Minutes stale time by default.
    staleTime = 600000,
    keepPreviousData = true,
    onSuccess,
  } = props;

  const {
    data: response,
    isLoading,
    isFetching,
    error,
  } = useQuery<T>([queryKey, params], () => requestFunction(params), {
    enabled: enableRequest,
    keepPreviousData,
    staleTime,
    onSuccess,
  });

  return { response, isLoading, isFetching, error };
}
