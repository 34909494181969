import { SurveyGroup } from '@zencity/survey-types';
import { CustomerExperienceContext } from 'contexts/CustomerExperienceContext';
import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import { useOpenEndedQuestions } from 'hooks/openEndedQuestions';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { CustomerExperienceRouterPaths, SurveyPageSubTabs } from 'screens/customer-experience/routerPaths';
import { ExtraFiltersParamsType } from 'types/misc';
import { autoRedirectState } from 'utils/router';
import { convertTimePeriodToDateRangeServerParams } from 'utils/time';
import { Header } from './components/Header/Header';

/**
 * Implementation of a single Survey's layout page.
 */
export const SurveyLayout = (): React.ReactElement => {
  const navigate = useNavigate();
  const { surveyGroups, cxServiceTypeIdFilterBy, cxTimePeriodFilterBy } = useContext(CustomerExperienceContext);
  const { surveyGroupId } = useParams<{ surveyGroupId: string }>();
  const [currentSurveyGroup, setCurrentSurveyGroup] = useState<SurveyGroup>();
  const [extraFiltersParams, setExtraFilterParams] = useState<ExtraFiltersParamsType>();
  const { openEndedQuestions, areOpenEndedQuestionsLoading } = useOpenEndedQuestions(currentSurveyGroup);

  useEffect(() => {
    setExtraFilterParams({
      ...(cxServiceTypeIdFilterBy && { cx_service_type: cxServiceTypeIdFilterBy }),
      ...(cxTimePeriodFilterBy && convertTimePeriodToDateRangeServerParams(cxTimePeriodFilterBy)),
    });
  }, [cxServiceTypeIdFilterBy, cxTimePeriodFilterBy]);

  /**
   * Navigate back to the "workspace" if the survey ID from the URL doesn't
   * belong to the current client.
   */
  useEffect(() => {
    if (!surveyGroupId || !surveyGroups) {
      return;
    }

    const potentialSurvey = surveyGroups.find((surveyGroup) => surveyGroup.id === parseInt(surveyGroupId));
    if (!potentialSurvey) {
      navigate(CustomerExperienceRouterPaths.WORKSPACE, { state: autoRedirectState });
    }

    setCurrentSurveyGroup(potentialSurvey);
  }, [surveyGroups, surveyGroupId, navigate]);

  return (
    <div>
      {currentSurveyGroup && (
        <OpenEndedQuestionFeedContext.Provider value={{ openEndedQuestions, areOpenEndedQuestionsLoading }}>
          <Header tabs={SurveyPageSubTabs} surveyGroup={currentSurveyGroup} />
          {/* Outlet is used to render the tabs as children components in React Router v6 */}
          <Outlet context={{ surveyGroup: currentSurveyGroup, extraFiltersParams }} />
        </OpenEndedQuestionFeedContext.Provider>
      )}
    </div>
  );
};
