import { Icon } from '@zencity/common-ui';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import { SurveyGroup } from '@zencity/survey-types';
import { formatDate } from 'utils/formatters';
import styles from './SurveyGroupTeaser.module.scss';

export interface Props {
  surveyGroup: SurveyGroup;
}

/**
 * The Community Asks Survey Request item, to be shown on the Workspace screen.
 *
 * @props surveyGroup - The Vault "survey group" object.
 */
export const SurveyGroupTeaser: React.FC<Props> = function SurveyGroupTeaser({ surveyGroup }: Props): ReactElement {
  const { t: translate } = useTranslation();

  return (
    <Link
      to={`/survey/${surveyGroup.id}`}
      className={styles.surveyGroupTeaser}
      onClick={() => analyticsService.customerExperienceEvents.viewAskPage(surveyGroup.title_for_display)}
    >
      <div className={styles.iconContainer}>
        <Icon name="topic-illustrations/text-bubbles.svg" width={83} height={53} />
      </div>
      <div className={styles.surveyGroupContent}>
        <h4 className={styles.requestTitle}>{surveyGroup.title_for_display}</h4>
        <div className={styles.statusDateContainer}>
          <p className={styles.updatedAt}>
            {`${translate('communityAsks.published')}: ${formatDate(surveyGroup.updated_at)}`}
          </p>
        </div>
      </div>
    </Link>
  );
};
