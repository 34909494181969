import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode;
  condition: boolean;
  link?: string;
  className?: string;
  onLinkClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

/**
 * Wrapper component that renders a Link based off of a condition,
 * otherwise renders a div. This can be used to make a whole UI item clickable and linkable.
 *
 * @param children - The wrapper's children.
 * @param condition - A boolean condition to determine if a link should be rendered.
 * @param className - The class for the wrapper.
 * @param link - Where to link to if the condition is true.
 * @param onLinkClick - An handler function triggered when the link is clicked.
 */
export const ConditionalLink: React.FC<Props> = function ConditionalLink(props: Props): ReactElement {
  const { children, condition, className, link, onLinkClick } = props;

  return condition && link ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link to={link} className={className} {...(onLinkClick && { onClick: onLinkClick })}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
};
