import React from 'react';
import { ToastsInit, ToastVariants } from '@zencity/common-ui';
import { useTranslation } from 'react-i18next';

interface CommunityAsksToastContextInterface {
  toastSuccess: (content?: string) => void;
  toastError: (content?: string) => void;
}

export const CommunityAsksToastContext = React.createContext<CommunityAsksToastContextInterface>(
  {} as CommunityAsksToastContextInterface,
);

interface CommunityAsksToastContextProviderProps {
  children: React.ReactNode;
}

export const CommunityAsksToastContextProvider: React.FunctionComponent<CommunityAsksToastContextProviderProps> = ({
  children,
}: CommunityAsksToastContextProviderProps) => {
  const { t: translate } = useTranslation();
  const { toastElements, toast } = ToastsInit();
  const AUTO_CLOSE_TIMER = 5;

  // Currently, all "Community Asks" forms should display the same messages,
  // thus, the generic implementation.
  const contextValue: CommunityAsksToastContextInterface = {
    toastSuccess: (content?) => {
      toast({
        content: content ?? translate('communityAsks.toastMessages.serverRequestSuccess'),
        variant: ToastVariants.SUCCESS,
        autoCloseTimer: AUTO_CLOSE_TIMER,
      });
    },
    toastError: (content?) => {
      toast({
        content: content ?? translate('communityAsks.toastMessages.serverRequestError'),
        variant: ToastVariants.ERROR,
        autoCloseTimer: AUTO_CLOSE_TIMER,
      });
    },
  };

  return (
    <CommunityAsksToastContext.Provider value={contextValue}>
      {children}
      {toastElements}
    </CommunityAsksToastContext.Provider>
  );
};
