import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyGroupTeaser } from 'screens/customer-experience/Workspace/components/SurveyGroupTeaser/SurveyGroupTeaser';
import { SurveyGroup } from '@zencity/survey-types';
import styles from './SurveyGroupList.module.scss';

interface Props {
  surveyGroups: SurveyGroup[];
}
/**
 * List the given Survey Group instances, as linked cards.
 */
export const SurveyGroupList: React.FC<Props> = function SurveyGroupList(props: Props): ReactElement {
  const { surveyGroups } = props;
  const { t: translate } = useTranslation();

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{translate('customerExperience.listHeading')}</h2>
      {surveyGroups.length > 0 ? (
        <div className={styles.surveysList}>
          {surveyGroups.map((surveyGroup) => (
            <SurveyGroupTeaser surveyGroup={surveyGroup} key={`survey-group-${surveyGroup.id}`} />
          ))}
        </div>
      ) : (
        <div>{translate('customerExperience.noSurveys')}</div>
      )}
    </div>
  );
};
