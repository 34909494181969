/* eslint-disable max-statements */
/* eslint-disable i18next/no-literal-string */
import { Button } from '@zencity/common-ui';
import { SuperAdminBadge } from 'components/SuperAdminBadge/SuperAdminBadge';
import { AccountsContext } from 'contexts/AccountsContext';
import { CommunityAsksToastContext } from 'contexts/CommunityAsksToastContext';
import { SurveyRequestContext } from 'contexts/SurveyRequestContext';
import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { createReportForSurvey, updateReportForSurvey } from 'services/surveyReport';
import { SurveyType } from '@zencity/survey-types';
import { logger } from 'utils/community-asks/logger';
import { getRequestTitle } from 'utils/community-asks/surveyRequest';
import styles from '../CommunityAsksReport.module.scss';

interface Props {
  surveyId?: number;
  setReport: (val: { report_id: string; id: number; survey: number; report_title: string }) => void;
  report?: { report_id?: string; id?: number; survey?: number };
}

export const ReportUploader = (props: Props): ReactElement => {
  const { setReport, report, surveyId } = props;
  const surveyRequest = useContext(SurveyRequestContext);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { client } = useContext(AccountsContext);
  const { toastSuccess, toastError } = useContext(CommunityAsksToastContext);

  const { t: translate } = useTranslation();

  const openFileBrowserDialog = useCallback(() => {
    const surveyRequestTitle = getRequestTitle(surveyRequest);
    analyticsService.communityAsksEvents.uploadReportButtonClicked(surveyRequestTitle, surveyRequest.id);
    if (!fileInputRef || !fileInputRef.current) {
      return logger.error('Can not open file browser dialog because the file input element does not exist.');
    }
    fileInputRef.current?.click();
    return null;
  }, [surveyRequest, fileInputRef]);

  const triggerFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    setFile(files?.[0]);
  };

  useEffect(() => {
    async function uploadFileToStorage() {
      if (file && surveyId) {
        try {
          let response;
          setIsLoading(true);
          const payload = new FormData();
          payload.append('survey', surveyId.toString());
          payload.append('title', file.name.replace('.pdf', ''));
          payload.append('client', client.id);
          payload.append('type', SurveyType.COMMUNITY_ASKS);
          payload.append('report', new Blob([file], { type: 'application/pdf' }), 'report');
          if (report?.report_id && report.id) {
            payload.append('current_report', report.report_id);
            response = await updateReportForSurvey(report.id, payload);
          } else {
            response = await createReportForSurvey(payload);
          }
          setReport({
            id: response.id,
            report_id: response.report_id,
            survey: response.survey,
            report_title: response.report_title ?? '',
          });
          toastSuccess(translate('communityAsks.reportTab.emptyState.successMsg'));
        } catch (error) {
          toastError(translate('communityAsks.reportTab.emptyState.failureMsg'));
          logger.error(error);
        }
        setIsLoading(false);
      }
    }
    uploadFileToStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <div className={styles.communityAsksReportButton}>
      <Button
        type="button"
        onClick={openFileBrowserDialog}
        variant={`${report?.report_id ? 'secondary' : 'primary'}`}
        size="lg"
        className={`${styles.button} ${report?.report_id && styles.saButton}`}
        disabled={isLoading}
      >
        <SuperAdminBadge />
        {report?.report_id
          ? translate('superAdmin.edit')
          : translate('communityAsks.reportTab.emptyState.publishReport')}
      </Button>
      <input
        type="file"
        id="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={triggerFileUpload}
        accept=".pdf"
        data-testid="hidden-input-upload-file"
      />
    </div>
  );
};
