import React from 'react';

export interface FlexProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  flexDirection?: 'row' | 'column';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  width?: string | number;
  maxWidth?: string;
  maxHeight?: string;
  style?: React.CSSProperties;
}
export const Flex = (props: FlexProps): React.ReactElement => {
  const {
    id,
    children,
    className,
    justifyContent = 'flex-start',
    alignItems = 'flex-start',
    flexBasis = 'auto',
    flexGrow = 0,
    flexShrink = 1,
    flexDirection = 'row',
    flexWrap = 'nowrap',
    width = 'auto',
    maxWidth = 'none',
    maxHeight = 'none',
    style,
  } = props;

  const idAttribute: Record<string, string> = {};
  if (id) {
    idAttribute.id = id;
  }

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        justifyContent,
        flexDirection,
        flexGrow,
        flexBasis,
        flexShrink,
        flexWrap,
        alignItems,
        width,
        maxWidth,
        maxHeight,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...idAttribute}
    >
      {children}
    </div>
  );
};
