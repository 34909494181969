import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import styles from './GraphTooltip.module.scss';

interface ScoreTitleProps {
  value?: number;
  title: string;
  valueColor: string;
}
export const ScoreTitle = (props: ScoreTitleProps): JSX.Element => {
  const { value = 0, title, valueColor } = props;
  return (
    <div className={styles.scoreTitleContainer}>
      <div className={styles.scoreTitle}>{title}</div>
      <Spacer orientation="horizontal" size="large" />
      <div className={styles.scoreTitleValue} style={{ color: valueColor }}>
        {value}%
      </div>
    </div>
  );
};
