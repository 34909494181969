import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from '@zencity/common-ui';
import styles from './BackLink.module.scss';

interface Props {
  href: string;
}
/**
 * A "Back" link with a left arrow (animation on hover).
 */
export const BackLink: React.FC<Props> = function BackLink(props: Props): React.ReactElement {
  const { href } = props;
  const { t: translate } = useTranslation();

  return (
    <Link to={href} className={styles.backLink}>
      {/* eslint-disable i18next/no-literal-string */}
      <Icon name="arrow-left.svg" width={24} height={24} className={styles.icon} />
      {translate('back')}
    </Link>
  );
};
