import { SuperAdminBadge } from 'components/SuperAdminBadge/SuperAdminBadge';
import { AccountsContext } from 'contexts/AccountsContext';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { analyticsService } from 'services/analytics/analytics';
import { userIsSuperAdmin } from 'utils/user';
import styles from './ManagementPageLink.module.scss';

export enum AnalyticsEventsRouteTo {
  MANAGEMENT = 'management',
  WORKSPACE = 'workspace',
}

/**
 * A navigation link for "superadmin" users to the Management page.
 */
export const ManagementPageLink = (): ReactElement => {
  const { t: translate } = useTranslation();
  const { user } = useContext(AccountsContext);
  const location = useLocation();

  const isSuperAdmin = userIsSuperAdmin(user);
  const isManagementPage = location.pathname === CommunityAsksRouterPaths.MANAGEMENT;
  const routeTo = isManagementPage ? CommunityAsksRouterPaths.WORKSPACE : CommunityAsksRouterPaths.MANAGEMENT;
  const linkLabel = isManagementPage
    ? translate('communityAsksManagement.buttonToWorkspace')
    : translate('communityAsksManagement.title');
  const analyticsEventRouteTo = isManagementPage ? AnalyticsEventsRouteTo.WORKSPACE : AnalyticsEventsRouteTo.MANAGEMENT;

  return isSuperAdmin ? (
    <Link
      to={routeTo}
      className={styles.managementLink}
      onClick={() => analyticsService.communityAsksEvents.managementPageLinkClicked(analyticsEventRouteTo)}
    >
      {linkLabel}
      <SuperAdminBadge />
    </Link>
  ) : (
    <></>
  );
};
