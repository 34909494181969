import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../Question.module.scss';

interface Props {
  totalAnswersCount: number;
}

const Counter = ({ totalAnswersCount }: Props): ReactElement => {
  const { t: translate } = useTranslation();
  return (
    <span id="uptime--feed-tab-answer-counter" className={styles.answerCounter}>
      {translate('questions.responses', {
        responseCount: totalAnswersCount === 0 ? translate('questions.noQuestions') : totalAnswersCount,
      })}
    </span>
  );
};
export default Counter;
