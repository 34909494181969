import { TreeNode, TreeSelectSelectionKeys, ZCDSpinner, ZCDTreeSelect } from '@zencity/common-ui';
import { SurveyType } from '@zencity/survey-types';
import { AnalyticEventsChangeTypes, AnalyticFilterNames } from 'constants/analytics';
import { FiltersContext } from 'contexts/FiltersContext';
import { MapsContext } from 'contexts/MapsContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { fetchDivisionsTree } from 'services/division';
import { logger } from 'utils/blockwise/logger';
import { getClientId } from 'utils/client';
import { generateAreaFilterValue, generateLevelFilterValue } from 'utils/utils';
import styles from './GeographicDivisionsFilter.module.scss';
import { mapDivisionsTreeToFilterOptions, recursivelyGenerateAllSelectedDivisionIds } from './utils';

interface Props {
  surveyType: SurveyType;
  isDisabled?: boolean;
}

/**
 * A tree-select filter component with Divisions data.
 */
export const GeographicDivisionsFilter: React.FC<Props> = function GeographicDivisionsFilter({
  isDisabled = false,
  surveyType,
}: Props): ReactElement {
  const zcClientId = getClientId();

  const { t: translate } = useTranslation();
  const [isDivisionsTreeLoading, setIsDivisionsTreeLoading] = useState(false);
  const [geographicDivisionFilterOptions, setGeographicDivisionFilterOptions] = useState<TreeNode[]>([]);
  const { divisions, setBaseDivisionId, baseDivisionId, divisionsDictionary } = useContext(MapsContext);
  const { selectedDivisionId, setSelectedDivisionId, selectedDivisionIds, setSelectedDivisionIds } =
    useContext(FiltersContext);

  useEffect(() => {
    const fetchDivisionsData = async () => {
      try {
        setIsDivisionsTreeLoading(true);
        const divisionsTreeData = await fetchDivisionsTree(surveyType, zcClientId);
        const geographicDivisionOptions = mapDivisionsTreeToFilterOptions(divisionsTreeData);
        setGeographicDivisionFilterOptions(geographicDivisionOptions);
      } catch (error) {
        logger.error(error);
      } finally {
        setIsDivisionsTreeLoading(false);
      }
    };

    fetchDivisionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zcClientId]);

  const handleClick = (event: { value: TreeSelectSelectionKeys }) => {
    if (!event?.value || !divisionsDictionary || Object.keys(divisionsDictionary).length === 0) return;
    // Check if the selected division has children and update accordingly.
    const selectedDivision = divisionsDictionary[event.value as unknown as number];
    setSelectedDivisionId(selectedDivision.id);
    const selectedDivisionChildren = divisions?.filter((division) => division.parent_division === selectedDivision.id);
    if (selectedDivisionChildren?.length) {
      setBaseDivisionId?.(event.value as unknown as number);
    } else {
      // This keeps the map level at the parent division's level, since there are no
      // children divisions for the selected filter division.
      setBaseDivisionId?.(selectedDivision.parent_division || 0);
    }
    setSelectedDivisionIds(
      recursivelyGenerateAllSelectedDivisionIds(geographicDivisionFilterOptions, [selectedDivision.id]),
    );

    analyticsService.crossProductEvents.filterChanged({
      filterName: AnalyticFilterNames.GEOGRAPHIC,
      filterValue: generateLevelFilterValue(selectedDivision.depth),
      areaValue: generateAreaFilterValue(selectedDivision?.geo_targeting_ref?.name),
      cycleNumber: AnalyticEventsChangeTypes.IRRELEVANT,
      ageValue: AnalyticEventsChangeTypes.IRRELEVANT,
      ethnicityValue: AnalyticEventsChangeTypes.IRRELEVANT,
      genderValue: AnalyticEventsChangeTypes.IRRELEVANT,
    });
  };

  useEffect(() => {
    const baseDivision = divisionsDictionary?.[baseDivisionId ?? 0];
    if (baseDivision?.depth === 0) {
      setSelectedDivisionId(undefined);
      setSelectedDivisionIds([]);
    }
  }, [baseDivisionId, divisionsDictionary, setSelectedDivisionId, setSelectedDivisionIds]);

  useEffect(() => {
    if (selectedDivisionIds.length === 0) {
      setSelectedDivisionId(undefined);
    }
  }, [selectedDivisionIds, setSelectedDivisionId]);

  return (
    <div className={styles.geographicDivisionsFilterContainer}>
      {isDivisionsTreeLoading ? (
        <ZCDSpinner />
      ) : (
        <ZCDTreeSelect
          disabled={isDisabled || isDivisionsTreeLoading || !divisions?.length}
          options={geographicDivisionFilterOptions}
          value={selectedDivisionId?.toString()}
          showSearch
          selectionMode="single"
          searchPlaceholder={translate('geographicAreasFilter.searchPlaceholder')}
          placeholder={translate('geographicAreasFilter.placeholder')}
          onChange={handleClick}
          onClearValue={() => {
            setSelectedDivisionId(undefined);
            setSelectedDivisionIds([]);
          }}
        />
      )}
    </div>
  );
};
