import { SummaryOverallSatisfactionWidget } from 'types/summaryOverallSatisfactionWidget';
import { getRandomNumber } from 'utils/randomization';
import { mockDateRanges } from './dateRanges.mock';

export const summaryOverallSatisfactionMock = [
  {
    aspect_id: 0,
    aspect_name: 'General',
    scores: [
      {
        score: 37.793,
        total_submissions: 10018,
        start_date: '2022-04-01',
        end_date: '2022-06-30',
      },
      {
        score: 38.287,
        total_submissions: 10210,
        start_date: '2022-01-01',
        end_date: '2022-03-31',
      },
    ],
  },
];

export const generateSummaryOverallSatisfactionMock = (numberOfAspects: number): SummaryOverallSatisfactionWidget[] => {
  const mock: SummaryOverallSatisfactionWidget[] = [];
  const dateRanges = mockDateRanges.completed_cycles;

  for (let i = 0; i < numberOfAspects; i++) {
    mock.push({
      aspect_id: i,
      aspect_name: `Aspect ${i}`,
      scores: [],
    });
  }

  mock.forEach((aspect) => {
    dateRanges.forEach((dateRange) => {
      aspect.scores.push({
        score: getRandomNumber(0, 100),
        total_submissions: getRandomNumber(0, 10000),
        start_date: dateRange.start_date,
        end_date: dateRange.end_date,
      });
    });
  });

  return mock;
};
