import { SurveyReport } from '@zencity/survey-types';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.surveyReport),
);

/**
 * Updates a report id.
 * @param surveyReportRecordId
 * @return Promise<SurveyReportData>
 */
export async function updateReportForSurvey(surveyReportRecordId: number, payload: FormData): Promise<SurveyReport> {
  const result = await axiosInstance.patch<SurveyReport>(`${surveyReportRecordId}/`, payload, {});
  return result.data;
}

/**
 * Creates a survey report record.
 * @return Promise<SurveyReportData>
 */
export async function createReportForSurvey(payload: FormData): Promise<SurveyReport> {
  const result = await axiosInstance.post<SurveyReport>('', payload, {});
  return result.data;
}
