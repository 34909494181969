/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { User } from '../types/user';
import { sentryClient } from './sentry';

export class Logger {
  scope: Sentry.Scope;

  constructor(product: string, user: User, clientName: string) {
    const scope = new Sentry.Scope();
    scope.setTag('product', product);
    scope.setTag('clientName', clientName);
    scope.setUser({
      id: user.id,
      username: `${user.firstName} ${user.lastName}`,
    });
    this.scope = scope;
  }

  isUptimeUser(): boolean {
    return this.scope.getUser()?.username === 'Uptime Monitor';
  }

  setDynamicTags(): void {
    const loc = window.location; // eslint-disable-line id-length
    this.scope.setTag('host', loc.origin);
    this.scope.setTag('path', loc.pathname + loc.search);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  log(message: string | any, ...extraArgs: any): void { // eslint-disable-line id-length,prettier/prettier
    console.log(message, ...extraArgs);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  info(message: string | any, ...extraArgs: any): void {
    console.info(message, ...extraArgs);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(message: string | any): void {
    console.debug(message);
    this.setDynamicTags();
    if (this.isUptimeUser()) {
      // Avoid spamming sentry as part of an Uptime check.
      return;
    }
    sentryClient?.captureMessage(message, Sentry.Severity.Debug, undefined, this.scope);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(message: string | any): void {
    console.warn(message);
    this.setDynamicTags();
    if (this.isUptimeUser()) {
      // Avoid spamming sentry as part of an Uptime check.
      return;
    }
    sentryClient?.captureMessage(message, Sentry.Severity.Warning, undefined, this.scope);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(errorObj: Error | any): void {
    console.error(errorObj);
    if (this.isUptimeUser()) {
      // Avoid spamming sentry as part of an Uptime check.
      return;
    }
    this.setDynamicTags();
    sentryClient?.captureException(errorObj, undefined, this.scope);
  }
}

const loggerInstances: Record<string, Logger> = {};

export const loggers = {
  get: function getLogger(product: string): Logger {
    if (!loggerInstances[product]) {
      // set an empty logger as a fallback
      loggers.set(product, { id: '0', firstName: 'logger not', lastName: 'initiated' } as User, 'logger not initiated');
    }
    return loggerInstances[product];
  },

  set: function setLogger(product: string, user: User, clientName: string): void {
    loggerInstances[product] = new Logger(product, user, clientName);
  },
};
