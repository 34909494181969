import { TreeNode } from '@zencity/common-ui';
import { DivisionTree } from '@zencity/survey-types';

export function mapDivisionsTreeToFilterOptions(divisionsTree: DivisionTree[]): TreeNode[] {
  return divisionsTree
    .reduce<TreeNode[]>((divisionsFilterOptions, divisionLeaf) => {
      // There will be no filter option for the division with depth = 0.
      if (divisionLeaf.depth === 0) {
        return mapDivisionsTreeToFilterOptions(divisionLeaf.children_divisions);
      }
      divisionsFilterOptions.push({
        key: divisionLeaf.id,
        label: divisionLeaf.geo_targeting_ref.polygon_name,
        children: mapDivisionsTreeToFilterOptions(divisionLeaf.children_divisions),
      });
      return divisionsFilterOptions;
    }, [])
    .sort((division1, division2) => division1.label!.localeCompare(division2.label!, undefined, { numeric: true }));
}

export function recursivelyGenerateAllSelectedDivisionIds(
  divisionTree: TreeNode[],
  selectedDivisionIds: number[],
): number[] {
  return divisionTree.reduce<number[]>((selectedDivisionIdsAcc, divisionLeaf) => {
    if (selectedDivisionIds.includes(divisionLeaf.key as number)) {
      selectedDivisionIdsAcc.push(...divisionLeaf.children!.map((child) => child.key as number));
      return recursivelyGenerateAllSelectedDivisionIds(divisionLeaf.children!, selectedDivisionIdsAcc);
    }
    return recursivelyGenerateAllSelectedDivisionIds(divisionLeaf.children!, selectedDivisionIdsAcc);
  }, selectedDivisionIds);
}
