import { HorizontalSeparator } from '@zencity/common-ui';
import { CustomerExperienceContext } from 'contexts/CustomerExperienceContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CXDashboardBox } from 'screens/customer-experience/Dashboard/components/CXDashboardBox';
import { CXSatisfactionScoreCard } from 'screens/customer-experience/Dashboard/components/CXSatisfactionScoreCard';
import { CxDashboardData } from 'types/cx_survey';
import styles from '../Dashboard.module.scss';
import { CXOverallScoreGraph } from './CXOverallScoreGraph';
import { ServiceTypesScoresTable } from './ServiceTypesScoresTable';

export const DashboardTopSection = ({ dashboardData }: { dashboardData?: CxDashboardData }): React.ReactElement => {
  const { t: translate } = useTranslation();
  const { cxServiceTypeIdFilterBy } = useContext(CustomerExperienceContext);
  const overallScore = dashboardData?.performance;
  const overallScoreTrendData = overallScore?.score?.trend;

  return (
    <section className={styles.dashboardTopSection}>
      <CXDashboardBox
        articleClassName={styles.performanceRating}
        boxClassName={styles.performanceRatingBox}
        title={overallScore?.label as string}
        isTitleLarge
      >
        <div className={styles.overallPerformanceContainer}>
          <article className={styles.overallPerformanceSnapshot}>
            {overallScore?.score && (
              <CXSatisfactionScoreCard scoreData={overallScore.score} description={overallScore.sentence} />
            )}
          </article>
          <article className={styles.overallPerformanceTimeseries}>
            {overallScoreTrendData && <CXOverallScoreGraph trendData={overallScoreTrendData} />}
          </article>
        </div>
        <HorizontalSeparator height={5} />
        <div className={styles.respondentCount}>
          {dashboardData?.submissions_count === dashboardData?.filtered_submissions_count
            ? `${dashboardData?.filtered_submissions_count} resident responses`
            : `${dashboardData?.filtered_submissions_count} resident responses for this filter (${dashboardData?.submissions_count} in total for this survey)`}
        </div>
      </CXDashboardBox>
      <CXDashboardBox
        articleClassName={styles.leaderboard}
        boxClassName={styles.leaderboardBox}
        title={translate('cxDashboard.serviceTypeLeaderboardTitle')}
        isTitleLarge
      >
        <ServiceTypesScoresTable
          serviceTypes={dashboardData?.service_types || []}
          serviceTypeIdToFilterBy={cxServiceTypeIdFilterBy}
        />
      </CXDashboardBox>
    </section>
  );
};
