import { createAxiosInstance } from 'utils/Api';
import {} from 'types/survey';
import path from 'utils/path';
import { mockDateRanges } from 'mocks/data/dateRanges.mock';
import { DashboardDateRangesResponse } from 'types/rakeWeighterExecution';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.rakeWeighterExecutions),
);

/**
 * Get the date ranges for a client's community survey cadence.
 * @param surveyGroupId - The survey group ID for the client's Community Surveys.
 */
export async function fetchDashboardDateRanges(surveyGroupId: number): Promise<DashboardDateRangesResponse> {
  if (config.shouldMockRequests) {
    return Promise.resolve(mockDateRanges);
  }
  const result = await axiosInstance.get<DashboardDateRangesResponse>('dashboard_date_ranges/', {
    params: { survey_group: surveyGroupId },
  });

  return result.data;
}
