import React from 'react';
import { Nullable } from 'types/misc';
import { SurveyGroup } from '@zencity/survey-types';

export interface CustomerExperienceContextInterface {
  surveyGroups: SurveyGroup[];
  cxServiceTypeIdFilterBy: Nullable<number>;
  setCXServiceTypeIdFilter: (selectedOption: Nullable<number>) => void;
  cxTimePeriodFilterBy: Nullable<number>;
  setCXTimePeriodFilter: (selectedOption: Nullable<number>) => void;
}

export const CustomerExperienceContext = React.createContext<CustomerExperienceContextInterface>(
  {} as CustomerExperienceContextInterface,
);
