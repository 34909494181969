import { DashboardContentEmptyState } from 'components/DashboardContentEmptyState/DashboardContentEmptyState';
import { Spacer } from 'components/Spacer/Spacer';
import React from 'react';
import { AspectWidget } from './AspectWidget/AspectWidget';
import styles from './BlockwiseSummaryTab.module.scss';
import { OverallWidgets } from './OverviewWidget/OverallWidgets';

export const BlockwiseSummaryTab = (): JSX.Element => {
  const [isEmptyState, setIsEmptyState] = React.useState(false);
  if (isEmptyState) {
    return <DashboardContentEmptyState cleanupAction={() => setIsEmptyState(false)} />;
  }
  return (
    <div id="uptime--bw-summary-tab" className={styles.blockwiseSummaryTab}>
      <OverallWidgets setIsEmptyState={setIsEmptyState} />
      <Spacer orientation="vertical" size="small" />
      <AspectWidget />
    </div>
  );
};
