import { ZCDCard } from '@zencity/common-ui';
import React from 'react';
import styles from './OverallWidgetWrapper.module.scss';

interface Props {
  children: React.ReactNode;
}
export const OverallWidgetWrapper = (props: Props): JSX.Element => {
  const { children } = props;

  return (
    <div className={styles.overallWidgetWrapper}>
      <ZCDCard elevated>{children}</ZCDCard>
    </div>
  );
};
