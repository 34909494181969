/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { StatusIndicatorDotTypes } from 'components/StatusIndicatorDot/StatusIndicatorDot';
import { Nullable } from 'types/misc';
import {
  CrossChoiceData,
  Question,
  QuestionChoice,
  QuestionChoiceResponse,
  QuestionResponse,
} from 'types/questionsScores';
import { SatisfactionScore } from 'types/score';
import { getOrderedNumericValueThresholds } from '@zencity/surveys-common-utilities';

export const SENTIMENT_BOUNDS_THRESHOLD = 0.36;

/**
 * Add the comparison question data to the question object.
 */
export const remapQuestions = (questionsScores: Question[], comparisonQuestions: Question[]): Question[] | undefined =>
  questionsScores?.map((question) => {
    const comparisonQuestion = comparisonQuestions?.find(
      (comparisonQuestionItem) => comparisonQuestionItem.genericQuestionId === question.genericQuestionId,
    );
    if (comparisonQuestion) {
      return {
        ...question,
        comparisonQuestion,
      };
    }
    return question;
  });

/**
 * Map the choice fields from the API to the QuestionChoice type.
 */
export const mapChoice = (questionChoice: QuestionChoiceResponse): QuestionChoice => ({
  choiceId: questionChoice.id,
  choiceText: questionChoice.text,
  score: questionChoice.score,
  totalSubmissions: questionChoice.total_submissions,
  numericValue: questionChoice.numeric_value,
  sortValue: questionChoice.sort_value,
});

/**
 * Map the question fields from the API response to the Question type.
 */
export const mapQuestion = (questionScore: QuestionResponse, mappedQuestionChoices?: QuestionChoice[]): Question => ({
  genericQuestionId: questionScore.generic_question_id,
  questionText: questionScore.question_text,
  questionType: questionScore.question_type,
  totalSubmissions: questionScore.total_submissions,
  questionIndex: questionScore.question_index,
  questionChoices: mappedQuestionChoices || [],
});

/** Returns the score per sentiment in percentage. */
export function calculateScoreSegmentationPerSatisfaction(
  questionChoicesToCalculate: QuestionChoice[],
  thresholds: Pick<SatisfactionScore, 'positive' | 'negative'>,
): SatisfactionScore {
  const initialState = {
    positive: 0,
    negative: 0,
    neutral: 0,
  };
  const sumNumericValues = questionChoicesToCalculate.reduce(
    (accumulator, choiceItem) => accumulator + choiceItem.score,
    0,
  );
  const scorePerSatisfactionGroup = questionChoicesToCalculate.reduce((accumulator, choiceItem) => {
    if (!choiceItem?.score || choiceItem.numericValue === null) {
      return accumulator;
    }
    if (choiceItem.numericValue < thresholds.positive && choiceItem.numericValue > thresholds.negative) {
      accumulator.neutral += choiceItem.score;
      return accumulator;
    }
    if (choiceItem.numericValue >= thresholds.positive) {
      accumulator.positive += choiceItem.score;
      return accumulator;
    }
    if (choiceItem.numericValue <= thresholds.negative) {
      accumulator.negative += choiceItem.score;
      return accumulator;
    }
    return accumulator;
  }, initialState);
  return sumNumericValues
    ? (Object.keys(scorePerSatisfactionGroup) as (keyof SatisfactionScore)[]).reduce(
        (accumulator, satisfactionGroupName) => {
          accumulator[satisfactionGroupName] = (accumulator[satisfactionGroupName] / sumNumericValues) * 100;
          return accumulator;
        },
        scorePerSatisfactionGroup,
      )
    : initialState;
}

export const getTopQuestionChoice = (questionChoices: QuestionChoice[]): QuestionChoice =>
  questionChoices.reduce((prev, current) => (prev.score > current.score ? prev : current), questionChoices[0]);

export const calculateSatisfactionScore = (
  questionChoices: QuestionChoice[],
  comparisonQuestion?: Question,
  isNoScoresQuestion?: boolean,
): {
  satisfactionScore?: SatisfactionScore;
  comparisonSatisfactionScore?: SatisfactionScore;
} => {
  const sortedQuestionChoices = questionChoices.sort(
    (choice1, choice2) => Number(choice1.numericValue) - Number(choice2.numericValue),
  );

  const { negativeNumericValueThreshold, positiveNumericValueThreshold } = getOrderedNumericValueThresholds(
    sortedQuestionChoices.map((choice) => choice.numericValue),
  );

  if (positiveNumericValueThreshold < negativeNumericValueThreshold) {
    // todo: handle case where there are not enough choices to calculate the negative and positive scores.
    return {
      satisfactionScore: undefined,
      comparisonSatisfactionScore: undefined,
    };
  }
  const {
    positive: positiveScore,
    negative: negativeScore,
    neutral: neutralScore,
  } = calculateScoreSegmentationPerSatisfaction(sortedQuestionChoices, {
    positive: positiveNumericValueThreshold,
    negative: negativeNumericValueThreshold,
  });
  const satisfactionScore = {
    positive: positiveScore,
    neutral: neutralScore,
    negative: negativeScore,
  };
  if (!comparisonQuestion) {
    return { satisfactionScore };
  }

  let comparisonSatisfactionScore;

  if (comparisonQuestion.questionChoices.length) {
    comparisonSatisfactionScore = calculateScoreSegmentationPerSatisfaction(comparisonQuestion.questionChoices, {
      positive: positiveNumericValueThreshold,
      negative: negativeNumericValueThreshold,
    });
  }

  // If the question has no scores, we get the weighted ratio for each choice.
  // And we set the score to the choice with the most submissions to be the positive score to be aligned with the other questions.
  if (isNoScoresQuestion) {
    const topQuestionChoice = getTopQuestionChoice(questionChoices);
    const comparisonQuestionChoice = comparisonQuestion?.questionChoices.find(
      (choice) => choice.choiceId === topQuestionChoice.choiceId,
    );
    satisfactionScore.positive = Math.round(topQuestionChoice.score);
    if (comparisonSatisfactionScore && comparisonQuestionChoice) {
      comparisonSatisfactionScore.positive = Math.round(comparisonQuestionChoice.score);
    }
  }

  return { satisfactionScore, comparisonSatisfactionScore };
};

export interface FormattedQuestionChoice extends QuestionChoice {
  choiceHeader: {
    choiceText: string;
    choiceType: StatusIndicatorDotTypes;
  };
  compareChoice?: QuestionChoice;
  change?: number;
}

const getChoiceType = (
  negativeIndexThreshold: Nullable<number>,
  positiveIndexThreshold: Nullable<number>,
  numericValue?: Nullable<number>,
) => {
  if (!numericValue) {
    return StatusIndicatorDotTypes.NON_SUBSTANTIATIVE;
  }
  if (numericValue && negativeIndexThreshold && numericValue <= negativeIndexThreshold) {
    return StatusIndicatorDotTypes.NEGATIVE;
  }
  if (numericValue && positiveIndexThreshold && numericValue >= positiveIndexThreshold) {
    return StatusIndicatorDotTypes.POSITIVE;
  }
  return StatusIndicatorDotTypes.NEUTRAL;
};

export const formatQuestionChoices = (
  questionChoices: QuestionChoice[],
  comparingChoices?: QuestionChoice[],
): FormattedQuestionChoice[] => {
  if (questionChoices.length === 0) {
    return [];
  }
  const numericValues = questionChoices.map((choice) => choice.numericValue);
  const { negativeNumericValueThreshold, positiveNumericValueThreshold } =
    getOrderedNumericValueThresholds(numericValues);

  // Process each question choice to add the "choiceType" and "compareChoice" properties.
  const processedChoices = questionChoices.map((questionChoice) => {
    const compareChoice = comparingChoices?.find((choice) => {
      if (choice.numericValue) {
        return choice.numericValue === questionChoice.numericValue;
      }
      return choice.choiceId === questionChoice.choiceId;
    });
    const change = compareChoice?.score && questionChoice.score && questionChoice.score - compareChoice.score;

    return {
      ...questionChoice,
      choiceHeader: {
        choiceText: questionChoice.choiceText,
        choiceType: getChoiceType(
          negativeNumericValueThreshold,
          positiveNumericValueThreshold,
          questionChoice?.numericValue,
        ),
      },
      compareChoice,
      change,
    };
  });

  // Sort the processed choices based on either the numeric value or the sort value.
  return processedChoices.sort((firstChoice, secondChoice) => {
    if (firstChoice.numericValue && secondChoice.numericValue) {
      return Number(secondChoice.numericValue) - Number(firstChoice.numericValue);
    }
    if (firstChoice.sortValue && secondChoice.sortValue) {
      return firstChoice.sortValue - secondChoice.sortValue;
    }
    return 0;
  });
};

export interface FormattedCrossQuestion {
  choiceHeader: {
    choiceText: string;
    choiceType: StatusIndicatorDotTypes;
  };
  numericValue: Nullable<number>;
  choiceId: Nullable<number>;
  sortIndexToKey: Record<string, number>;
  choiceScore?: number;
}
const sortQuestionChoices = (firstChoice: FormattedCrossQuestion, secondChoice: FormattedCrossQuestion) => {
  /*
   * In case we have numeric value, we want to sort in descending order.
   * In case we don't have numeric value, we want to sort in ascending order.
   */
  if (firstChoice.numericValue && secondChoice.numericValue) {
    return secondChoice.numericValue - firstChoice.numericValue;
  }
  return 0;
};

export const formatCrossQuestion = (
  crossChoices: CrossChoiceData[],
  questionChoices?: QuestionChoice[],
): FormattedCrossQuestion[] => {
  if (crossChoices.length === 0) {
    return [];
  }
  const numericValues = crossChoices.map((choice) => choice.numeric_value);
  const { negativeNumericValueThreshold, positiveNumericValueThreshold } =
    getOrderedNumericValueThresholds(numericValues);

  return crossChoices
    .map((questionChoice) => {
      const originalChoice = questionChoices?.find((choice) =>
        choice.choiceId && questionChoice.choice_id
          ? choice.choiceId === questionChoice.choice_id
          : choice.numericValue === questionChoice.numeric_value && choice.choiceText === questionChoice.text,
      );
      const choices = questionChoice.breakdown_by_cross.reduce(
        (cross, choice) => ({ ...cross, [choice.text]: choice.score }),
        {},
      );
      const sortIndexToKey = questionChoice.breakdown_by_cross.reduce(
        (cross, choice) => ({ ...cross, [choice.text]: choice.sort_index }),
        {},
      );

      return {
        choiceHeader: {
          choiceText: questionChoice.text,
          choiceType: getChoiceType(
            negativeNumericValueThreshold,
            positiveNumericValueThreshold,
            questionChoice.numeric_value,
          ),
        },
        numericValue: questionChoice.numeric_value,
        choiceScore: originalChoice?.score,
        choiceId: questionChoice?.choice_id,
        sortIndexToKey,
        ...choices,
      };
    })
    .sort(sortQuestionChoices);
};

export enum CrossTableColumns {
  CHOICE_HEADER = 'choiceHeader',
  NUMERIC_VALUE = 'numericValue',
  SORT_INDEX_TO_KEY = 'sortIndexToKey',
  COMPARE_CHOICE = 'compareChoice',
  CHOICE_SCORE = 'choiceScore',
  CHOICE_ID = 'choiceId',
}

export interface CrossQuestionTable {
  [CrossTableColumns.CHOICE_HEADER]: {
    choiceText: string;
    choiceType: StatusIndicatorDotTypes;
  };
  [CrossTableColumns.NUMERIC_VALUE]: Nullable<number> /* We can have either choice id or numeric value, both will never be null. */;
  [CrossTableColumns.CHOICE_SCORE]?: number;
  [CrossTableColumns.CHOICE_ID]: Nullable<number>;
  [CrossTableColumns.SORT_INDEX_TO_KEY]: Record<string, number>;
  [CrossTableColumns.COMPARE_CHOICE]?: FormattedCrossQuestion;
}

export const crossQuestionsTable = (
  selectedQuestion: FormattedCrossQuestion[],
  crossQuestion?: FormattedCrossQuestion[],
): CrossQuestionTable[] => {
  if (selectedQuestion.length === 0) {
    return [];
  }

  return selectedQuestion
    .map((questionChoice) => {
      const compareChoice = crossQuestion?.find((choice) =>
        choice.choiceId && questionChoice.choiceId
          ? choice.choiceId === questionChoice.choiceId
          : choice.numericValue === questionChoice.numericValue &&
            choice.choiceHeader.choiceText === questionChoice.choiceHeader.choiceText,
      );

      return {
        ...questionChoice,
        compareChoice,
      };
    })
    .sort(sortQuestionChoices);
};

export const calculateScoreComparisonDifference = (currentScore: number, previousScore: number) =>
  Math.round(currentScore) - Math.round(previousScore);
