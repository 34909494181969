import { DateRangeFilterOption } from 'contexts/DateRangeContext';
import { fetchSurveyGroups } from 'services/surveyGroup';
import { ScoreFilters, DateRange } from 'types/dateRange';
import { ScorePerDateRange } from 'types/score';
import { SurveyGroup, SurveyType } from '@zencity/survey-types';
import { format, parseISO, addDays } from 'date-fns';
import i18next from 'i18next';
import { TimeFormat } from 'utils/time';
import { fetchDashboardDateRanges } from 'services/rakeWeighterExecution';

export const latestResultsDateKey = 'latest-results';

export const getStartDate = (latestCompletedCycle: DateRangeFilterOption): Date => {
  const latestCompletedCycleEndDate = <string>latestCompletedCycle.endDate;
  return addDays(parseISO(latestCompletedCycleEndDate), 1);
};

export const isFeedLatestResult = (selectedDateRange: undefined | DateRangeFilterOption): boolean =>
  !selectedDateRange || selectedDateRange?.key === latestResultsDateKey;

export const isScoreLatestResult = (selectedDateRange: DateRangeFilterOption): boolean =>
  selectedDateRange?.key === latestResultsDateKey;

export const getMostRecentSurveyGroup = async (surveyType: SurveyType): Promise<SurveyGroup> => {
  const paginatedSurveyGroups = await fetchSurveyGroups(surveyType);
  const mostRecentSurveyGroup = paginatedSurveyGroups.results[0];
  return mostRecentSurveyGroup;
};

export const getScorePerDateRange = ({
  scorePerDateRange,
  dateRangeFilter,
}: {
  scorePerDateRange: ScorePerDateRange[];
  dateRangeFilter: ScoreFilters;
}): ScorePerDateRange | undefined =>
  scorePerDateRange?.find(
    (period) => period.endDate === dateRangeFilter?.endDate && period.startDate === dateRangeFilter?.startDate,
  );

export const hasScoreDataForAnyDateRange = (scorePerDateRange: (undefined | DateRangeFilterOption)[] = []): boolean =>
  scorePerDateRange?.length > 0;

/**
 * Helper function to convert the fetched date ranges to Common-UI
 * filter options.
 */
export const convertDateRangesToFilterOptions = (
  dateRanges: DateRange[],
): { [key: string]: DateRangeFilterOption[] } => {
  const convertedFilterOptions = dateRanges.map((dateRange) => {
    const { startDate, endDate } = dateRange;
    const optionKey = `${startDate}_to_${endDate}`;

    const startDateFormatted = format(parseISO(startDate), TimeFormat.MMM_D);
    const endDateFormatted = format(parseISO(endDate), TimeFormat.MMM_D_YYYY);
    const optionLabel = `${startDateFormatted} - ${endDateFormatted}`;
    return {
      key: optionKey,
      label: optionLabel,
      startDate,
      endDate,
    };
  });
  const latestResultsFilterOption = {
    key: latestResultsDateKey,
    label: i18next.t('header.dateFilter.placeholderLabel'),
    startDate: '',
    endDate: '',
  };
  const completedCyclesFilterLabelOption = {
    key: 'completed-cycles-label',
    label: i18next.t('header.dateFilter.completedCycles'),
    labelOnly: true,
    startDate: '',
    endDate: '',
  };

  // in case there is no data ranges we return an empty array as the filter options.
  const dateRangeFilterOptions =
    dateRanges.length > 0
      ? [latestResultsFilterOption, completedCyclesFilterLabelOption, ...convertedFilterOptions]
      : [];

  // Add the "Latest Results" as an option to the beginning.
  return {
    scoreDateRangeFilterOptions: convertedFilterOptions,
    dateRangeFilterOptions,
  };
};

export const getDashboardDateRanges = async (
  surveyGroup: SurveyGroup,
): Promise<{
  scoreDateRangeFilterOptions: DateRangeFilterOption[];
  dateRangeFilterOptions: DateRangeFilterOption[];
  first_submission_date: string | undefined;
}> => {
  const { completed_cycles: dateRanges, first_submission_date } = await fetchDashboardDateRanges(surveyGroup.id);
  // Convert snake_case from the request to camelCase.
  const { scoreDateRangeFilterOptions, dateRangeFilterOptions } = convertDateRangesToFilterOptions(
    dateRanges.map((dateRange) => ({ startDate: dateRange.start_date, endDate: dateRange.end_date })),
  );

  return {
    scoreDateRangeFilterOptions,
    dateRangeFilterOptions,
    first_submission_date,
  };
};
