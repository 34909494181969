import classnames from 'classnames';
import { getTabPath, TabKeys } from 'constants/tabs';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import styles from './HeaderTabs.module.scss';

interface HeaderProps {
  tabs: TabKeys[];
  basePath?: string;
  onTabSwitch?: (tab: TabKeys) => void;
}

/**
 * A navigation component for a header.
 * @param tabs - Array of TabKeys to be rendered as NavLinks.
 * @param basePath - The starting point path from which these tabs extend.
 * @param analyticsSwitchTab - Function to send analytics data.
 */
// eslint-disable-next-line max-statements
export const HeaderTabs = (props: HeaderProps): ReactElement => {
  const { t: translate } = useTranslation();
  const { tabs, onTabSwitch, basePath = '' } = props;
  const activeFilters = useFilterNames();

  const handleAnalytics = (tabKey: TabKeys) => {
    analyticsService.crossProductEvents.dashboardTabSwitched({
      changeValue: tabKey,
      activeFilters,
    });
  };

  return (
    <div className={styles.tabs}>
      {tabs.map((tabKey) => (
        // NavLink allows us to track which tab is active.
        <NavLink
          id={`uptime--tab-link-${tabKey}`}
          key={tabKey}
          to={getTabPath(tabKey, basePath)}
          className={({ isActive }) => classnames(styles.tabItem, { [styles.active]: isActive })}
          onClick={() => {
            handleAnalytics(tabKey);
            onTabSwitch?.(tabKey);
          }}
        >
          {/* Look up the translate id for the given link. */}
          {translate(`header.tabs.${tabKey}`)}
        </NavLink>
      ))}
    </div>
  );
};
