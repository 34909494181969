import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import styles from './CircularProgressbar.module.scss';

interface Props {
  value: number;
  innerText?: string;
  pathColor: string;
}

export const CircularProgressbar = (props: Props): JSX.Element => {
  const { value, innerText, pathColor } = props;
  return (
    <div className={styles.circularProgressbar}>
      <CircularProgressbarWithChildren
        value={value}
        styles={buildStyles({
          pathColor,
          trailColor: styles.trailColor,
        })}
      >
        {innerText && <span className={styles.circularProgressbarInnerText}>{innerText}</span>}
      </CircularProgressbarWithChildren>
    </div>
  );
};
