/* eslint-disable max-lines, max-lines-per-function */
import { useMapLabelData } from 'components/ReactMapGLHOC/hooks/useMapLabelData';
import { useMapLayerData } from 'components/ReactMapGLHOC/hooks/useMapLayerData';
import { useMapLayerFilters } from 'components/ReactMapGLHOC/hooks/useMapLayerFilters';
import { useMapViewPort } from 'components/ReactMapGLHOC/hooks/useMapViewPort';
import { ReactMapGLHOC } from 'components/ReactMapGLHOC/ReactMapGLHOC';
import { AnalyticEventsChangeTypes, AnalyticOriginPropertyValues } from 'constants/analytics';

import { MapRenderTypes } from 'constants/map';
import { AccountsContext } from 'contexts/AccountsContext';
import { FiltersContext } from 'contexts/FiltersContext';
import { MapsContext } from 'contexts/MapsContext';
import { useSingleAndDoubleClick } from 'hooks/useSingleAndDoubleClick';
import React, { ReactElement, useCallback, useContext, useEffect, useRef } from 'react';
import { MapEvent } from 'react-map-gl';
import { analyticsService } from 'services/analytics/analytics';
import { FeatureFlag } from 'types/featureFlag';
import { userHasCorePlatformFeature } from 'utils/user';
import { generateAreaFilterValue, generateLevelFilterValue } from 'utils/utils';
import { POLYGON_LAYER } from './Layers';
import { getDivisionWithLeastGranularDepth } from './MapView.helpers';
import { useMapFilteredAreasWithScores } from './useMapFilteredAreasWithScores';

interface Props {
  renderType: MapRenderTypes;
}

// eslint-disable-next-line max-statements
export const MapView: React.FC<Props> = function MapView(props: Props): ReactElement {
  const { renderType = MapRenderTypes.SCREEN } = props;
  const renderAsWidget = renderType === MapRenderTypes.WIDGET || renderType === MapRenderTypes.SUMMARY_WIDGET;
  const {
    divisions,
    selectedAreaIds,
    setSelectedAreaIds,
    hoveredAreaId,
    baseDivisionId,
    setBaseDivisionId,
    divisionsDictionary,
  } = useContext(MapsContext);
  const { user, client } = useContext(AccountsContext);
  const { selectedDivisionId } = useContext(FiltersContext);
  const hasLondonFeature = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);
  const mapRef = useRef<HTMLDivElement>(null);
  const { filteredAreasWithScoresData } = useMapFilteredAreasWithScores({
    renderType,
  });
  const { viewport, setViewport } = useMapViewPort({
    areasWithScores: filteredAreasWithScoresData,
    mapRef,
    renderType,
  });
  const { layerData } = useMapLayerData({
    areasWithScores: filteredAreasWithScoresData,
  });
  const { labelData } = useMapLabelData({
    areasWithScores: filteredAreasWithScoresData,
  });
  const { hoveredAreaFilter, selectedAreaFilter } = useMapLayerFilters({
    hoveredAreaId,
    selectedAreaIds,
  });

  useEffect(() => {
    // Set initial value for baseDivisionId.
    const divisionWithLeastGranularDepth = getDivisionWithLeastGranularDepth(divisions);
    if (selectedDivisionId && divisionsDictionary) {
      const selectedDivision = divisionsDictionary[selectedDivisionId];
      const selectedDivisionChildren = divisions?.filter(
        (division) => division.parent_division === selectedDivision.id,
      );
      if (!selectedDivisionChildren.length) {
        setBaseDivisionId?.(selectedDivision.parent_division ?? divisionWithLeastGranularDepth?.id);
      }
    } else {
      setBaseDivisionId?.(divisionWithLeastGranularDepth?.id);
    }
  }, [divisions, hasLondonFeature, setBaseDivisionId, setSelectedAreaIds, selectedDivisionId, divisionsDictionary]);

  const handleDoubleClick = (event: MapEvent) => {
    // Get the clicked area by the layer.
    const feature = event.features?.find((feat) => feat.layer.id === POLYGON_LAYER.id);
    const areaId = feature?.properties?.areaId;

    // Set base division to the clicked division.
    const clickedDivision = divisions?.find((division) => division.geo_targeting_ref.id === areaId);
    const hasChildren = divisions?.some((division) => division.parent_division === clickedDivision?.id);
    const clickedOutsideBaseDivision = clickedDivision?.parent_division !== baseDivisionId;

    // If the clicked division has children, set it as the base division.
    if (clickedDivision && hasChildren) {
      setBaseDivisionId?.(clickedDivision.id);

      analyticsService.crossProductEvents.mapLevelChanges({
        origin: AnalyticOriginPropertyValues.DOUBLE_CLICK,
        filterValue: generateLevelFilterValue(clickedDivision?.depth),
        areaValue: generateAreaFilterValue(clickedDivision?.geo_targeting_ref?.name),
      });
    } else if ((clickedOutsideBaseDivision || !areaId) && baseDivisionId) {
      // If clicked outside of the base division, set the base division parent as the base division.
      const baseDivision = divisionsDictionary?.[baseDivisionId];
      const fallbackBaseDivision = divisions?.find((division) => division.depth === 0);
      setBaseDivisionId?.(baseDivision?.parent_division || fallbackBaseDivision?.id);

      analyticsService.crossProductEvents.mapLevelChanges({
        origin: AnalyticOriginPropertyValues.DOUBLE_CLICK,
        filterValue: generateLevelFilterValue(clickedDivision?.depth),
        areaValue: generateAreaFilterValue(clickedDivision?.geo_targeting_ref?.name),
      });
    }
    setSelectedAreaIds([]);
  };

  const handleSingleClick = useCallback(
    (event: MapEvent) => {
      // Get the clicked area by the layer.
      const feature = event.features?.find((feat) => feat.layer.id === POLYGON_LAYER.id);
      const areaId = feature?.properties?.areaId;

      if (!areaId) return;

      const selectedDivision = divisions.find((division) => division.geo_targeting_ref.id === areaId);

      if (selectedAreaIds.includes(areaId)) {
        setSelectedAreaIds(selectedAreaIds.filter((id) => areaId !== id));
        analyticsService.crossProductEvents.mapLevelSelected({
          origin: AnalyticOriginPropertyValues.MAP_CLICK,
          filterValue: generateLevelFilterValue(selectedDivision?.depth),
          areaValue: generateAreaFilterValue(selectedDivision?.geo_targeting_ref?.name),
          changeType: AnalyticEventsChangeTypes.HIDE,
        });
      } else {
        setSelectedAreaIds([...selectedAreaIds, areaId]);

        analyticsService.crossProductEvents.mapLevelSelected({
          origin: AnalyticOriginPropertyValues.MAP_CLICK,
          filterValue: generateLevelFilterValue(selectedDivision?.depth),
          areaValue: generateAreaFilterValue(selectedDivision?.geo_targeting_ref?.name),
          changeType: AnalyticEventsChangeTypes.SHOW,
        });
      }
    },
    [divisions, selectedAreaIds, setSelectedAreaIds],
  );

  const onClickHandleWithDoubleClick = useSingleAndDoubleClick(
    (event: MapEvent) => handleSingleClick(event),
    (event: MapEvent) => (hasLondonFeature ? handleDoubleClick(event) : handleSingleClick(event)),
  );

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <ReactMapGLHOC
      {...viewport}
      scrollZoom={!renderAsWidget}
      onClick={renderAsWidget ? undefined : onClickHandleWithDoubleClick}
      reuseMaps
      attributionControl={false}
      doubleClickZoom={!hasLondonFeature}
      layerData={layerData}
      labelData={labelData}
      hoveredAreaFilter={hoveredAreaFilter}
      selectedAreaFilter={selectedAreaFilter}
      ref={mapRef}
      renderType={renderType}
      onViewportChange={setViewport}
    />
  );
};
