import classNames from 'classnames';
import React from 'react';
import styles from './Score.module.scss';

interface Props {
  value?: number;
  maxScore?: number;
  isBold?: boolean;
}

export const Score = (props: Props) => {
  const { value, maxScore, isBold } = props;
  return (
    <span
      className={classNames(styles.score, {
        [styles.comparisonScore]: value === maxScore,
        [styles.bold]: isBold,
      })}
    >
      {value?.toFixed(1)}%
    </span>
  );
};
