/* eslint-disable max-statements */
import { ChartConfiguration } from 'chart.js';
import { GraphTooltip } from 'components/ScoreCardWidget/components/OverallScoreGraph/GraphTooltip/GraphTooltip';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ScorePerDateRange } from 'types/score';
import { chartConfig } from 'utils/chartWidget';
import { GraphContext } from './misc';

/*
  This function is used to generate the chart configuration for the overall score graph.
 */
export const getOverallScoresChartConfig = (
  overallScores: ScorePerDateRange[],
  disableTooltip?: boolean,
): Omit<ChartConfiguration, 'data'> => {
  /*
    This function, we build inner HTML with react components to be used as tooltip content.
  */
  const externalHandler = (context: GraphContext): void => {
    // Tooltip Element.
    let tooltipEl = document.getElementById('chartjs-tooltip');
    const reactElement = React.createElement(GraphTooltip, { context, overallScores });

    // Create element on first render.
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip.
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Inject react element.
    tooltipEl.innerHTML = ReactDOMServer.renderToString(reactElement);

    // Biding position.
    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles.
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
    tooltipEl.style.pointerEvents = 'none';
  };

  return {
    type: chartConfig.type,
    options: {
      ...chartConfig.options,
      interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false,
      },
      plugins: {
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: disableTooltip ? () => {} : externalHandler,
        },
      },
    },
  };
};
