import { ZCDButton } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { AnalyticWidgetNames } from 'constants/analytics';
import { TabKeys } from 'constants/tabs';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import styles from './TopConcernsWidgetHeader.module.scss';

export const TopConcernsWidgetHeader = (): ReactElement => {
  const { t: translate } = useTranslation();
  const activeFilters = useFilterNames();
  const navigate = useNavigate();

  const handleRedirectToFeed = () => {
    navigate(`/${TabKeys.FEED}`);

    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('blockwiseSummaryTab.overallWidgets.topConcernsWidget.goToFeed'),
      widgetName: AnalyticWidgetNames.TOP_CONCERNS_WIDGET,
      activeFilters,
    });
  };

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <div className={styles.title}>{translate('blockwiseSummaryTab.overallWidgets.topConcernsWidget.title')}</div>
      <ZCDButton
        text={translate('blockwiseSummaryTab.overallWidgets.topConcernsWidget.goToFeed')}
        variant="link"
        onClick={handleRedirectToFeed}
      />
    </Flex>
  );
};
