/* eslint-disable max-statements */
import { SurveyType, SurveyGroup } from '@zencity/survey-types';
import { getDateRangeComparison } from 'components/DateRangeCompareFilter/utils';
import { DateRangeContextInterface, DateRangeFilterOption } from 'contexts/DateRangeContext';
import { useEffect, useState } from 'react';
import { logger } from 'utils/community-survey/logger';
import { getDashboardDateRanges, getMostRecentSurveyGroup } from 'utils/dateRange';

export const useDashboardDateRanges = (
  surveyType: SurveyType,
): {
  dateRangeDataContextData: Omit<DateRangeContextInterface, 'scoreDateRangeFilters'>;
  currentSurveyGroup?: SurveyGroup;
} => {
  // Date Range Context.
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilterOption>();
  const [comparisonDateRange, setComparisonDateRange] = useState<DateRangeFilterOption>();
  const [firstSubmissionDate, setFirstSubmissionDate] = useState<string>();
  const [dashboardDateRanges, setDashboardDateRanges] = useState<DateRangeFilterOption[]>();
  const [completedCyclesDateRanges, setCompletedCyclesDateRanges] = useState<DateRangeFilterOption[]>();
  const [clientCadence, setClientCadence] = useState<string>();
  const [isContextLoaded, setIsContextLoaded] = useState<boolean>(false);
  const [currentSurveyGroup, setCurrentSurveyGroup] = useState<SurveyGroup>();

  useEffect(() => {
    async function fetchDateRangeData() {
      try {
        const surveyGroup = await getMostRecentSurveyGroup(surveyType);
        setCurrentSurveyGroup(surveyGroup);
        if (surveyGroup) {
          const { scoreDateRangeFilterOptions, dateRangeFilterOptions, first_submission_date } =
            await getDashboardDateRanges(surveyGroup);
          setDashboardDateRanges(dateRangeFilterOptions);
          setCompletedCyclesDateRanges(scoreDateRangeFilterOptions);
          if (dateRangeFilterOptions.length > 2) {
            setSelectedDateRange(dateRangeFilterOptions[0]);
            const defaultComparisonDateRange = getDateRangeComparison({
              selectedDateRange: dateRangeFilterOptions[0],
              completedCyclesDateRanges: scoreDateRangeFilterOptions,
            });
            setComparisonDateRange(defaultComparisonDateRange);
          }
          setFirstSubmissionDate(first_submission_date);
          setClientCadence(surveyGroup.cadence);
          setIsContextLoaded(true);
        } else {
          setIsContextLoaded(true);
        }
      } catch (error) {
        logger.error(error);
        setIsContextLoaded(false);
      }
    }
    fetchDateRangeData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    dateRangeDataContextData: {
      selectedDateRange,
      setSelectedDateRange,
      firstSubmissionDate,
      setFirstSubmissionDate,
      dashboardDateRanges,
      setDashboardDateRanges,
      completedCyclesDateRanges,
      setCompletedCyclesDateRanges,
      clientCadence,
      setClientCadence,
      isContextLoaded,
      setIsContextLoaded,
      comparisonDateRange,
      setComparisonDateRange,
    },
    currentSurveyGroup,
  };
};
