import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SuperAdminBadge.module.scss';

/**
 * A "Super Admin Badge".
 *
 * This component is usually used to indicate features that are only relevant
 * for Super Admin users.
 */
export const SuperAdminBadge: React.FC = function SuperAdminBadge(): React.ReactElement {
  const { t: translate } = useTranslation();

  return <span className={styles.superAdminBadge}>{translate('superAdmin.badgeLabel')}</span>;
};
