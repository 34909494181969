import { AreasWithScoresObject } from 'src/types/areas';
import { ScoreFilters } from 'types/dateRange';
import { getScorePerDateRange } from 'utils/dateRange';

interface MinMax {
  min: number;
  max: number;
}

export enum ColorDictionary {
  RED_1 = '#DB6D73',
  RED_2 = '#F2ADB1',
  RED_3 = '#FAE3E5',
  GRAY = '#CBCBCB',
  GREEN_3 = '#D2E8CF',
  GREEN_2 = '#9DCFA4',
  GREEN_1 = '#76B07E',
  NOT_AVAILABLE_GRAY = '#F7F7F7',
}

export interface ColorStep {
  low: number;
  high: number;
  color: {
    low: ColorDictionary;
    high: ColorDictionary;
  };
}

/**
 * The colors and their starting (low) numbers are directly alligned with the
 * POLYGON_LAYER used in the Map for community survey MapView component.
 * Any changes to them should also be reflected there as well.
 */
export const COLOR_MAP = [
  {
    low: -1,
    high: -1,
    color: {
      low: ColorDictionary.NOT_AVAILABLE_GRAY,
      high: ColorDictionary.NOT_AVAILABLE_GRAY,
    },
  },
  {
    low: 0,
    high: 25,
    color: {
      low: ColorDictionary.RED_1,
      high: ColorDictionary.RED_2,
    },
  },
  {
    low: 26,
    high: 35,
    color: {
      low: ColorDictionary.RED_2,
      high: ColorDictionary.RED_3,
    },
  },
  {
    low: 36,
    high: 45,
    color: {
      low: ColorDictionary.RED_3,
      high: ColorDictionary.GRAY,
    },
  },
  {
    low: 46,
    high: 60,
    color: {
      low: ColorDictionary.GRAY,
      high: ColorDictionary.GREEN_3,
    },
  },
  {
    low: 61,
    high: 70,
    color: {
      low: ColorDictionary.GREEN_3,
      high: ColorDictionary.GREEN_2,
    },
  },
  {
    low: 71,
    high: 85,
    color: {
      low: ColorDictionary.GREEN_2,
      high: ColorDictionary.GREEN_1,
    },
  },
  {
    low: 86,
    high: 100,
    color: {
      low: ColorDictionary.GREEN_1,
      high: ColorDictionary.GREEN_1,
    },
  },
];

export const getMinMax = ({
  areas,
  dateRangeFilter,
}: {
  areas: AreasWithScoresObject;
  dateRangeFilter: ScoreFilters;
}): MinMax => {
  // Make a deep copy of the areas object.
  const areasWithoutOverallScore = { ...areas };
  if (areasWithoutOverallScore.overall_score) {
    delete areasWithoutOverallScore.overall_score;
  }
  const positiveScores = Object.values(areasWithoutOverallScore).map((area) => {
    const selectedFilterScores =
      getScorePerDateRange({
        scorePerDateRange: area.scoresPerPeriod,
        dateRangeFilter,
      })?.scores || null;
    // If scores are null, need to return -1 for the map color rendering.
    return selectedFilterScores ? selectedFilterScores.positive : -1;
  });
  return {
    max: Math.max(...positiveScores),
    min: Math.min(...positiveScores),
  };
};

export const trim = (minMaxValues: MinMax, colorList: ColorStep[]): ColorStep[] =>
  colorList.filter((color) => color.high >= minMaxValues.min && color.low <= minMaxValues.max);
