/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Client } from '@zencity/survey-types';
import { User } from '../types/user';
import { Client as ZcClient } from '../types/client';

export interface AccountsContextInterface {
  user: User;
  client: ZcClient;
  leanSurveyClient?: Client;
}

export const AccountsContext = React.createContext<AccountsContextInterface>({} as AccountsContextInterface);
