import { ZCDSpinner } from '@zencity/common-ui';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { DivisionQuestionDropdown } from 'components/DivisionQuestionDropdown/DivisionQuestionDropdown';
import { EmptyStateWithCustomIcon } from 'components/EmptyStateWithCustomIcon/EmptyStateWithCustomIcon';
import { ErrorBoundaryWithIcon } from 'components/ErrorBoundaryWithIcon/ErrorBoundaryWithIcon';
import { ColorLegend } from 'components/Map/ColorLegend/ColorLegend';
import { OverallScoreByArea } from 'components/MapGraphWidget/OverallScoreByArea/OverallScoreByArea';
import { SatisfactionScoresByAreaList } from 'components/MapGraphWidget/SatisfactionScoresByAreaList/SatisfactionScoresByAreaList';
import { WidgetBox } from 'components/MapGraphWidget/WidgetBox/WidgetBox';
import { MapView } from 'components/MapView/MapView';
import { AnalyticOriginPropertyValues } from 'constants/analytics';
import { MapRenderTypes } from 'constants/map';
import { AccountsContext } from 'contexts/AccountsContext';
import { MapsContext } from 'contexts/MapsContext';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { DivisionsDictionary } from 'types/division';
import { DivisionWithPolygon } from '@zencity/survey-types';
import { FeatureFlag } from 'types/featureFlag';
import { userHasCorePlatformFeature } from 'utils/user';
import { generateAreaFilterValue, generateLevelFilterValue } from 'utils/utils';
import styles from './Map.module.scss';

interface GetBreadcrumbsByBaseDivision {
  baseDivisionId?: number;
  divisionsDictionary?: DivisionsDictionary;
}
const getBreadcrumbsByBaseDivision = ({ baseDivisionId, divisionsDictionary }: GetBreadcrumbsByBaseDivision) => {
  if (!baseDivisionId || !divisionsDictionary) return [];
  // Add each division parent to the breadcrumbs until we reach the base division
  const breadcrumbs = [];
  let currentDivision: DivisionWithPolygon | null = divisionsDictionary?.[baseDivisionId];
  while (currentDivision) {
    breadcrumbs.unshift({
      label: currentDivision.geo_targeting_ref.name,
      value: currentDivision.id,
    });
    currentDivision = currentDivision.parent_division ? divisionsDictionary?.[currentDivision.parent_division] : null;
  }
  return breadcrumbs;
};

// eslint-disable-next-line id-length
export const Map = (): React.ReactElement => {
  const { t: translate } = useTranslation();
  const {
    areasWithScoresData,
    isLoading,
    divisionAreaScoresByQuestion,
    baseDivisionId,
    divisionsDictionary,
    setBaseDivisionId,
    setSelectedAreaIds,
    selectedGenericQuestionId,
    setAreasWithScoresData,
  } = useContext(MapsContext);
  const breadcrumbs = getBreadcrumbsByBaseDivision({ baseDivisionId, divisionsDictionary });
  const { user, client } = useContext(AccountsContext);
  const hasLondonFeature = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);

  useEffect(() => {
    // Setting the Division scores per Question. These will be used in the Map Tab, unlike the Summary tab, which sets Division scores per Aspect.
    if (hasLondonFeature && divisionAreaScoresByQuestion?.[selectedGenericQuestionId]) {
      setAreasWithScoresData(divisionAreaScoresByQuestion[selectedGenericQuestionId]?.divisionScores);
    }
  }, [divisionAreaScoresByQuestion, hasLondonFeature, selectedGenericQuestionId, setAreasWithScoresData]);

  if (isLoading) {
    return (
      <div className={styles.loadingSpinner}>
        <ZCDSpinner size={48} />
      </div>
    );
  }

  if (!Object.keys(areasWithScoresData).length) {
    return (
      <EmptyStateWithCustomIcon
        description={translate('map.emptyState.description')}
        title={translate('map.emptyState.title')}
        // eslint-disable-next-line i18next/no-literal-string
        iconPath="location-pin.svg"
      />
    );
  }

  const handleBreadcrumbClick = (value: number) => {
    setBaseDivisionId?.(value);
    setSelectedAreaIds([]);
    const selectedDivision = divisionsDictionary?.[value];

    analyticsService.crossProductEvents.mapLevelChanges({
      origin: AnalyticOriginPropertyValues.BREADCRUMBS,
      filterValue: generateLevelFilterValue(selectedDivision?.depth),
      areaValue: generateAreaFilterValue(selectedDivision?.geo_targeting_ref?.name),
    });
  };

  return (
    <ErrorBoundaryWithIcon
      errorTitle={translate('errorBoundary.title')}
      errorDescription={translate('errorBoundary.description')}
    >
      <main className={styles.mapScreen}>
        {hasLondonFeature && breadcrumbs.length > 0 && (
          <div className={styles.breadcrumbsContainer}>
            <Breadcrumbs crumbs={breadcrumbs} onClick={handleBreadcrumbClick} />
          </div>
        )}
        <MapView renderType={MapRenderTypes.SCREEN} />
        <div className={styles.colorLegend}>
          <ColorLegend />
        </div>
        <div className={styles.satisfcationScoresByAreas}>
          <WidgetBox>
            {Object.keys(divisionAreaScoresByQuestion).length !== 0 && <DivisionQuestionDropdown />}
            <OverallScoreByArea />
            <SatisfactionScoresByAreaList />
          </WidgetBox>
        </div>
      </main>
    </ErrorBoundaryWithIcon>
  );
};
