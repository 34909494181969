import { ColorLegend } from 'components/Map/ColorLegend/ColorLegend';
import { MapView } from 'components/MapView/MapView';
import { MapRenderTypes } from 'constants/map';
import React from 'react';
import styles from './MapWidget.module.scss';

export const MapWidget = (): JSX.Element => (
  <div className={styles.mapWidget}>
    <MapView renderType={MapRenderTypes.SUMMARY_WIDGET} />
    <div className={styles.colorLegend}>
      <ColorLegend />
    </div>
  </div>
);
