import { ChartConfiguration } from 'chart.js';
import { differenceInDays, format, getMonth, getWeek, parseISO } from 'date-fns';
import { CxScoreTrendBreakdown } from 'types/cx_survey';
import { DateRange } from 'types/dateRange';
import { chartConfig, DateLabelFormat } from 'utils/chartWidget';

export enum Granularity {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface DateDataForTrend {
  granularity: Granularity;
  startDate: Date;
  endDate: Date;
  dayCount: number;
  labelDateFormat: string;
}

export const getDateDataForTrend = (dateRange: DateRange): DateDataForTrend => {
  const startDate = parseISO(dateRange.startDate);
  const endDate = parseISO(dateRange.endDate);
  const dayCount = differenceInDays(endDate, startDate);
  let granularity = Granularity.MONTH;
  let labelDateFormat = DateLabelFormat.ABBREVIATED_MONTH_AND_YEAR;
  if (dayCount <= 31) {
    // dayCount has no inherent meaning apart from as a simple
    // mechanism to decide on the granularity of data for the chart. Discussion:
    // https://zencity.slack.com/archives/C03R6E332E5/p1662997551165749?thread_ts=1662997116.295799&cid=C03R6E332E5
    // if we have a date filter that ensures we are showing data for a month of time or less
    // then we show trend data points with weekly granularity
    // rather than the monthly granularity default.
    // This is just a simple/naive mechanism to ensure we show a palatable time series of data
    // as this is ultimately displayed on a small graph providing a high-level view of a trend
    // and is not for deep diving into specifics of data.
    granularity = Granularity.WEEK;
    labelDateFormat = DateLabelFormat.ABBREVIATED_DAY_FOR_WEEK;
  }
  return {
    dayCount,
    granularity,
    startDate,
    endDate,
    labelDateFormat,
  };
};

const groupForGranularity = (breakdown: CxScoreTrendBreakdown[], granularity: Granularity) => {
  // given a granularity, group trend data using that granularity.
  // granularities are date range based, so, based on the value we assign a
  // particular dateGroupingValueFn callable to run on our date
  // this gives us number for each possible group (0-11 for months, 0-51 for weeks)
  // these numbers are used as keys of an object, and the value for each key is an array of scores
  // populated as we reduce trendData.
  let dateGroupingValueFn = getMonth;
  if (granularity === Granularity.WEEK) {
    dateGroupingValueFn = getWeek;
  }
  const grouped = breakdown.reduce((group, dataPoint) => {
    const date = parseISO(dataPoint.submitted_at);
    // +1: dateGroupingValueFn returns a number which is an index from a zero-based array.
    // we are adding 1 so value is logical for humans (e.g: january is month 1 and not month 0).
    const groupValue = dateGroupingValueFn(date) + 1;
    // eslint-disable-next-line no-param-reassign
    group[groupValue] = group[groupValue] ?? [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    group[groupValue].push(dataPoint.score);
    return group;
  }, {} as Record<string, []>);
  return grouped;
};

export const makeGranularBreakdown = (breakdown: CxScoreTrendBreakdown[], granularity: Granularity): number[] => {
  // Trend data is day based, and yet we show trend data by week or by month.
  // So, we have a custom group by function to group trend data by week or month values ...
  const groupedScores = groupForGranularity(breakdown, granularity);
  // Then, map over our data that is grouped to the appropriate granularity
  // To return the actual scores for that granular data point
  // e.g. if we are grouping by week, one of our object entries will look like:
  // [1, [67, 49, 19, 89, 78, 92, 89]]
  // where the first value in the array is the week number, and the second value is an array of the scores
  // for each day of that week. We then calculate the average score for that week and return it
  // (we dont care about the actual week as a value, only as a grouping mechanism)
  const granularScores = Object.entries(groupedScores).map(
    (entry) => Math.round(entry[1].reduce((curr, next) => curr + next, 0) / entry[1].length) // eslint-disable-line prettier/prettier
  );
  return granularScores;
};

export const makeScoreLabelFromDate = (date: Date, labelDateFormat: string): string =>
  format(date, labelDateFormat).toUpperCase();

export const getCXScoreChartChartConfig = (): Omit<ChartConfiguration, 'data'> => ({
  type: chartConfig.type,
  options: {
    ...chartConfig.options,
    interaction: {
      mode: 'index',
      axis: 'x',
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
      },
    },
  },
});
