/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { ZCDSpinner, ZCDTable } from '@zencity/common-ui';
import classNames from 'classnames';
import { Flex } from 'components/Flex/Flex';
import { DashboardContext } from 'contexts/DashboardContext';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { FiltersContext } from 'contexts/FiltersContext';
import useCompareDateRangeScoreLabel from 'hooks/score/compareDateRangeLabel';
import useScoreDateRangeFilter from 'hooks/score/dateRangeFilter';
import useDateRangeScoreLabel from 'hooks/score/dateRangeLabel';
import { useCustomQuery } from 'hooks/useCustomQuery';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { ExtraFiltersParams } from 'types/misc';
import { QuestionChoice } from 'types/questionsScores';
import { SurveyGroupOutletContext } from 'types/surveyGroup';
import { TreeSelectSelectionKeysType } from 'utils/blockwise/filters';
import { ReactQueryKeys } from 'utils/reactQueryKeys';
import { crossQuestionsTable, formatCrossQuestion } from 'utils/analysis';
import { fetchCrossQuestionsData } from '../helpers';
import tableStyles from '../AnalysisTable.module.scss';
import { getCrossTableColumns } from './columns';
import styles from './CrossQuestionTable.module.scss';

const MAX_CHOICES_LIMIT = 50;

interface Props {
  questionChoices: QuestionChoice[];
  comparingChoices?: QuestionChoice[];
  isNoScoreQuestion?: boolean;
}

export const CrossQuestionTable: React.FC<Props> = function CrossQuestionTable(props: Props): React.ReactElement {
  const { questionChoices, comparingChoices, isNoScoreQuestion } = props;
  const { expandedQuestionId, crossSelectedQuestionId } = useContext(DashboardContext);
  const { surveyGroup } = useOutletContext<SurveyGroupOutletContext & ExtraFiltersParams>();
  const { dateRangeFilter } = useScoreDateRangeFilter();
  const { comparisonDateRange } = useContext(DateRangeContext);
  const { dateRangeLabel } = useDateRangeScoreLabel();
  const { comparisonDateRangeLabel } = useCompareDateRangeScoreLabel();
  const { selectedDemographics, selectedDivisionId } = useContext(FiltersContext);
  const { t: translate } = useTranslation();

  const {
    response: crossQuestionResponse,
    isFetching: areCrossQuestionDataLoading,
    error: crossQuestionDataError,
  } = useCustomQuery({
    params: {
      surveyGroupId: surveyGroup.id,
      questionId: expandedQuestionId as number,
      crossQuestionId: crossSelectedQuestionId as number,
      startDate: dateRangeFilter?.startDate as string,
      endDate: dateRangeFilter?.endDate as string,
      demographics: selectedDemographics as TreeSelectSelectionKeysType,
      division: selectedDivisionId,
    },
    queryKey: ReactQueryKeys.CROSS_QUESTIONS_SCORES,
    requestFunction: fetchCrossQuestionsData,
    enableRequest: !!(
      surveyGroup.id &&
      dateRangeFilter?.startDate &&
      dateRangeFilter?.endDate &&
      expandedQuestionId &&
      crossSelectedQuestionId
    ),
  });

  const formattedCrossQuestionData = useMemo(
    () => (crossQuestionResponse ? formatCrossQuestion(crossQuestionResponse.cross_choices_data, questionChoices) : []),
    [crossQuestionResponse, questionChoices],
  );

  const {
    response: comparisonCrossQuestionResponse,
    isFetching: areComparisonCrossQuestionDataLoading,
    error: comparisonCrossQuestionDataError,
  } = useCustomQuery({
    params: {
      surveyGroupId: surveyGroup.id,
      questionId: expandedQuestionId as number,
      crossQuestionId: crossSelectedQuestionId as number,
      startDate: comparisonDateRange?.startDate as string,
      endDate: comparisonDateRange?.endDate as string,
      demographics: selectedDemographics as TreeSelectSelectionKeysType,
      division: selectedDivisionId,
    },
    queryKey: ReactQueryKeys.COMPARISON_CROSS_QUESTIONS_SCORES,
    requestFunction: fetchCrossQuestionsData,
    enableRequest: !!(
      surveyGroup.id &&
      comparisonDateRange?.startDate &&
      comparisonDateRange?.endDate &&
      expandedQuestionId &&
      crossSelectedQuestionId
    ),
  });

  const formattedComparisonCrossQuestionData = useMemo(
    () =>
      comparisonCrossQuestionResponse
        ? formatCrossQuestion(comparisonCrossQuestionResponse.cross_choices_data, comparingChoices)
        : [],
    [comparisonCrossQuestionResponse, comparingChoices],
  );

  const tableData = useMemo(
    () => crossQuestionsTable(formattedCrossQuestionData, formattedComparisonCrossQuestionData),
    [formattedComparisonCrossQuestionData, formattedCrossQuestionData],
  );

  const columns = useMemo(
    () =>
      tableData.length > 0
        ? getCrossTableColumns({
            tableData,
            dateRangeLabel: dateRangeLabel as string,
            comparisonDateRangeLabel,
            crossQuestionData: formattedCrossQuestionData,
            comparisonCrossData: formattedComparisonCrossQuestionData,
            isNoScoreQuestion,
          })
        : [],
    [
      isNoScoreQuestion,
      comparisonDateRangeLabel,
      dateRangeLabel,
      formattedComparisonCrossQuestionData,
      formattedCrossQuestionData,
      tableData,
    ],
  );
  const hasError = crossQuestionDataError || comparisonCrossQuestionDataError;
  if (hasError) console.error(hasError);
  const isLoading = areCrossQuestionDataLoading || areComparisonCrossQuestionDataLoading;
  const hasCrossQuestionData =
    crossQuestionResponse?.cross_choices_data && crossQuestionResponse.cross_choices_data.length > 0;
  const hasComparisonQuestionData =
    comparisonCrossQuestionResponse?.cross_choices_data &&
    comparisonCrossQuestionResponse.cross_choices_data.length > 0;
  const showTable = (hasCrossQuestionData || hasComparisonQuestionData) && tableData.length > 0;
  if (isLoading) {
    return (
      <Flex justifyContent="center">
        <ZCDSpinner />
      </Flex>
    );
  }
  if (showTable) {
    return (
      <div className={classNames(tableStyles.tableWrapper, styles.crossQuestionTable)}>
        <ZCDTable tableData={tableData} columnArray={columns} maxItemsLimit={MAX_CHOICES_LIMIT} />
      </div>
    );
  }
  return <p className={styles.noData}>{translate('resultsScreen.crossTable.noData')}</p>;
};
