import { BreakdownRowTitle } from 'components/BreakdownRow/BreakdownRowTitle/BreakdownRowTitle';
import { Flex } from 'components/Flex/Flex';
import { Spacer } from 'components/Spacer/Spacer';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { truncateString } from 'utils/utils';
import styles from './NoScoresRow.module.scss';

interface Props {
  questionText: string;
  topQuestionChoiceScore: number;
  choiceText: string;
  showUnrepresentativeIndicator?: boolean;
  totalSubmissions?: number;
}

export const NoScoresRow = (props: Props): ReactElement => {
  const { choiceText, topQuestionChoiceScore, totalSubmissions, questionText, showUnrepresentativeIndicator } = props;
  const { t: translate } = useTranslation();
  const topQuestionChoiceScoreToDisplay = Math.round(topQuestionChoiceScore);

  return (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <BreakdownRowTitle
        text={questionText}
        showUnrepresentativeIndicator={showUnrepresentativeIndicator}
        totalSubmissions={totalSubmissions}
      />
      <div className={styles.responseContainer}>
        <span className={styles.topResponse}>{translate('resultsScreen.noScoresRow.topResponse')}</span>
        <Flex alignItems="center">
          <span className={styles.submissionsPercentage}>{topQuestionChoiceScoreToDisplay}%</span>
          <Spacer orientation="horizontal" size="xsmall" />
          <span className={styles.middot} />
          <Spacer orientation="horizontal" size="xsmall" />
          <span className={styles.choiceText}>{`"${truncateString(choiceText, 24)}"`}</span>
        </Flex>
      </div>
    </Flex>
  );
};
