import { Question } from '@zencity/survey-types';
import React from 'react';

export interface OpenEndedQuestionFeedContextInterface {
  openEndedQuestions: Question[];
  areOpenEndedQuestionsLoading: boolean;
}

export const OpenEndedQuestionFeedContext = React.createContext<OpenEndedQuestionFeedContextInterface>({
  openEndedQuestions: [],
  areOpenEndedQuestionsLoading: false,
});
