import { TreeNode } from '@zencity/common-ui';
import { AnalyticEventsChangeTypes, AnalyticFilterPropertyValues } from 'constants/analytics';
import { DateRangeFilterOption } from 'contexts/DateRangeContext';
import { FilterOption } from 'contexts/FiltersContext';
import { ScorePerDateRange } from 'types/score';
import { TreeSelectSelectionKeysType } from './blockwise/filters';
import { isFeedLatestResult } from './dateRange';

export function sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function truncateString(string: string, number: number): string {
  if (string.length <= number) {
    return string;
  }
  return `${string.substring(0, number)}...`;
}

export function sortScoresPerDateRange(
  scorePerDateRange: ScorePerDateRange[],
  order: 'asc' | 'desc' = 'asc',
): ScorePerDateRange[] {
  return scorePerDateRange.sort((elementA, elementB) => {
    if (order === 'asc') {
      return Date.parse(elementA.startDate) - Date.parse(elementB.startDate);
    }
    return Date.parse(elementB.startDate) - Date.parse(elementA.startDate);
  });
}

export const isPathIncluded = (path: string, currentUrl: string): boolean => {
  const urlPath = new URL(currentUrl).pathname;
  return urlPath.includes(path);
};

/*
 * Returns the value of the level filter for the analytics and easily future changes.
 * Currently we return the following: `level <DEPTH>`
 */
export const generateLevelFilterValue = (depth?: number): string => {
  if (!depth && depth !== 0) {
    return AnalyticEventsChangeTypes.UNKNOWN;
  }
  return `${AnalyticFilterPropertyValues.LEVEL} ${depth}`;
};

/*
 * Returns the value of the area filter for the analytics and easily future changes.
 */
export const generateAreaFilterValue = (areaName?: string): string => {
  if (!areaName) {
    return AnalyticEventsChangeTypes.UNKNOWN;
  }
  return areaName;
};

export const formatTreeSelection = (
  options: FilterOption[] | TreeNode[],
  selectedOptions?: TreeSelectSelectionKeysType,
) => {
  const result: { [key: string]: string[] } = {};
  options.forEach((option) => {
    const selectedChildren: string[] = [];
    // Check if the option is selected, or if it has children and one of them is selected
    if (option?.key && (selectedOptions?.[option?.key]?.checked || selectedOptions?.[option.key]?.partialChecked)) {
      option?.children?.forEach((child) => {
        if (child.key && selectedOptions?.[child.key]?.checked) {
          if (child.label) {
            selectedChildren.push(child.label?.toLowerCase());
          }
        }
      });
      if (option.label) {
        result[option.label?.toLowerCase()] = selectedChildren;
      }
    }
  });
  return result;
};

/*
 * Returns the value of the value for data/comparison filter for the analytics and easily future changes.
 */
export const generateDemographicsFilterValue = (selectDateRange?: DateRangeFilterOption) => {
  const isLatest = isFeedLatestResult(selectDateRange);
  return isLatest ? AnalyticFilterPropertyValues.LATEST_RESULTS : AnalyticFilterPropertyValues.COMPLETED_CYCLE;
};

/*
 * Checking the numeric value of the cycle number to be sent to analytics,
 * in case the user selects the latest result option the cycle number will be 1.
 */
export const getCycleNumber = (
  selectDateRange?: DateRangeFilterOption,
  completedCyclesDateRanges?: (undefined | DateRangeFilterOption)[],
) => {
  const cycleIndex = completedCyclesDateRanges?.findIndex((cycle) => cycle?.key === selectDateRange?.key);
  return cycleIndex !== undefined && cycleIndex !== -1 ? cycleIndex + 1 : 1;
};
