import { Client } from '@zencity/survey-types';

export const mockClient = {
  segmentExperience: {},
  timezone: 'America/Los_Angeles',
  id: '3',
};

export const mockLeanClient: Client = {
  id: 3,
  name: 'Test Client',
  machine_name: 'test_client',
  zc_client_id: '3',
  state: 'CA',
};
