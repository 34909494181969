export default {
  apiUrls: {
    vaultSections: {
      answers: '/answers/',
      answersLeadingSubTopics: '/answers_leading_sub_topics/',
      questions: '/questions/',
      groupedQuestions: '/grouped_questions/',
      survey: '/survey/',
      surveyGroup: '/survey_group/',
      surveyReport: '/survey_report/',
      surveyRequest: '/survey_request/',
      typeformExamples: '/typeform_examples/',
      exportSubmissions: '/export_community_asks_submissions/',
      rakeWeighterExecutions: '/rake_weighter_executions/',
      divisions: '/divisions/',
      classificationSubTopics: '/classification_sub_topics/',
    },
  },
  surveyApiUrls: {
    vaultSections: {
      communitySurveyScores: '/community_survey_scores/',
    },
  },
  mapbox: {
    token: 'pk.eyJ1IjoiemVuY2l0eSIsImEiOiJjaWZzOGxwNm4wMGk1dDRtN3hjeWl6M2VvIn0.nNv1qxUG7fIJiQW46ig3lw',
    tilesUrl: 'mapbox://styles/zencity/ckzpf8nen004015npg4plhd9q',
  },
  simulateAmplitude: false,
  communityAsks: {
    baseRouterUrl: `${process.env.BASE_ROUTER_PREFIX ?? ''}community-asks`,
  },
  communitySurveys: {
    baseRouterUrl: `${process.env.BASE_ROUTER_PREFIX ?? ''}community-survey`,
  },
  customerExperience: {
    baseRouterUrl: `${process.env.BASE_ROUTER_PREFIX ?? ''}customer-experience`,
  },
  blockwise: {
    baseRouterUrl: `${process.env.BASE_ROUTER_PREFIX ?? ''}blockwise`,
  },
  pulse: {
    baseRouterUrl: `${process.env.BASE_ROUTER_PREFIX ?? ''}pulse`,
  },
  shouldMockRequests: false,
};
