import { Flex } from 'components/Flex/Flex';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ZCDFilter } from '@zencity/common-ui';
import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import { SelectOption } from 'types/selectOption';
import styles from './DemographicGroupHeader.module.scss';

interface Props {
  selectedDemographicType?: string;
  handleDemographicTypeChange: (demographicType: SelectOption) => void;
}

export const DemographicGroupHeader = (props: Props): JSX.Element => {
  const { selectedDemographicType, handleDemographicTypeChange } = props;
  const { demographicTypes } = useContext(SummaryAspectWidgetContext);
  const { t: translate } = useTranslation();

  const selectedDemographicTypeOption = demographicTypes?.find(
    (demographicType) => demographicType.value === selectedDemographicType,
  );

  return (
    <div className={styles.demographicGroupIemHeader}>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <span className={styles.title}>{translate('blockwiseSummaryTab.demographicGroups.title')}</span>
        <ZCDFilter
          key={selectedDemographicTypeOption?.value}
          value={selectedDemographicTypeOption}
          defaultValue={selectedDemographicTypeOption}
          onChange={(newValue) => handleDemographicTypeChange(newValue as SelectOption)}
          options={demographicTypes}
          selectedValueLabel={translate('blockwiseSummaryTab.demographicGroups.demographicsFilterLabel')}
        />
      </Flex>
    </div>
  );
};
