/* eslint-disable id-denylist */
import { DateRangeFilterOption } from 'contexts/DateRangeContext';
import { mockScores } from 'mocks/data/areaScores.mock';
import { mockDemographicsScores } from 'mocks/data/demographics.mock';
import { mockOverallScores } from 'mocks/data/overallScore.mock';
import { mockGenericQuestionScores } from 'mocks/data/genericQuestionScores.mock';
import path from 'utils/path';
import {
  DemographicScore,
  GenericQuestionScore,
  ScorePerDateRange,
  ScoresByArea,
  VaultDemographicScore,
  VaultGenericQuestionScore,
  VaultScoresByArea,
  VaultScoresPerDateRange,
} from 'types/score';
import { createAxiosInstance } from 'utils/Api';
import { DateRange } from 'types/dateRange';
import { config } from '../../config/config';

const vaultScoresAxiosInstance = createAxiosInstance(
  path.joinURL(config.surveyApiUrls.vault, config.surveyApiUrls.vaultSections.communitySurveyScores),
);

export interface DateRangeScorePayload {
  surveyType: string;
  dateRanges: DateRangeFilterOption[];
}

interface VaultScoresResponse<T> {
  data: T;
}

/**
 *  Fetch the overall satisfaction score.
 *  This score is based on the Aspects that are defined with the Aspect model
 *  boolean field in Vault called use_in_overall_satisfaction_score.
 */
export async function fetchOverallScore(clientId: number): Promise<ScorePerDateRange[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve(mockOverallScores);
  }
  const result = await vaultScoresAxiosInstance.get<VaultScoresResponse<VaultScoresPerDateRange[]>>(
    `${clientId}/overall/`,
  );

  const { data } = result.data;

  return data.map((score) => ({
    endDate: score.end_date,
    startDate: score.start_date,
    totalSubmissions: score.total_submissions,
    scores: score.scores,
  }));
}

export async function fetchDemographicScore(clientId: number, dateRange: DateRange): Promise<DemographicScore[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve(mockDemographicsScores);
  }
  const { startDate, endDate } = dateRange;
  const result = await vaultScoresAxiosInstance.get<VaultScoresResponse<VaultDemographicScore[]>>(
    `${clientId}/demographics/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  );

  const { data } = result.data;

  return data.map((demographicScore) => ({
    demographicValue: demographicScore.demographic_value,
    demographicLabel: demographicScore.demographic_label,
    demographicType: demographicScore.demographic_type,
    scores: demographicScore.scores.map((score) => ({
      endDate: score.end_date,
      startDate: score.start_date,
      totalSubmissions: score.total_submissions,
      scores: score.scores,
    })),
  }));
}

export async function fetchGenericQuestionsScore(
  clientId: number,
  dateRange?: DateRange,
): Promise<GenericQuestionScore[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve(mockGenericQuestionScores);
  }
  const result = await vaultScoresAxiosInstance.get<VaultScoresResponse<VaultGenericQuestionScore[]>>(
    `${clientId}/generic_questions/`,
    {
      params: {
        start_date: dateRange?.startDate,
        end_date: dateRange?.endDate,
      },
    },
  );

  const { data } = result.data;
  return data.map<GenericQuestionScore>((genericQuestionScore) => ({
    genericQuestionId: genericQuestionScore.generic_question_id,
    text: genericQuestionScore.text,
    aspectValue: genericQuestionScore.aspect_value,
    scores: genericQuestionScore.scores.map((score) => ({
      endDate: score.end_date,
      startDate: score.start_date,
      totalSubmissions: score.total_submissions,
      scores: score.scores,
    })),
  }));
}

export async function fetchAreaScore(clientId: number): Promise<ScoresByArea> {
  if (config.shouldMockRequests) {
    return Promise.resolve(mockScores);
  }
  const result = await vaultScoresAxiosInstance.get<VaultScoresResponse<VaultScoresByArea>>(`${clientId}/areas/`);
  const { data } = result.data;
  return Object.entries(data).reduce<ScoresByArea>(
    (scoresByArea, [polygonId, vaultScoreByArea]) => ({
      ...scoresByArea,
      [polygonId]: {
        polygonId,
        polygonName: vaultScoreByArea.polygon_name,
        scores: vaultScoreByArea.scores.map((score) => ({
          endDate: score.end_date,
          startDate: score.start_date,
          totalSubmissions: score.total_submissions,
          scores: score.scores,
        })),
      },
    }),
    {},
  );
}
