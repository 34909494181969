import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { config } from '../../../config/config';

const client = new QueryClient();

export const QueryClientProviderHOC = ({ children }: { children: React.ReactNode }) => (
  // eslint-disable-next-line react/jsx-boolean-value
  <QueryClientProvider client={client}>
    {children}
    <ReactQueryDevtools initialIsOpen={config.environment === 'development'} />
  </QueryClientProvider>
);
