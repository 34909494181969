import { Icon, ZCDSpinner } from '@zencity/common-ui';
import ContentPlaceholder from 'components/ContentPlaceholder';
import { SurveyContext } from 'contexts/SurveyContext';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Answer from 'screens/OpenQuestionFeed/components/Answer/Answer';
import styles from './AnswerList.module.scss';

export const QUESTION_FEED_SCROLLABLE_DIV_ID = 'question-feed-scrollable-div';

const AnswerList = (): ReactElement => {
  const { answers, fetchNextPageOfAnswers, totalAnswers } = useContext(SurveyContext);
  const { t: translate } = useTranslation();

  return (
    <div className={styles.answerList}>
      {!answers?.length ? (
        <ContentPlaceholder
          iconComponent={<Icon className={styles.icon} name="empty-state.svg" width={67} height={85} />}
          title={translate('answers.placeholder.title')}
          description={translate('answers.placeholder.explanation')}
          borderless
        />
      ) : (
        <InfiniteScroll
          scrollableTarget={QUESTION_FEED_SCROLLABLE_DIV_ID}
          dataLength={answers.length}
          style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
          next={fetchNextPageOfAnswers}
          hasMore={answers.length < totalAnswers}
          loader={
            <div className={styles.loader}>
              <ZCDSpinner />
            </div>
          }
        >
          {answers.map((answer) => (
            <Answer key={answer.id?.toString()} answer={answer} />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default AnswerList;
