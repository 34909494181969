import { ZCDCard } from '@zencity/common-ui';
import { LeadingClassificationTopic } from 'components/LeadingTopicsWidget/components/LeadingClassificationTopic';
import { LoaderMask } from 'components/LoaderMask/LoaderMask';
import { AnalyticEventsChangeTypes, AnalyticWidgetNames } from 'constants/analytics';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { LeadingTopic } from '../../types/leadingTopics';
import ExpandButton from '../ExpandButton';
import { groupLeadingTopicsByClassificationTopic, prepareGroupedLeadingTopics } from './LeadingTopicsWidget.helpers';
import styles from './LeadingTopicsWidget.module.scss';

interface Props {
  leadingTopics: LeadingTopic[];
  isLoading: boolean;
}

export const LeadingTopicsWidget: React.FC<Props> = (props: Props): ReactElement => {
  const { leadingTopics: leadingClassificationSubTopics, isLoading } = props;
  const [showAll, setShowAll] = useState(false);
  const { t: translate } = useTranslation();

  const groupedLeadingTopics = groupLeadingTopicsByClassificationTopic(leadingClassificationSubTopics);
  let displayableGroupedLeadingTopics = prepareGroupedLeadingTopics(groupedLeadingTopics);

  if (!showAll) {
    displayableGroupedLeadingTopics = displayableGroupedLeadingTopics.slice(0, 3);
  }

  const handleExpandAll = () => {
    setShowAll(!showAll);

    analyticsService.crossProductEvents.widgetExpanded({
      widgetName: AnalyticWidgetNames.LEADING_TOPICS_WIDGET,
      changeType: showAll ? AnalyticEventsChangeTypes.COLLAPSE : AnalyticEventsChangeTypes.EXPAND,
    });
  };

  return (
    <ZCDCard elevated>
      <LoaderMask isLoading={isLoading}>
        <div className={styles.title}>{translate('leadingTopics.title')}</div>
        <div className={styles.inner}>
          {leadingClassificationSubTopics.length === 0 ? (
            <div className={styles.emptyStateTitle}>{translate('leadingTopics.emptyState.title')}</div>
          ) : (
            displayableGroupedLeadingTopics.map((classificationTopic) => (
              <LeadingClassificationTopic
                key={classificationTopic.classificationTopicId}
                classificationTopic={classificationTopic}
              />
            ))
          )}
        </div>
        {displayableGroupedLeadingTopics.length > 0 && (
          <div className={styles.buttonContainer}>
            <ExpandButton expanded={showAll} onClick={handleExpandAll} />
          </div>
        )}
      </LoaderMask>
    </ZCDCard>
  );
};
