import { TreeNode, TreeSelectSelectionKeys } from '@zencity/common-ui';
import React from 'react';

export interface FilterOption {
  key: string;
  label: string;
  children?: FilterOption[];
}

export interface FiltersContextInterface {
  selectedDemographics: TreeSelectSelectionKeys;
  setSelectedDemographics: React.Dispatch<React.SetStateAction<TreeSelectSelectionKeys>>;
  dashboardDemographics: FilterOption[];
  setDashboardDemographics: React.Dispatch<React.SetStateAction<FilterOption[]>>;
  selectedDivisionId?: number;
  setSelectedDivisionId: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedDivisionIds: number[];
  setSelectedDivisionIds: React.Dispatch<React.SetStateAction<number[]>>;
  classificationTopicsOptions: TreeNode[];
  setClassificationTopicsOptions: React.Dispatch<React.SetStateAction<TreeNode[]>>;
  selectedClassificationTopicsKeys: string[];
  setSelectedClassificationTopicsKeys: React.Dispatch<React.SetStateAction<string[]>>;
  isClassificationTopicsLoading: boolean;
  answerSearchTerm: string;
  setAnswerSearchTerm: (value: string) => void;
  clearFilters: () => void;
}

export const defaultFilterContext = {
  selectedDemographics: [],
  setSelectedDemographics: () => {},
  dashboardDemographics: [],
  setDashboardDemographics: () => {},
  isDivisionFilterDisabled: false,
  setIsDivisionFilterDisabled: () => {},
  selectedDivisionId: undefined,
  setSelectedDivisionId: () => {},
  selectedDivisionIds: [],
  setSelectedDivisionIds: () => {},
  classificationTopicsOptions: [],
  setClassificationTopicsOptions: () => {},
  selectedClassificationTopicsKeys: [],
  setSelectedClassificationTopicsKeys: () => {},
  isClassificationTopicsLoading: false,
  answerSearchTerm: '',
  setAnswerSearchTerm: () => {},
  clearFilters: () => {},
};
export const FiltersContext = React.createContext<FiltersContextInterface>(defaultFilterContext);
