import { TabKeys } from 'constants/tabs';

/*
 * We include these here instead of in the router.tsx so as
 * to avoid dependency cycle errors.
 *
 * Note: parent paths (like SURVEY_PAGE) need the `/*` to embed
 * children (aka nested routes) since in React Router v6
 * all paths are exactly matched.
 */
export enum CustomerExperienceRouterPaths {
  WORKSPACE = '/',
  SURVEY_PAGE = '/survey/:surveyGroupId',
}

/*
 * The Header component of the SURVEY_PAGE should render these
 * tabs in the order they appear here.
 */
export const SurveyPageSubTabs = [TabKeys.DASHBOARD, TabKeys.QUESTIONS, TabKeys.SUGGESTIONS];
