import React from 'react';

export interface DateRangeFilterOption {
  key: string;
  label: string;
  labelOnly?: boolean;
  startDate: string;
  endDate: string;
  disabled?: boolean;
}

export interface DateRangeContextInterface {
  selectedDateRange?: DateRangeFilterOption;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<DateRangeFilterOption | undefined>>;
  firstSubmissionDate?: string;
  setFirstSubmissionDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  dashboardDateRanges?: (undefined | DateRangeFilterOption)[];
  setDashboardDateRanges: React.Dispatch<React.SetStateAction<DateRangeFilterOption[] | undefined>>;
  completedCyclesDateRanges?: (undefined | DateRangeFilterOption)[];
  setCompletedCyclesDateRanges: React.Dispatch<React.SetStateAction<DateRangeFilterOption[] | undefined>>;
  clientCadence?: string;
  setClientCadence: React.Dispatch<React.SetStateAction<string | undefined>>;
  isContextLoaded: boolean;
  setIsContextLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  comparisonDateRange?: DateRangeFilterOption;
  setComparisonDateRange: React.Dispatch<React.SetStateAction<DateRangeFilterOption | undefined>>;
  scoreDateRangeFilters?: DateRangeFilterOption[];
}

export const DateRangeContext = React.createContext<DateRangeContextInterface>({} as DateRangeContextInterface);
