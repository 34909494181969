import { CrossQuestionTable, CrossTableColumns } from 'utils/analysis';

export const sortColumnsByIndex = (tableData: CrossQuestionTable[], uniqueColumns: string[]) => {
  const sortIndexToKeyArrays = tableData.map((rowData) => rowData.sortIndexToKey);
  const flatIndexesMapping = sortIndexToKeyArrays.reduce(
    (sortIndexes, sortIndex) => ({ ...sortIndexes, ...sortIndex }),
    {},
  );
  const sortedColumnsWithIndex = Object.entries(flatIndexesMapping)
    .sort(([, firstIndex], [, secondIndex]) => firstIndex - secondIndex)
    .reduce((sortedColumns, [columnLabel, columnIndex]) => ({ ...sortedColumns, [columnLabel]: columnIndex }), {});
  const columnLabelsSorted = Object.keys(sortedColumnsWithIndex);
  return uniqueColumns.sort(
    (firstColumn, secondColumn) => columnLabelsSorted.indexOf(firstColumn) - columnLabelsSorted.indexOf(secondColumn),
  );
};

export const sortCompareColumnsByIndex = (tableData: CrossQuestionTable[], uniqueColumns: string[]) => {
  const sortIndexToKeyArrays = tableData.map(
    (rowData) => rowData?.compareChoice && rowData.compareChoice.sortIndexToKey,
  ) as Record<string, number>[];
  const flatIndexesMapping = sortIndexToKeyArrays.reduce(
    (sortIndexes, sortIndex) => ({ ...sortIndexes, ...sortIndex }),
    {},
  );
  const sortedColumnsWithIndex = Object.entries(flatIndexesMapping)
    .sort(([, firstIndex], [, secondIndex]) => firstIndex - secondIndex)
    .reduce((sortedColumns, [columnLabel, columnIndex]) => ({ ...sortedColumns, [columnLabel]: columnIndex }), {});
  const columnLabelsSorted = Object.keys(sortedColumnsWithIndex);
  return uniqueColumns.sort(
    (firstColumn, secondColumn) => columnLabelsSorted.indexOf(firstColumn) - columnLabelsSorted.indexOf(secondColumn),
  );
};

export const extractAndSortChoicesColumns = (tableData: CrossQuestionTable[]) => {
  const dynamicColumns = tableData.map((rowData) => Object.keys(rowData)).flat(1);
  const uniqueColumns = dynamicColumns.filter(
    (value, index) =>
      dynamicColumns.indexOf(value) === index &&
      value !== CrossTableColumns.CHOICE_HEADER &&
      value !== CrossTableColumns.NUMERIC_VALUE &&
      value !== CrossTableColumns.COMPARE_CHOICE &&
      value !== CrossTableColumns.CHOICE_ID &&
      value !== CrossTableColumns.SORT_INDEX_TO_KEY,
  );
  return sortColumnsByIndex(tableData, uniqueColumns);
};

// eslint-disable-next-line id-length
export const extractAndSortChoicesColumnsFromCompareCycle = (tableData: CrossQuestionTable[]) => {
  const dynamicColumns = tableData
    .map((rowData) => rowData?.compareChoice && Object.keys(rowData?.compareChoice))
    .flat(1);
  const uniqueColumns = dynamicColumns.filter(
    (value, index) =>
      dynamicColumns.indexOf(value) === index &&
      value !== CrossTableColumns.CHOICE_HEADER &&
      value !== CrossTableColumns.NUMERIC_VALUE &&
      value !== CrossTableColumns.CHOICE_ID &&
      value !== CrossTableColumns.SORT_INDEX_TO_KEY,
  );
  return sortCompareColumnsByIndex(tableData, uniqueColumns as string[]);
};
