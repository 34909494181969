/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import {
  HOVERED_AREA_LAYER,
  LABEL_LAYER,
  NOT_AVAILABLE_BORDER_LAYER,
  POLYGON_LAYER,
  SELECTED_AREAS_LAYER,
} from 'components/MapView/Layers';
import { MapRenderTypes } from 'constants/map';
import React, { forwardRef } from 'react';
import ReactMapGL, { InteractiveMapProps, Layer, NavigationControl, Source } from 'react-map-gl';
import { config } from '../../../config/config';
import styles from './ReactMapGLHOC.module.scss';

interface Props extends InteractiveMapProps {
  layerData?: GeoJSON.FeatureCollection;
  labelData?: GeoJSON.FeatureCollection;
  selectedAreaFilter: (string | (string | string[])[])[];
  hoveredAreaFilter: (string | (string | string[])[])[];
  renderType: MapRenderTypes;
}

// eslint-disable-next-line id-length
export const ReactMapGLHOC = forwardRef((props: Props, mapRef: React.Ref<HTMLDivElement>) => {
  const {
    scrollZoom,
    onClick,
    attributionControl,
    doubleClickZoom,
    layerData,
    labelData,
    selectedAreaFilter,
    hoveredAreaFilter,
    renderType,
    ...rest
  } = props;

  return (
    <div
      ref={mapRef}
      className={classNames({
        [styles.screenView]: renderType === MapRenderTypes.SCREEN,
        [styles.widgetView]: renderType !== MapRenderTypes.SCREEN,
      })}
    >
      <ReactMapGL
        {...rest}
        width="100%"
        height="100%"
        mapStyle={config.mapbox.tilesUrl}
        mapboxApiAccessToken={config.mapbox.token}
        scrollZoom={scrollZoom}
        onClick={onClick}
        reuseMaps
        attributionControl={attributionControl}
        doubleClickZoom={doubleClickZoom}
      >
        {renderType !== MapRenderTypes.WIDGET && (
          <NavigationControl
            showCompass={false}
            className={classNames(styles.baseNavigationControl, {
              [styles.screenNavigationControl]: renderType === MapRenderTypes.SCREEN,
              [styles.summaryWidgetNavigationControl]: renderType === MapRenderTypes.SUMMARY_WIDGET,
            })}
          />
        )}
        <Source id="areas-data" type="geojson" data={layerData}>
          <Layer {...POLYGON_LAYER} />
          <Layer {...NOT_AVAILABLE_BORDER_LAYER} />
          {selectedAreaFilter && <Layer {...SELECTED_AREAS_LAYER} filter={selectedAreaFilter} />}
          {hoveredAreaFilter && <Layer {...HOVERED_AREA_LAYER} filter={hoveredAreaFilter} />}
        </Source>
        <Source id="layer-data" type="geojson" data={labelData}>
          <Layer {...LABEL_LAYER} />
        </Source>
      </ReactMapGL>
    </div>
  );
});
