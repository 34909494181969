/* eslint-disable react/destructuring-assignment */
import { ZCDChangeIndicatorHOC } from 'components/BlockwiseSummaryTab/ZCDChangeIndicatorHOC/ZCDChangeIndicatorHOC';
import { Flex } from 'components/Flex/Flex';
import React, { ReactElement } from 'react';
import { SummaryWidgetType } from '../misc';
import { ProgressBarWrapper, ScoreType } from '../ProgressBarWrapper/ProgressBarWrapper';
import { AnalysisWidgetWrapper } from '../AnalysisWidgetWrapper/AnalysisWidgetWrapper';

import styles from './SummaryWidget.module.scss';

interface SentimentProps {
  label: string;
  score: number;
  widgetType: SummaryWidgetType.POSITIVE | SummaryWidgetType.NEGATIVE | SummaryWidgetType.ANSWERS;
  comparisonLabel?: string;
  comparisonScore?: number;
  hideOvertimeTrends?: boolean;
}

type Props = SentimentProps;

const getBarColor = (widgetType: SummaryWidgetType): string | undefined => {
  if (widgetType === SummaryWidgetType.POSITIVE) return styles.positiveColor;
  if (widgetType === SummaryWidgetType.NEGATIVE) return styles.negativeColor;
  if (widgetType === SummaryWidgetType.ANSWERS) return styles.topChoiceColor;
  return undefined;
};
const SummaryWidgetSentiment = (props: Props): ReactElement => {
  const { score, label, comparisonLabel, comparisonScore, widgetType, hideOvertimeTrends } = props;
  /* TODO: replace ProgressBar with non-deprecated ProgressBar */
  return (
    <div className={styles.summaryWidget}>
      <AnalysisWidgetWrapper>
        <span className={styles.widgetLabel}>{label}</span>
        <Flex alignItems="flex-end" justifyContent="space-between">
          <div className={styles.score}>
            <ProgressBarWrapper score={score} color={getBarColor(widgetType)} scoreType={ScoreType.PERCENTAGE} />
          </div>

          {!hideOvertimeTrends && (
            <div className={styles.comparison}>
              <ZCDChangeIndicatorHOC currentScore={score} comparisonScore={comparisonScore} grayscale />
              <p className={styles.comparisonLabel}>vs {comparisonLabel}</p>
            </div>
          )}
        </Flex>
      </AnalysisWidgetWrapper>
    </div>
  );
};

export default SummaryWidgetSentiment;
