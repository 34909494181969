import { generateMockAspectWidgetData } from 'mocks/data/summaryAspectWidget.mock';
import { generateSummaryOverallSatisfactionMock } from 'mocks/data/summaryOverallSatisfaction.mock';
import { getSummarySubmissionsWidgetMock } from 'mocks/data/summarySubmissionsWidget.mock';
import { SummaryAspectWidget } from 'types/summaryAspectWidget';
import { SummaryOverallSatisfactionWidget } from 'types/summaryOverallSatisfactionWidget';
import { SummarySubmissionsWidget } from 'types/summarySubmissionsWidget';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { sleep } from 'utils/utils';
import { config } from '../../config/config';

const REQUESTS_TIMEOUT = 3000;
const axiosInstance = createAxiosInstance(path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.surveyGroup));

interface FetchSummaryWidget {
  surveyGroupId: number;
  startDate: string;
  endDate: string;
  divisionId?: number;
}

export async function fetchSummaryAspectWidget({
  surveyGroupId,
  startDate,
  endDate,
  divisionId,
}: FetchSummaryWidget): Promise<SummaryAspectWidget> {
  if (config.shouldMockRequests) {
    await sleep(1000);
    return Promise.resolve(generateMockAspectWidgetData(15));
  }
  const response = await axiosInstance.get<SummaryAspectWidget>(`${surveyGroupId}/aspect_scores/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      division_id: divisionId,
    },
  });

  return response.data;
}

export async function fetchSummarySubmissionsWidget({
  surveyGroupId,
  startDate,
  endDate,
  divisionId,
}: FetchSummaryWidget): Promise<SummarySubmissionsWidget> {
  if (config.shouldMockRequests) {
    await sleep(REQUESTS_TIMEOUT);
    return Promise.resolve(getSummarySubmissionsWidgetMock());
  }
  const response = await axiosInstance.get<SummarySubmissionsWidget>(`${surveyGroupId}/total_submissions/`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      division_id: divisionId,
    },
  });

  return response.data;
}

export async function fetchOverallSatisfactionWidget({
  surveyGroupId,
  divisionId,
}: Partial<FetchSummaryWidget>): Promise<SummaryOverallSatisfactionWidget[]> {
  if (config.shouldMockRequests) {
    await sleep(REQUESTS_TIMEOUT);
    return Promise.resolve(generateSummaryOverallSatisfactionMock(5));
  }
  const response = await axiosInstance.get<SummaryOverallSatisfactionWidget[]>(`${surveyGroupId}/overall_scores/`, {
    params: {
      division_id: divisionId,
    },
  });

  return response.data;
}
