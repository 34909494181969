/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, StatusBox } from '@zencity/common-ui';
import { AnalyticWidgetNames } from 'constants/analytics';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from '../../constants/state';
import { analyticsService } from '../../services/analytics/analytics';
import { formatInteractions } from '../../utils/formatters';

import styles from './OrganicDiscourseCard.module.scss';

interface Props {
  isInsightsUser?: boolean;
  interactions?: number;
  fetchStatus: Status;
}

/**
 * a card that shows organic discourse (interactions) or offers to sign-up for insights
 * @param {object} props
 */
export const OrganicDiscourseCard: React.FC<Props> = function OrganicDiscourseCard({
  isInsightsUser = true,
  interactions = 0,
  fetchStatus,
}: Props): ReactElement {
  const { t: translate } = useTranslation();
  const activeFilters = useFilterNames();

  const onClickOrganicDiscourse = () => {
    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('organicDiscourse.card.linkText'),
      widgetName: AnalyticWidgetNames.DISCOURSE_WIDGET,
      activeFilters,
    });
  };

  const formattedInteractions = useMemo(() => formatInteractions(interactions), [interactions]);

  function determineContent() {
    if (!isInsightsUser) {
      return (
        <>
          <Card.SubTitle>{translate('organicDiscourse.card.subTitle')}</Card.SubTitle>
          <span>
            <Card.Title>{translate('organicDiscourse.noInsightsCard.title')}</Card.Title>
            <Card.Paragraph>{translate('organicDiscourse.noInsightsCard.paragraph')}</Card.Paragraph>
          </span>
          <Card.Link>
            <a href="/admin/zencity-pd/request">{translate('organicDiscourse.noInsightsCard.linkText')}</a>
          </Card.Link>
        </>
      );
    }

    return (
      <>
        <Card.SubTitle>{translate('organicDiscourse.card.subTitle')}</Card.SubTitle>
        <span className={styles.cardTexts}>
          <Card.Title>{translate('organicDiscourse.card.title', { interactions: formattedInteractions })}</Card.Title>
          <Card.Paragraph>{translate('organicDiscourse.card.paragraph')}</Card.Paragraph>
        </span>
        <Card.Link>
          <a href="/admin/items" onClick={onClickOrganicDiscourse}>
            {translate('organicDiscourse.card.linkText')}
          </a>
        </Card.Link>
      </>
    );
  }

  return (
    <div className={styles.organicDiscourseCard}>
      <Card.Wrapper>
        <StatusBox status={fetchStatus}>{determineContent()}</StatusBox>
      </Card.Wrapper>
    </div>
  );
};
