import React, { ErrorInfo, ReactElement } from 'react';
import { Icon } from '@zencity/common-ui';
import { logger } from 'utils/community-survey/logger';
import styles from './AreaBreakdownErrorBoundary.module.scss';

interface Props {
  children: React.ReactNode;
  title: string;
  description: string;
}

interface State {
  hasError: boolean;
}

/**
 * An error boundary for the Area Breakdown component on the summary tab.
 * This will render an empty map and an error message.
 *
 * @param children - The children to be wrapped by this error boundary.
 * @param title - The error title.
 * @param description - The error description.
 */
export class AreaBreakdownErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    } as State;
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logger.error(error);
    logger.error(errorInfo);
  }

  render(): ReactElement {
    const { hasError } = this.state;
    const { children, title, description } = this.props;

    if (hasError) {
      // eslint-disable-next-line i18next/no-literal-string
      return (
        <>
          <section className={styles.errorBoundaryContent}>
            <h4>{title}</h4>
            <p>{description}</p>
          </section>
          <section className={styles.iconContainer}>
            <Icon name="empty-map.svg" width={577} height={332} className={styles.icon} />
          </section>
        </>
      );
    }

    return <>{children}</>;
  }
}
