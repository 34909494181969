/* eslint max-statements: ["error", 10, { "ignoreTopLevelFunctions": true }] */
import { Button } from '@zencity/common-ui';
import classnames from 'classnames';
import { CommunityAsksContext } from 'contexts/CommunityAsksContext';
import { CommunityAsksToastContext } from 'contexts/CommunityAsksToastContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { analyticsService } from 'services/analytics/analytics';
import { submitSurveyRequest } from 'services/surveyRequest';
import { SurveyType, TypeformExample } from '@zencity/survey-types';
import { SurveyRequestPayload } from 'types/surveyRequest';
import { logger } from 'utils/community-asks/logger';
import styles from './TypeformExampleRequestForm.module.scss';

/**
 * Form that shows example survey info and gives option to request a similar survey
 */
export const TypeformExampleRequestForm = (): React.ReactElement => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { typeformExamples, setSurveyRequests } = useContext(CommunityAsksContext);
  const { toastSuccess, toastError } = useContext(CommunityAsksToastContext);
  const { typeformExampleId } = useParams();

  const [typeformExample, setTypeformExample] = useState<TypeformExample>();
  const [requestText, setRequestText] = useState('');
  const [submitRequestLoading, setSubmitRequestLoading] = useState(false);

  useEffect(() => {
    const found = typeformExamples.filter((item: TypeformExample) => item.id.toString() === typeformExampleId);
    if (found) setTypeformExample(found[0]);
  }, [typeformExamples, typeformExampleId]);

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setRequestText(event.target.value);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setSubmitRequestLoading(true);
    analyticsService.communityAsksEvents.requestTemplateClicked(typeformExample?.title ?? '');
    const payload: SurveyRequestPayload = {
      surveyType: SurveyType.COMMUNITY_ASKS,
      typeformExample: typeformExample?.id,
      requestText,
      // TODO: Get the CSM info from insights (`/api/slack/userInfo`).
      csmSlack: '',
    };

    submitSurveyRequest(payload)
      .then((surveyRequest) => {
        setSurveyRequests((prevSurveyRequests) => [...prevSurveyRequests, surveyRequest]);
        setSubmitRequestLoading(false);
        navigate(CommunityAsksRouterPaths.WORKSPACE);

        // Display a success message to the user.
        toastSuccess();
      })
      .catch((error) => {
        logger.error(error);
        setSubmitRequestLoading(false);
        // Display an error message to the user.
        toastError();
      });
  };

  return (
    <div className={styles.typeformExampleRequestForm}>
      {typeformExample && (
        <>
          <h5>{translate('typeformExampleRequestForm.descriptionHeader').toUpperCase()}</h5>
          <div className={styles.description}>{typeformExample.description}</div>
          <div className={styles.details}>
            <div className={classnames(styles.box, styles.detailsBox)}>
              <h5>{translate('typeformExampleRequestForm.question')}</h5>
              <h2>{typeformExample.number_of_questions}</h2>
            </div>
            <div className={classnames(styles.box, styles.detailsBox)}>
              <h5>{translate('typeformExampleRequestForm.timeToAnswer')}</h5>
              <h2>{typeformExample.est_time_to_complete}</h2>
            </div>
          </div>
          <div>
            <span className={styles.description}>{translate('typeformExampleRequestForm.addAComment')}</span>
            <span className={styles.lighter}> ({translate('typeformExampleRequestForm.optional')})</span>
            <form onSubmit={handleOnSubmit}>
              <textarea
                className={classnames(styles.box, styles.requestBox)}
                value={requestText}
                onChange={handleOnChange}
              />
              <Button type="submit" disabled={submitRequestLoading}>
                {translate('typeformExampleRequestForm.requestTypeformExample')}
              </Button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
