/* eslint-disable max-lines-per-function */
import { Status, StatusBox, ZCDIconColor } from '@zencity/common-ui';
import { SurveyType } from '@zencity/survey-types';
import { IconWithTooltip } from 'components/IconWithTooltip/IconWithTooltip';
import { OvertimeTrendsIndication } from 'components/OvertimeTrendsIndication/OvertimeTrendsIndication';
import { GraphEmptyState } from 'components/ScoreCardWidget/components/GraphEmptyState/GraphEmptyState';
import { AccountsContext } from 'contexts/AccountsContext';
import { DateRangeContext } from 'contexts/DateRangeContext';
import useScoreDateRangeFilter from 'hooks/score/dateRangeFilter';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/misc';
import { SatisfactionScore, ScorePerDateRange } from 'types/score';
import { getScorePerDateRange } from 'utils/dateRange';
import { sortScoresPerDateRange } from 'utils/utils';
import { formatToStringifiedNumbersWithCommas } from '../../utils/formatters';
import { PercentageSize } from '../SatisfactionDistribution/components/PercentageDisplay';
import { SatisfactionDistribution } from '../SatisfactionDistribution/SatisfactionDistribution';
import { OverallScoreGraph } from './components/OverallScoreGraph/OverallScoreGraph';

import styles from './ScoreCardWidget.module.scss';

interface Props {
  fetchStatus: Status;
  overallScores: ScorePerDateRange[];
  surveyType?: SurveyType.BLOCKWISE | SurveyType.COMMUNITY_SURVEY;
  mainTitle?: Nullable<string>;
}

/**
 * Widget for showing overall satisfaction score.
 */
export const ScoreCardWidget: React.FC<Props> = function ScoreCardWidget(props: Props): ReactElement {
  const { overallScores, fetchStatus, surveyType = SurveyType.COMMUNITY_SURVEY, mainTitle } = props;
  const { dashboardDateRanges, comparisonDateRange } = useContext(DateRangeContext);
  const { client } = useContext(AccountsContext);
  const { t: translate } = useTranslation();
  const percentageTitle = mainTitle || `${translate('scoreCard.percentageTitle')} ${client?.name}`;

  const emptySatisfactionScore: SatisfactionScore = {
    positive: 0,
    negative: 0,
    neutral: 0,
  };

  const [displayedOverallScore, setDisplayedOverallScore] = useState<SatisfactionScore>(emptySatisfactionScore);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const { dateRangeFilter } = useScoreDateRangeFilter();
  const sortedOverallScores = sortScoresPerDateRange(overallScores);

  // Adding comparison score to display in the breakdown.
  const comparisonScore = getScorePerDateRange({
    scorePerDateRange: overallScores,
    dateRangeFilter: {
      endDate: comparisonDateRange?.endDate ?? '',
      startDate: comparisonDateRange?.startDate ?? '',
    },
  });

  // If there is a selected date range, use that for the overall score.
  // Otherwise, use the most recent date range's overall score.
  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!dateRangeFilter) {
      return;
    }
    if (overallScores.length > 0) {
      const selectedOverallScore = getScorePerDateRange({
        scorePerDateRange: overallScores,
        dateRangeFilter,
      });
      setDisplayedOverallScore(selectedOverallScore?.scores || emptySatisfactionScore);
      setTotalSubmissions(selectedOverallScore?.totalSubmissions || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallScores, dateRangeFilter?.endDate, dateRangeFilter?.startDate]);

  return (
    <div className={styles.scoreCardWidget}>
      <StatusBox status={fetchStatus}>
        <section className={styles.percentage}>
          {surveyType === SurveyType.COMMUNITY_SURVEY && (
            <div className={styles.title}>
              <h3>{translate('scoreCard.title')}</h3>
              <IconWithTooltip
                iconColor={ZCDIconColor.BLUE}
                iconName="info"
                tooltipText={translate('scoreCard.infoParagraph')}
              />
            </div>
          )}
          <div>
            <SatisfactionDistribution
              className={styles.satisfactionDistribution}
              positive={displayedOverallScore.positive}
              negative={displayedOverallScore.negative}
              neutral={displayedOverallScore.neutral}
              percentageSize={PercentageSize.LARGE}
              percentageTitle={percentageTitle}
              hideOvertimeTrendsIndication
              noBar={surveyType === SurveyType.BLOCKWISE}
            />
          </div>
          <div className={styles.bottomInfo}>
            <div className={styles.bottomInfoItem}>
              <OvertimeTrendsIndication
                currentScore={{
                  positive: displayedOverallScore.positive,
                  negative: displayedOverallScore.negative,
                  neutral: displayedOverallScore.neutral,
                }}
                comparisonScore={comparisonScore?.scores}
                inScoreWidget
              />
              <span className={styles.bottomInfoItemTitle}>{translate('scoreCard.comparisonCycle')}</span>
            </div>
            <div className={styles.bottomInfoItem}>
              <span className={styles.bottomInfoItemRespondents}>
                {formatToStringifiedNumbersWithCommas(totalSubmissions)}
              </span>
              <span className={styles.bottomInfoItemTitle}>{translate('scoreCard.totalRespondents')}</span>
            </div>
          </div>
        </section>
        <section className={styles.overallScoreGraph}>
          {/* A length of 3 date ranges means that there is only 1 completed cycle, 
          which is not enough data to display the graph. */}
          {(!dashboardDateRanges || dashboardDateRanges.length <= 3) && <GraphEmptyState />}
          {dashboardDateRanges && dashboardDateRanges.length > 3 && (
            <OverallScoreGraph
              overallScores={sortedOverallScores}
              disableTooltip={surveyType === SurveyType.BLOCKWISE}
            />
          )}
        </section>
      </StatusBox>
    </div>
  );
};
