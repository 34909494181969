import { crossQuestionsScoresRequestMock, resultsQuestionsScoresRequestMock } from 'mocks/data/questionScores.mock';
import {
  CrossQuestionParams,
  CrossQuestionResponse,
  QuestionsScoresParams,
  ResponseQuestions,
} from 'types/questionsScores';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.surveyGroup));

/**
 * Fetch questions scores, and choices breakdown.
 */
export async function fetchQuestionsScores(
  params: QuestionsScoresParams,
  demographicsParamString: string,
  surveyGroupId?: number,
): Promise<ResponseQuestions> {
  const resultsScoresEndpoint = demographicsParamString
    ? `${surveyGroupId}/question_scores/?${demographicsParamString}`
    : `${surveyGroupId}/question_scores/`;

  if (config.shouldMockRequests) {
    return Promise.resolve(resultsQuestionsScoresRequestMock());
  }

  const result = await axiosInstance.get<ResponseQuestions>(resultsScoresEndpoint, {
    params,
  });

  return result.data;
}

/**
 * Fetch cross questions data.
 */
export async function fetchCrossQuestions(
  params: CrossQuestionParams,
  demographicsParamString: string,
  surveyGroupId?: number,
): Promise<CrossQuestionResponse> {
  const resultsScoresEndpoint = demographicsParamString
    ? `${surveyGroupId}/cross_question_scores/?${demographicsParamString}`
    : `${surveyGroupId}/cross_question_scores/`;

  if (config.shouldMockRequests) {
    return Promise.resolve(crossQuestionsScoresRequestMock);
  }

  const result = await axiosInstance.get<CrossQuestionResponse>(resultsScoresEndpoint, {
    params,
  });

  return result.data;
}
