import { ScorePerDateRange } from 'types/score';

export const mockOverallScores: ScorePerDateRange[] = [
  {
    totalSubmissions: 732,
    startDate: '2022-04-01',
    endDate: '2022-06-30',
    scores: {
      positive: 73,
      negative: 1,
      neutral: 26,
    },
  },
  {
    totalSubmissions: 655,
    startDate: '2022-01-01',
    endDate: '2022-03-31',
    scores: {
      positive: 67,
      negative: 3,
      neutral: 30,
    },
  },
  {
    totalSubmissions: 626,
    startDate: '2021-10-01',
    endDate: '2021-12-31',
    scores: {
      positive: 58,
      negative: 3,
      neutral: 39,
    },
  },
  {
    totalSubmissions: 306,
    startDate: '2021-07-01',
    endDate: '2021-09-30',
    scores: {
      positive: 53,
      negative: 3,
      neutral: 44,
    },
  },
  {
    totalSubmissions: 231,
    startDate: '2021-04-01',
    endDate: '2021-06-30',
    scores: {
      positive: 59,
      negative: 1,
      neutral: 40,
    },
  },
];
