import { Question } from '@zencity/survey-types';

export const groupQuestions: Question[] = [
  {
    id: 2399,
    generic_question: 22,
    text: 'What is your favorite thing about living in Cary?',
    question_type: 'open_ended',
    survey: 181,
    index: 6,
    aspect: { id: 4, value: 'quality_of_life', label: 'Quality of Life' },
    overridden_text: null,
    overridden_text_to_speech: null,
    group_question: 2392,
    configs: [],
    logic_actions: [],
    updated_at: '2022-06-15T11:34:13.376941Z',
  },
  {
    id: 2400,
    generic_question: 23,
    text: 'What is the thing you would most want to change about life in Cary?',
    question_type: 'open_ended',
    survey: 181,
    index: 7,
    aspect: { id: 4, value: 'quality_of_life', label: 'Quality of Life' },
    overridden_text: null,
    overridden_text_to_speech: null,
    group_question: 2392,
    configs: [],
    logic_actions: [],
    updated_at: '2022-06-15T11:34:33.555568Z',
  },
  {
    id: 2401,
    generic_question: 754,
    text: 'How old are you?',
    question_type: 'choices_options',
    survey: 181,
    index: 8,
    aspect: {
      id: 7,
      value: 'demographics',
      label: 'Demographics',
    },
    overridden_text: null,
    overridden_text_to_speech: null,
    group_question: 2392,
    configs: [],
    logic_actions: [],
    updated_at: '2022-09-22T11:56:31.198090Z',
  },
  {
    id: 2402,
    generic_question: 755,
    text: 'What is your sex?',
    question_type: 'choices_options',
    survey: 181,
    index: 9,
    aspect: {
      id: 7,
      value: 'demographics',
      label: 'Demographics',
    },
    overridden_text: null,
    overridden_text_to_speech: null,
    group_question: 2392,
    configs: [],
    logic_actions: [],
    updated_at: '2022-09-22T11:56:31.198090Z',
  },
];
