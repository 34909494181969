/* eslint-disable max-lines */
import { MIN_UNREPRESENTATIVE_INDICATOR } from 'components/UnrepresentativeIndicator/constants';
import {
  AGGREGATED_OVERALL_SCORE_KEY,
  Area,
  AreasResponse,
  AreasWithScoresObject,
  AreaWithScore,
  DivisionAreaScoresByAspect,
  DivisionAreaScoresByQuestion,
} from 'types/areas';
import { Nullable } from 'types/misc';
import {
  DivisionScore,
  DivisionScoresByAspect,
  DivisionScoresByGenericQuestion,
  ScoreByArea,
  ScorePerDateRange,
  ScoresByArea,
} from 'types/score';
import { SummaryAspectWidget } from 'types/summaryAspectWidget';
import { getWcagAccessibleColors } from 'utils/chartWidget';

export const EMPTY_PERIOD_SCORE: ScorePerDateRange = {
  scores: null,
  startDate: '',
  endDate: '',
  totalSubmissions: 0,
};

interface GetAreaScoresParams {
  scoreByArea: ScoreByArea;
  areaId: string;
  dateRangeOptionsLength: number;
}

/**
 * Determines if the area score is undefined. If so, change the score to an empty score (-1 for all satisfactions)
 * and creates a custom period.
 * @param scoreByArea - The areas scores.
 * @param areaId - The area ID, fetched from core platform.
 * @param dateRangeOptionsLength - The length of date range options for scores.
 */
export const getAreaScores = ({ scoreByArea, dateRangeOptionsLength }: GetAreaScoresParams): ScorePerDateRange[] => {
  if (scoreByArea && scoreByArea.scores.length) {
    return scoreByArea.scores;
  }
  // The areas from the core platform and the areas scores endpoint have mismatching area ID's.
  const emptyScoresWithEmptyPeriods = Array(dateRangeOptionsLength)
    .fill(0)
    .map(() => EMPTY_PERIOD_SCORE);
  return emptyScoresWithEmptyPeriods;
};

interface MatchAreaWithScoreParams {
  scoresByArea: ScoresByArea;
  areas: AreasResponse;
  overallScores: ScorePerDateRange[];
  dateRangeOptionsLength: number;
}
/**
 * Creates one object with the areas and scores merged as one.
 * @param scoreMap - The scores by area fetched from the score service.
 * @param areas - The areas fetched from core platform.
 */
export const matchAreaWithScore = ({
  scoresByArea,
  areas,
  overallScores,
  dateRangeOptionsLength,
}: MatchAreaWithScoreParams): AreasWithScoresObject => {
  const areasWithScores: AreasWithScoresObject = {};

  if (!Object.keys(areas).length) {
    return {};
  }

  const colors = getWcagAccessibleColors(Object.keys(areas).length);

  Object.values(areas).forEach((area: Area, index: number) => {
    const { id } = area;
    if (!area.other) {
      const areaScores = getAreaScores({
        scoreByArea: scoresByArea[id],
        areaId: id,
        dateRangeOptionsLength,
      });
      areasWithScores[id] = {
        ...area,
        scoresPerPeriod: areaScores,
        color: colors[index],
      };
    }
  });

  // Add the overall score to the areas with scores object.
  areasWithScores.overall_score = {
    id: AGGREGATED_OVERALL_SCORE_KEY,
    name: 'Overall Score',
    scoresPerPeriod: overallScores,
    color: '#6d6d6d',
    other: false,
  };

  return areasWithScores;
};

export const findDivisionScoreInTree = (
  divisionScore: DivisionScore,
  matchingDivisionPolygonId: string,
): Nullable<DivisionScore> => {
  const { division_polygon_id: divisionPolygonId, children_division_scores: childrenDivisionScores } = divisionScore;
  if (divisionPolygonId === matchingDivisionPolygonId) {
    return divisionScore;
  }
  if (childrenDivisionScores?.length) {
    let matchingDivisionScore: Nullable<DivisionScore> = null;
    for (let index = 0; !matchingDivisionScore && index < childrenDivisionScores.length; index += 1) {
      matchingDivisionScore = findDivisionScoreInTree(childrenDivisionScores[index], matchingDivisionPolygonId);
    }

    return matchingDivisionScore;
  }

  return null;
};

const formatDivisionAreaToScoreByArea = (
  divisionArea: Area,
  startDate: string,
  endDate: string,
  matchingDivisionScore?: DivisionScore,
): ScoreByArea => ({
  polygonId: divisionArea.id,
  polygonName: divisionArea.name,
  scores: matchingDivisionScore
    ? [
        {
          startDate,
          endDate,
          totalSubmissions: matchingDivisionScore.total_submissions,
          scores: {
            positive: matchingDivisionScore.score,
            negative: 100 - matchingDivisionScore.score,
            neutral: 0,
          },
        },
      ]
    : [],
});

const reduceDivisionScoreToAreasWithScoresObject = (params: {
  divisionScores: DivisionScore[];
  divisionAreas: AreasResponse;
  startDate: string;
  endDate: string;
  colors: string[];
  dateRangeOptionsLength: number;
  existingDivisionAreasWithScores: AreasWithScoresObject;
}): AreasWithScoresObject => {
  const {
    divisionScores,
    divisionAreas,
    startDate,
    endDate,
    colors,
    dateRangeOptionsLength,
    existingDivisionAreasWithScores,
  } = params;
  return Object.values(divisionAreas).reduce<AreasWithScoresObject>((divisionAreasWithScores, divisionArea, index) => {
    const { id: areaId, other: divisionAreaOther } = divisionArea;
    if (!divisionAreaOther) {
      let matchingDivisionScore;
      divisionScores.forEach((divisionScore) => {
        const foundDivisionScore = findDivisionScoreInTree(divisionScore, areaId);
        if (foundDivisionScore) {
          const isDivisionScoreRepresentative = foundDivisionScore.total_submissions >= MIN_UNREPRESENTATIVE_INDICATOR;
          matchingDivisionScore = isDivisionScoreRepresentative ? foundDivisionScore : 0;
        }
      });

      const divisionScoreByArea: ScoreByArea = formatDivisionAreaToScoreByArea(
        divisionArea,
        startDate,
        endDate,
        matchingDivisionScore,
      );

      const divisionAreaScoresPerPeriod = getAreaScores({
        scoreByArea: divisionScoreByArea,
        areaId,
        dateRangeOptionsLength,
      });

      if (Object.keys(existingDivisionAreasWithScores).length) {
        // The division score for a specific date range already exists,
        // so we just concatenate the new date ranges scores to the existing one.
        existingDivisionAreasWithScores[areaId].scoresPerPeriod =
          existingDivisionAreasWithScores[areaId].scoresPerPeriod.concat(divisionAreaScoresPerPeriod);

        return { ...divisionAreasWithScores, ...existingDivisionAreasWithScores };
      }

      const areaWithScore: AreaWithScore = {
        ...divisionArea,
        scoresPerPeriod: divisionAreaScoresPerPeriod,
        color: colors[index],
      };
      return {
        ...divisionAreasWithScores,
        [areaId]: areaWithScore,
      };
    }

    return { ...divisionAreasWithScores };
  }, {});
};

/**
 * Format the scores for breaking down divisions by question, and match
 * the fetched Divisions to the Divisions from the scores request.
 */
export const matchAndFormatDivisionWithScore = (params: {
  questionScoresByDivisionDateRange: DivisionScoresByGenericQuestion[];
  divisionAreas: AreasResponse;
  dateRangeOptionsLength: number;
}): DivisionAreaScoresByQuestion => {
  const { divisionAreas, dateRangeOptionsLength, questionScoresByDivisionDateRange } = params;
  const divisionAreaScoresByQuestion: DivisionAreaScoresByQuestion = {};

  if (!Object.keys(divisionAreas).length) {
    return {};
  }

  const colors = getWcagAccessibleColors(Object.keys(divisionAreas).length);

  questionScoresByDivisionDateRange.forEach((questionScoresByDivision) => {
    const { start_date: startDate, end_date: endDate, question_scores: questionScores } = questionScoresByDivision;
    Object.entries(questionScores).forEach(([genericQuestionId, questionDivisionScore]) => {
      const {
        division_scores: divisionScores,
        question_text: questionText,
        question_index: questionIndex,
      } = questionDivisionScore;

      const divisionScoresAsAreaWithScoresObject = reduceDivisionScoreToAreasWithScoresObject({
        divisionScores,
        divisionAreas,
        startDate,
        endDate,
        colors,
        dateRangeOptionsLength,
        existingDivisionAreasWithScores: divisionAreaScoresByQuestion[genericQuestionId]
          ? divisionAreaScoresByQuestion[genericQuestionId].divisionScores
          : {},
      });

      divisionAreaScoresByQuestion[genericQuestionId] = {
        genericQuestionId,
        questionText,
        questionIndex,
        divisionScores: divisionScoresAsAreaWithScoresObject,
      };
    });
  });

  return divisionAreaScoresByQuestion;
};

export const matchAndFormatDivisionWithScoreByAspect = (params: {
  aspectScoresByDivisionDateRange: DivisionScoresByAspect[];
  divisionAreas: AreasResponse;
  dateRangeOptionsLength: number;
}): DivisionAreaScoresByAspect => {
  const { divisionAreas, dateRangeOptionsLength, aspectScoresByDivisionDateRange } = params;
  const divisionAreaScoresByAspect: DivisionAreaScoresByAspect = {};

  if (!Object.keys(divisionAreas).length) {
    return {};
  }

  const colors = getWcagAccessibleColors(Object.keys(divisionAreas).length);

  aspectScoresByDivisionDateRange?.forEach((aspectScoresByDivision) => {
    const { start_date: startDate, end_date: endDate, aspect_scores: aspectScores } = aspectScoresByDivision;
    Object.entries(aspectScores).forEach(([aspectId, aspectDivisionScore]) => {
      const { division_scores: divisionScores, aspect_name: aspectName } = aspectDivisionScore;

      const divisionScoresAsAreaWithScoresObject = reduceDivisionScoreToAreasWithScoresObject({
        divisionScores,
        divisionAreas,
        startDate,
        endDate,
        colors,
        dateRangeOptionsLength,
        existingDivisionAreasWithScores: divisionAreaScoresByAspect[aspectId]
          ? divisionAreaScoresByAspect[aspectId].divisionScores
          : {},
      });

      divisionAreaScoresByAspect[aspectId] = {
        aspectId,
        aspectName,
        divisionScores: divisionScoresAsAreaWithScoresObject,
      };
    });
  });

  return divisionAreaScoresByAspect;
};

export const formatAspectScoresToDivisionScoresByAspect = (
  summaryAspectWidget: SummaryAspectWidget,
): DivisionScoresByAspect[] => {
  const { start_date, end_date, aspect_scores } = summaryAspectWidget;
  return aspect_scores?.map((aspectScore) => {
    const { aspect_id, aspect_name, divisions } = aspectScore;
    return {
      start_date,
      end_date,
      aspect_scores: {
        [aspect_id]: {
          aspect_name,
          division_scores: divisions,
        },
      },
    };
  });
};
