import { QuestionType } from '@zencity/survey-types';
import BreakdownRow from 'components/BreakdownRow';
import React from 'react';
import { QuestionChoice } from 'types/questionsScores';
import { SatisfactionScore } from 'types/score';
import { NoScoresRow } from './NoScoresRow/NoScoresRow';
import OpenEndedQuestionRow from './OpenEndedQuestionRow/OpenEndedQuestionRow';

interface Props {
  questionType: string;
  questionText: string;
  satisfactionScore?: SatisfactionScore;
  comparisonSatisfactionScore?: SatisfactionScore;
  totalSubmissions: number;
  comparisonTotalSubmissions?: number;
  isNoScoresQuestion?: boolean;
  topQuestionChoice?: QuestionChoice;
  hideOvertimeTrends?: boolean;
}

export const RowElement = (props: Props) => {
  const {
    questionType,
    questionText,
    satisfactionScore,
    totalSubmissions,
    comparisonSatisfactionScore,
    comparisonTotalSubmissions,
    isNoScoresQuestion,
    topQuestionChoice,
    hideOvertimeTrends,
  } = props;

  if (isNoScoresQuestion && topQuestionChoice) {
    return (
      <NoScoresRow
        questionText={questionText}
        topQuestionChoiceScore={topQuestionChoice.score}
        choiceText={topQuestionChoice.choiceText}
        showUnrepresentativeIndicator
        totalSubmissions={totalSubmissions}
      />
    );
  }

  if (questionType !== QuestionType.OPEN_ENDED) {
    return (
      <BreakdownRow
        text={questionText}
        showUnrepresentativeIndicator
        totalSubmissions={totalSubmissions}
        totalSubmissionsToCompare={comparisonTotalSubmissions}
        satisfactionScore={satisfactionScore}
        comparisonScore={comparisonSatisfactionScore}
        hideOvertimeTrends={hideOvertimeTrends}
      />
    );
  }

  if (questionType === QuestionType.OPEN_ENDED) {
    return <OpenEndedQuestionRow questionText={questionText} totalSubmissions={totalSubmissions} />;
  }

  return null;
};
