import { Feature, MultiPolygon, Polygon } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { AreasWithScoresObject, AreaWithScore, FEATURE, PointCoordinates } from 'types/areas';
import { DivisionWithPolygon } from '@zencity/survey-types';
import center from '@turf/center';

enum PolygonType {
  POLYGON = 'Polygon',
  MULTI_POLYGON = 'MultiPolygon',
  POINT = 'Point',
}

export const computeBounds = (coordinates: GeoJSON.Position[]): [PointCoordinates, PointCoordinates] => {
  const mapGLBounds = coordinates.reduce(
    (bounds, coord) => bounds.extend(coord as PointCoordinates),
    new mapboxgl.LngLatBounds(coordinates[0] as PointCoordinates, coordinates[0] as PointCoordinates),
  );
  const northEast = mapGLBounds.getNorthEast();
  const southWest = mapGLBounds.getSouthWest();
  if (!northEast || !southWest) {
    return [
      [0, 0],
      [0, 0],
    ];
  }
  const northEastCoords: PointCoordinates = [northEast.lng, northEast.lat];
  const southWestCoords: PointCoordinates = [southWest.lng, southWest.lat];
  return [northEastCoords, southWestCoords];
};

export const getMapFeatureByArea = ({
  area,
  positiveScore,
}: {
  area: AreaWithScore;
  positiveScore: number;
}): Feature => ({
  type: FEATURE,
  geometry: area?.area?.type === PolygonType.POLYGON ? (area?.area as Polygon) : (area?.area as MultiPolygon),
  properties: {
    areaId: area?.id,
    areaName: area?.name,
    positiveScore,
  },
});

/**
 * Returns a GeoJSON Feature with a Point geometry being the center of the polygon.
 */
export const getMapCenterByArea = (area: AreaWithScore): Feature => {
  const { name, area: polygonArea } = area;
  const centerPoint = center(polygonArea as Polygon | MultiPolygon, {
    properties: {
      name,
    },
  });
  return centerPoint;
};

export const getFullCoordinateList = (areasWithScores: AreasWithScoresObject): PointCoordinates[] => {
  const coordinates: PointCoordinates[] = [];
  Object.values(areasWithScores).forEach((areaWithScore) => {
    if (areaWithScore?.area) {
      const { area } = areaWithScore;
      const coordArray = area.coordinates[0];
      if (area.type === PolygonType.POLYGON) {
        coordinates.push(...(coordArray as PointCoordinates[]));
      } else if (area.type === PolygonType.MULTI_POLYGON) {
        coordinates.push(...(coordArray[0] as PointCoordinates[]));
      }
    }
  });
  return coordinates;
};

export const getDivisionWithLeastGranularDepth = (divisions: DivisionWithPolygon[]): DivisionWithPolygon | undefined =>
  divisions?.find((division) => division.depth === 0);
