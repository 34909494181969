import { MapRenderTypes } from 'constants/map';
import { AccountsContext } from 'contexts/AccountsContext';
import { MapsContext } from 'contexts/MapsContext';
import { useContext, useMemo } from 'react';
import { AreasWithScoresObject } from 'types/areas';
import { FeatureFlag } from 'types/featureFlag';
import { userHasCorePlatformFeature } from 'utils/user';

interface Props {
  renderType: MapRenderTypes;
}

export const useMapFilteredAreasWithScores = (props: Props) => {
  const { renderType } = props;
  const { divisions, areasWithScoresData, selectedAreaIds, baseDivisionId, divisionsDictionary } =
    useContext(MapsContext);
  const { user, client } = useContext(AccountsContext);
  const hasLondonFeature = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);

  const filteredAreasWithScoresData = useMemo(() => {
    // In case there is no london feature, we don't want to filter the data.
    if (!hasLondonFeature || !baseDivisionId || renderType === MapRenderTypes.SUMMARY_WIDGET)
      return areasWithScoresData;

    // Return all areas which are children of the base division and the base division itself and selected divisions.
    const areasWithScoresDataKeys = Object.keys(areasWithScoresData);
    return areasWithScoresDataKeys.reduce((accumulate, key) => {
      const currentArea = areasWithScoresData[key];
      // eslint-disable-next-line max-nested-callbacks
      const currentDivision = divisions?.find((division) => division.geo_targeting_ref.id === currentArea.id);
      const baseDivision = divisionsDictionary?.[baseDivisionId];
      if (currentDivision?.parent_division === baseDivisionId || selectedAreaIds.includes(currentArea.id)) {
        // eslint-disable-next-line no-param-reassign
        accumulate[key] = currentArea;
      }
      if (baseDivision?.geo_targeting_ref.id) {
        // eslint-disable-next-line no-param-reassign
        accumulate[baseDivision?.geo_targeting_ref.id] = areasWithScoresData[baseDivision?.geo_targeting_ref.id];
      }
      return accumulate;
    }, {} as AreasWithScoresObject);
  }, [
    hasLondonFeature,
    baseDivisionId,
    renderType,
    areasWithScoresData,
    divisions,
    divisionsDictionary,
    selectedAreaIds,
  ]);

  return { filteredAreasWithScoresData };
};
