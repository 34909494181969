import { useEffect, useReducer } from 'react';

type ReducerProps<T> = {
  count: number;
  event: T;
};

const getReducer =
  <T>() =>
  (state: ReducerProps<T>, action: { type: 'update'; payload: ReducerProps<T> }): ReducerProps<T> => {
    switch (action.type) {
      case 'update':
        return { ...action.payload };
      default:
        throw new Error();
    }
  };

const getInitialState = <T>(): ReducerProps<T> => ({
  count: 0,
  event: null as unknown as T,
});

export const useSingleAndDoubleClick = <T>(
  singleClickHandler: (event: T) => void,
  doubleClickHandler: (event: T) => void,
  delay = 250,
): ((event: T) => void) => {
  const reducer = getReducer<T>();
  const initialState = getInitialState<T>();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Single click.
      if (state.count === 1) singleClickHandler(state.event);
      dispatch({ type: 'update', payload: { count: 0, event: null as unknown as T } });
    }, delay);

    // The duration between this click and the previous one.
    // Is less than the value of delay = double-click.
    if (state.count === 2) doubleClickHandler(state.event);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.count]);

  return (event: ReducerProps<T>['event']) => dispatch({ type: 'update', payload: { count: state.count + 1, event } });
};
