import { SurveyType, SurveyGroup, SurveyGroupSummary, Cadence } from '@zencity/survey-types';
import { SurveyGroupTotalSubmissions } from 'types/surveyGroup';
import { getRandomNumber } from 'utils/randomization';

export const surveyGroupMock = {
  id: 199,
  client: {
    id: 2,
    name: 'Mock Survey Group',
  },
  title_for_display: 'demo',
  type: SurveyType.COMMUNITY_ASKS,
  published: true,
  created_at: '2022-01-01 00:00:00.000000+03',
  updated_at: '2022-01-01 00:00:00.000000+03',
  survey_public_link: 'https://surveys-development.zencity.io/test-client/xxx/',
  cadence: Cadence.QUARTERLY,
  summary: {
    total_respondents: 123,
  },
} as SurveyGroup;

export const surveyGroupSummary = {
  total_respondents: 123,
} as SurveyGroupSummary;

export const SurveyGroupSubmissions = {
  total_submissions: getRandomNumber(1000, 6000),
  total_valid_submissions: getRandomNumber(1000, 6000),
} as SurveyGroupTotalSubmissions;
