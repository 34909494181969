import { ScorePerDateRange } from './score';

export type PointCoordinates = [number, number];

export interface Area {
  id: string;
  name: string;
  area?: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  other: boolean;
}

export interface AreasResponse {
  [key: string]: Area;
}

export interface AreaWithScore extends Area {
  scoresPerPeriod: ScorePerDateRange[];
  color: string;
}

// Combined area polygon data with its corresponding scores.
export interface AreasWithScoresObject {
  [areaId: string]: AreaWithScore;
}

export const FEATURE_COLLECTION = 'FeatureCollection';
export const FEATURE = 'Feature';
export const US_CENTER = {
  lat: 37.0902,
  lon: -95.7129,
};
export const AGGREGATED_OVERALL_SCORE_KEY = 'overall_score';

export interface DivisionAreaScoresByQuestion {
  [genericQuestionId: string]: {
    genericQuestionId: string;
    questionText: string;
    questionIndex: number;
    divisionScores: AreasWithScoresObject;
  };
}

export interface DivisionAreaScoresByAspect {
  [aspectId: string]: {
    aspectId: string;
    aspectName: string;
    divisionScores: AreasWithScoresObject;
  };
}
