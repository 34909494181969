import { useCallback, useEffect, useMemo, useState } from 'react';

export enum Layout {
  SCREEN_LARGE = 'screenLayoutLarge',
  SCREEN_MEDIUM = 'screenLayoutMedium',
  SCREEN_SMALL = 'screenLayoutSmall',
  MOBILE_LARGE = 'screenLayoutMobileLarge',
  MOBILE_SMALL = 'screenLayoutMobileSmall',
}

/**
 * get the layout (class name) according to screen's or container's width
 * @param width - containers width in pixels
 * @returns layout (class name)
 */
export function getLayout(width: number): Layout {
  if (width >= 1340) return Layout.SCREEN_LARGE;
  if (width >= 1024) return Layout.SCREEN_MEDIUM;
  if (width >= 768) return Layout.SCREEN_SMALL;
  if (width >= 480) return Layout.MOBILE_LARGE;
  return Layout.MOBILE_SMALL; // width is probably >= 320 but this is also a fallback for smaller screens
}

export function useObserverResize(): [Layout, (node: HTMLElement | null) => void] {
  const [currentLayout, setCurrentLayout] = useState<Layout>(Layout.SCREEN_SMALL);

  // eslint-disable-next-line complexity
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (entries[0]?.target) {
          const divElm = entries[0].target as HTMLElement;
          const width = divElm.scrollWidth;
          setCurrentLayout(getLayout(width));
        }
      }),
    [],
  );

  useEffect(
    () =>
      function cleanup() {
        resizeObserver.disconnect();
      },
    [resizeObserver],
  );

  const onContentRender = useCallback(
    (node) => {
      if (node !== null) {
        resizeObserver.disconnect();
        resizeObserver.observe(node); // will respond to width change
      }
    },
    [resizeObserver],
  );

  return [currentLayout, onContentRender];
}
