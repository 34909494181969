import { Question } from '@zencity/survey-types';
import { useState } from 'react';

export const useCrossQuestion = () => {
  const [isCrossModeEnabled, setIsCrossModeEnabled] = useState(false);
  const [crossTabQuestions, setCrossTabQuestions] = useState<Question[]>([]);
  const [expandedQuestionId, setExpandedQuestionId] = useState<number>();
  const [crossSelectedQuestionId, setCrossSelectedQuestionId] = useState<number>();

  return {
    isCrossModeEnabled,
    setIsCrossModeEnabled,
    crossTabQuestions,
    setCrossTabQuestions,
    expandedQuestionId,
    setExpandedQuestionId,
    crossSelectedQuestionId,
    setCrossSelectedQuestionId,
  };
};
