import { DemographicScore, GenericQuestionScore } from 'types/score';

export enum ActionTypes {
  DEMOGRAPHICS_LOADING,
  DEMOGRAPHICS_FETCHED,
  DEMOGRAPHICS_FAILED,
  QUESTIONS_LOADING,
  QUESTIONS_FETCHED,
  QUESTIONS_FAILED,
  DEMOGRAPHICS_TO_COMPARE_FETCHED,
  QUESTIONS_TO_COMPARE_FETCHED,
}

export type Score = DemographicScore | GenericQuestionScore;

export enum WidgetName {
  POSITIVE_DEMOGRAPHICS = 'positive-demographics',
  NEGATIVE_DEMOGRAPHICS = 'negative-demographics',
  AREAS = 'areas',
}
