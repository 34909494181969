import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { noScoresEmptyStateSvg } from 'assets/svgIcons';
import { getTabPath, TabKeys } from 'constants/tabs';
import emptyStateWithCustomIconStyles from '../EmptyStateWithCustomIcon/EmptyStateWithCustomIcon.module.scss';
import styles from './NoScoresEmptyState.module.scss';

/**
 * Empty state for when there are no scores.
 *
 * Using the same styling rules defined for `EmptyStateWithCustomIcon` component.
 */
export const NoScoresEmptyState = (): ReactElement => {
  const { t: translate } = useTranslation();
  // eslint-disable-next-line i18next/no-literal-string
  const baseTranslationPath = 'communitySurvey.noScoresEmptyState';

  return (
    <div className={emptyStateWithCustomIconStyles.emptyStateWithCustomIcon}>
      {noScoresEmptyStateSvg}
      <div className={emptyStateWithCustomIconStyles.content}>
        <h2>{translate(`${baseTranslationPath}.title`)}</h2>
        <p className={emptyStateWithCustomIconStyles.description}>{translate(`${baseTranslationPath}.description`)}</p>
      </div>
      <NavLink key={TabKeys.FEED} to={getTabPath(TabKeys.FEED)} className={styles.linkAsButton}>
        {translate(`${baseTranslationPath}.ctaLabel`)}
      </NavLink>
    </div>
  );
};
