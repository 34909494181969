import { ZCDButton, ZCDIcon } from '@zencity/common-ui';
import classNames from 'classnames';
import React from 'react';
import styles from './Breadcrumbs.module.scss';

interface Props<T> {
  crumbs: {
    label: string;
    value: T;
  }[];
  onClick: (value: T) => void;
}

/**
 * A Breadcrumbs component.
 */
export function Breadcrumbs<T>(props: Props<T>): JSX.Element {
  const { crumbs, onClick } = props;

  return (
    <div className={styles.breadcrumbs}>
      {crumbs.map((crumb, index) => {
        const isLast = index === crumbs.length - 1;
        return (
          <div className={styles.crumb} key={crumb.label}>
            <div
              className={classNames(styles.buttonWrapper, {
                [styles.disabledButtonWrapper]: isLast,
              })}
            >
              <ZCDButton
                variant="link"
                onClick={() => onClick(crumb.value)}
                text={crumb.label.toLocaleUpperCase()}
                disabled={isLast}
              />
            </div>
            {index < crumbs.length - 1 && <ZCDIcon name="chevron-right" />}
          </div>
        );
      })}
    </div>
  );
}
