import { createAxiosInstance } from 'utils/Api';
import { SurveyRequestFilterParams, SurveyRequestPayload } from 'types/surveyRequest';
import { notEmptyStringOrNull } from 'utils/formatters';
import path from 'utils/path';
import { SurveyRequest } from '@zencity/survey-types';
import { surveyRequest as mockSurveyRequest } from '../mocks/data/surveyRequest.mock';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.surveyRequest),
);

/**
 * Submit a new Survey Request to the server (i.e., Vault).
 *
 * @param surveyType: string - The type of the Survey to request.
 * @param requestText: string - The content of the request by the user.
 * @param typeformExample: number - The ID of the `TypeformExample` to use.
 * @param csmSlack: string - The CSM's Slack ID (may also be the full name or nothing)
 *
 * @return {Promise<SurveyRequest>}
 */
export const submitSurveyRequest = function submitSurveyRequest({
  surveyType,
  requestText,
  typeformExample,
  csmSlack,
}: SurveyRequestPayload): Promise<SurveyRequest> {
  return axiosInstance
    .post<SurveyRequest>('', {
      survey_type: surveyType,
      request_text: notEmptyStringOrNull(requestText ?? ''),
      typeform_example: typeformExample,
      csm_slack_id: notEmptyStringOrNull(csmSlack ?? ''),
    })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => {
      if (config.shouldMockRequests) {
        Promise.resolve(mockSurveyRequest);
      }
      const message = `Failed to submit a Survey Request to server [${error.message}]`;
      return Promise.reject(new Error(message));
    });
};

export async function fetchSurveyRequests(params: SurveyRequestFilterParams): Promise<SurveyRequest[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve([mockSurveyRequest]);
  }
  const result = await axiosInstance.get<SurveyRequest[]>('', { params });

  return result.data;
}

/**
 * Publish the survey drafted in the Google Sheet to Typeform.
 *
 * @param surveyRequestId: string - The id of the Survey Request.
 *
 * @return {Promise<SurveyRequest>}
 */
export async function publishSurveyRequest(surveyRequestId: string): Promise<SurveyRequest> {
  const result = await axiosInstance.post<SurveyRequest>(`${surveyRequestId}/publish/`, {});

  return result.data;
}

/**
 * Unpublish the typeform survey and set the survey request to closed.
 *
 * @param surveyRequestId - The ID of the Survey Request.
 *
 * @return {Promise<SurveyRequest>} The updated survey request.
 */
export async function closeSurveyBySurveyRequest(surveyRequestId: string): Promise<SurveyRequest> {
  const result = await axiosInstance.patch<SurveyRequest>(`${surveyRequestId}/close/`, {});

  return result.data;
}
