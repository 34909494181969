import { Icon } from '@zencity/common-ui';
import { Flex } from 'components/Flex/Flex';
import { getTabPath, TabKeys } from 'constants/tabs';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';

import { AnalyticEventsChangeTypes } from 'constants/analytics';
import { useFilterNames } from 'hooks/useFilterNames';
import { useTranslation } from 'react-i18next';
import styles from './OpenEndedQuestionRow.module.scss';

interface Props {
  questionText: string;
  totalSubmissions: number;
}

const OpenEndedQuestionRow = (props: Props): ReactElement => {
  const { totalSubmissions, questionText } = props;
  const { t: translate } = useTranslation();
  const activeFilters = useFilterNames();

  const handleClick = () => {
    analyticsService.crossProductEvents.ctaClicked({
      displayName: translate('resultsScreen.breakdownTable.validNumbersInFeed', {
        totalSubmissions,
      }),
      widgetName: AnalyticEventsChangeTypes.IRRELEVANT,
      activeFilters,
    });
  };

  return (
    <Flex className={styles.openEndedQuestionRow} alignItems="center" justifyContent="space-between" width="100%">
      <p>{questionText}</p>
      <div className={styles.feedLink}>
        <Link to={getTabPath(TabKeys.FEED)} onClick={handleClick}>
          {translate('resultsScreen.breakdownTable.validNumbersInFeed', {
            totalSubmissions,
          })}
          <Icon name="arrow-right.svg" width={24} height={24} />
        </Link>
      </div>
    </Flex>
  );
};

export default OpenEndedQuestionRow;
