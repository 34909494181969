import { useTimeoutHover } from 'hooks/useTimeoutHover';
import React from 'react';

interface Props {
  children: React.ReactNode;
  timeout?: number;
  onHover: () => void;
}
export const HoverTimeoutWrapper = (props: Props): React.ReactElement => {
  const { children, timeout = 1500, onHover } = props;
  const elementRef = React.useRef<HTMLDivElement>(null);
  useTimeoutHover(elementRef, onHover, timeout);

  return <div ref={elementRef}>{children}</div>;
};
