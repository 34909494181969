import { SurveyType } from '@zencity/survey-types';
import { ScoreCardWidget } from 'components/ScoreCardWidget/ScoreCardWidget';
import { Status } from 'constants/state';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import React, { useContext } from 'react';
import { ScorePerDateRange } from 'types/score';
import { SummaryOverallSatisfactionScores } from 'types/summaryOverallSatisfactionWidget';
import styles from './OverallSatisfaction.module.scss';
import { useOverallSatisfactionData } from './useOverallSatisfactionData';

const formatToOverallScores = (selectedAspect: SummaryOverallSatisfactionScores[] | undefined): ScorePerDateRange[] => {
  if (!selectedAspect) return [];
  return selectedAspect.map((aspect) => ({
    scores: {
      positive: aspect.score,
      negative: 0,
      neutral: 0,
    },
    startDate: aspect.start_date,
    endDate: aspect.end_date,
    totalSubmissions: aspect.total_submissions,
  }));
};

export const OverallSatisfaction = (): JSX.Element => {
  const { selectedDateWidgetData, activeTab } = useContext(SummaryAspectWidgetContext);
  const { completedCyclesDateRanges } = useContext(DateRangeContext);
  // It is assumed that the completed cycles are sorted from most recent to oldest.
  const mostRecentCompletedDateRange = completedCyclesDateRanges?.[0];
  const { loading, widgetData } = useOverallSatisfactionData(
    mostRecentCompletedDateRange?.startDate,
    mostRecentCompletedDateRange?.endDate,
  );
  const selectedAspect = widgetData?.find((aspect) => aspect.aspect_id === activeTab)?.scores;
  const overallScores = formatToOverallScores(selectedAspect);
  const aspectSentence =
    selectedDateWidgetData?.aspect_scores.find((aspect) => aspect.aspect_id === activeTab)?.aspect_sentence || null;

  return (
    <div className={styles.overallSatisfaction}>
      {overallScores && (
        <ScoreCardWidget
          fetchStatus={loading ? Status.LOADING : Status.OKAY}
          overallScores={overallScores}
          surveyType={SurveyType.BLOCKWISE}
          mainTitle={aspectSentence}
        />
      )}
    </div>
  );
};
