import React, { ReactElement } from 'react';
import styles from './MenuHeader.module.scss';

interface Props {
  headerTitle: string;
}

/**
 * An optional header for the vertical menu
 * @param {object} props - headerTitle: string - the header's title
 */
const MenuHeader = ({ headerTitle }: Props): ReactElement => <h4 className={styles.title}>{headerTitle}</h4>;

export default MenuHeader;
