/* eslint-disable max-lines-per-function */
import { ZCDFilter, ZCDSpinner } from '@zencity/common-ui';
import { getDateRangeComparison } from 'components/DateRangeCompareFilter/utils';
import { AnalyticEventsChangeTypes, AnalyticFilterNames } from 'constants/analytics';
import { DateRangeContext } from 'contexts/DateRangeContext';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { SelectOption } from 'types/selectOption';
import { generateDemographicsFilterValue, getCycleNumber } from 'utils/utils';

type Option = SelectOption & {
  disabled?: boolean;
};

/**
 * A component to select different community survey cadence date ranges.
 */
// eslint-disable-next-line max-statements
export const DateRangeFilter: React.FC = function DateRangeFilter(): ReactElement {
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    selectedDateRange,
    setSelectedDateRange,
    completedCyclesDateRanges,
    dashboardDateRanges,
    isContextLoaded,
    setComparisonDateRange,
  } = useContext(DateRangeContext);
  const dashboardDateRangesOptions = useMemo(() => dashboardDateRanges || [], [dashboardDateRanges]);

  const formattedDashboardDateRangesOptions = dashboardDateRangesOptions.map(
    (element) => ({ value: element?.key, label: element?.label, disabled: element?.disabled ?? false } as SelectOption),
  );

  const dateRangeFilterValue = formattedDashboardDateRangesOptions.find(
    (dateRange) => dateRange.value === selectedDateRange?.key,
  );

  const handleOnDateRangeSelection = (newSelectedOption: SelectOption) => {
    const selectDateRange = dashboardDateRangesOptions.find((dateRange) => dateRange?.key === newSelectedOption.value);
    setSelectedDateRange(selectDateRange);

    const comparisonDateRange = getDateRangeComparison({
      selectedDateRange: selectDateRange,
      completedCyclesDateRanges,
    });
    setComparisonDateRange(comparisonDateRange);

    analyticsService.crossProductEvents.filterChanged({
      filterName: AnalyticFilterNames.DATA,
      filterValue: generateDemographicsFilterValue(selectDateRange),
      cycleNumber: getCycleNumber(selectedDateRange, completedCyclesDateRanges),
      ageValue: AnalyticEventsChangeTypes.IRRELEVANT,
      areaValue: AnalyticEventsChangeTypes.IRRELEVANT,
      ethnicityValue: AnalyticEventsChangeTypes.IRRELEVANT,
      genderValue: AnalyticEventsChangeTypes.IRRELEVANT,
    });
  };

  useEffect(() => {
    setIsLoading(!isContextLoaded);
  }, [isContextLoaded]);

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1px',
    paddingBottom: '8px',
    fontWeight: 400,
    color: '#6D6D6D',
  };

  const formatGroupLabel = (option: Option) => (
    <div style={groupStyles}>
      <span>{option.label}</span>
    </div>
  );

  const groupedOptions = formattedDashboardDateRangesOptions.length
    ? [
        formattedDashboardDateRangesOptions[0],
        {
          label: formattedDashboardDateRangesOptions[1]?.label,
          options: formattedDashboardDateRangesOptions.slice(2),
        },
      ]
    : [];

  return (
    <div>
      {isLoading && <ZCDSpinner />}
      {!isLoading && (
        <ZCDFilter<Option>
          value={dateRangeFilterValue}
          defaultValue={dateRangeFilterValue}
          onChange={(newValue) => handleOnDateRangeSelection(newValue as SelectOption)}
          options={groupedOptions}
          label={translate('header.dateFilter.placeholderLabel')}
          selectedValueLabel={translate('header.dateFilter.valueLabel')}
          // The dashboard date ranges will minimum have 2 options for the
          // latest results option and the "Completed Cycles" label option.
          isDisabled={dashboardDateRangesOptions.length < 2}
          // the necessary type is not exported from common ui currently
          formatGroupLabel={formatGroupLabel as any}
          isOptionDisabled={(option) => !!(option as Option).disabled}
        />
      )}
    </div>
  );
};
