import { QuestionType } from '@zencity/survey-types';
import { FilteredQuestion, Question } from 'types/questionsScores';

export const useFilterScoreQuestions = (scoreQuestions?: Question[]): { filteredQuestions?: FilteredQuestion[] } => {
  // For each question, check if it has a score, and add isNoScoresQuestion flag
  const filteredScoreQuestions = scoreQuestions?.map((question) => {
    const isNoScoresQuestion =
      question.questionChoices.every(
        (questionChoice) =>
          !questionChoice.numericValue &&
          questionChoice.numericValue !== 0 &&
          !questionChoice.score &&
          questionChoice.score !== 0,
      ) && question.questionType !== QuestionType.OPEN_ENDED;
    return {
      ...question,
      isNoScoresQuestion,
    };
  });

  return {
    filteredQuestions: filteredScoreQuestions,
  };
};
