import { MIN_UNREPRESENTATIVE_INDICATOR } from 'components/UnrepresentativeIndicator/constants';
import { AspectDemographicsSegment } from 'types/summaryAspectWidget';

export const findAndSortDemographicGroupItems = (
  highestScoresDemographics: AspectDemographicsSegment[],
  selectedDemographicType?: string,
): AspectDemographicsSegment[] => {
  const representativeDemographicsToDisplay = highestScoresDemographics.filter(
    (item) => item.type === selectedDemographicType && item.total_submissions >= MIN_UNREPRESENTATIVE_INDICATOR,
  );

  const unrepresentativeDemographicsToDisplay = highestScoresDemographics
    .filter((item) => item.type === selectedDemographicType && item.total_submissions < MIN_UNREPRESENTATIVE_INDICATOR)
    .sort((firstItem, secondItem) => firstItem.label.localeCompare(secondItem.label, undefined, { numeric: true }));

  const demographicsToDisplay = representativeDemographicsToDisplay.concat(unrepresentativeDemographicsToDisplay);

  return demographicsToDisplay;
};
