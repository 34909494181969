import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.exportSubmissions),
);

interface ExportSubmissions {
  survey_group?: number;
  format: string;
}

/**
 * Export answers by survey group to xlsx file.
 * @param params
 * @return Promise<number>
 */
export async function exportSubmissionsBySurveyGroup(params: ExportSubmissions): Promise<number> {
  const result = await axiosInstance.get('', { params, responseType: 'blob' });

  const linkToFile = document.createElement('a');
  linkToFile.href = window.URL.createObjectURL(new Blob([result.data], { type: 'application/xlsx; charset=utf-8' }));
  linkToFile.download =
    result.headers?.['content-disposition']?.split('filename=')?.[1]?.replaceAll('"', '') || 'export.xlsx';
  linkToFile.style.display = 'none';
  document.body.appendChild(linkToFile);
  linkToFile.click();
  linkToFile.remove();

  return result.status;
}
