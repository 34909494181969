/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { DivisionWithPolygon, SurveyGroup, SurveyType } from '@zencity/survey-types';
import { useDashboardDateRanges } from 'hooks/dashboardDateRange';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { useEffect, useMemo, useState } from 'react';
import { fetchDivisionsWithPolygons } from 'services/division';
import { fetchSurveyGroupDivisionsScore } from 'services/surveyGroup';
import { AreasWithScoresObject, DivisionAreaScoresByQuestion } from 'types/areas';
import { Client } from 'types/client';
import { ServerDateRangeParams } from 'types/dateRange';
import { FeatureFlag } from 'types/featureFlag';
import { DivisionScoresByGenericQuestion } from 'types/score';
import { User } from 'types/user';
import { matchAndFormatDivisionWithScore } from 'utils/Map/AreaHelpers';
import { getDashboardDateRanges } from 'utils/dateRange';
import { ReactQueryKeys } from 'utils/reactQueryKeys';
import { userHasCorePlatformFeature } from 'utils/user';
import { reduceDivisionsToAreasResponse } from '../BlockwiseDashboard.helpers';

interface Props {
  user: User;
  client: Client;
}

export const useBlockwiseDashboard = ({ user, client }: Props) => {
  const [mapsDataIsLoading, setMapsDataIsLoading] = useState<boolean>(true);
  const [divisionAreaScoresByQuestion, setDivisionAreaScoresByQuestion] = useState<DivisionAreaScoresByQuestion>({});
  const [selectedGenericQuestionId, setSelectedGenericQuestionId] = useState<string>('');
  const [divisions, setDivisions] = useState<DivisionWithPolygon[]>([]);
  const [currentAreasWithScores, setCurrentAreasWithScores] = useState<AreasWithScoresObject>({});
  const [sortedAreaIdsByAreaName, setSortedAreaIdsByAreaName] = useState<string[]>([]);

  const { currentSurveyGroup } = useDashboardDateRanges(SurveyType.BLOCKWISE);

  const hasBlockwiseAccess = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);
  const divisionsDictionary = useMemo(
    () => (divisions ? Object.fromEntries(divisions.map((division) => [division.id, division])) : {}),
    [divisions],
  );
  const { response: dashboardDateRanges } = useCustomQuery({
    queryKey: ReactQueryKeys.DASHBOARD_DATE_RANGES,
    requestFunction: getDashboardDateRanges,
    params: currentSurveyGroup as SurveyGroup,
    enableRequest: !!currentSurveyGroup,
  });
  const dateRanges = dashboardDateRanges?.scoreDateRangeFilterOptions.map((dateRangeFilterOption) => ({
    start_date: dateRangeFilterOption.startDate,
    end_date: dateRangeFilterOption.endDate,
  }));

  const { response: questionScoresByDivisionDateRange } = useCustomQuery({
    queryKey: ReactQueryKeys.SURVEY_GROUP_DIVISIONS_SCORE,
    requestFunction: fetchSurveyGroupDivisionsScore,
    params: { dateRanges: dateRanges as ServerDateRangeParams[], surveyGroupId: currentSurveyGroup?.id as number },
    enableRequest: !!currentSurveyGroup,
    // We want to refetch the data every day.
    staleTime: 1000 * 60 * 60 * 24,
  });

  const { response: divisionAreasResponse } = useCustomQuery({
    params: {
      surveyType: SurveyType.BLOCKWISE,
    },
    queryKey: ReactQueryKeys.DIVISIONS_WITH_POLYGONS,
    requestFunction: fetchDivisionsWithPolygons,
    enableRequest: !!currentSurveyGroup,
    // We want to refetch the data every day.
    staleTime: 1000 * 60 * 60 * 24,
  });

  const formatAndSetData = ({
    questionDivisionDateRange,
    divisionResponse,
    dateRangeOptionsLength,
  }: {
    questionDivisionDateRange: DivisionScoresByGenericQuestion[];
    divisionResponse: DivisionWithPolygon[];
    dateRangeOptionsLength: number;
  }) => {
    // In case there are no divisionResponse or questionDivisionDateRange, we don't want to show the map
    if (!divisionResponse || !questionDivisionDateRange) {
      setMapsDataIsLoading(false);
      return;
    }
    const divisionAreas = reduceDivisionsToAreasResponse(divisionResponse);
    const matchedDivisionScoresByGenericQuestion = matchAndFormatDivisionWithScore({
      questionScoresByDivisionDateRange: questionDivisionDateRange,
      divisionAreas,
      dateRangeOptionsLength,
    });

    const sortedDivisionScoresByQuestion = Object.values(matchedDivisionScoresByGenericQuestion).sort(
      (firstQuestionScore, secondQuestionScore) => firstQuestionScore.questionIndex - secondQuestionScore.questionIndex,
    );

    const firstSelectedGenericQuestionId = sortedDivisionScoresByQuestion[0]?.genericQuestionId;
    setSelectedGenericQuestionId(firstSelectedGenericQuestionId);

    const sortedAreaIdsByName = Object.values(
      matchedDivisionScoresByGenericQuestion[firstSelectedGenericQuestionId]?.divisionScores ?? {},
    ).map((areaWithScore) => areaWithScore.id);

    setDivisionAreaScoresByQuestion(matchedDivisionScoresByGenericQuestion);
    if (matchedDivisionScoresByGenericQuestion[firstSelectedGenericQuestionId]?.divisionScores) {
      setCurrentAreasWithScores(matchedDivisionScoresByGenericQuestion[firstSelectedGenericQuestionId].divisionScores);
    }
    setSortedAreaIdsByAreaName(sortedAreaIdsByName);
    setMapsDataIsLoading(false);
    setDivisions(divisionResponse);
  };

  useEffect(() => {
    const enableFetchData =
      hasBlockwiseAccess &&
      dashboardDateRanges?.scoreDateRangeFilterOptions &&
      currentSurveyGroup &&
      divisionAreasResponse &&
      questionScoresByDivisionDateRange;

    function fetchData() {
      if (enableFetchData) {
        formatAndSetData({
          questionDivisionDateRange: questionScoresByDivisionDateRange,
          divisionResponse: divisionAreasResponse,
          dateRangeOptionsLength: dashboardDateRanges?.scoreDateRangeFilterOptions.length,
        });
      } else {
        setMapsDataIsLoading(false);
      }
    }
    fetchData();
  }, [
    client,
    user,
    hasBlockwiseAccess,
    currentSurveyGroup,
    dashboardDateRanges?.scoreDateRangeFilterOptions,
    divisionAreasResponse,
    questionScoresByDivisionDateRange,
  ]);

  return {
    mapsDataIsLoading,
    divisionAreaScoresByQuestion,
    selectedGenericQuestionId,
    divisions,
    currentAreasWithScores,
    sortedAreaIdsByAreaName,
    scoreDateRangeFilters: dashboardDateRanges?.scoreDateRangeFilterOptions,
    divisionsDictionary,
    setMapsDataIsLoading,
    setSelectedGenericQuestionId,
    setCurrentAreasWithScores,
  };
};
