/* eslint-disable react/destructuring-assignment */
import classNames from 'classnames';
import { Flex } from 'components/Flex/Flex';
import React, { ReactElement } from 'react';
import { ProgressBarWrapper, ScoreType } from '../ProgressBarWrapper/ProgressBarWrapper';
import { AnalysisWidgetWrapper } from '../AnalysisWidgetWrapper/AnalysisWidgetWrapper';

import styles from './SummaryWidget.module.scss';

interface OverallProps {
  label: string;
  score: number;
  totalQuestionSubmissions: number;
  postfix?: string;
}

type Props = OverallProps;

const SummaryWidgetOverall = (props: Props): ReactElement => {
  const { score, label, postfix, totalQuestionSubmissions } = props;
  const isScore = !totalQuestionSubmissions || totalQuestionSubmissions === 0;
  /* TODO: replace ProgressBar with non-deprecated ProgressBar */
  return (
    <div className={styles.summaryWidget}>
      <AnalysisWidgetWrapper>
        <span className={styles.widgetLabel}>{label}</span>
        <Flex alignItems="flex-end" justifyContent="space-between" width="100%">
          <div
            className={classNames({
              [styles.score]: isScore,
              [styles.responsiveScore]: !isScore,
            })}
          >
            <ProgressBarWrapper
              score={score}
              scoreType={ScoreType.NUMBER}
              ratio={totalQuestionSubmissions}
              postfix={postfix}
            />
          </div>
        </Flex>
      </AnalysisWidgetWrapper>
    </div>
  );
};

export default SummaryWidgetOverall;
