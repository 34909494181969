import { QuestionsFeedContext } from 'contexts/QuestionsFeedContext';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { useContext } from 'react';
import { fetchSurveyGroupDemographicQuestionsTypes } from 'services/surveyGroup';
import { DemographicFilters, SurveyGroupDemographicTypes } from 'types/demographicFilters';
import { SelectOption } from 'types/selectOption';
import { ReactQueryKeys } from 'utils/reactQueryKeys';

const convertDemographicsToFilterOptions = (demographics: SurveyGroupDemographicTypes): SelectOption[] => {
  const keys = Object.keys(demographics) as DemographicFilters[];
  return keys
    .map((key) => {
      const demographic = demographics[key];
      return demographic;
    })
    .sort((firstDemographicType, secondDemographicType) =>
      firstDemographicType.label.localeCompare(secondDemographicType.label, undefined, { numeric: true }),
    );
};

export const useDemographicTypesFilter = (): {
  filterData: SelectOption[] | undefined;
  loading: boolean;
} => {
  const { surveyGroupId } = useContext(QuestionsFeedContext);

  const { response: demographicFilter, isFetching } = useCustomQuery({
    params: {
      surveyGroupId: surveyGroupId as number,
    },
    requestFunction: fetchSurveyGroupDemographicQuestionsTypes,
    queryKey: ReactQueryKeys.DEMOGRAPHIC_WIDGET_FILTER,
    enableRequest: !!surveyGroupId,
  });

  const demographicFilterOptions = demographicFilter && convertDemographicsToFilterOptions(demographicFilter);

  return { filterData: demographicFilterOptions, loading: isFetching };
};
