import { useMemo } from 'react';
import { AGGREGATED_OVERALL_SCORE_KEY } from 'types/areas';

interface Props {
  selectedAreaIds: string[];
  hoveredAreaId: string;
}

export const useMapLayerFilters = (props: Props) => {
  const { selectedAreaIds, hoveredAreaId } = props;
  /**
   * Filter for the selected layers area outline.
   * The way it is filtered is by filtering by the areaId's that are in the selectedAreaIds.
   * We don't want an outline for the overall score area (since there is none), so it is filtered out.
   */
  const selectedAreaFilter = useMemo(() => {
    const filteredAreas = selectedAreaIds.filter((area) => area !== AGGREGATED_OVERALL_SCORE_KEY);
    return ['in', ['get', 'areaId'], ['literal', filteredAreas]];
  }, [selectedAreaIds]);

  const hoveredAreaFilter = useMemo(() => {
    const filteredAreas: string[] = [];
    // selected-area border takes precedence over hovering effect.
    if (hoveredAreaId !== AGGREGATED_OVERALL_SCORE_KEY && !selectedAreaIds.includes(hoveredAreaId)) {
      filteredAreas.push(hoveredAreaId);
    }
    return ['in', ['get', 'areaId'], ['literal', filteredAreas]];
  }, [hoveredAreaId, selectedAreaIds]);

  return {
    selectedAreaFilter,
    hoveredAreaFilter,
  };
};
