/* eslint-disable i18next/no-literal-string */
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { pdfIconSvg } from 'assets/svgIcons';
import styles from './DownloadReportLink.module.scss';

interface Props {
  reportDownloadUrl?: string;
  closeActionsDropdown: () => void;
}

export const DownloadReportLink = (props: Props): ReactElement => {
  const { reportDownloadUrl, closeActionsDropdown } = props;
  const { t: translate } = useTranslation();

  let elementTitle = '';
  if (!reportDownloadUrl) {
    elementTitle = translate('communityAsks.shareResults.downloadReportNotAvailable');
  }

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={reportDownloadUrl}
      onClick={closeActionsDropdown}
      aria-disabled={!reportDownloadUrl}
      className={classNames(styles.downloadReportLink, {
        [styles.disabled]: !reportDownloadUrl,
      })}
      title={elementTitle}
    >
      {pdfIconSvg}
      {translate('communityAsks.shareResults.downloadReport')}
    </a>
  );
};
