/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { SurveyType } from '@zencity/survey-types';
import classnames from 'classnames';
import { ErrorBoundaryWithIcon } from 'components/ErrorBoundaryWithIcon/ErrorBoundaryWithIcon';
import { AccountsContext } from 'contexts/AccountsContext';
import { DateRangeContext } from 'contexts/DateRangeContext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { BlockwiseDashboardRoutes } from 'screens/blockwise/router';
import { useObserverResize } from 'screens/Screens.helpers';
import { Client } from 'types/client';
import { User } from 'types/user';
import { initLogger } from 'utils/blockwise/logger';
import '../../i18n';
import '../../index.scss';

import { ProductNames } from 'constants/products';
import { DashboardContext } from 'contexts/DashboardContext';
import { FiltersContext } from 'contexts/FiltersContext';
import { MapsContext } from 'contexts/MapsContext';
import { OpenEndedQuestionFeedContext } from 'contexts/OpenEndedQuestionFeedContext';
import { QuestionsFeedContext } from 'contexts/QuestionsFeedContext';
import { useDashboardDateRanges } from 'hooks/dashboardDateRange';
import { useFiltersData } from 'hooks/dataFilters';
import { useOpenEndedQuestions } from 'hooks/openEndedQuestions';
import { useCrossQuestion } from 'hooks/useCrossQuestion';
import { useSurveysInitialLoad } from 'hooks/useSurveysInitialLoad';
import { FeatureFlag } from 'types/featureFlag';
import { userHasCorePlatformFeature } from 'utils/user';
import { FullScreenEmptyState } from 'components/FullScreenEmptyState/FullScreenEmptyState';
import { config } from '../../../config/config';
import styles from './BlockwiseDashboard.module.scss';
import { useBlockwiseDashboard } from './hooks/useBlockwiseDashboard';

interface Props {
  user: User;
  client: Client;
}

const BlockwiseDashboard = function BlockwiseDashboard({ user, client }: Props): React.ReactElement {
  const { t: translate } = useTranslation();

  const [selectedAreaIds, setSelectedAreaIds] = useState<string[]>([]);
  const [hoveredAreaId, setHoveredAreaId] = useState<string>('');
  const [baseDivisionId, setBaseDivisionId] = useState<undefined | number>();
  const [currentLayout, onContentRender] = useObserverResize();

  const crossSectionStates = useCrossQuestion();
  const { dateRangeDataContextData, currentSurveyGroup } = useDashboardDateRanges(SurveyType.BLOCKWISE);
  const { openEndedQuestions, areOpenEndedQuestionsLoading } = useOpenEndedQuestions(currentSurveyGroup);
  const { filtersContextData } = useFiltersData(currentSurveyGroup);
  const hasBlockwiseAccess = userHasCorePlatformFeature(client, user, FeatureFlag.BLOCKWISE);
  const { client: leanSurveyClient } = currentSurveyGroup ?? {};
  useSurveysInitialLoad(client, user, initLogger, ProductNames.BLOCKWISE);

  const {
    currentAreasWithScores,
    divisionAreaScoresByQuestion,
    divisions,
    divisionsDictionary,
    selectedGenericQuestionId,
    mapsDataIsLoading,
    scoreDateRangeFilters,
    sortedAreaIdsByAreaName,
    setCurrentAreasWithScores,
    setMapsDataIsLoading,
    setSelectedGenericQuestionId,
  } = useBlockwiseDashboard({
    client,
    user,
  });

  if (!dateRangeDataContextData.isContextLoaded) {
    return <></>;
  }

  if (!currentSurveyGroup) {
    return (
      <FullScreenEmptyState
        iconName="card-bars-bulb.svg"
        description={translate('emptyState.noSurveyGroup.description')}
        title={translate('emptyState.noSurveyGroup.title')}
      />
    );
  }

  return hasBlockwiseAccess ? (
    <DashboardContext.Provider
      value={{
        ...crossSectionStates,
        currentSurveyGroup,
      }}
    >
      <div className={classnames(styles.app, currentLayout)} ref={onContentRender}>
        <AccountsContext.Provider value={{ client, user, leanSurveyClient }}>
          <QuestionsFeedContext.Provider
            value={{ surveyType: SurveyType.BLOCKWISE, surveyGroupId: currentSurveyGroup?.id }}
          >
            <OpenEndedQuestionFeedContext.Provider value={{ openEndedQuestions, areOpenEndedQuestionsLoading }}>
              <DateRangeContext.Provider
                value={{
                  ...dateRangeDataContextData,
                  scoreDateRangeFilters,
                }}
              >
                <FiltersContext.Provider value={filtersContextData}>
                  <MapsContext.Provider
                    value={{
                      isLoading: mapsDataIsLoading,
                      setIsLoading: setMapsDataIsLoading,
                      sortedAreaIdsByAreaName,
                      selectedAreaIds,
                      setSelectedAreaIds,
                      hoveredAreaId,
                      setHoveredAreaId,
                      divisionAreaScoresByQuestion,
                      divisions,
                      baseDivisionId,
                      setBaseDivisionId,
                      selectedGenericQuestionId,
                      areasWithScoresData: currentAreasWithScores,
                      setSelectedGenericQuestionId,
                      setAreasWithScoresData: setCurrentAreasWithScores,
                      divisionsDictionary,
                    }}
                  >
                    <BrowserRouter basename={config.blockwise.baseRouterUrl}>
                      <ErrorBoundaryWithIcon
                        errorTitle={translate('errorBoundary.title')}
                        errorDescription={translate('errorBoundary.description')}
                      >
                        {currentSurveyGroup && (
                          <BlockwiseDashboardRoutes
                            basename={config.blockwise.baseRouterUrl}
                            currentSurveyGroup={currentSurveyGroup}
                          />
                        )}
                      </ErrorBoundaryWithIcon>
                    </BrowserRouter>
                  </MapsContext.Provider>
                </FiltersContext.Provider>
              </DateRangeContext.Provider>
            </OpenEndedQuestionFeedContext.Provider>
          </QuestionsFeedContext.Provider>
        </AccountsContext.Provider>
      </div>
    </DashboardContext.Provider>
  ) : (
    <></>
  );
};

export default BlockwiseDashboard;
