export default {
  environment: 'development',
  apiBaseUrl: 'http://localhost:8000',
  insights: {
    domain: 'http://localhost:3000',
  },
  organicApiBaseUrl: 'http://localhost:1337',
  surveyManager: {
    domain: 'http://localhost:3006',
    newSurvey: '/survey/new',
  },
  localFeatureSelection: {
    baseRouterUrl: `${process.env.BASE_ROUTER_PREFIX ?? ''}feature-selection`,
  },
  simulateAmplitude: true,
  shouldMockRequests: process.env.MOCK_PAYLOADS === 'true',
};
