/* eslint-disable i18next/no-literal-string */
import { Button, ScreenStickyHeader } from '@zencity/common-ui';
import { wideAngelDownArrowIconSvg } from 'assets/svgIcons';
import classNames from 'classnames';
import { BackLink } from 'components/BackLink/BackLink';
import { CopySurveyPublicLink } from 'components/CopySurveyPublicLink/CopySurveyPublicLink';
import { DownloadReportLink } from 'components/DownloadReportLink/DownloadReportLink';
import { HeaderTabs } from 'components/HeaderTabs/HeaderTabs';
import { SurveyRequestStatusBadge } from 'components/SurveyRequestStatusBadge/SurveyRequestStatusBadge';
import { TabKeys } from 'constants/tabs';
import { SurveyRequestContext } from 'contexts/SurveyRequestContext';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { CommunityAsksRouterPaths } from 'screens/community-asks/routerPaths';
import { fetchReportForSurvey } from 'services/reportsLibrary';
import { SurveyRequestStatus } from '@zencity/survey-types';
import { logger } from 'utils/community-asks/logger';
import { formatDate } from 'utils/formatters';
import styles from './Header.module.scss';

interface HeaderProps {
  tabs: TabKeys[];
  surveyGroupId: number;
}

/**
 * The header component of a single Survey page for "Community Asks".
 * @param tabs - Array of TabKeys to be rendered as tabs in the Header.
 */
// eslint-disable-next-line max-statements, complexity
export const Header = (props: HeaderProps): ReactElement => {
  const [toggleActions, setToggleActions] = useState(false);
  const [reportDownloadUrl, setReportDownloadUrl] = useState('');
  const { t: translate } = useTranslation();
  const { tabs, surveyGroupId } = props;
  const surveyRequest = useContext(SurveyRequestContext);
  const title = surveyRequest.survey_group?.title_for_display || '';
  const createdAt = formatDate(surveyRequest.survey_group?.created_at || '');

  // FixMe: Select the typeform ID of the relevant language.
  // Currently, arbitrary take the ID of the first Typeform Survey.
  const typeformId = surveyRequest.survey_group?.survey?.[0]?.typeform_survey?.[0]?.typeform_id;
  const surveyReport = surveyRequest?.survey_group?.survey?.[0]?.survey_report?.[0];
  const shouldDisplayPublicLink = surveyRequest.status === SurveyRequestStatus.ACTIVE;

  const closeActionsDropdown = () => setToggleActions(false);

  // Used in HeaderTabs to create NavLinks to the specific subpaths for this surveyGroupId.
  const basePath = generatePath(CommunityAsksRouterPaths.SURVEY_PAGE, { surveyGroupId: surveyGroupId.toString() });

  useEffect(() => {
    const fetchReportUrl = async () => {
      if (!surveyReport) {
        return;
      }
      try {
        const result = await fetchReportForSurvey({ id: surveyReport.report_id });
        // Core-Platform sends a `report-library-item-accessed` event to
        // Amplitude when a user visits the report's URL. In order to help the
        // analysts to understand the origin of the referrer, the `type`
        // parameter is added with the `community_asks` value.
        const communityAsksReportDownloadUrl = `${
          result.reportDownloadUrl
        }&type=community_asks&fromAsksPage=true&itemId=${surveyReport.report_id}&title=${
          surveyReport.report_title ?? ''
        }`;
        setReportDownloadUrl(communityAsksReportDownloadUrl);
      } catch (error) {
        logger.error(error);
      }
    };

    fetchReportUrl();
  }, [surveyReport]);

  return (
    <>
      <ScreenStickyHeader bottomPadding={false}>
        <BackLink href={CommunityAsksRouterPaths.WORKSPACE} />
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>{title}</h1>
            <SurveyRequestStatusBadge status={surveyRequest.status} />
          </div>
          <div className={styles.date}>{createdAt}</div>
        </div>
        <div className={styles.tabsAndActions}>
          <HeaderTabs tabs={tabs} basePath={basePath} />
          {typeformId && (
            <div className={styles.actionButtons}>
              {shouldDisplayPublicLink && surveyRequest.survey_group && (
                <CopySurveyPublicLink surveyGroup={surveyRequest.survey_group} />
              )}
              <div
                className={classNames(styles.shareResultsWrapper, {
                  [`${styles.toggled}`]: toggleActions,
                })}
              >
                <Button type="button" onClick={() => setToggleActions(!toggleActions)} variant="primary" size="lg">
                  {translate('communityAsks.shareResults.ctaLabel')}
                  {wideAngelDownArrowIconSvg}
                </Button>

                {toggleActions && surveyRequest.survey_group && (
                  <div className={styles.shareResultsDropdown}>
                    <DownloadReportLink
                      reportDownloadUrl={reportDownloadUrl}
                      closeActionsDropdown={closeActionsDropdown}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </ScreenStickyHeader>
    </>
  );
};
