/* eslint-disable complexity */
import { useEffect, useContext, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DateRangeContext } from 'contexts/DateRangeContext';
import { getStartDate, isFeedLatestResult } from 'utils/dateRange';

const useFeedDateRangeLabel = (): { dateRangeLabel?: string; liveIcon: boolean } => {
  const dateRangeContextValue = useContext(DateRangeContext);
  const { selectedDateRange, firstSubmissionDate, completedCyclesDateRanges, isContextLoaded } = dateRangeContextValue;
  const { t: translate } = useTranslation();
  const [dateRangeLabel, setDateRangeLabel] = useState<string>();
  const [liveIcon, setLiveIcon] = useState<boolean>(false);

  useEffect(() => {
    const resolveLatestResultLabel = () => {
      if (completedCyclesDateRanges?.[0]) {
        const latestCompletedCycle = completedCyclesDateRanges[0];
        const startDate = format(getStartDate(latestCompletedCycle), 'MMM d, yyyy');
        setDateRangeLabel(`${startDate} - ${translate('dateRangeLabel.today')}`);
      } else if (firstSubmissionDate) {
        setDateRangeLabel(
          `${format(parseISO(firstSubmissionDate), 'MMM d, yyyy')} - ${translate('dateRangeLabel.today')}`,
        );
      }
    };

    if (!isContextLoaded) {
      return;
    }

    const isLatestResult = isFeedLatestResult(selectedDateRange);
    setLiveIcon(isLatestResult);

    if (isLatestResult) {
      resolveLatestResultLabel();
    } else {
      setDateRangeLabel(selectedDateRange?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCyclesDateRanges, selectedDateRange, firstSubmissionDate, isContextLoaded]);

  return { dateRangeLabel, liveIcon };
};

export default useFeedDateRangeLabel;
