import { getRandomNumber } from 'utils/randomization';

export const summarySubmissionsWidgetMock = {
  total_submissions: getRandomNumber(65, 5000),
  total_valid_submissions: getRandomNumber(65, 5000),
};

// Helper function to generate mock data for the summary submissions widget.
export const getSummarySubmissionsWidgetMock = (): typeof summarySubmissionsWidgetMock => {
  return { total_submissions: getRandomNumber(65, 5000), total_valid_submissions: getRandomNumber(65, 5000) };
};
