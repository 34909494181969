import classNames from 'classnames';
import { ZCDChangeIndicatorHOC } from 'components/BlockwiseSummaryTab/ZCDChangeIndicatorHOC/ZCDChangeIndicatorHOC';
import { Flex } from 'components/Flex/Flex';
import { HoverTimeoutWrapper } from 'components/HoverTimeoutWrapper/HoverTimeoutWrapper';
import { IconWithTooltip } from 'components/IconWithTooltip/IconWithTooltip';
import { Spacer } from 'components/Spacer/Spacer';
import { AnalyticEventsChangeTypes, AnalyticTooltipNames, AnalyticTypePropertyValues } from 'constants/analytics';
import { SummaryAspectWidgetContext } from 'contexts/SummaryAspectWidgetContext';
import { useFilterNames } from 'hooks/useFilterNames';
import React, { useContext } from 'react';
import { analyticsService } from 'services/analytics/analytics';
import { SummaryAspectWidget } from 'types/summaryAspectWidget';
import styles from './AspectWidgetTab.module.scss';

interface Props {
  aspectScores: SummaryAspectWidget['aspect_scores'][0];
  isActive: boolean;
}

export const AspectWidgetTab = (props: Props): JSX.Element => {
  const { aspectScores, isActive } = props;
  const { comparisonDateWidgetData, handleTabChange } = useContext(SummaryAspectWidgetContext);
  const activeFilters = useFilterNames();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { aspect_name, overall, aspect_id, aspect_sentence } = aspectScores;
  const { score } = overall;
  const comparisonOverall = comparisonDateWidgetData?.aspect_scores?.find(
    (item) => item.aspect_id === aspect_id,
  )?.overall;

  const handleTabClick = () => {
    handleTabChange(aspect_id);
    buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });

    analyticsService.crossProductEvents.questionOrAspectSelected({
      type: AnalyticTypePropertyValues.ASPECT,
      aspectId: aspect_id,
      aspectText: aspect_name,
      isCrossTabMode: AnalyticEventsChangeTypes.IRRELEVANT,
      questionId: AnalyticEventsChangeTypes.IRRELEVANT,
      questionText: AnalyticEventsChangeTypes.IRRELEVANT,
      activeFilters,
    });
  };

  const handleOnHoverAnalytics = () => {
    analyticsService.crossProductEvents.tooltipViewed({
      tooltipName: AnalyticTooltipNames.ASPECT_TAB,
      widgetName: aspect_name,
    });
  };

  return (
    <button
      ref={buttonRef}
      type="button"
      onClick={handleTabClick}
      className={classNames(styles.aspectWidgetTab, {
        [styles.active]: isActive,
      })}
    >
      <Flex flexDirection="column" justifyContent="space-between" width="100%">
        <Flex justifyContent="space-between" width="100%">
          {aspect_name}
          {aspect_sentence && (
            <HoverTimeoutWrapper onHover={handleOnHoverAnalytics}>
              <IconWithTooltip iconName="info" tooltipText={`${score.toFixed(0)}% ${aspect_sentence}`} />
            </HoverTimeoutWrapper>
          )}
        </Flex>
        <Flex alignItems="baseline">
          <span className={styles.score}>{score.toFixed(0)}%</span>
          <Spacer orientation="horizontal" size="small" />
          <ZCDChangeIndicatorHOC currentScore={score} comparisonScore={comparisonOverall?.score} />
        </Flex>
      </Flex>
    </button>
  );
};
