import { useContext, useState, useEffect } from 'react';
import { DateRangeContext, DateRangeFilterOption } from 'contexts/DateRangeContext';
import { isScoreLatestResult } from 'utils/dateRange';
import { ScoreFilters } from 'types/dateRange';

const useScoreDateRangeFilter = (): { dateRangeFilter?: ScoreFilters } => {
  const dateRangeContextValue = useContext(DateRangeContext);
  const { selectedDateRange, completedCyclesDateRanges, isContextLoaded } = dateRangeContextValue;
  const [dateRangeFilter, setDateRangeFilter] = useState<ScoreFilters>();

  useEffect(() => {
    // Check `completedCyclesDateRanges` as well, as it's the main piece of
    // content for this feature.
    if (!isContextLoaded || !selectedDateRange || !completedCyclesDateRanges) {
      return;
    }

    const isLatestResult = isScoreLatestResult(<DateRangeFilterOption>selectedDateRange);

    if (isLatestResult) {
      const latestCompletedCycle = completedCyclesDateRanges?.[0];
      setDateRangeFilter({
        startDate: <string>(<DateRangeFilterOption>latestCompletedCycle).startDate,
        endDate: <string>(<DateRangeFilterOption>latestCompletedCycle).endDate,
      });
    } else {
      setDateRangeFilter({
        startDate: <string>(<DateRangeFilterOption>selectedDateRange).startDate,
        endDate: <string>(<DateRangeFilterOption>selectedDateRange).endDate,
      });
    }
  }, [completedCyclesDateRanges, selectedDateRange, isContextLoaded]);

  return { dateRangeFilter };
};

export default useScoreDateRangeFilter;
