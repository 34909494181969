import { TabKeys } from 'constants/tabs';

/*
 * We include these here instead of in the router.tsx so as
 * to avoid dependency cycle errors.
 *
 * Note: parent paths (like SURVEY_PAGE) need the `/*` to embed
 * children (aka nested routes) since in React Router v6
 * all paths are exactly matched.
 */
export enum CommunityAsksRouterPaths {
  WORKSPACE = '/',
  CUSTOM_REQUEST = '/request',
  TYPEFORM_PREVIEW = '/typeformExample/:typeformExampleId',
  SURVEY_PAGE = '/survey/:surveyGroupId',
  MANAGEMENT = '/management',
}

/*
 * The Header component of the SURVEY_PAGE should render these
 * tabs in the order they appear here.
 */
export const SurveyPageSubTabs = [TabKeys.SUMMARY, TabKeys.FEED, TabKeys.REPORT];
