import { AnswerSummaryData, ChoiceSummaryData } from 'types/answer';

export const answersSummary = [
  {
    question_id: 1,
    choices: [
      { id: 1, label: 'Vanilla', responses: 1 },
      { id: 2, label: 'Chocolate', responses: 2 },
      { id: 3, label: 'Strawberry', responses: 1 },
    ],
    total_responses: 4,
  },
  {
    question_id: 2,
    choices: [
      { value: '1', label: '1', responses: 5 },
      { value: '2', label: '2', responses: 4 },
      { value: '3', label: '3', responses: 3 },
      { value: '4', label: '4', responses: 2 },
      { value: '5', label: '5', responses: 1 },
    ],
    total_responses: 15,
  },
  {
    question_id: 3,
    choices: [],
    total_responses: 3,
  },
  {
    question_id: 4,
    choices: [
      { value: 'yes', label: 'Yes', responses: 3 },
      { value: 'no', label: 'No', responses: 2 },
    ],
    total_responses: 5,
  },
] as AnswerSummaryData[];
