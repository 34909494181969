import { Status, StatusBox } from '@zencity/common-ui';
import { FeedEmptyState } from 'components/FeedEmptyState/FeedEmptyState';
import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DashboardBottomSection } from 'screens/customer-experience/Dashboard/components/DashboardBottomSection';
import { DashboardTopSection } from 'screens/customer-experience/Dashboard/components/DashboardTopSection';
import { fetchCXSurveyDashboardData } from 'services/survey';
import { CxDashboardData, CxDashboardDataKeys } from 'types/cx_survey';
import { ExtraFiltersParams } from 'types/misc';
import { SurveyGroupOutletContext } from 'types/surveyGroup';
import { logger } from 'utils/customer-experience/logger';
import styles from './Dashboard.module.scss';

export const Dashboard = (): React.ReactElement => {
  const { surveyGroup, extraFiltersParams } = useOutletContext<SurveyGroupOutletContext & ExtraFiltersParams>();
  const [loadingStatus, setLoadingStatus] = useState<Status>(Status.LOADING);
  const [dashboardData, setDashboardData] = useState<CxDashboardData>();

  const fetchData = useCallback(
    async (surveyId: number) => {
      try {
        setLoadingStatus(Status.LOADING);
        const response = await fetchCXSurveyDashboardData(surveyId, extraFiltersParams);
        setDashboardData(response);
        setLoadingStatus(Status.OKAY);
      } catch (error) {
        logger.error(error);
        setLoadingStatus(Status.ERROR);
      }
    },
    [extraFiltersParams],
  );

  /**
   * Fetch the dashboard's data.
   */
  useEffect(() => {
    // Arbitrary use the first survey's ID.
    const surveyId = surveyGroup.survey?.[0].id;
    if (!surveyId) {
      return;
    }
    fetchData(surveyId);
  }, [surveyGroup.survey, extraFiltersParams, fetchData]);

  return (
    <StatusBox status={loadingStatus}>
      {dashboardData && dashboardData[CxDashboardDataKeys.FILTERED_SUBMISSIONS_COUNT] > 0 ? (
        <div className={styles.screen}>
          <main className={styles.summary}>
            <DashboardTopSection dashboardData={dashboardData} />
            <DashboardBottomSection dashboardData={dashboardData} />
          </main>
        </div>
      ) : (
        <FeedEmptyState />
      )}
    </StatusBox>
  );
};
