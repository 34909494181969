/* eslint-disable */
import React from 'react';

export const xlsIconSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 21H17C18.1046 21 19 20.1046 19 19V9L14 4H7C5.89543 4 5 4.89543 5 6V19C5 20.1046 5.89543 21 7 21Z"
      fill="#317041"
    />
    <path d="M14 9V4L19 9H14Z" fill="#629A79" />
    <path
      d="M8.72736 15.091L9.36799 16.1564H9.39071L10.0342 15.091H10.635L9.73873 16.5456L10.6492 18.0001H10.0384L9.39071 16.9419H9.36799L8.72026 18.0001H8.1123L9.03134 16.5456L8.12367 15.091H8.72736Z"
      fill="white"
    />
    <path d="M11.0487 18.0001V15.091H11.5757V17.5584H12.857V18.0001H11.0487Z" fill="white" />
    <path
      d="M14.8953 15.8908C14.8821 15.7667 14.8262 15.6701 14.7277 15.601C14.6302 15.5319 14.5033 15.4973 14.347 15.4973C14.2372 15.4973 14.143 15.5139 14.0644 15.547C13.9858 15.5802 13.9256 15.6251 13.884 15.682C13.8423 15.7388 13.821 15.8036 13.82 15.8766C13.82 15.9372 13.8338 15.9897 13.8612 16.0342C13.8896 16.0787 13.928 16.1166 13.9763 16.1479C14.0246 16.1782 14.0781 16.2037 14.1368 16.2246C14.1955 16.2454 14.2547 16.2629 14.3144 16.2771L14.5871 16.3453C14.6969 16.3709 14.8025 16.4054 14.9039 16.449C15.0061 16.4926 15.0975 16.5475 15.178 16.6138C15.2594 16.6801 15.3238 16.7601 15.3712 16.8538C15.4185 16.9476 15.4422 17.0574 15.4422 17.1834C15.4422 17.3538 15.3986 17.5039 15.3115 17.6337C15.2244 17.7624 15.0985 17.8633 14.9337 17.9362C14.7699 18.0082 14.5715 18.0442 14.3385 18.0442C14.1122 18.0442 13.9157 18.0091 13.749 17.9391C13.5833 17.869 13.4536 17.7667 13.3598 17.6322C13.267 17.4978 13.2168 17.3339 13.2093 17.1408H13.7277C13.7353 17.2421 13.7665 17.3264 13.8215 17.3936C13.8764 17.4608 13.9479 17.511 14.036 17.5442C14.125 17.5773 14.2244 17.5939 14.3343 17.5939C14.4488 17.5939 14.5492 17.5768 14.6354 17.5427C14.7225 17.5077 14.7907 17.4594 14.8399 17.3979C14.8892 17.3354 14.9143 17.2624 14.9152 17.1791C14.9143 17.1034 14.892 17.0409 14.8485 16.9916C14.8049 16.9414 14.7438 16.8998 14.6652 16.8666C14.5876 16.8325 14.4967 16.8022 14.3925 16.7757L14.0615 16.6905C13.8219 16.6289 13.6325 16.5356 13.4933 16.4106C13.3551 16.2847 13.286 16.1176 13.286 15.9092C13.286 15.7378 13.3324 15.5877 13.4252 15.4589C13.5189 15.3302 13.6463 15.2302 13.8073 15.1592C13.9682 15.0873 14.1505 15.0513 14.3541 15.0513C14.5606 15.0513 14.7414 15.0873 14.8968 15.1592C15.053 15.2302 15.1756 15.3292 15.2646 15.4561C15.3537 15.582 15.3996 15.7269 15.4024 15.8908H14.8953Z"
      fill="white"
    />
  </svg>
);

export const pdfIconSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2051_117757)">
      <path
        d="M6.77768 4.00006C5.79771 4.00006 5 4.78848 5 5.75631V19.2438C5 20.2116 5.79771 21 6.77768 21H17.2201C18.2001 21 18.9978 20.2116 18.9978 19.2438V8.15332C18.9978 8.15332 19.0257 7.82857 18.883 7.51255C18.7492 7.21633 18.5311 7.01016 18.5311 7.01016C18.5306 7.00943 18.5301 7.0087 18.5295 7.00798L15.9387 4.49814C15.9373 4.49671 15.9358 4.49529 15.9344 4.49389C15.9344 4.49389 15.7132 4.28587 15.3853 4.1469C14.9992 3.98327 14.6013 4.00054 14.6013 4.00054L14.6072 4L6.77768 4.00006Z"
        fill="#FF2116"
      />
      <path
        d="M6.77754 4.57715H14.6071C14.6091 4.57717 14.611 4.57717 14.613 4.57715C14.613 4.57715 14.9261 4.58015 15.1551 4.6772C15.3755 4.77061 15.5318 4.91202 15.5328 4.91297C15.5328 4.91297 15.5339 4.91406 15.5339 4.91406L18.1182 7.41804C18.1182 7.41804 18.2739 7.58039 18.3494 7.74747C18.4103 7.88232 18.4141 8.12905 18.4141 8.12905C18.4138 8.13313 18.4136 8.1372 18.4135 8.14129V19.244C18.4135 19.9023 17.8865 20.4233 17.22 20.4233H6.77754C6.11101 20.4233 5.58398 19.9023 5.58398 19.244V5.75652C5.58398 5.09819 6.11101 4.57717 6.77754 4.57717V4.57715Z"
        fill="white"
      />
      <path
        d="M8.4947 14.1218C8.09281 13.7249 8.52763 13.1795 9.70766 12.6003L10.4502 12.2359L10.7394 11.6108C10.8985 11.267 11.136 10.706 11.2671 10.3642L11.5055 9.74274L11.3412 9.2828C11.1392 8.71727 11.0672 7.86742 11.1953 7.56183C11.3689 7.1479 11.9375 7.19037 12.1627 7.63408C12.3385 7.98061 12.3205 8.60818 12.1121 9.39963L11.9412 10.0485L12.0917 10.3008C12.1745 10.4396 12.4163 10.7691 12.629 11.0331L13.0289 11.5243L13.5264 11.4602C15.107 11.2565 15.6484 11.6027 15.6484 12.0991C15.6484 12.7256 14.4073 12.7773 13.3651 12.0543C13.1306 11.8917 12.9695 11.7301 12.9695 11.7301C12.9695 11.7301 12.3166 11.8614 11.9952 11.9469C11.6633 12.0353 11.4978 12.0906 11.0117 12.2526C11.0117 12.2526 10.8412 12.4972 10.7301 12.675C10.3166 13.3366 9.83387 13.8849 9.48911 14.0844C9.10311 14.3078 8.69845 14.3231 8.4947 14.1218V14.1218ZM9.12541 13.8993C9.35132 13.7614 9.80856 13.2273 10.1251 12.7316L10.2532 12.5309L9.66973 12.8206C8.76851 13.2681 8.35622 13.6899 8.57061 13.9451C8.69104 14.0884 8.83512 14.0765 9.12541 13.8993V13.8993ZM14.9793 12.2764C15.2003 12.1236 15.1682 11.8156 14.9184 11.6914C14.724 11.5947 14.5673 11.5749 14.0623 11.5822C13.7519 11.6031 13.2529 11.6649 13.1683 11.6837C13.1683 11.6837 13.4424 11.8708 13.5642 11.9396C13.7262 12.031 14.1199 12.2007 14.4074 12.2876C14.691 12.3733 14.855 12.3643 14.9793 12.2764V12.2764ZM12.6255 11.3102C12.4918 11.1714 12.2644 10.8816 12.1202 10.6663C11.9317 10.422 11.8371 10.2497 11.8371 10.2497C11.8371 10.2497 11.6993 10.6876 11.5862 10.9511L11.2334 11.8123L11.1311 12.0077C11.1311 12.0077 11.6749 11.8316 11.9516 11.7602C12.2446 11.6846 12.8393 11.5691 12.8393 11.5691L12.6255 11.3102ZM11.867 8.3059C11.9011 8.02319 11.9156 7.74091 11.8236 7.59856C11.5685 7.32314 11.2606 7.55282 11.3128 8.20761C11.3303 8.42789 11.3858 8.80444 11.4598 9.03654L11.5945 9.45851L11.6893 9.14069C11.7414 8.96589 11.8214 8.59023 11.867 8.3059V8.3059Z"
        fill="#FF2116"
      />
      <path
        d="M9.1792 15.96H9.83168C10.0402 15.96 10.2094 15.9797 10.3394 16.0191C10.4694 16.0572 10.5788 16.143 10.6676 16.2765C10.7564 16.4086 10.8008 16.5682 10.8008 16.755C10.8008 16.9266 10.7647 17.074 10.6927 17.1973C10.6206 17.3206 10.5234 17.4096 10.4012 17.4642C10.2802 17.5189 10.0936 17.5462 9.84133 17.5462H9.61548V18.5624H9.1792V15.96ZM9.61548 16.2936V17.2011H9.83168C10.0247 17.2011 10.1579 17.1655 10.2313 17.0944C10.3059 17.0232 10.3432 16.9075 10.3432 16.7474C10.3432 16.6279 10.3188 16.5313 10.2699 16.4576C10.221 16.3826 10.1669 16.3368 10.1077 16.3203C10.0498 16.3025 9.9578 16.2936 9.83168 16.2936L9.61548 16.2936Z"
        fill="#2C2C2C"
      />
      <path
        d="M11.1348 15.96H11.7274C12.0144 15.96 12.2435 16.0102 12.4146 16.1106C12.5871 16.211 12.7171 16.3603 12.8046 16.5586C12.8934 16.7569 12.9378 16.9768 12.9378 17.2183C12.9378 17.4725 12.8979 17.6994 12.8181 17.8989C12.7396 18.0972 12.6186 18.2574 12.4552 18.3794C12.293 18.5014 12.0614 18.5624 11.7602 18.5624H11.1348V15.96ZM11.571 16.305V18.2173H11.7525C12.006 18.2173 12.1901 18.1309 12.3046 17.958C12.4191 17.7839 12.4764 17.5519 12.4764 17.2621C12.4764 16.6241 12.2351 16.305 11.7525 16.305H11.571Z"
        fill="#2C2C2C"
      />
      <path
        d="M13.3564 15.96H14.8197V16.305H13.7927V17.0829H14.6151V17.428H13.7927V18.5624H13.3564V15.96Z"
        fill="#2C2C2C"
      />
    </g>
    <defs>
      <clipPath id="clip0_2051_117757">
        <rect width="14" height="17" fill="white" transform="translate(5 4)" />
      </clipPath>
    </defs>
  </svg>
);

export const wideAngelDownArrowIconSvg = (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9596 1.01041C11.1548 0.815145 11.1548 0.498563 10.9596 0.303301C10.7643 0.108038 10.4477 0.108039 10.2524 0.303301L5.65625 4.89949L1.06006 0.303301C0.864794 0.108039 0.548212 0.108038 0.352949 0.303301C0.157687 0.498563 0.157687 0.815145 0.352949 1.01041L5.37341 6.03087C5.52962 6.18708 5.78288 6.18708 5.93909 6.03087L10.9596 1.01041Z"
      fill="currentColor"
    />
  </svg>
);

export const liveIconSvg = (
  <svg width="53" height="20" viewBox="0 0 53 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="53" height="20" rx="4" fill="#FF4C59" />
    <circle cx="11" cy="10" r="3" fill="white" />
    <path
      d="M18.8608 14V5.27273H20.4418V12.6747H24.2855V14H18.8608ZM27.2386 5.27273V14H25.6577V5.27273H27.2386ZM30.1183 5.27273L32.3896 12.142H32.479L34.7461 5.27273H36.4847L33.408 14H31.4563L28.3839 5.27273H30.1183ZM37.6342 14V5.27273H43.3104V6.59801H39.2152V8.96733H43.0163V10.2926H39.2152V12.6747H43.3445V14H37.6342Z"
      fill="white"
    />
  </svg>
);

export const noScoresEmptyStateSvg = (
  <svg width="69" height="67" viewBox="0 0 69 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.9912 1.38311L62.8342 4.87863C65.5845 5.12613 67.6135 7.55634 67.366 10.3066L63.2432 56.12C62.9957 58.8703 60.5655 60.8992 57.8152 60.6517L18.9721 57.1562C16.2218 56.9087 14.1929 54.4785 14.4404 51.7282L18.5632 5.91484C18.8107 3.16453 21.2409 1.13561 23.9912 1.38311Z"
      fill="#25C98F"
      stroke="#4A2855"
      strokeWidth="2"
    />
    <path
      d="M14 10H53C55.7614 10 58 12.2386 58 15V61C58 63.7614 55.7614 66 53 66H14C11.2386 66 9 63.7614 9 61V15C9 12.2386 11.2386 10 14 10Z"
      fill="#FDF2FF"
      stroke="#4A2855"
      strokeWidth="2"
    />
    <path
      d="M0 27C0 26.4477 0.447715 26 1 26H23C23.5523 26 24 26.4477 24 27V27C24 27.5523 23.5523 28 23 28H1C0.447716 28 0 27.5523 0 27V27Z"
      fill="#4A2855"
    />
    <path
      d="M30 22C30 21.4477 30.4477 21 31 21H45C45.5523 21 46 21.4477 46 22V22C46 22.5523 45.5523 23 45 23H31C30.4477 23 30 22.5523 30 22V22Z"
      fill="#4A2855"
    />
    <path
      d="M30 27C30 26.4477 30.4477 26 31 26H48C48.5523 26 49 26.4477 49 27V27C49 27.5523 48.5523 28 48 28H31C30.4477 28 30 27.5523 30 27V27Z"
      fill="#4A2855"
    />
    <path
      d="M30 32C30 31.4477 30.4477 31 31 31H39C39.5523 31 40 31.4477 40 32V32C40 32.5523 39.5523 33 39 33H31C30.4477 33 30 32.5523 30 32V32Z"
      fill="#4A2855"
    />
    <path
      d="M17.7072 21.3072C18.0977 20.9167 18.7309 20.9167 19.1214 21.3072L24.0712 26.257C24.4617 26.6475 24.4617 27.2806 24.0712 27.6712V27.6712C23.6806 28.0617 23.0475 28.0617 22.6569 27.6712L17.7072 22.7214C17.3167 22.3309 17.3167 21.6977 17.7072 21.3072V21.3072Z"
      fill="#4A2855"
    />
    <path
      d="M17.7072 32.6711C18.0977 33.0616 18.7309 33.0616 19.1214 32.6711L24.0712 27.7213C24.4617 27.3308 24.4617 26.6976 24.0712 26.3071V26.3071C23.6806 25.9166 23.0475 25.9166 22.6569 26.3071L17.7072 31.2569C17.3167 31.6474 17.3167 32.2805 17.7072 32.6711V32.6711Z"
      fill="#4A2855"
    />
    <path
      d="M17 51C17 49.8954 17.8954 49 19 49L35 49C36.1046 49 37 49.8954 37 51L37 55C37 56.1046 36.1046 57 35 57L19 57C17.8954 57 17 56.1046 17 55L17 51Z"
      fill="#25C98F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 51L19 51L19 55L35 55L35 51ZM19 49C17.8954 49 17 49.8954 17 51L17 55C17 56.1046 17.8954 57 19 57L35 57C36.1046 57 37 56.1046 37 55L37 51C37 49.8954 36.1046 49 35 49L19 49Z"
      fill="#4A2855"
    />
    <path
      d="M17 40C17 38.8954 17.8954 38 19 38L47 38C48.1046 38 49 38.8954 49 40L49 44C49 45.1046 48.1046 46 47 46L19 46C17.8954 46 17 45.1046 17 44L17 40Z"
      fill="#7FB8FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47 40L19 40L19 44L47 44L47 40ZM19 38C17.8954 38 17 38.8954 17 40L17 44C17 45.1046 17.8954 46 19 46L47 46C48.1046 46 49 45.1046 49 44L49 40C49 38.8954 48.1046 38 47 38L19 38Z"
      fill="#4A2855"
    />
  </svg>
);

export const mockLineChart = (
  <svg width="130" height="66" viewBox="0 0 130 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 65C19.1855 58.9382 25.1204 35.6316 43.6667 33C60.7655 30.5738 69.3602 52.9402 86.3333 49C106.916 44.2218 108.204 2.73299 129 1"
      stroke="#12B76A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
