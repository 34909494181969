import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Widget } from '@typeform/embed-react';
import { CommunityAsksContext } from 'contexts/CommunityAsksContext';
import { TypeformExample } from '@zencity/survey-types';
import { AskRequestHeader } from 'components/AskRequestHeader/AskRequestHeader';
import { TypeformExampleRequestForm } from './components/TypeformExampleRequestForm/TypeformExampleRequestForm';
import styles from './TypeformPreview.module.scss';

/**
 * Embedded typeform survey example with option to request similar survey
 */
export const TypeformPreview = (): React.ReactElement => {
  const { t: translate } = useTranslation();
  const { typeformExamples } = useContext(CommunityAsksContext);
  const { typeformExampleId } = useParams();

  const [typeformExample, setTypeformExample] = useState<TypeformExample>();

  useEffect(() => {
    const found = typeformExamples.filter((item: TypeformExample) => item.id.toString() === typeformExampleId);
    setTypeformExample(found[0]);
  }, [typeformExamples, typeformExampleId]);

  return (
    <div className={styles.screenWrapper}>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      {typeformExample && (
        <>
          <AskRequestHeader title={typeformExample.title} />
          <div className={styles.mainWrapper}>
            <TypeformExampleRequestForm />
            <div className={styles.previewArea}>
              <h2>{translate('typeformPreview.surveyHeader')}</h2>
              <Widget id={typeformExample.typeform_survey_id} className={styles.survey} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
