import { createAxiosInstance } from 'utils/Api';
import { CxDashboardData } from 'types/cx_survey';
import { ExtraFiltersParamsType } from 'types/misc';
import { SurveyResponse } from 'types/survey';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.survey));

/**
 * Get all surveys for a campaign
 * @param campaignId
 * @return {Promise<SurveyResponse>}
 */
export async function fetchAllQuestions(campaignId: number): Promise<SurveyResponse> {
  const result = await axiosInstance.get<SurveyResponse>('getAllByCampaign/', {
    params: { campaign: campaignId },
  });

  return result.data;
}

/**
 * Get the dashboard data of a CX Survey.
 */
export async function fetchCXSurveyDashboardData(
  surveyId: number,
  extraParams?: ExtraFiltersParamsType,
): Promise<CxDashboardData> {
  const result = await axiosInstance.get<CxDashboardData>(`${surveyId}/cx_survey_dashboard_data/`, {
    params: {
      ...extraParams,
    },
  });

  return result.data;
}
