import { useEffect, useContext, useState } from 'react';
import { DateRangeContext, DateRangeFilterOption } from 'contexts/DateRangeContext';
import { isScoreLatestResult } from 'utils/dateRange';

const useDateRangeScoreLabel = (): { dateRangeLabel?: string } => {
  const dateRangeContextValue = useContext(DateRangeContext);
  const { selectedDateRange, completedCyclesDateRanges, isContextLoaded } = dateRangeContextValue;
  const [dateRangeLabel, setDateRangeLabel] = useState<string>();

  useEffect(() => {
    // Check `completedCyclesDateRanges` as well, as it's the main piece of
    // content for this feature.
    if (!isContextLoaded || !selectedDateRange || !completedCyclesDateRanges) {
      return;
    }

    const isLatestResult = isScoreLatestResult(<DateRangeFilterOption>selectedDateRange);

    if (isLatestResult) {
      const latestCompletedCycle = completedCyclesDateRanges?.[0];
      setDateRangeLabel(<string>(<DateRangeFilterOption>latestCompletedCycle).label);
    } else {
      setDateRangeLabel(<string>(<DateRangeFilterOption>selectedDateRange).label);
    }
  }, [completedCyclesDateRanges, selectedDateRange, isContextLoaded]);

  return { dateRangeLabel };
};

export default useDateRangeScoreLabel;
