import { Answer } from '@zencity/survey-types';

export const answerMock: Answer[] = [
  {
    id: 1436375,
    value: 'The Food Courst',
    selected_choices: [],
    submission: {
      id: 61672,
      submitted_at: '2022-09-10T16:42:13Z',
      submission_area: 69,
      demographic_info: {
        gender: { id: 25, label: 'Female', value: 'female' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: 'The Food Courst',
  },
  {
    id: 1431979,
    value: 'The weather',
    selected_choices: [],
    submission: {
      id: 61512,
      submitted_at: '2022-09-09T19:56:37Z',
      submission_area: 72,
      demographic_info: {
        gender: { id: 26, label: 'Male', value: 'male' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: 'The weather',
  },
  {
    id: 1431709,
    value: 'The food options',
    selected_choices: [],
    submission: {
      id: 61499,
      submitted_at: '2022-09-09T18:59:43Z',
      submission_area: 69,
      demographic_info: {
        gender: { id: 25, label: 'Female', value: 'female' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: 'The food options',
  },
  {
    id: 1431576,
    value: 'Restaurants, Amenities, good people',
    selected_choices: [],
    submission: {
      id: 61495,
      submitted_at: '2022-09-09T18:44:53Z',
      submission_area: 69,
      demographic_info: {
        gender: { id: 25, label: 'Female', value: 'female' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: ['neighborly-relations'],
    value_en: 'Restaurants, Amenities, good people',
  },
  {
    id: 1431014,
    value: 'How nice it looks and feels',
    selected_choices: [],
    submission: {
      id: 61477,
      submitted_at: '2022-09-09T15:44:18Z',
      submission_area: 69,
      demographic_info: {
        gender: { id: 25, label: 'Female', value: 'female' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [
          { id: 33, label: 'Black or African American', value: 'black_or_african_american' },
          { id: 54, label: 'Hispanic', value: 'hispanic' },
        ],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: 'How nice it looks and feels',
  },
  {
    id: 1430430,
    value: 'Cigar shop',
    selected_choices: [],
    submission: {
      id: 61458,
      submitted_at: '2022-09-09T12:19:31Z',
      submission_area: null,
      demographic_info: {
        gender: null,
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 34, label: 'Native American or Alaskan Native', value: 'native_american_or_alaskan_native' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: 'Cigar shop',
  },
  {
    id: 1426336,
    value: '20 minutes to get anywhere',
    selected_choices: [],
    submission: {
      id: 61317,
      submitted_at: '2022-09-08T04:41:33Z',
      submission_area: null,
      demographic_info: {
        gender: { id: 25, label: 'Female', value: 'female' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: '20 minutes to get anywhere',
  },
  {
    id: 1424601,
    value: 'The people',
    selected_choices: [],
    submission: {
      id: 61250,
      submitted_at: '2022-09-07T22:28:14Z',
      submission_area: 71,
      demographic_info: {
        gender: { id: 26, label: 'Male', value: 'male' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: 'The people',
  },
  {
    id: 1423988,
    value: 'Overall',
    selected_choices: [],
    submission: {
      id: 61229,
      submitted_at: '2022-09-07T21:10:43Z',
      submission_area: 69,
      demographic_info: {
        gender: { id: 25, label: 'Female', value: 'female' },
        age_group: { id: 90, label: '35-54' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: ['miscellaneous'],
    value_en: 'Overall',
  },
  {
    id: 1418037,
    value: 'Quiet and Diverse',
    selected_choices: [],
    submission: {
      id: 61021,
      submitted_at: '2022-09-06T14:15:39Z',
      submission_area: 70,
      demographic_info: {
        gender: { id: 25, label: 'Female', value: 'female' },
        age_group: { id: 89, label: '18-34' },
        ethnicity: [{ id: 33, label: 'Black or African American', value: 'black_or_african_american' }],
      },
      via_self_distribution: false,
      cx_data: null,
    },
    sub_topics: [],
    value_en: 'Quiet and Diverse',
  },
];
