import { Icon } from '@zencity/common-ui';
import classNames from 'classnames';
import { CommunityAsksToastContext } from 'contexts/CommunityAsksToastContext';
import copy from 'copy-to-clipboard';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from 'services/analytics/analytics';
import { SurveyGroup } from '@zencity/survey-types';
import styles from './CopySurveyPublicLink.module.scss';

interface Props {
  asIcon?: boolean;
  surveyGroup: SurveyGroup;
}

/**
 * A button to copy a specific public link of a Survey to the clipboard.
 */
export const CopySurveyPublicLink: React.FC<Props> = function CopySurveyPublicLink({
  asIcon = false,
  surveyGroup,
}): React.ReactElement {
  const { toastSuccess, toastError } = useContext(CommunityAsksToastContext);
  const { t: translate } = useTranslation();
  const [copied, setCopied] = useState(false);

  const surveyTypeformLink = `${surveyGroup.survey_public_link}?s=sd`;

  const copyToClipboard = () => {
    analyticsService.communityAsksEvents.copyAskLink();
    if (copy(surveyTypeformLink)) {
      // Indicate on the button the copy is succeeded for a single second.
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);

      toastSuccess(translate('communityAsks.toastMessages.copiedToClipboard'));
    } else {
      toastError(translate('communityAsks.toastMessages.somethingWentWrong'));
    }
  };

  return (
    <div className={styles.copySurveyPublicLinkWrapper}>
      {asIcon ? (
        <></>
      ) : (
        <a
          href={surveyTypeformLink}
          target="_blank"
          rel="noreferrer"
          className={styles.displayedLink}
          title={surveyTypeformLink}
          onClick={() => analyticsService.communityAsksEvents.askLinkAccessed()}
        >
          {surveyTypeformLink}
        </a>
      )}
      <button
        type="button"
        disabled={copied}
        onClick={copyToClipboard}
        className={classNames(styles.copySurveyPublicLink, {
          [`${styles.copied}`]: copied,
          [`${styles.btnDecoration}`]: !asIcon,
        })}
        title={translate('copySurveyLinkToClipboard')}
      >
        {/* eslint-disable i18next/no-literal-string */}
        {asIcon ? <Icon name="tick-circle.svg" width={24} height={24} /> : translate('Copy')}
      </button>
    </div>
  );
};
