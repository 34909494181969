import { mockLeadingTopics } from 'mocks/data/leadingTopics.mock';
import { LeadingTopic, LeadingTopicFilterParams } from 'src/types/leadingTopics';
import { createAxiosInstance } from 'utils/Api';
import path from 'utils/path';
import { config } from '../../config/config';

const axiosInstance = createAxiosInstance(
  path.joinURL(config.apiUrls.vault, config.apiUrls.vaultSections.answersLeadingSubTopics),
);

interface ResponseLeadingTopic {
  results: {
    classification_topic_id: number;
    classification_topic_value: string;
    classification_sub_topic_id: number;
    classification_sub_topic_value: string;
    responses: number;
  }[];
}

/**
 * Fetches leading topics for given question
 */
export async function fetchLeadingTopics(params: LeadingTopicFilterParams): Promise<LeadingTopic[]> {
  if (config.shouldMockRequests) {
    return Promise.resolve(mockLeadingTopics);
  }
  const response = await axiosInstance.get<ResponseLeadingTopic>('', {
    params,
  });

  const { results } = response.data;
  if (!results.length) {
    return [];
  }

  return results.map((result) => {
    const {
      classification_topic_id: classificationTopicId,
      classification_topic_value: classificationTopicValue,
      classification_sub_topic_id: classificationSubTopicId,
      classification_sub_topic_value: classificationSubTopicValue,
      responses,
    } = result;
    return {
      classificationTopicId,
      classificationTopicValue,
      classificationSubTopicId,
      classificationSubTopicValue,
      responses,
    };
  });
}
