const { merge } = require('webpack-merge');
import path from 'utils/path';
import { Config } from '../src/types/config';
import common from './common';
import development from './development';
import testing from './testing';
import staging from './staging';
import production from './production';

let config: Config;

switch (process.env.NODE_ENV) {
  case 'development':
    config = merge(common, development) as Config;
    break;
  case 'testing':
    config = merge(common, testing) as Config;
    break;
  case 'staging':
    config = merge(common, staging) as Config;
    break;
  case 'production':
    config = merge(common, production) as Config;
    break;
  default:
    throw new Error(`invalid environment provided - "${process.env.NODE_ENV}"`);
}

// Endpoints in Core-Platform.
config.apiUrls.reportsLibrary =
  process.env.REPORTS_LIBRARY_URL || path.joinURL(config.organicApiBaseUrl, '/api/reportlibrary/');
config.apiUrls.volumeOfInteractions =
  process.env.VOLUME_OF_INTERACTIONS_URL || path.joinURL(config.organicApiBaseUrl, '/api/categoryScore/getReport');
config.apiUrls.areas =
  process.env.AREAS_DATA_URL || path.joinURL(config.organicApiBaseUrl, '/api/v2/geotargetingpreset');
// external APIs URLs
config.apiUrls.vault = path.joinURL(process.env.VAULT_BASE_URL || config.apiBaseUrl, '/api/v2/');
config.surveyApiUrls.vault = path.joinURL(process.env.VAULT_BASE_URL || config.apiBaseUrl, '/survey/');

export { config };
