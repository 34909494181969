import { SummaryAspectWidget } from 'types/summaryAspectWidget';
import { getRandomNumber } from 'utils/randomization';
import { mockDateRanges } from './dateRanges.mock';
import { getPolygonsById } from './divisions.mock';

const getRandomDemographicsType = () => {
  const types = ['age_group', 'ethnicity', 'education', 'income', 'gender'];
  return types[Math.floor(Math.random() * types.length)];
};

const getDivisionsMock = () => [
  {
    depth: 0,
    division_id: 1,
    division_polygon_name: getPolygonsById('634e5a8a95350a5d3321308f').name,
    division_polygon_id: '634e5a8a95350a5d3321308f',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 2,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6023').name,
    division_polygon_id: '634fbed03e7b2a65aaac6023',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 3,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6049').name,
    division_polygon_id: '634fbed03e7b2a65aaac6049',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 4,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6044').name,
    division_polygon_id: '634fbed03e7b2a65aaac6044',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 5,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac603f').name,
    division_polygon_id: '634fbed03e7b2a65aaac603f',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 6,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6047').name,
    division_polygon_id: '634fbed03e7b2a65aaac6047',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 7,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6045').name,
    division_polygon_id: '634fbed03e7b2a65aaac6045',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 8,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac601b').name,
    division_polygon_id: '634fbed03e7b2a65aaac601b',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 9,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6040').name,
    division_polygon_id: '634fbed03e7b2a65aaac6040',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 10,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac604a').name,
    division_polygon_id: '634fbed03e7b2a65aaac604a',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 11,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac601a').name,
    division_polygon_id: '634fbed03e7b2a65aaac601a',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 12,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6013').name,
    division_polygon_id: '634fbed03e7b2a65aaac6013',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 13,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6016').name,
    division_polygon_id: '634fbed03e7b2a65aaac6016',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 14,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6012').name,
    division_polygon_id: '634fbed03e7b2a65aaac6012',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 15,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6018').name,
    division_polygon_id: '634fbed03e7b2a65aaac6018',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 16,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6015').name,
    division_polygon_id: '634fbed03e7b2a65aaac6015',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 17,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6048').name,
    division_polygon_id: '634fbed03e7b2a65aaac6048',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 18,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac603e').name,
    division_polygon_id: '634fbed03e7b2a65aaac603e',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 19,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6019').name,
    division_polygon_id: '634fbed03e7b2a65aaac6019',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 20,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac604b').name,
    division_polygon_id: '634fbed03e7b2a65aaac604b',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 21,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6046').name,
    division_polygon_id: '634fbed03e7b2a65aaac6046',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 22,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac601e').name,
    division_polygon_id: '634fbed03e7b2a65aaac601e',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 23,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6024').name,
    division_polygon_id: '634fbed03e7b2a65aaac6024',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 24,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac601d').name,
    division_polygon_id: '634fbed03e7b2a65aaac601d',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 25,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac601f').name,
    division_polygon_id: '634fbed03e7b2a65aaac601f',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 26,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6014').name,
    division_polygon_id: '634fbed03e7b2a65aaac6014',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 27,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac601c').name,
    division_polygon_id: '634fbed03e7b2a65aaac601c',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 28,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6025').name,
    division_polygon_id: '634fbed03e7b2a65aaac6025',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 29,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6017').name,
    division_polygon_id: '634fbed03e7b2a65aaac6017',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 30,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac603c').name,
    division_polygon_id: '634fbed03e7b2a65aaac603c',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 31,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6021').name,
    division_polygon_id: '634fbed03e7b2a65aaac6021',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 32,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6020').name,
    division_polygon_id: '634fbed03e7b2a65aaac6020',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 2,
    division_id: 33,
    division_polygon_name: getPolygonsById('634fbed03e7b2a65aaac6022').name,
    division_polygon_id: '634fbed03e7b2a65aaac6022',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 35,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f0').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f0',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 36,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f7').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f7',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 37,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9fb').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9fb',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 38,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f3').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f3',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 39,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9fa').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9fa',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 40,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f9').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f9',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 41,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f5').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f5',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 42,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f2').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f2',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 43,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f6').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f6',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 44,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f8').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f8',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 45,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f1').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f1',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 46,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9f4').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9f4',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
  {
    depth: 1,
    division_id: 47,
    division_polygon_name: getPolygonsById('634fbdf08cfb9d593dcfd9fc').name,
    division_polygon_id: '634fbdf08cfb9d593dcfd9fc',
    score: getRandomNumber(10, 100),
    total_submissions: getRandomNumber(65, 5000),
  },
];

const getDemographicsByAmount = (amount: number, prefixLabel: string) => {
  const result = [];
  for (let i = 0; i < amount; i++) {
    result.push({
      type: getRandomDemographicsType(),
      label: `${prefixLabel} ${i}`,
      total_submissions: getRandomNumber(65, 5000),
      score: getRandomNumber(10, 100),
    });
  }
  return result;
};

export const generateMockAspectWidgetData = (amount: number): SummaryAspectWidget => {
  const result = {
    start_date: mockDateRanges.completed_cycles[0].start_date,
    end_date: mockDateRanges.completed_cycles[0].end_date,
    aspect_scores: [] as SummaryAspectWidget['aspect_scores'],
  };
  for (let i = 0; i < amount; i++) {
    result.aspect_scores.push({
      aspect_id: i,
      aspect_name: `Aspect ${i}`,
      aspect_sentence: `Aspect ${i} sentence`,
      overall: {
        score: getRandomNumber(10, 100),
        total_submissions: getRandomNumber(65, 5000),
      },
      demographics: {
        most_satisfied: getDemographicsByAmount(10, 'Most satisfied'),
        least_satisfied: getDemographicsByAmount(10, 'Least satisfied'),
      },
      divisions: getDivisionsMock().filter((division) => division.depth <= 1),
    });
  }
  return result;
};
