import React from 'react';
import { SurveyRequest, TypeformExample } from '@zencity/survey-types';

export interface CommunityAsksContextInterface {
  typeformExamples: TypeformExample[];
  surveyRequests: SurveyRequest[];
  setSurveyRequests: React.Dispatch<React.SetStateAction<SurveyRequest[]>>;
}

export const CommunityAsksContext = React.createContext<CommunityAsksContextInterface>(
  {} as CommunityAsksContextInterface,
);
