import React, { ReactElement } from 'react';
import { ProgressBar } from '@zencity/common-ui';
import { ChoiceSummaryData } from 'types/answer';
import styles from './AnswerChoicesBreakdown.module.scss';

interface Props {
  answerChoices: ChoiceSummaryData[];
  total: number; // total responses for all choices for this question.
}

/**
 * Horizontal bars of answers' choices by percentage.
 */
export const AnswerChoicesBreakdown: React.FC<Props> = function AnswerChoicesBreakdown({
  answerChoices,
  total,
}: Props): ReactElement {
  return (
    <div className={styles.answerChoicesBreakdown} data-testid="answer-choices-breakdown">
      {answerChoices.map((answerChoice) => {
        const percent = total <= 0 ? 0 : Math.round((answerChoice.responses / total) * 100);
        return (
          <React.Fragment key={answerChoice.id}>
            <div className={styles.texts} data-testid="answer-choice">
              <span>{answerChoice.label}</span>
              <span>{`${answerChoice.responses} resp.`}</span>
              <span>{`${percent}%`}</span>
            </div>
            <ProgressBar fill={percent} />
          </React.Fragment>
        );
      })}
    </div>
  );
};
