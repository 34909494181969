import React, { FunctionComponent } from 'react';
import { BreakdownWidget, BreakdownRowContent } from '../components/BreakdownWidget/BreakdownWidget';
import { GenericQuestionScore } from '../types/score';

interface Props {
  title: string;
  genericQuestionScores: GenericQuestionScore[];
  isLoading: boolean;
}

export const QuestionBreakdown: FunctionComponent<Props> = (props: Props) => {
  const { isLoading, genericQuestionScores, title } = props;
  const displayData = genericQuestionScores.reduce<BreakdownRowContent[]>((rowItems, genericQuestionScore) => {
    const { scores, text, comparisonScore } = genericQuestionScore;
    const selectedDateRangeScores = scores[0];
    const breakdownRowContent: BreakdownRowContent = {
      scores: selectedDateRangeScores.scores,
      totalSubmissions: selectedDateRangeScores.totalSubmissions,
      text,
      comparisonScore,
    };
    return [...rowItems, breakdownRowContent];
  }, []);
  return <BreakdownWidget rowItems={displayData} headerText={title} isLoading={isLoading} />;
};
