import { PercentageSize } from 'components/SatisfactionDistribution/components/PercentageDisplay';
import React, { ReactElement } from 'react';
import { CxSurveyScoreData } from 'types/cx_survey';
import styles from '../Dashboard.module.scss';
import { CXSatisfactionDistribution } from './CXSatisfactionDistribution';

interface Props {
  description: string;
  scoreData?: CxSurveyScoreData;
}

export const CXSatisfactionScoreCard: React.FC<Props> = function CXSatisfactionScoreCard(props): ReactElement {
  const { description, scoreData } = props;
  return (
    <>
      {scoreData ? (
        <CXSatisfactionDistribution
          className={styles.satisfactionDistribution}
          score={scoreData.average_score}
          positive={scoreData.satisfaction_distribution.positive}
          negative={scoreData.satisfaction_distribution.negative}
          neutral={scoreData.satisfaction_distribution.neutral}
          percentageSize={PercentageSize.LARGE}
        />
      ) : (
        <div>No data yet</div>
      )}
      <div className={styles.datapointText}>{description}</div>
    </>
  );
};
