/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState, useContext, ReactElement } from 'react';
import { ZCDSpinner } from '@zencity/common-ui';
import { Document, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { AccountsContext } from 'contexts/AccountsContext';
import { SurveyRequestContext } from 'contexts/SurveyRequestContext';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { fetchReportForSurvey } from 'services/reportsLibrary';
import { logger } from 'utils/community-asks/logger';
import { FullScreenEmptyState } from 'components/FullScreenEmptyState/FullScreenEmptyState';
import { userIsSuperAdmin } from 'utils/user';
import { SurveyReport } from '@zencity/survey-types';
import styles from './CommunityAsksReport.module.scss';
import { PageRender } from './components/PageRender';
import { ReportUploader } from './components/ReportUploader';

// It is crucial for performance to use PDF.js worker whenever possible.
// This ensures that PDF files will be rendered in a separate thread without affecting page performance.
// Instead of coping pdf.worker.min.js file from pdfjs-dist/build to the project's output folder
// We use minified pdf.worker.min.js from an external CDN.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const CommunityAsksReport = (): ReactElement => {
  const surveyRequest = useContext(SurveyRequestContext);
  const { user } = useContext(AccountsContext);

  const [reportViewUrl, setReportViewUrl] = useState('');
  const [pageIndexes, setPageIndexes] = useState([1]);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [report, setReport] = useState<SurveyReport | undefined>(
    surveyRequest?.survey_group?.survey?.[0]?.survey_report?.[0],
  );

  const isSuperAdmin = userIsSuperAdmin(user);
  const { t: translate } = useTranslation();

  useEffect(() => {
    const fetchPDF = async () => {
      if (report?.report_id) {
        try {
          const result = await fetchReportForSurvey({ id: report.report_id });
          // For local development:
          // We can't make a request to Azure from localhost so set to fileUrl variable to:
          // `data:application/pdf;base64,{{base64 string}}`
          // where base64 string is an actual PDF represented in base64.
          const communityAsksReportViewUrl = `${result.reportViewUrl}&type=community_asks&fromAsksPage=true&itemId=${
            report.report_id
          }&title=${report.report_title ?? ''}`;
          setReportViewUrl(communityAsksReportViewUrl);
        } catch (error) {
          logger.error(error);
          setHasError(true);
        }
      }
    };

    fetchPDF();
  }, [report]);

  /**
   * Use numPages property from the file metadata
   * available after the document is successfully loaded
   * to create an array of page indexes.
   * pages are rendered using an index.
   * Example: when numPages:= 3, pagesArray:= [1, 2, 3]
   * @param payload: file metadata
   * @return void
   */
  const onDocumentLoadSuccess = (payload: { numPages: number }) => {
    const pagesArray = [];
    for (let step = 1; step <= payload.numPages; step += 1) {
      pagesArray.push(step);
    }
    setPageIndexes(pagesArray);
    setNumPages(payload.numPages);
  };

  return (
    <>
      {!report?.report_id && (
        <FullScreenEmptyState
          iconName="card-bars-bulb.svg"
          description={translate('communityAsks.reportTab.emptyState.description')}
          title={translate('communityAsks.reportTab.emptyState.title')}
        >
          {isSuperAdmin && (
            <ReportUploader setReport={setReport} surveyId={surveyRequest?.survey_group?.survey?.[0]?.id} />
          )}
        </FullScreenEmptyState>
      )}
      {hasError && <div>{translate('communityAsks.reportTab.errorMessage')}</div>}
      {reportViewUrl && !hasError && (
        <ErrorBoundary>
          <div className={styles.communityAsksReportTab}>
            <div className={styles.report}>
              {isLoading && <ZCDSpinner />}
              <Document
                file={reportViewUrl}
                options={{
                  // Send the cookies as part of the URL request.
                  withCredentials: true,
                }}
                loading=""
                onLoadSuccess={onDocumentLoadSuccess}
                error=""
                onLoadError={() => setHasError(true)}
              >
                {pageIndexes.map((pageNumber) => (
                  <PageRender
                    key={pageNumber}
                    pageNumber={pageNumber}
                    numPages={numPages}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                ))}
              </Document>
              {isSuperAdmin && !isLoading && (
                <ReportUploader
                  setReport={setReport}
                  surveyId={surveyRequest?.survey_group?.survey?.[0]?.id}
                  report={report}
                />
              )}
            </div>
          </div>
        </ErrorBoundary>
      )}
    </>
  );
};
