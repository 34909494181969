import { DivisionScoresByGenericQuestion } from 'types/score';

export const divisionScoresByQuestionMock: DivisionScoresByGenericQuestion[] = [
  {
    start_date: '2022-04-01',
    end_date: '2022-06-30',
    question_scores: {
      '4040': {
        question_text:
          'Taking everything into account, how good a job do you think the police in your local area are doing?',
        question_index: 2,
        division_scores: [
          {
            division_id: 1,
            score: 33.51,
            division_polygon_id: '634e5a8a95350a5d3321308f',
            division_polygon_name: 'London',
            depth: 0,
            total_submissions: 9883,
            children_division_scores: [
              {
                division_id: 47,
                score: 63.015,
                division_polygon_id: '634fbdf08cfb9d593dcfd9fc',
                division_polygon_name: 'City of London',
                depth: 1,
                total_submissions: 14,
                children_division_scores: [
                  {
                    division_id: 48,
                    score: 63.015,
                    total_submissions: 14,
                    division_polygon_id: '634fbed03e7b2a65aaac603a',
                    division_polygon_name: 'City of London',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 36,
                score: 35.311,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f7',
                division_polygon_name: 'North',
                depth: 1,
                total_submissions: 552,
                children_division_scores: [
                  {
                    division_id: 5,
                    score: 32.483,
                    total_submissions: 282,
                    division_polygon_id: '634fbed03e7b2a65aaac603f',
                    division_polygon_name: 'Haringey',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 31,
                    score: 38.264,
                    total_submissions: 270,
                    division_polygon_id: '634fbed03e7b2a65aaac6021',
                    division_polygon_name: 'Enfield',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 37,
                score: 38.255,
                division_polygon_id: '634fbdf08cfb9d593dcfd9fb',
                division_polygon_name: 'Central East',
                depth: 1,
                total_submissions: 682,
                children_division_scores: [
                  {
                    division_id: 7,
                    score: 40.092,
                    total_submissions: 425,
                    division_polygon_id: '634fbed03e7b2a65aaac6045',
                    division_polygon_name: 'Tower Hamlets',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 9,
                    score: 35.218,
                    total_submissions: 257,
                    division_polygon_id: '634fbed03e7b2a65aaac6040',
                    division_polygon_name: 'Hackney',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 38,
                score: 31.634,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f3',
                division_polygon_name: 'East Area',
                depth: 1,
                total_submissions: 966,
                children_division_scores: [
                  {
                    division_id: 2,
                    score: 31.244,
                    total_submissions: 362,
                    division_polygon_id: '634fbed03e7b2a65aaac6023',
                    division_polygon_name: 'Redbridge',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 29,
                    score: 33.012,
                    total_submissions: 279,
                    division_polygon_id: '634fbed03e7b2a65aaac6017',
                    division_polygon_name: 'Havering',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 30,
                    score: 30.884,
                    total_submissions: 325,
                    division_polygon_id: '634fbed03e7b2a65aaac603c',
                    division_polygon_name: 'Barking and Dagenham',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 39,
                score: 29.135,
                division_polygon_id: '634fbdf08cfb9d593dcfd9fa',
                division_polygon_name: 'Central North',
                depth: 1,
                total_submissions: 572,
                children_division_scores: [
                  {
                    division_id: 4,
                    score: 25.251,
                    total_submissions: 257,
                    division_polygon_id: '634fbed03e7b2a65aaac6044',
                    division_polygon_name: 'Islington',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 21,
                    score: 32.304,
                    total_submissions: 315,
                    division_polygon_id: '634fbed03e7b2a65aaac6046',
                    division_polygon_name: 'Camden',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 40,
                score: 37.639,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f9',
                division_polygon_name: 'Central West',
                depth: 1,
                total_submissions: 855,
                children_division_scores: [
                  {
                    division_id: 3,
                    score: 34.3,
                    total_submissions: 283,
                    division_polygon_id: '634fbed03e7b2a65aaac6049',
                    division_polygon_name: 'Hammersmith and Fulham',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 6,
                    score: 42.825,
                    total_submissions: 366,
                    division_polygon_id: '634fbed03e7b2a65aaac6047',
                    division_polygon_name: 'Westminster',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 17,
                    score: 33.013,
                    total_submissions: 206,
                    division_polygon_id: '634fbed03e7b2a65aaac6048',
                    division_polygon_name: 'Kensington and Chelsea',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 41,
                score: 33.018,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f5',
                division_polygon_name: 'Central South',
                depth: 1,
                total_submissions: 633,
                children_division_scores: [
                  {
                    division_id: 24,
                    score: 32.018,
                    total_submissions: 325,
                    division_polygon_id: '634fbed03e7b2a65aaac601d',
                    division_polygon_name: 'Southwark',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 27,
                    score: 34.073,
                    total_submissions: 308,
                    division_polygon_id: '634fbed03e7b2a65aaac601c',
                    division_polygon_name: 'Lambeth',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 42,
                score: 31.644,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f2',
                division_polygon_name: 'West Area',
                depth: 1,
                total_submissions: 944,
                children_division_scores: [
                  {
                    division_id: 13,
                    score: 32.446,
                    total_submissions: 290,
                    division_polygon_id: '634fbed03e7b2a65aaac6016',
                    division_polygon_name: 'Ealing',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 15,
                    score: 29.764,
                    total_submissions: 365,
                    division_polygon_id: '634fbed03e7b2a65aaac6018',
                    division_polygon_name: 'Hillingdon',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 16,
                    score: 33.215,
                    total_submissions: 289,
                    division_polygon_id: '634fbed03e7b2a65aaac6015',
                    division_polygon_name: 'Hounslow',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 43,
                score: 27.414,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f6',
                division_polygon_name: 'South East',
                depth: 1,
                total_submissions: 902,
                children_division_scores: [
                  {
                    division_id: 22,
                    score: 24.034,
                    total_submissions: 303,
                    division_polygon_id: '634fbed03e7b2a65aaac601e',
                    division_polygon_name: 'Lewisham',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 25,
                    score: 31.007,
                    total_submissions: 301,
                    division_polygon_id: '634fbed03e7b2a65aaac601f',
                    division_polygon_name: 'Greenwich',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 32,
                    score: 27.222,
                    total_submissions: 298,
                    division_polygon_id: '634fbed03e7b2a65aaac6020',
                    division_polygon_name: 'Bexley',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 35,
                score: 33.643,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f0',
                division_polygon_name: 'South West',
                depth: 1,
                total_submissions: 1103,
                children_division_scores: [
                  {
                    division_id: 10,
                    score: 34.83,
                    total_submissions: 299,
                    division_polygon_id: '634fbed03e7b2a65aaac604a',
                    division_polygon_name: 'Wandsworth',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 14,
                    score: 34.686,
                    total_submissions: 216,
                    division_polygon_id: '634fbed03e7b2a65aaac6012',
                    division_polygon_name: 'Kingston upon Thames',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 20,
                    score: 36.061,
                    total_submissions: 262,
                    division_polygon_id: '634fbed03e7b2a65aaac604b',
                    division_polygon_name: 'Merton',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 28,
                    score: 29.92,
                    total_submissions: 326,
                    division_polygon_id: '634fbed03e7b2a65aaac6025',
                    division_polygon_name: 'Richmond upon Thames',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 44,
                score: 38.113,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f8',
                division_polygon_name: 'North East',
                depth: 1,
                total_submissions: 683,
                children_division_scores: [
                  {
                    division_id: 18,
                    score: 43.357,
                    total_submissions: 339,
                    division_polygon_id: '634fbed03e7b2a65aaac603e',
                    division_polygon_name: 'Newham',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 33,
                    score: 32.945,
                    total_submissions: 344,
                    division_polygon_id: '634fbed03e7b2a65aaac6022',
                    division_polygon_name: 'Waltham Forest',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 45,
                score: 33.502,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f1',
                division_polygon_name: 'South Area',
                depth: 1,
                total_submissions: 1020,
                children_division_scores: [
                  {
                    division_id: 12,
                    score: 36.077,
                    total_submissions: 278,
                    division_polygon_id: '634fbed03e7b2a65aaac6013',
                    division_polygon_name: 'Croydon',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 23,
                    score: 29.524,
                    total_submissions: 370,
                    division_polygon_id: '634fbed03e7b2a65aaac6024',
                    division_polygon_name: 'Sutton',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 26,
                    score: 35.534,
                    total_submissions: 372,
                    division_polygon_id: '634fbed03e7b2a65aaac6014',
                    division_polygon_name: 'Bromley',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 46,
                score: 33.959,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f4',
                division_polygon_name: 'North West',
                depth: 1,
                total_submissions: 957,
                children_division_scores: [
                  {
                    division_id: 8,
                    score: 30.698,
                    total_submissions: 348,
                    division_polygon_id: '634fbed03e7b2a65aaac601b',
                    division_polygon_name: 'Barnet',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 11,
                    score: 37.369,
                    total_submissions: 335,
                    division_polygon_id: '634fbed03e7b2a65aaac601a',
                    division_polygon_name: 'Brent',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 19,
                    score: 33.932,
                    total_submissions: 274,
                    division_polygon_id: '634fbed03e7b2a65aaac6019',
                    division_polygon_name: 'Harrow',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      '4100': {
        question_text: 'When it comes to the threat of crime, how safe do you feel in your local area?',
        question_index: 1,
        division_scores: [
          {
            division_id: 1,
            score: 35.769,
            division_polygon_id: '634e5a8a95350a5d3321308f',
            division_polygon_name: 'London',
            depth: 0,
            total_submissions: 9860,
            children_division_scores: [
              {
                division_id: 47,
                score: 66.245,
                division_polygon_id: '634fbdf08cfb9d593dcfd9fc',
                division_polygon_name: 'City of London',
                depth: 1,
                total_submissions: 15,
                children_division_scores: [
                  {
                    division_id: 48,
                    score: 66.245,
                    total_submissions: 15,
                    division_polygon_id: '634fbed03e7b2a65aaac603a',
                    division_polygon_name: 'City of London',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 36,
                score: 33.319,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f7',
                division_polygon_name: 'North',
                depth: 1,
                total_submissions: 551,
                children_division_scores: [
                  {
                    division_id: 5,
                    score: 31.751,
                    total_submissions: 284,
                    division_polygon_id: '634fbed03e7b2a65aaac603f',
                    division_polygon_name: 'Haringey',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 31,
                    score: 34.987,
                    total_submissions: 267,
                    division_polygon_id: '634fbed03e7b2a65aaac6021',
                    division_polygon_name: 'Enfield',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 37,
                score: 32.492,
                division_polygon_id: '634fbdf08cfb9d593dcfd9fb',
                division_polygon_name: 'Central East',
                depth: 1,
                total_submissions: 674,
                children_division_scores: [
                  {
                    division_id: 7,
                    score: 35.916,
                    total_submissions: 418,
                    division_polygon_id: '634fbed03e7b2a65aaac6045',
                    division_polygon_name: 'Tower Hamlets',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 9,
                    score: 26.902,
                    total_submissions: 256,
                    division_polygon_id: '634fbed03e7b2a65aaac6040',
                    division_polygon_name: 'Hackney',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 38,
                score: 26.725,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f3',
                division_polygon_name: 'East Area',
                depth: 1,
                total_submissions: 960,
                children_division_scores: [
                  {
                    division_id: 2,
                    score: 29.467,
                    total_submissions: 359,
                    division_polygon_id: '634fbed03e7b2a65aaac6023',
                    division_polygon_name: 'Redbridge',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 29,
                    score: 27.513,
                    total_submissions: 281,
                    division_polygon_id: '634fbed03e7b2a65aaac6017',
                    division_polygon_name: 'Havering',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 30,
                    score: 22.957,
                    total_submissions: 320,
                    division_polygon_id: '634fbed03e7b2a65aaac603c',
                    division_polygon_name: 'Barking and Dagenham',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 39,
                score: 34.999,
                division_polygon_id: '634fbdf08cfb9d593dcfd9fa',
                division_polygon_name: 'Central North',
                depth: 1,
                total_submissions: 565,
                children_division_scores: [
                  {
                    division_id: 4,
                    score: 34.915,
                    total_submissions: 254,
                    division_polygon_id: '634fbed03e7b2a65aaac6044',
                    division_polygon_name: 'Islington',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 21,
                    score: 35.068,
                    total_submissions: 311,
                    division_polygon_id: '634fbed03e7b2a65aaac6046',
                    division_polygon_name: 'Camden',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 40,
                score: 40.304,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f9',
                division_polygon_name: 'Central West',
                depth: 1,
                total_submissions: 852,
                children_division_scores: [
                  {
                    division_id: 3,
                    score: 37.203,
                    total_submissions: 285,
                    division_polygon_id: '634fbed03e7b2a65aaac6049',
                    division_polygon_name: 'Hammersmith and Fulham',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 6,
                    score: 42.159,
                    total_submissions: 362,
                    division_polygon_id: '634fbed03e7b2a65aaac6047',
                    division_polygon_name: 'Westminster',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 17,
                    score: 41.34,
                    total_submissions: 205,
                    division_polygon_id: '634fbed03e7b2a65aaac6048',
                    division_polygon_name: 'Kensington and Chelsea',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 41,
                score: 37.775,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f5',
                division_polygon_name: 'Central South',
                depth: 1,
                total_submissions: 631,
                children_division_scores: [
                  {
                    division_id: 24,
                    score: 35.383,
                    total_submissions: 324,
                    division_polygon_id: '634fbed03e7b2a65aaac601d',
                    division_polygon_name: 'Southwark',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 27,
                    score: 40.299,
                    total_submissions: 307,
                    division_polygon_id: '634fbed03e7b2a65aaac601c',
                    division_polygon_name: 'Lambeth',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 42,
                score: 34.472,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f2',
                division_polygon_name: 'West Area',
                depth: 1,
                total_submissions: 942,
                children_division_scores: [
                  {
                    division_id: 13,
                    score: 33.774,
                    total_submissions: 290,
                    division_polygon_id: '634fbed03e7b2a65aaac6016',
                    division_polygon_name: 'Ealing',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 15,
                    score: 34.239,
                    total_submissions: 366,
                    division_polygon_id: '634fbed03e7b2a65aaac6018',
                    division_polygon_name: 'Hillingdon',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 16,
                    score: 35.477,
                    total_submissions: 286,
                    division_polygon_id: '634fbed03e7b2a65aaac6015',
                    division_polygon_name: 'Hounslow',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 43,
                score: 33.268,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f6',
                division_polygon_name: 'South East',
                depth: 1,
                total_submissions: 905,
                children_division_scores: [
                  {
                    division_id: 22,
                    score: 29.824,
                    total_submissions: 304,
                    division_polygon_id: '634fbed03e7b2a65aaac601e',
                    division_polygon_name: 'Lewisham',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 25,
                    score: 36.269,
                    total_submissions: 303,
                    division_polygon_id: '634fbed03e7b2a65aaac601f',
                    division_polygon_name: 'Greenwich',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 32,
                    score: 33.73,
                    total_submissions: 298,
                    division_polygon_id: '634fbed03e7b2a65aaac6020',
                    division_polygon_name: 'Bexley',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 35,
                score: 43.939,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f0',
                division_polygon_name: 'South West',
                depth: 1,
                total_submissions: 1110,
                children_division_scores: [
                  {
                    division_id: 10,
                    score: 40.667,
                    total_submissions: 301,
                    division_polygon_id: '634fbed03e7b2a65aaac604a',
                    division_polygon_name: 'Wandsworth',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 14,
                    score: 45.143,
                    total_submissions: 217,
                    division_polygon_id: '634fbed03e7b2a65aaac6012',
                    division_polygon_name: 'Kingston upon Thames',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 20,
                    score: 39.545,
                    total_submissions: 263,
                    division_polygon_id: '634fbed03e7b2a65aaac604b',
                    division_polygon_name: 'Merton',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 28,
                    score: 49.651,
                    total_submissions: 329,
                    division_polygon_id: '634fbed03e7b2a65aaac6025',
                    division_polygon_name: 'Richmond upon Thames',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 44,
                score: 30.638,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f8',
                division_polygon_name: 'North East',
                depth: 1,
                total_submissions: 682,
                children_division_scores: [
                  {
                    division_id: 18,
                    score: 31.019,
                    total_submissions: 336,
                    division_polygon_id: '634fbed03e7b2a65aaac603e',
                    division_polygon_name: 'Newham',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 33,
                    score: 30.268,
                    total_submissions: 346,
                    division_polygon_id: '634fbed03e7b2a65aaac6022',
                    division_polygon_name: 'Waltham Forest',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 45,
                score: 39.705,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f1',
                division_polygon_name: 'South Area',
                depth: 1,
                total_submissions: 1022,
                children_division_scores: [
                  {
                    division_id: 12,
                    score: 28.189,
                    total_submissions: 279,
                    division_polygon_id: '634fbed03e7b2a65aaac6013',
                    division_polygon_name: 'Croydon',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 23,
                    score: 42.751,
                    total_submissions: 374,
                    division_polygon_id: '634fbed03e7b2a65aaac6024',
                    division_polygon_name: 'Sutton',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 26,
                    score: 45.325,
                    total_submissions: 369,
                    division_polygon_id: '634fbed03e7b2a65aaac6014',
                    division_polygon_name: 'Bromley',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
              {
                division_id: 46,
                score: 36.805,
                division_polygon_id: '634fbdf08cfb9d593dcfd9f4',
                division_polygon_name: 'North West',
                depth: 1,
                total_submissions: 951,
                children_division_scores: [
                  {
                    division_id: 8,
                    score: 36.435,
                    total_submissions: 348,
                    division_polygon_id: '634fbed03e7b2a65aaac601b',
                    division_polygon_name: 'Barnet',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 11,
                    score: 37.258,
                    total_submissions: 332,
                    division_polygon_id: '634fbed03e7b2a65aaac601a',
                    division_polygon_name: 'Brent',
                    depth: 2,
                    children_division_scores: [],
                  },
                  {
                    division_id: 19,
                    score: 36.725,
                    total_submissions: 271,
                    division_polygon_id: '634fbed03e7b2a65aaac6019',
                    division_polygon_name: 'Harrow',
                    depth: 2,
                    children_division_scores: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
];
