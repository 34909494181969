/* eslint-disable max-lines */

import amplitude from 'amplitude-js';
import { AnalyticEventNames } from 'constants/analytics';
import { ProductNames } from 'constants/products';
import { User } from 'types/user';
import { config } from '../../../config/config';
import {
  AspectSelectedPayload,
  CrossTabAnalyzedPayload,
  CrossTabModeChangedPayload,
  CtaClickedPayload,
  DashboardTabSwitchedPayload,
  FeedScrolledPayload,
  FeedTopicSelectedPayload,
  FilterChangedPayload,
  MapLevelChangePayload,
  MapLevelSelectedPayload,
  QuestionSelectedPayload,
  TooltipViewedPayload,
  WidgetDisplayTabChangedPayload,
  WidgetExpandedPayload,
} from './analyticTypes';

const AMPLITUDE_ZENCITY_API_ENDPOINT = 'amplitude-api.zencity.io';

let amplitudeClient: amplitude.AmplitudeClient | null = null;
let isLogging = false;
let productName: ProductNames = 'No product name set' as ProductNames;

const init = (apiKey: string, user: User, product: ProductNames): void => {
  const allowedEnvironments = ['production', 'testing'];
  // Setting the product name here is a bit of a hack, but it's the easiest way to get the product name.
  productName = product;

  if (!apiKey || !allowedEnvironments.includes(config.environment)) {
    return;
  }

  amplitudeClient = amplitude.getInstance();
  amplitudeClient.init(apiKey, user.id, {
    apiEndpoint: AMPLITUDE_ZENCITY_API_ENDPOINT,
  });
  isLogging = true;
};

const clear = (): void => {
  // This is destructive, and should not be called during the app lifecycle
  amplitudeClient = null;
  isLogging = false;
};

const enrichPayload = (payload: Record<string, unknown>): Record<string, unknown> => {
  // We want to get the current path without the leading slash
  const currentRoute = window.location.pathname.slice(1);
  const enrichedPayload = { ...payload, productName, pageRoute: currentRoute };
  return enrichedPayload;
};

const baseLog = (eventName: string, params: Record<string, unknown> = {}) => {
  const paramsWithProduct = enrichPayload(params);
  if (isLogging && amplitudeClient) {
    amplitudeClient.logEvent(eventName, paramsWithProduct);
  } else if (config.simulateAmplitude) {
    // eslint-disable-next-line no-console
    console.log('[SIMULATE amplitudeClient]', eventName, paramsWithProduct);
  }
};

const screenViewedEvent = (newRoute: string, newUrl: string, prevRoute: string, autoRedirect: boolean): void => {
  baseLog('surveys-screen-viewed', {
    url: newUrl,
    referrer: prevRoute,
    autoRedirect,
    // These are sent for backwards compatibility (so it'll be possible to use
    // the new data along the existing data in Amplitude).
    newPageRoute: newRoute,
    newPageLocation: newUrl,
  });
};

const communitySurveyEvents = {
  surveyAddOnSectionClick: (name: string): void => {
    baseLog('community-survey-add-on-section-click', { name });
  },

  surveysDashboardTabSwitched: (changeType: string): void => {
    // changeType -> one of  'summary', 'feed', 'map', 'questions',
    baseLog('community-survey-dashboard-tab-switched', { changeType });
  },

  surveysAreasWidgetViewMapClick: (): void => {
    baseLog('community-survey-areas-widget-view-map-click');
  },

  surveysNegativeQuestionsWidgetViewAllClick: (): void => {
    baseLog('community-survey-negative-questions-widget-view-all-click');
  },

  surveysPositiveQuestionsWidgetViewAllClick: (): void => {
    baseLog('community-survey-positive-questions-widget-view-all-click');
  },

  surveysViewedWidgetTooltip: (): void => {
    baseLog('community-survey-viewed-widget-tooltip');
  },
};

const blockwiseEvents = {
  surveyFeedQuestionSelected: (questionId: number): void => {
    baseLog('blockwise-feed-question-selected', { questionId });
  },

  surveyAddOnSectionClick: (name: string): void => {
    baseLog('blockwise-add-on-section-click', { name });
  },

  surveyViewDiscourseClick: (): void => {
    baseLog('blockwise-view-discourse-click');
  },

  surveyInsightRequestInline: (): void => {
    baseLog('blockwise-insight-request-inline');
  },

  surveyRequestInline: (): void => {
    baseLog('blockwise-request-a-survey-inline');
  },

  surveyCreateProjectInline: (): void => {
    baseLog('blockwise-create-project-inline');
  },

  surveysDashboardTabSwitched: (changeType: string): void => {
    // changeType -> one of  'summary', 'feed', 'map', 'questions',
    baseLog('blockwise-dashboard-tab-switched', { changeType });
  },

  surveysWidgetExpanded: (widgetName: string, expanded: boolean): void => {
    // widgetName -> one of 'positive-demographics', 'negative-demographics', 'areas'
    baseLog('blockwise-widget-expanded', { widgetName, expanded });
  },

  surveysAreasWidgetViewMapClick: (): void => {
    baseLog('blockwise-areas-widget-view-map-click');
  },

  surveysNegativeQuestionsWidgetViewAllClick: (): void => {
    baseLog('blockwise-negative-questions-widget-view-all-click');
  },

  surveysPositiveQuestionsWidgetViewAllClick: (): void => {
    baseLog('blockwise-positive-questions-widget-view-all-click');
  },

  surveysViewedWidgetTooltip: (): void => {
    baseLog('blockwise-viewed-widget-tooltip');
  },

  surveysMapTabModalAreaClick: (changeType: string): void => {
    baseLog('blockwise-map-tab-modal-area-click', { changeType });
  },

  surveysMapTabAreaClick: (changeType: string): void => {
    baseLog('blockwise-map-tab-area-click', { changeType });
  },

  dateRangeFilterOptionSelect: (filterValue: string): void => {
    baseLog('blockwise-cycle-filter-selected', { filterValue });
  },

  dateRangeFilterToggled: (currentTab: string): void => {
    baseLog('blockwise-cycle-filter-clicked', { origin: currentTab });
  },

  comparisonDateRangeFilterOptionSelect: (filterValue: string): void => {
    baseLog('blockwise-comparison-cycle-filter-selected', { filterValue });
  },

  comparisonDateRangeFilterToggled: (currentTab: string): void => {
    baseLog('blockwise-comparison-cycle-filter-clicked', { origin: currentTab });
  },
};

const communityAsksEvents = {
  asksFeedQuestionSelected: (questionId: number, questionText: string): void => {
    baseLog('community-asks-feed-question-selected', { questionId, questionText });
  },

  requestCustomSentInitialState: (): void => {
    baseLog('community-asks-request-custom-sent-initial-state');
  },

  requestCustomSentActivatedState: (): void => {
    baseLog('community-asks-request-custom-sent-activated-state');
  },

  requestCustomClicked: (): void => {
    baseLog('community-asks-request-custom-clicked');
  },

  previewTemplateClicked: (templateTitle: string): void => {
    baseLog('community-asks-preview-template-clicked', { templateTitle });
  },

  requestTemplateClicked: (templateTitle: string): void => {
    baseLog('community-asks-request-template-clicked', { templateTitle });
  },

  helpCenterClicked: (): void => {
    baseLog('community-asks-help-center-clicked');
  },

  openInFeedButtonClicked: (): void => {
    baseLog('community-asks-open-feed-clicked');
  },

  tabSwitched: (changeType: string): void => {
    // changeType -> one of  'summary', 'feed', 'report',
    baseLog('community-asks-tab-switched', { changeType });
  },

  /**
   * @param routingTo - Known routing values: `workspace`/`management`.
   */
  managementPageLinkClicked: (routingTo: string): void => {
    baseLog(`community-asks-management-page-link-to-${routingTo}-clicked`);
  },

  managementPageAskPublished: (askTitle: string, askId: number): void => {
    baseLog('community-asks-management-page-ask-published', { askTitle, askId });
  },

  managementPageAskClosed: (askTitle: string, askId: number): void => {
    baseLog('community-asks-management-page-ask-closed', { askTitle, askId });
  },

  resultsExport: (): void => {
    baseLog('community-asks-results-export');
  },

  viewAskPage: (askTitle: string): void => {
    baseLog('community-asks-view-ask-page', { askTitle });
  },

  copyAskLink: (): void => {
    baseLog('community-asks-copy-link');
  },

  askLinkAccessed: (): void => {
    baseLog('community-asks-accessed-link');
  },

  uploadReportButtonClicked: (askTitle: string, askId: number): void => {
    baseLog('community-asks-upload-report-button-clicked', { askTitle, askId });
  },
};

const customerExperienceEvents = {
  openInFeedButtonClicked: (): void => {
    baseLog('customer-experience-open-feed-clicked');
  },
  resultsExport: (): void => {
    baseLog('customer-experience-results-export');
  },
  viewAskPage: (askTitle: string): void => {
    baseLog('customer-experience-view-ask-page', { askTitle });
  },
};

const crossProductEvents = {
  mapLevelChanges: (payload: MapLevelChangePayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_MAP_AREA_LEVEL_CHANGED, { ...payload });
  },
  mapLevelSelected: (payload: MapLevelSelectedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_MAP_AREA_SELECTED, { ...payload });
  },
  questionOrAspectSelected: (payload: QuestionSelectedPayload | AspectSelectedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_NEW_QUESTION_OR_ASPECT_SELECTED, { ...payload });
  },
  dashboardTabSwitched: (payload: DashboardTabSwitchedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_DASHBOARD_TAB_SWITCHED, { ...payload });
  },
  ctaClicked: (payload: CtaClickedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_CTA_CLICKED, { ...payload });
  },
  crossTabModeChanged: (payload: CrossTabModeChangedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_CROSS_TAB_MODE_CHANGED, { ...payload });
  },
  surveysCrossTabAnalyzed: (payload: CrossTabAnalyzedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_CROSS_TAB_ANALYZED, { ...payload });
  },
  tooltipViewed: (payload: TooltipViewedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_TOOLTIP_VIEWED, { ...payload });
  },
  widgetExpanded: (payload: WidgetExpandedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_WIDGET_EXPANDED, { ...payload });
  },
  widgetDisplayTabChanged: (payload: WidgetDisplayTabChangedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_WIDGET_FILTER_CHANGED, { ...payload });
  },
  filterChanged: (payload: FilterChangedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_FILTER_CHANGED, { ...payload });
  },
  feedScrolled: (payload: FeedScrolledPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_FEED_SCROLLED, { ...payload });
  },
  feedTopicSelected: (payload: FeedTopicSelectedPayload): void => {
    baseLog(AnalyticEventNames.SURVEYS_FEED_TOPIC_SELECTED, { ...payload });
  },
};

export const analyticsService = {
  init,
  clear,
  screenViewedEvent,
  blockwiseEvents,
  communitySurveyEvents,
  communityAsksEvents,
  customerExperienceEvents,
  crossProductEvents,
};
