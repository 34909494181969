import { AspectValue } from 'types/aspect';
import { GenericQuestionScore } from 'types/score';

export const mockGenericQuestionScores: GenericQuestionScore[] = [
  {
    genericQuestionId: 6,
    aspectValue: AspectValue.CHARACTERISTIC,
    text: 'How likely are you to recommend Springfield as a place to live?',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-01-31',
        scores: {
          positive: 90,
          neutral: 5,
          negative: 5,
        },
        totalSubmissions: 42,
      },
    ],
  },
  {
    genericQuestionId: 5,
    aspectValue: AspectValue.QUALITY_OF_LIFE,
    text: 'How is the quality of life in Springfield?',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-01-31',
        scores: {
          positive: 10,
          neutral: 40,
          negative: 50,
        },
        totalSubmissions: 42,
      },
    ],
  },
  {
    genericQuestionId: 4,
    aspectValue: AspectValue.CHARACTERISTIC,
    text: 'How likely are you to be living in Springfield in 5 years from now?',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-01-31',
        scores: {
          positive: 15,
          neutral: 50,
          negative: 35,
        },
        totalSubmissions: 42,
      },
    ],
  },
  {
    genericQuestionId: 3,
    aspectValue: AspectValue.QUALITY_OF_LIFE,
    text: 'Do you feel that Springfield makes spending choices according to community priorities?',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-01-31',
        scores: {
          positive: 60,
          neutral: 30,
          negative: 10,
        },
        totalSubmissions: 42,
      },
    ],
  },
  {
    genericQuestionId: 2,
    aspectValue: AspectValue.DEMOGRAPHICS,
    text: 'I can easily access police officers in my community if I have any questions or problems.',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-01-31',
        scores: {
          positive: 50,
          neutral: 30,
          negative: 20,
        },
        totalSubmissions: 42,
      },
    ],
  },
  {
    genericQuestionId: 1,
    aspectValue: AspectValue.GENERAL,
    text: 'Do you feel safe in parks and open spaces?',
    scores: [
      {
        startDate: '2020-01-01',
        endDate: '2020-01-31',
        scores: {
          positive: 75,
          neutral: 20,
          negative: 5,
        },
        totalSubmissions: 42,
      },
    ],
  },
];
