import { SurveyRequestStatus } from '@zencity/survey-types';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SurveyRequestStatusBadge.module.scss';

export interface Props {
  status: SurveyRequestStatus;
}

/**
 * Display a styled status badge.
 *
 * @props status - The status to display.
 */
export const SurveyRequestStatusBadge: React.FC<Props> = function SurveyRequestItem({ status }: Props): ReactElement {
  const { t: translate } = useTranslation();

  return (
    <div className={classnames(styles.surveyRequestStatusBadge, styles[status])}>
      {translate(`communityAsks.surveyRequests.status.${status}`)}
    </div>
  );
};
