import { ZCDFilter, ZCDSpinner } from '@zencity/common-ui';
import { AnalyticEventsChangeTypes, AnalyticFilterNames } from 'constants/analytics';

import { TabKeys } from 'constants/tabs';
import { DateRangeContext, DateRangeFilterOption } from 'contexts/DateRangeContext';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { analyticsService } from 'services/analytics/analytics';
import { SelectOption } from 'types/selectOption';
import { isScoreLatestResult } from 'utils/dateRange';
import { generateDemographicsFilterValue, getCycleNumber } from 'utils/utils';

type Option = SelectOption & {
  disabled?: boolean;
};

/**
 * A component to select different community survey cadence date ranges to compare.
 */
// eslint-disable-next-line max-lines-per-function, max-statements
export const DateRangeCompareFilter = (): React.ReactElement => {
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const {
    completedCyclesDateRanges,
    selectedDateRange,
    dashboardDateRanges,
    isContextLoaded,
    comparisonDateRange,
    setComparisonDateRange,
  } = useContext(DateRangeContext);
  // disable selection in case there is no more than one completed cycles or the user is on the feed tab.
  const disabledSelection =
    (completedCyclesDateRanges && completedCyclesDateRanges?.length < 2) || location.pathname.includes(TabKeys.FEED);

  const dashboardDateRangesOptions = useMemo(
    () =>
      dashboardDateRanges?.map((element, index) => {
        if (!selectedDateRange) return element;

        const penultimateIndex = 2;
        const penultimateCycle = dashboardDateRanges?.[penultimateIndex];
        const lastCycleSelectedInFilter = penultimateCycle?.key === selectedDateRange?.key;
        const disableElement =
          selectedDateRange?.key === element?.key ||
          (isScoreLatestResult(selectedDateRange) && index === penultimateIndex) ||
          (lastCycleSelectedInFilter && isScoreLatestResult(selectedDateRange));

        if (disableElement) {
          return { ...element, disabled: true };
        }

        return element;
      }) || [],
    [dashboardDateRanges, selectedDateRange],
  );

  const formattedDashboardDateRangesOptions = dashboardDateRangesOptions.map(
    (element) => ({ value: element?.key, label: element?.label, disabled: element?.disabled ?? false } as Option),
  );

  const comparisonDateRangeFilterValue = formattedDashboardDateRangesOptions.find(
    (dateRange) => dateRange.value === comparisonDateRange?.key,
  );

  const handleOnDateRangeSelection = (newSelectedOption: SelectOption) => {
    const selectDateRange = dashboardDateRangesOptions.find(
      (dateRange) => dateRange?.key === newSelectedOption.value,
    ) as DateRangeFilterOption | undefined;
    setComparisonDateRange(selectDateRange);

    analyticsService.crossProductEvents.filterChanged({
      filterName: AnalyticFilterNames.COMPARISON,
      filterValue: generateDemographicsFilterValue(selectDateRange),
      cycleNumber: getCycleNumber(selectedDateRange, completedCyclesDateRanges),
      ageValue: AnalyticEventsChangeTypes.IRRELEVANT,
      areaValue: AnalyticEventsChangeTypes.IRRELEVANT,
      ethnicityValue: AnalyticEventsChangeTypes.IRRELEVANT,
      genderValue: AnalyticEventsChangeTypes.IRRELEVANT,
    });
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1px',
    paddingBottom: '8px',
    fontWeight: 400,
    color: '#6D6D6D',
  };

  const formatGroupLabel = (option: Option) => (
    <div style={groupStyles}>
      <span>{option.label}</span>
    </div>
  );

  useEffect(() => {
    setIsLoading(!isContextLoaded);
  }, [isContextLoaded]);
  const groupedOptions = formattedDashboardDateRangesOptions.length
    ? [
        formattedDashboardDateRangesOptions[0],
        {
          label: formattedDashboardDateRangesOptions[1]?.label,
          options: formattedDashboardDateRangesOptions.slice(2),
        },
      ]
    : [];

  return (
    <div>
      {isLoading && <ZCDSpinner />}
      {!isLoading && (
        <ZCDFilter<Option>
          key={comparisonDateRangeFilterValue?.value}
          value={comparisonDateRangeFilterValue}
          defaultValue={comparisonDateRangeFilterValue}
          onChange={(newValue) => handleOnDateRangeSelection(newValue as SelectOption)}
          options={groupedOptions}
          label={translate('header.compareDateFilter.placeholderLabel')}
          selectedValueLabel={translate('header.compareDateFilter.valueLabel')}
          isDisabled={disabledSelection}
          // the necessary type is not exported from common ui currently
          formatGroupLabel={formatGroupLabel as any}
          isOptionDisabled={(option) => !!(option as Option).disabled}
        />
      )}
    </div>
  );
};
