import { mockClassificationSubTopics } from 'mocks/data/classificationSubTopic';
import { LeadingTopic } from 'types/leadingTopics';

export const mockLeadingTopics: LeadingTopic[] = mockClassificationSubTopics.map((classificationSubTopic, index) => {
  return {
    classificationTopicId: classificationSubTopic.classification_topic.id,
    classificationTopicValue: classificationSubTopic.classification_topic.value,
    classificationSubTopicId: classificationSubTopic.id,
    classificationSubTopicValue: classificationSubTopic.value,
    responses: (index + 1) * 100,
  };
});
